import { Entity } from '.'
import { EntityTypeMeta } from '../EntityTypeMeta'
import { RoadKind } from '../RoadKind.enum'
import { createAPCEntity } from './EntityType/APC'
import { createBuildingEntity } from './EntityType/Building'
import { createDesertEntity } from './EntityType/Desert'
import { createFactoryEntity } from './EntityType/Factory'
import { createForestEntity } from './EntityType/Forest'
import { createHQEntity } from './EntityType/HQ'
import { createInfantryEntity } from './EntityType/Infantry'
import { createLightReconEntity } from './EntityType/LightRecon'
import { createLightTankEntity } from './EntityType/LightTank'
import { createMediumArtillaryEntity } from './EntityType/MediumArtillary'
import { createMediumCopterEntity } from './EntityType/MediumCopter'
import { createMediumGunAntiAirEntity } from './EntityType/MediumGunAntiAir'
import { createMediumMissileAntiAirEntity } from './EntityType/MediumMissileAntiAir'
import { createMediumRocketsEntity } from './EntityType/MediumRockets'
import { createMediumTankEntity } from './EntityType/MediumTank'
import { createMountainEntity } from './EntityType/Mountain'
import { createPlainEntity } from './EntityType/Plain'
import { createRiverEntity } from './EntityType/River'
import { createRoadEntity } from './EntityType/Road'
import { createSeaEntity } from './EntityType/Sea'
import { createShoalEntity } from './EntityType/Shoal'
import { createSnowEntity } from './EntityType/Snow'
import { createTransportCopterEntity } from './EntityType/TransportCopter'
import { EntityTypeId } from './EntityTypeId.enum'

export default function createSampleEntity(
  entityMeta: EntityTypeMeta,
  player_id: number | null,
  id: number = 1
): Entity {
  const entityTypeId: EntityTypeId = entityMeta.id
  // console.log('createSampleEntity', entityMeta.id, player_id, id)
  if (entityTypeId === EntityTypeId.Plain) {
    return createPlainEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Snow) {
    return createSnowEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Desert) {
    return createDesertEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Sea) {
    return createSeaEntity(id, 0, 0)
    // } else if (entityTypeId === EntityTypeId.Barren) {
    //   return createBarrenEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Forest) {
    return createForestEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Mountain) {
    return createMountainEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Road) {
    return createRoadEntity(id, 0, 0, RoadKind.Straight)
  } else if (entityTypeId === EntityTypeId.River) {
    return createRiverEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Shoal) {
    return createShoalEntity(id, 0, 0)
  } else if (entityTypeId === EntityTypeId.Building) {
    return createBuildingEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.Factory) {
    return createFactoryEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.HQ) {
    return createHQEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.Infantry) {
    return createInfantryEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.LightRecon) {
    return createLightReconEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.APC) {
    return createAPCEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.LightTank) {
    return createLightTankEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumTank) {
    return createMediumTankEntity(id, 0, 0, player_id)
    // } else if (entityTypeId === EntityTypeId.HeavyTank) {
    //   return createHeavyTankEntity(id, 0, 0, player_id)

    // } else if (entityTypeId === EntityTypeId.LightArtillary) {
    //   return createLightArtillaryEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumArtillary) {
    return createMediumArtillaryEntity(id, 0, 0, player_id)
    // } else if (entityTypeId === EntityTypeId.HeavyArtillary) {
    //   return createHeavyArtillaryEntity(id, 0, 0, player_id)

    // } else if (entityTypeId === EntityTypeId.LightRockets) {
    //   return createLightRocketsEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumRockets) {
    return createMediumRocketsEntity(id, 0, 0, player_id)
    // } else if (entityTypeId === EntityTypeId.HeavyRockets) {
    //   return createHeavyRocketsEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumGunAntiAir) {
    return createMediumGunAntiAirEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumMissileAntiAir) {
    return createMediumMissileAntiAirEntity(id, 0, 0, player_id)
    // } else if (entityTypeId === EntityTypeId.HeavyAntiAir) {
    //   return createHeavyAntiAirEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.TransportCopter) {
    return createTransportCopterEntity(id, 0, 0, player_id)

    // } else if (entityTypeId === EntityTypeId.LightCopter) {
    //   return createLightCopterEntity(id, 0, 0, player_id)
  } else if (entityTypeId === EntityTypeId.MediumCopter) {
    return createMediumCopterEntity(id, 0, 0, player_id)
    // } else if (entityTypeId === EntityTypeId.HeavyCopter) {
    //   return createHeavyCopterEntity(id, 0, 0, player_id)
  }
  throw new Error(`unknown entity type: ${entityMeta.id}`)
}
