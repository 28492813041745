import { DEV } from 'solid-js'
import { PlaneGeometry } from 'three'
import { mergeGeometries, mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { createSharedEntityXYGridMapMemo } from '../../../../rx/memo/createSharedEntityXYGridMapMemo'
import type { Engine } from '../../../core/engine/Engine.type'
import type { RiverEntity } from '../../../core/entity/EntityType/River'
import { DIRECTION_DXDY_DOWN, DIRECTION_DXDY_LEFT, DIRECTION_DXDY_RIGHT, DIRECTION_DXDY_UP } from '../../../core/tile_position_xy/dijkstraDirections'
import { abs, max, min } from '../../../core/util/math'
import Unexpected from '../../../Exception/Unexpected.class'
import identity from '../../../ldsh/identity'
import { VERTICES_PER_TILE } from '../../consts'
import setGeometryPositionAttributeValues from '../../fn/setGeometryPositionAttributeValues'
import getShoalNeighborType from '../Shoal/getShoalNeighborType'
import { ShoalNeighborType } from '../Shoal/ShoalNeighborType.enum'

function isSeaOrShoal (stype: ShoalNeighborType) {
  return stype === ShoalNeighborType.Sea || stype === ShoalNeighborType.Shoal
}

// a regular plane will have all it's triangles
// pointing diagonally in the same direction
export default function createRiverTerrainGeometry (
  engine : Engine,
  riverEnts : Array<RiverEntity>,
) {
  console.log('createRiverTerrainGeometry')

  if (DEV) {
    if (riverEnts.length === 0) {
      throw new Unexpected('riverEnts.length === 0')
    }
  }
  const getEntityXYGridMap = createSharedEntityXYGridMapMemo(engine)
  const entityXYGridMap = getEntityXYGridMap()

  const size = VERTICES_PER_TILE
  const segments = 9

  // return new PlaneGeometry(size, size, segments, segments)

  // console.log('riverEnts', deepClone(riverEnts))

  const depth = 2

  const t1 = 10
  const t2 = 14

  const geometryList = riverEnts.map((ent : RiverEntity) => {
    const geometry = new PlaneGeometry(size, size, segments, segments)

    const northType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_UP, ent)
    const southType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_DOWN, ent)
    const westType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_LEFT, ent)
    const eastType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_RIGHT, ent)

    const isNorthWater = isSeaOrShoal(northType)
    const isSouthWater = isSeaOrShoal(southType)
    const isEastWater = isSeaOrShoal(eastType)
    const isWestWater = isSeaOrShoal(westType)

    const waterNeighborsCount = [isNorthWater, isSouthWater, isEastWater, isWestWater].filter(identity).length

    setGeometryPositionAttributeValues(geometry, (va): void => {
      // console.log(deepClone(va))
      const { x, y, z } = va

      const absX = abs(x)
      const absY = abs(y)
      // const absZ = abs(z)
      const maxAbsXZ = max(absX, absY)

      if (waterNeighborsCount < 2 && maxAbsXZ < t2) {
        // if one or zero water neighbors, then spring/lake
        va.z = min(z, -depth)
      } else if (maxAbsXZ < t1) {
        va.z = min(z, -depth)
      } else if (isSouthWater && y > t1 && absX < t1) {
        va.z = min(z, -depth)
      } else if (isNorthWater && y < -t1 && absX < t1) {
        va.z = min(z, -depth)
      } else if (isEastWater && x > t1 && absY < t1) {
        va.z = min(z, -depth)
      } else if (isWestWater && x < -t1 && absY < t1) {
        va.z = min(z, -depth)
      }
    })

    geometry.translate(
      ent.x * VERTICES_PER_TILE,
      ent.y * -VERTICES_PER_TILE, 0)
    // setObjPosition(geometry, ent)
    return geometry
  })

  const geometry = mergeGeometries(geometryList, false)
  mergeVertices(geometry)

  return geometry
}
