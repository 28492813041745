import type { Nullable } from "../../typescript"

type HasLoseContext = {
  loseContext(): void
}
type HasExtension = {
  getExtension(name: string): Nullable<HasLoseContext>
}

export function loseWebGL2Context(elem: Nullable<HTMLCanvasElement | OffscreenCanvas>): void {
  if (elem) {
    const ctx = elem.getContext('webgl2')
    if (ctx) {
      const extension = (ctx as HasExtension).getExtension('WEBGL_lose_context')
      if (extension) {
        extension.loseContext()
      }
    }
  }
}
