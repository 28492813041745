import { Entity } from '../entity'
import { createAPCEntity } from '../entity/EntityType/APC'
import { createBuildingEntity } from '../entity/EntityType/Building'
import { createDesertEntity } from '../entity/EntityType/Desert'
import { createFactoryEntity } from '../entity/EntityType/Factory'
import { createForestEntity } from '../entity/EntityType/Forest'
import { createHQEntity } from '../entity/EntityType/HQ'
import { createInfantryEntity } from '../entity/EntityType/Infantry'
import { createLightReconEntity } from '../entity/EntityType/LightRecon'
import { createLightTankEntity } from '../entity/EntityType/LightTank'
import { createMediumArtillaryEntity } from '../entity/EntityType/MediumArtillary'
import { createMediumCopterEntity } from '../entity/EntityType/MediumCopter'
import { createMediumGunAntiAirEntity } from '../entity/EntityType/MediumGunAntiAir'
import { createMediumMissileAntiAirEntity } from '../entity/EntityType/MediumMissileAntiAir'
import { createMediumRocketsEntity } from '../entity/EntityType/MediumRockets'
import { createMediumTankEntity } from '../entity/EntityType/MediumTank'
import { createMountainEntity } from '../entity/EntityType/Mountain'
import { createPlainEntity } from '../entity/EntityType/Plain'
import { createRiverEntity } from '../entity/EntityType/River'
import { createRoadEntity } from '../entity/EntityType/Road'
import { createSeaEntity } from '../entity/EntityType/Sea'
import { createShoalEntity } from '../entity/EntityType/Shoal'
import { createSnowEntity } from '../entity/EntityType/Snow'
import { createTransportCopterEntity } from '../entity/EntityType/TransportCopter'
import { RoadKind } from '../RoadKind.enum'
import { PaintTileOption } from './paint_tile_option'
import { PaintTileOptionFolderId as FolderId } from './paint_tile_option_folder_id.enum'

export function defaultPaintTileOption(): PaintTileOption {
  return paintTileOptionList[1]
}

export const paintTileOptionList: Array<PaintTileOption> = [
  { folder_id: FolderId.None, dname: 'None' },
  c1(FolderId.T1, 'Plain', createPlainEntity(1, 0, 0)),
  c1(FolderId.T1, 'Desert', createDesertEntity(1, 0, 0)),
  c1(FolderId.T1, 'Snow', createSnowEntity(1, 0, 0)),

  c1(FolderId.T2, 'Forest', createForestEntity(1, 0, 0)),
  c1(FolderId.T2, 'Mountain', createMountainEntity(1, 0, 0)),
  // c1(FolderId.T2, 'Barren', createBarrenEntity(1, 0, 0)),

  c1(FolderId.Road, 'Road (Straight)', createRoadEntity(1, 0, 0, RoadKind.Straight)),
  c1(FolderId.Road, 'Road (Corner)', createRoadEntity(1, 0, 0, RoadKind.Corner)),
  c1(FolderId.Road, 'Road (Three Way)', createRoadEntity(1, 0, 0, RoadKind.ThreeWay)),
  c1(FolderId.Road, 'Road (Four Way)', createRoadEntity(1, 0, 0, RoadKind.FourWay)),

  c1(FolderId.Water, 'River', createRiverEntity(1, 0, 0)),
  c1(FolderId.Water, 'Sea', createSeaEntity(1, 0, 0)),
  c1(FolderId.Water, 'Shoal', createShoalEntity(1, 0, 0)),

  c1(FolderId.Building, 'Building', createBuildingEntity(1, 0, 0, null)),
  c1(FolderId.Building, 'Factory', createFactoryEntity(1, 0, 0, null)),
  c1(FolderId.Building, 'HQ', createHQEntity(1, 0, 0, null)),

  c1(FolderId.Unit, 'Infantry', createInfantryEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Recon', createLightReconEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'APC', createAPCEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Light Tank', createLightTankEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Medium Tank', createMediumTankEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Heavy Tank', createHeavyTankEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Light Artillary', createLightArtillaryEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Medium Artillary', createMediumArtillaryEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Heavy Artillary', createHeavyArtillaryEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Light Rockets', createLightRocketsEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Medium Rockets', createMediumRocketsEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Heavy Rockets', createHeavyRocketsEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Light Anti-Air', createMediumGunAntiAirEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Medium Anti-Air', createMediumMissileAntiAirEntity(1, 0, 0, null)),
  // c1(FolderId.Unit, 'Heavy Anti-Air', createHeavyAntiAirEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Transport Copter', createTransportCopterEntity(1, 0, 0, null)),
  c1(FolderId.Unit, 'Medium Copter', createMediumCopterEntity(1, 0, 0, null)),
]

function c1(folder_id: FolderId, dname: string, entity: Entity): PaintTileOption {
  return {
    folder_id,
    dname,
    entity,
    assign_selected_player: 'player_id' in entity,
  }
}
