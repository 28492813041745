import { DEV } from 'solid-js'
import { CatmullRomCurve3, EllipseCurve, Vector3, type BufferGeometry } from 'three'
import { mergeGeometries, mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import type { Nullable } from '../../../../typescript'
import type { EntityId } from '../../../core/entity/EntityId.type'
import type { MountainEntity } from '../../../core/entity/EntityType/Mountain'
import { next, nextNumberRange } from '../../../core/rng/index'
import tmpRngWith from '../../../core/rng/tmpRngWith'
import coord from '../../../core/tile_position_xy/coord'
import { dijkstraDirections, DIRECTION_DXDY_DOWN, DIRECTION_DXDY_DOWN_LEFT, DIRECTION_DXDY_DOWN_RIGHT, DIRECTION_DXDY_LEFT, DIRECTION_DXDY_RIGHT, DIRECTION_DXDY_UP, DIRECTION_DXDY_UP_LEFT, DIRECTION_DXDY_UP_RIGHT } from '../../../core/tile_position_xy/dijkstraDirections'
import getNeighborTilePosition from '../../../core/tile_position_xy/getNeighborTilePosition'
import type { TilePositionXY } from '../../../core/tile_position_xy/TilePositionXY.type'
import toCoord from '../../../core/tile_position_xy/toCoord'
import { abs, max, min } from '../../../core/util/math'
import createDefaultWait from '../../../createDefaultWait'
import Unexpected from '../../../Exception/Unexpected.class'
import { VERTICES_PER_TILE } from '../../consts'
import createInwardPointedPlaneGeometry from '../../fn/createInwardPointedPlaneGeometry'
import setGeometryZValuesV3 from '../../fn/setGeometryZValuesV3'
import truncateTrianglesBelowZ from '../../fn/truncateTrianglesBelowZ'

const terrainMinZ = -2

export default async function createMountainTerrainGeometry (
  // engine : Engine,
  mountainEnts : Array<MountainEntity>,
) {
  // console.log('createMountainTerrainGeometry', performance.now())

  if (DEV) {
    if (mountainEnts.length === 0) {
      throw new Unexpected('mountainEnts.length === 0')
    }
  }

  // const getEntityXYGridMap = createSharedEntityXYGridMapMemo(engine)
  // const entityXYGridMap = getEntityXYGridMap()

  const size = VERTICES_PER_TILE / 2
  const segments = 7
  const tileRadius = size

  const basePeakHeight = 18

  const mountainMap : Map<TilePositionXY, MountainEntity> = new Map()
  const tpSet : Set<TilePositionXY> = new Set()
  // console.log('createMountainTerrainGeometry46', performance.now())

  for (const ent of mountainEnts) {
    const tp = toCoord(ent)
    mountainMap.set(tp, ent)
    tpSet.add(tp)
    for (const dir of dijkstraDirections) {
      const tp2 = coord(tp.x + dir.dx, tp.y + dir.dy)
      tpSet.add(tp2)
    }
  }

  const geometryList : Array<BufferGeometry> = []
  // console.log('createMountainTerrainGeometry58', performance.now())

  await createDefaultWait(1)

  let c = 0
  for (const tp of tpSet) {
    // const noise2D: (x: number, y: number) => number = createPerlinNoise2D(tmpRngWith(ent.id))

    // const geometry = createSingleMountainGeometry(engine, ent)
    const geometry = createInwardPointedPlaneGeometry(size, size, segments, segments)

    // geometry.rotateZ(PI/4)

    geometry.translate(
      tp.x * VERTICES_PER_TILE,
      tp.y * -VERTICES_PER_TILE, 0)

    geometryList.push(geometry)

    if (c++ === 4) {
      await createDefaultWait(1)
      c = 0
    }
  }

  await createDefaultWait(1)

  // console.log('mergeGeometries before', performance.now())
  const g1 = mergeGeometries(geometryList, false)
  // console.log('mergeGeometries after', performance.now())
  await createDefaultWait(1)
  const geometry = mergeVertices(g1)
  // console.log('mergeVertices after', performance.now())
  await createDefaultWait(1)

  const distances : Array<number> = []

  // const zRange = new Set<number>()

  const mainPeaks : Array<PeakMeta> = []

  for (const [tp, ent] of mountainMap) {
    const northTp = getNeighborTilePosition(ent, DIRECTION_DXDY_UP)
    const southTp = getNeighborTilePosition(ent, DIRECTION_DXDY_DOWN)
    const eastTp = getNeighborTilePosition(ent, DIRECTION_DXDY_RIGHT)
    const westTp = getNeighborTilePosition(ent, DIRECTION_DXDY_LEFT)
    const northWestTp = getNeighborTilePosition(ent, DIRECTION_DXDY_UP_LEFT)
    const northEastTp = getNeighborTilePosition(ent, DIRECTION_DXDY_UP_RIGHT)
    const southEastTp = getNeighborTilePosition(ent, DIRECTION_DXDY_DOWN_RIGHT)
    const southWestTp = getNeighborTilePosition(ent, DIRECTION_DXDY_DOWN_LEFT)

    const northEnt = mountainMap.get(northTp)
    const southEnt = mountainMap.get(southTp)
    const eastEnt = mountainMap.get(eastTp)
    const westEnt = mountainMap.get(westTp)
    const northWestEnt = mountainMap.get(northWestTp)
    const northEastEnt = mountainMap.get(northEastTp)
    const southEastEnt = mountainMap.get(southEastTp)
    const southWestEnt = mountainMap.get(southWestTp)

    const neighbors : Array<Nullable<MountainEntity>> = [
      northEnt,
      southEnt,
      eastEnt,
      westEnt,
      northWestEnt,
      northEastEnt,
      southEastEnt,
      southWestEnt,
    ]

    const filteredNeighbors = neighbors.filter(Boolean) as Array<MountainEntity>

    const rng = tmpRngWith(filteredNeighbors.reduce((acc, ent): number => acc + ent.id, 0))

    const filteredNeighborsLen = filteredNeighbors.length

    const sumDistance : number = filteredNeighborsLen > 1 ? (neighbors.reduce((acc: number, neighbor): number => {
      if (neighbor) {
        const distance = (((neighbor.x - ent.x)**2) + ((neighbor.y - ent.y)**2)) ** 0.25
        acc += distance
      }
      return acc
    }, 0) / 3) : 0

    const sumDistanceSquared = sumDistance ** 2

    if (filteredNeighborsLen > 1) {
      distances.push(sumDistance)
    }

    // mountain tile center
    const cx0 = tp.x * VERTICES_PER_TILE
    const cy0 = tp.y * -VERTICES_PER_TILE

    // I need a guaranteed positive number
    const sumDistanceSquaredPlusNoise = sumDistanceSquared + ((next(rng)+0.001) ** 3)

    // main peak center (noised)
    const cx1 = cx0 + (next(rng) ** 2) * (sumDistanceSquaredPlusNoise)
    const cy1 = cy0 + (next(rng) ** 2) * (sumDistanceSquaredPlusNoise)

    // main peak radius
    const pr = tileRadius * ((next(rng) ** 2) + 0.5)
    const pr3 = pr * 3

    // console.log({cx0, cy0, cx1, cy1, pr})

    const peakHeight = basePeakHeight + (sumDistanceSquared * next(rng))

    const peak : PeakMeta = {
      id: ent.id,
      p: new Vector3(cx1, cy1, peakHeight),
      r: pr3,
    }
    mainPeaks.push(peak)
    // addPeak(geometry, radius, tp.x * VERTICES_PER_TILE, tp.y * -VERTICES_PER_TILE)
  }
  // console.log('createMountainTerrainGeometry162', performance.now())

  const allPeaks = [...mainPeaks]

  for (let i0 = 0; i0 < mainPeaks.length; i0++) {
    const peak0 = mainPeaks[i0]
    const { p: p0, id } = peak0

    const rng = tmpRngWith(id)

    const ac = new EllipseCurve(p0.x, p0.y, 12, 12)
    let t = nextNumberRange(0.1, 0.35, rng)

    while (t < 1) {
      const v2 = ac.getPoint(t)
      const p = new Vector3(v2.x, v2.y, p0.z)
      p.z *= nextNumberRange(0.1, 0.6, rng)
      const peak : PeakMeta = {
        id: id + t,
        p,
        r: peak0.r * nextNumberRange(0.6, 0.9, rng),
      }
      allPeaks.push(peak)
      t += nextNumberRange(0.04, 0.14, rng)
    }

    for (let i1 = i0 + 1; i1 < mainPeaks.length; i1++) {
      const peak1 = mainPeaks[i1]
      const distance = peak0.p.distanceTo(peak1.p)
      // console.log('distance', distance)
      if (distance < 60) {
        const id = peak0.id + peak1.id
        const rng = tmpRngWith(id)
        // const peaksToAdd = floor(next(rng) * distance)

        const midpoint = peak0.p.clone().lerp(peak1.p, nextNumberRange(0.3, 0.7, rng))
        midpoint.x += nextNumberRange(-4, 4, rng)
        midpoint.y += nextNumberRange(-4, 4, rng)
        midpoint.z *= nextNumberRange(0.6, 0.9, rng)

        const lc = new CatmullRomCurve3([
          peak0.p,
          midpoint,
          peak1.p,
        ])

        let t = nextNumberRange(0.1, 0.3, rng)
        while (t < 1) {
          const p = lc.getPoint(t)
          p.z *= nextNumberRange(0.8, 1, rng)
          const peak : PeakMeta = {
            id: -id,
            p,
            r: min(peak0.r, peak1.r) * nextNumberRange(0.6, 0.9, rng),
          }
          allPeaks.push(peak)
          t += nextNumberRange(0.04, 0.14, rng)
        }

        // console.log(peaksToAdd, distance)
        // const peak2 : PeakMeta = {
        //   id: -id,
        //   p: midpoint,
        //   t: min(peak0.t, peak1.t) * nextNumberRange(0.3, 0.7, rng),
        // }
      }
    }
    if (c++ === 16) {
      await createDefaultWait(1)
      // console.log('createMountainTerrainGeometry247', performance.now())
      c = 0
    }
  }
  // console.log('createMountainTerrainGeometry251', performance.now())

  await createDefaultWait(1)
  for (const peak of allPeaks) {
    addPeakToGeometry(geometry, peak)
    if (c++ === 24) {
      await createDefaultWait(1)
      // console.log('createMountainTerrainGeometry258', performance.now())
      c = 0
    }
  }
  await createDefaultWait(1)
  // console.log('createMountainTerrainGeometry263', performance.now())

  // console.log('zRange', max(...zRange), min(...zRange))

  geometry.translate(0, 0, terrainMinZ)
  await createDefaultWait(1)
  // console.log('createMountainTerrainGeometry264', performance.now())

  // addErosionToGeometry(geometry)

  // return geometry
  const g2 = truncateTrianglesBelowZ(geometry, terrainMinZ)
  await createDefaultWait(1)
  // console.log('createMountainTerrainGeometry271', performance.now())
  return g2
}

type PeakMeta = {
  id: EntityId,
  // peak location
  p: Vector3,
  // radius of peak's base
  r: number,
}

function addPeakToGeometry(
  geometry: BufferGeometry,
  peak: PeakMeta
): void {

  setGeometryZValuesV3(geometry, (gx: number, gy: number, z: number): number => {
    const { p, r } = peak
    // zRange.add(z)
    // relative x, y
    const rx1 = gx - p.x
    const ry1 = gy - p.y
    // const rx1 = gx
    // const ry1 = gy

    const absRX1 = abs(rx1)
    const absRY1 = abs(ry1)

    // console.log(gx, gy, z)
    // const distanceToRadiusEdge = pr - max(absRX1, absRY1)

    const distanceToCenter1 = (((rx1**2) + (ry1**2)) ** 0.5)
    if (DEV && isNaN(distanceToCenter1)) {
      throw new Unexpected('distanceToCenter1 is NaN')
    }
    // console.log({
    //   // gx: toFixed1(gx), gy: toFixed1(gy),
    //   rx1: toFixed1(rx1), ry1: toFixed1(ry1),
    //   dtc: toFixed1(distanceToCenter1),
    // //   toFixed1(pr),
    // //   toFixed1(rx1), toFixed1(ry1)
    // })

    if (r >= max(absRX1, absRY1)) {

      // console.log(distanceToCenter1)
      // const peakZ = (distanceToCenter1 ** 1.5) / 4
      // const peakZ = 5
      const peakZ = (max(0, (p.z) - distanceToCenter1) ** 1.4) / 4
      if (DEV && isNaN(peakZ)) {
        // console.log({
        //   basePeakHeight,
        //   gx, gy, z,
        //   cx1, cy1, pr,
        //   rx1, ry1,
        //   absRX1, absRY1,
        //   distanceToCenter1,
        //   peakZ,
        // })
        // console.log((max(0, basePeakHeight - distanceToCenter1)))
        // console.log((max(0, basePeakHeight - distanceToCenter1) ** 1.5))
        // console.log((max(0, basePeakHeight - distanceToCenter1) ** 1.5) / 4)
        throw new Unexpected('peakZ is NaN')
      }
      // console.log(peakZ)
      return max(peakZ, z)
    }
    return z
  })
}
