import { Vector3 } from "three"
import { MapControls } from "three/examples/jsm/controls/MapControls.js"
import setVectorV3Position from "../../../three/fn/setVectorV3Position"
import type { Engine } from "../../engine/Engine.type"
import type { TilePositionXY } from "../../tile_position_xy/TilePositionXY.type"

export default function centerPanCameraToPosition(
  engine: Engine,
  // camera: Camera,
  // mapControls: MapControls,
  tilePosition: TilePositionXY
): void {
  
  const { threeCtx } = engine
  if (threeCtx) {
    const { camera, mc: mapControls } = threeCtx
    if (camera && mapControls instanceof MapControls) {
      const target = new Vector3()
      setVectorV3Position(target, tilePosition)
    
      // Calculate the current camera offset from the target
      const offset = new Vector3().subVectors(camera.position, mapControls.target)
    
      // Update the target position for MapControls (this centers the camera)
      mapControls.target.copy(target)
    
      // Move the camera to maintain the same offset from the new target position
      camera.position.copy(target).add(offset)
    
      // Ensure the camera continues looking at the new target position
      camera.lookAt(target)
    }
  }

}
