import { createNoise2D } from 'simplex-noise'
import { BufferAttribute, Mesh, MeshBasicMaterial, MeshLambertMaterial } from 'three'
import type { MountainEntity } from '../../../core/entity/EntityType/Mountain'
import { next } from '../../../core/rng/index'
import tmpRngWith from '../../../core/rng/tmpRngWith'
import { max, PI } from '../../../core/util/math'
import { VERTICES_PER_TILE } from '../../consts'
import applyVertexFunctionStandardMaterialV3, { type VertexRGBFunctionStandardMaterialArg1 } from '../../fn/applyVertexFunctionStandardMaterialV3'
import { useMountainSandColors } from './colors'
import createMountainTerrainGeometry from './createMountainTerrainGeometry'

const debugWireFrame = false

// a regular plane will have all it's triangles
// pointing diagonally in the same direction
export default async function createMountainTerrainMesh (
  // engine : Engine,
  mountainEnts : Array<MountainEntity>,
) {
  // mountainEnts = mountainEnts.filter(ent => samePosition(ent, coord(6, 3)))
  // mountainEnts = mountainEnts.filter(ent => samePosition(ent, coord(8, 1)))
  const geometry = await createMountainTerrainGeometry(mountainEnts)
  geometry.rotateX(-PI/2)
  // console.log('geometry', geometry)

  // const geometry = new PlaneGeometry(10, 10, 10, 10)

  if (debugWireFrame) {
    const material = new MeshBasicMaterial({
      wireframe: true,
    })
    const mesh = new Mesh(geometry, material)
    // mesh.position.y += 20

    return mesh
  } else {

    // Look	Metalness	Roughness	Notes
    // Dry Rocky Mountain	0.0	0.8	Default, matte and natural.
    // Wet Rocks	0.0	0.4 - 0.6	For rainy/snowy effects.
    // Icy Peaks	0.1	0.3	Slightly reflective ice patches.
    // Volcanic Rock	0.0	0.9	Extra rough, porous lava rock.
    // const metalness = 0
    // const roughness = 0.8
    // roughness = 0.5-0.6, // if wet

    const position = geometry.attributes.position
    // 3 for RGB
    const size = 3
    const colorBuffer = new Float32Array(position.count * size)
    applyVertexFunctionStandardMaterialV3(geometry, colorBuffer, useMountainSandColors)
    {
      const radius = VERTICES_PER_TILE / 2
      const rng = tmpRngWith(0)
      const noise2D = createNoise2D(() => next(rng))
      applyVertexFunctionStandardMaterialV3(geometry, colorBuffer, (va : VertexRGBFunctionStandardMaterialArg1): void => {
        const { x, y, z } = va
        if (z < 1) {
          return
        }
        const rx = (x % VERTICES_PER_TILE) + radius
        const ry = (y % VERTICES_PER_TILE) + radius
        const noise = noise2D(rx * 2, ry * 2)
        // console.log(rx, ry, noise2D(rx, ry))
        va.z = z + ((noise ** 2) * 2)
        // console.log(noise, ((noise ** 2) * 2), va.r, va.g, va.b)

        const noise2 = (noise ** 2) / 50
        va.r = max(0, va.r - (noise2))
        va.g = max(0, va.g - (noise2))
        va.b = max(0, va.b - (noise2))
      })
    }
    // addErosionToMesh(mesh)
    geometry.setAttribute('color', new BufferAttribute(colorBuffer, size))

    // const noiseTexture1 = generateNoiseTexture(64, tmpRng, 127, 255)
    // noiseTexture1.wrapS = noiseTexture1.wrapT = RepeatWrapping
    // noiseTexture1.repeat.set(64, 64)

    const material = new MeshLambertMaterial({
      // metalness,
      // roughness,
      vertexColors: true,
      // wireframe: true,
      // bumpMap: noiseTexture1,
      // bumpScale: 0.001,
      // aoMap: noiseTexture1,
    })

    // const noiseTexture2 = generateNoiseTexture(64, tmpRng, 127, 255)
    // noiseTexture2.wrapS = noiseTexture2.wrapT = RepeatWrapping
    // noiseTexture2.repeat.set(64, 64)
    // material.lightMap = noiseTexture2
    // material.lightMapIntensity = 1
    // // material.wireframe = true

    const mesh = new Mesh(geometry, material)

    return mesh
  }
}
