import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import type { Entity } from "../../core/entity/index"

export const LOW_AMMO_SPRITE_NAME = "LowAmmo"

export enum LowAmmoSpriteType {
  None = 0, // Fully visible bullets
  Low = 1,  // Two bullets faded
  Out = 2,  // All three bullets faded
}

export function getEntityLowAmmoSpriteType(entity: Entity): LowAmmoSpriteType {
  const { weapons } = entity
  if (weapons) {
    for (const weapon of weapons) {
      if (weapon.ammo === 0) {
        return LowAmmoSpriteType.Out
      }
    }
    for (const weapon of weapons) {
      const { ammo } = weapon
      if ((ammo as number) > 0 && (ammo as number) < 2) {
        return LowAmmoSpriteType.Low
      }
    }
  }
  return LowAmmoSpriteType.None
}

export default function createLowAmmoCanvas(
  outerWidth: number = 128,
  outerHeight: number = 64,
  padding: number = 16,
  type: LowAmmoSpriteType
): OffscreenCanvas {
  const canvas = createOffscreenCanvas(outerWidth, outerHeight)
  const ctx = getCanvas2dContext(canvas)
  if (!ctx) throw new Error("Failed to get 2D context")

  ctx.fillStyle = "rgba(0, 0, 0, 0)" // Transparent background
  ctx.fillRect(0, 0, outerWidth, outerHeight)

  // Bullet properties
  const bulletWidth = 12
  const bulletHeight = 40
  const bulletSpacing = 20
  const startX = padding
  const startY = (outerHeight - bulletHeight) / 2

  // Colors
  const bodyColor = "#DAA520" // Gold
  const headColor = "#B8860B" // Darker gold
  const fadedOpacity = type === LowAmmoSpriteType.Out ? 0.3 : type === LowAmmoSpriteType.Low ? 0.5 : 1

  for (let i = 0; i < 3; i++) {
    const x = startX + i * bulletSpacing
    const opacity = i >= 3 - type ? fadedOpacity : 1 // Apply fading based on LowAmmo type

    ctx.globalAlpha = opacity

    // Draw bullet body
    ctx.fillStyle = bodyColor
    ctx.fillRect(x, startY, bulletWidth, bulletHeight)

    // Draw bullet head (triangle)
    ctx.fillStyle = headColor
    ctx.beginPath()
    ctx.moveTo(x, startY) // Top left
    ctx.lineTo(x + bulletWidth, startY) // Top right
    ctx.lineTo(x + bulletWidth / 2, startY - bulletWidth) // Bullet tip
    ctx.closePath()
    ctx.fill()
  }

  // Reset opacity
  ctx.globalAlpha = 1

  return canvas
}
