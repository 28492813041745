import { createEffect } from "solid-js"
import { type Group, type Mesh, type MeshStandardMaterial, type Scene } from "three"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import type { Engine } from "../../core/engine/Engine.type"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import { SelectedToolId } from "../../core/map_editor/SelectedToolId.enum"
import toCoord from "../../core/tile_position_xy/toCoord"
import { sin } from "../../core/util/math"
import { Cursor_BombardTileCursor1_GLTF_Model } from "../gltf_models.generated"
import { appGltfLoader } from "./appGltfLoader"
import onGltfLoaderError from "./onGltfLoaderError"
import setObjPosition from "./setObjPosition"

export default function createBombardCursorGroup(
  engine : Engine,
  scene : Scene
): void {
  const defaultPositionY = 1
  let baseHeight : number = 0

  let group : Group

  appGltfLoader.load(Cursor_BombardTileCursor1_GLTF_Model, function (ModelObj) {
    group = ModelObj.scene
    scene.add(group)

    const cursor = group.getObjectByName('Cursor') as Mesh
    const material = cursor.material as MeshStandardMaterial
    material.transparent = true
    material.opacity = 0.6

    group.userData.update = function (elapsedMs: number, frameAt: number) {
      const s1 = sin(frameAt / 500)
      group.position.y = baseHeight + (1 + (s1 * s1) / 2)

      group.rotateY(elapsedMs / 5000)
    }
    group.visible = false
  }, undefined, onGltfLoaderError)

  const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

  createEffect(() => {
    const { hoveredPosition, draftMove, selectedTool } = engine

    let newValue : boolean = false
    if (group) {
      if (hoveredPosition && draftMove) {
        const { hoveredPositionPaths } = draftMove
        const targetPosition = hoveredPosition
        const targetPositionStack = getEntityListAtXYMap().get(toCoord(targetPosition)) || []
        baseHeight = defaultPositionY + getEntityRenderHeight(targetPositionStack)
        if (selectedTool === SelectedToolId.RangedAttackRadius) {
          // if ranged attack
          // follow around the cursor
          newValue = true
        } else if (hoveredPositionPaths) {
          // if direct attack
          // only show if hovering an enemy that can be attacked same-turn
          if (hoveredPositionPaths.turns.length <= 1) {
            const { targetStep } = hoveredPositionPaths
            if (targetStep && targetStep.enemies.find((enemy) => enemy.canDirectAttack)) {
              newValue = true
            }
          }
        }

        setObjPosition(group, targetPosition, baseHeight)
      }
      group.visible = newValue
    }
  })
}
