import entityDisplayName from "../../core/entity/entityDisplayName"
import onNextTick from "../../ldsh/onNextTick"
import createDebugTextSprite from "../fn/createDebugTextSprite"
import getComputedBoundingBoxSizeVector3 from "../fn/getComputedBoundingBoxSizeVector3"
import type { EntityThreeModel } from "./EntityThreeModel.type"

export default function addEntityThreeModelDebugTextSprite(
  obj: NonNullable<EntityThreeModel['obj']>,
  ent: EntityThreeModel['ent']
): void {
  onNextTick(() => {
    const groupSize = getComputedBoundingBoxSizeVector3(obj)
    // console.log(entityDisplayName(ent), groupSize)

    // Create and add the debug text
    const debugText = createDebugTextSprite(`${entityDisplayName(ent)}
ID: ${ent.id}
(${ent.x}, ${ent.y})`)
    // debugText.scale.set(2, 2, 2) // Scale the text
    // console.log(entityDisplayName(ent), groupSize)
    debugText.scale.set(12, 12, 0.3) // Adjust size to match box
    debugText.position.set(0, 1 + 6 + (3 * groupSize.y), 0) // Position the text above the box
    // console.log('height', getGroupHeight(newGroup))
    obj.add(debugText)

    // newGroup.renderOrder = RenderOrder.UnitEntity
    // console.log(newGroup)
  })
}