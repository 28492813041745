import { BoxGeometry, Mesh, MeshBasicMaterial, Object3D } from "three"
import type { Entity } from "../../core/entity/index"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"

export default function makePlaceHolderUnitGroup (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  // const { material: baseMaterial } = pto
  
  // Tank Group
  const group = new Object3D()
  
  // Main Hull
  // const hullGeometry = new BoxGeometry(4, 1, 6)
  // const hullGeometry = new BoxGeometry(2, 1, 3)
  const hullGeometry = new BoxGeometry(12, 6, 16)
  // const hullMaterial = new MeshStandardMaterial({ color: 0x556b2f })
  // const hullMaterial = baseMaterial
  const material = new MeshBasicMaterial({
    wireframe: true,
  })
  const hull = new Mesh(hullGeometry, material)
  hull.position.set(0, 4, 0)
  group.add(hull)
  
  callback(group)
}

