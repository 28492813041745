import { MeshBasicMaterial } from "three"
import type { Nullable } from "../../../typescript"
import type { Player } from "../../core/player/Player.type"
import { hexToPlayerColor } from "../../core/player/player_color"
import type { PlayerThreeObject } from "./PlayerThreeObject.type"

export default function createPlayerThreeObject(player: Nullable<Player>): PlayerThreeObject {
  if (!player) {
    const neutralPto : PlayerThreeObject = {
      player: null,
      material: new MeshBasicMaterial({
        color: 0x888888,
      }),
    }
    return neutralPto
  }

  const material = new MeshBasicMaterial({
    color: hexToPlayerColor(player.color1),
  })
  material.userData.id = player
  material.userData.g = -1
  const pto : PlayerThreeObject = {
    player,
    material,
  }

  return pto
}
