import { InferOutput, number, object, optional } from 'valibot'
import { EntityTypeIdSchema } from './entity/EntityTypeId.enum'

export const RestrictedUnitRuleSchema = object({
  /**
   * The type of unit this rule applies to
   */
  etype_id: EntityTypeIdSchema,
  /**
   * If this rule applies to only one player.
   * Otherwise will apply to everyone.
   */
  player_id: optional(number()),
  /**
   * Add a custom limit (e.g. only 3 tanks per player)
   */
  limit: optional(number()),
  /**
   * Maybe players can't build transport copters until turn 10
   */
  turn: optional(number()),
})

export type RestrictedUnitRule = InferOutput<typeof RestrictedUnitRuleSchema>
