import type { Camera, Mesh, Raycaster, Vector2, Vector3 } from "three"
import type { Nullable } from "vite-node"
import firstIfOnlyOne from "../../ldsh/firstIfOnlyOne"
import { VERTICES_PER_TILE } from "../consts"

export type GetPointerVector3PointFn = (
  pointerRef : Vector2,
  // debug?: boolean
) => Nullable<Vector3>

export default function createGetPointerVector3Point(
  camera : Camera,
  raycaster : Raycaster,
  mesh : Mesh,
): GetPointerVector3PointFn {
  const offsetX = 0.5 * VERTICES_PER_TILE
  const offsetZ = 0.5 * VERTICES_PER_TILE
  // console.log('createGetPointerVector3Point.mesh', mesh)
  return function getPointerVector3Point(
    pointerRef : Vector2,
    // debug = false
  ): Nullable<Vector3> {
    // mesh.visible = !mesh.visible
    // if (debug) {
    //   console.log('getPointerVector3Point', deepClone(pointerRef))
    // }
    // update the picking ray with the camera and pointer position
    raycaster.setFromCamera(pointerRef, camera)
    const intersects = raycaster.intersectObject(mesh)
    const intersection = firstIfOnlyOne(intersects)

    // if (debug) {
    //   console.log('getPointerVector3Point.intersects', intersects)
    // }

    // console.log('intersection.point', pointerRef.toArray(), intersection?.point)

    if (intersection) {
      intersection.point.z += offsetZ
      intersection.point.x += offsetX
      return intersection.point
    } else {
      return null
    }
  }
}
