import { createEffect, on } from 'solid-js'
import { PerspectiveCamera, type Camera, type WebGLRenderer } from 'three'
import { getDeviceUserPreferenceHiDPI } from '../rx/shared/device/userPreference/getDeviceUserPreferenceHiDPI'
import { Engine } from './core/engine/Engine.type'
import onWindowEvent from './onWindowEvent'

export default function bindViewCtxToElementV2(
  engine: Engine,
  canvasElem: HTMLCanvasElement,
  renderer: WebGLRenderer,
  camera: Camera,
  props: {
    width: number,
    height: number,
  }
) {
  let HiDPI = false

  const onResize = () => {
    canvasElem.style.top = '0px'
    canvasElem.style.left = '0px'

    const dpr = window.devicePixelRatio || 1

    const { width: innerWidth, height: innerHeight } = props
    // console.log({ innerWidth, innerHeight })

    const renderW = (HiDPI ? (innerWidth * dpr) : innerWidth)
    const renderH = (HiDPI ? (innerHeight * dpr) : innerHeight)

    canvasElem.width = renderW
    canvasElem.height = renderH

    renderer.setSize(renderW, renderH)

    if (camera instanceof PerspectiveCamera) {
      camera.aspect = renderW / renderH
      camera.updateProjectionMatrix()
    }

    // note that the render sets this, so we must set it after.
    canvasElem.style.width = `${innerWidth}px`
    canvasElem.style.height = `${innerHeight}px`
  }
  onWindowEvent('resize', onResize)

  createEffect(on(getDeviceUserPreferenceHiDPI, (newValue: boolean) => {
    HiDPI = newValue
    onResize()
  }))

  // createMountedInverval(() => {
  //   HiDPI = !HiDPI
  //   onResize()
  // }, 1500)

  // manually trigger the effect of the resize listener
  onResize()
}
