import type { Color } from "three"
import type { Engine } from "../../../core/engine/Engine.type"
import coord from "../../../core/tile_position_xy/coord"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import { VERTICES_PER_TILE } from "../../consts"
import { getTerrainMesh1TileColor } from "./colors"
import type { TerrainMesh1TileData } from "./createTerrainMesh1Mesh"
import getTerrainMesh1TileElevation from "./getTerrainMesh1TileElevation"

export default function addVoidSurfaces(
  engine: Engine,
  data: Map<TilePositionXY, TerrainMesh1TileData>,
  indices: Array<number>,
  getVertexIndex: (x: number, y: number, z: number, color: Color) => number,
): void {
  const tileSize = VERTICES_PER_TILE
  const halfSize = tileSize / 2
  const quarterSize = tileSize / 4

  const { width: maxX, height: maxY } = engine.state

  for (let stateX = 0; stateX < maxX; stateX++) {
    for (let stateY = 0; stateY < maxY; stateY++) {
      const position = coord(stateX, stateY)
      const ent = data.get(position)
      if (!ent) {
        const baseColor = getTerrainMesh1TileColor(ent)
        const elevation = getTerrainMesh1TileElevation(ent)
  
        const x1 = tileSize * stateX
        const y1 = tileSize * -stateY
  
        // 8 outer vertices
        const a0 = getVertexIndex(x1 - halfSize, y1 - halfSize, elevation, baseColor)
        const a1 = getVertexIndex(x1, y1 - halfSize, elevation, baseColor)
        const a2 = getVertexIndex(x1 + halfSize, y1 - halfSize, elevation, baseColor)
        const a3 = getVertexIndex(x1 + halfSize, y1, elevation, baseColor)
        const a4 = getVertexIndex(x1 + halfSize, y1 + halfSize, elevation, baseColor)
        const a5 = getVertexIndex(x1, y1 + halfSize, elevation, baseColor)
        const a6 = getVertexIndex(x1 - halfSize, y1 + halfSize, elevation, baseColor)
        const a7 = getVertexIndex(x1 - halfSize, y1, elevation, baseColor)
  
        // 4 inner vertices
        const b0 = getVertexIndex(x1 - quarterSize, y1 - quarterSize, elevation, baseColor)
        const b1 = getVertexIndex(x1 + quarterSize, y1 - quarterSize, elevation, baseColor)
        const b2 = getVertexIndex(x1 + quarterSize, y1 + quarterSize, elevation, baseColor)
        const b3 = getVertexIndex(x1 - quarterSize, y1 + quarterSize, elevation, baseColor)
  
        // Create 8 trapezoids
        indices.push(a0, a1, b0)
        indices.push(a1, b1, b0)
  
        indices.push(a1, a2, b1)
        indices.push(a2, a3, b1)
  
        indices.push(a3, b1, b2)
        indices.push(a3, a4, b2)
  
        indices.push(a4, a5, b2)
        indices.push(a5, b3, b2)
  
        indices.push(a5, a6, b3)
        indices.push(a6, a7, b3)
  
        indices.push(a7, b0, b3)
        indices.push(a7, a0, b0)
  
        // indices.push(a5, b2, b3)
        // indices.push(a7, b3, b0)
  
        // Create the inner quad
        indices.push(b0, b1, b2)
        indices.push(b0, b2, b3)
  
        data.set(coord(stateX, stateY), {
          ent,
          edgeIndexes: [a0, a1, a2, a3, a4, a5, a6, a7]
        })
      }
    }
  }
}
