import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'

const entDefaults = {
  etype_id: EntityTypeId.Snow,
  layer_id: EntityLayerId.TerrainL1,
} as const

export const SnowEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Snow),
  layer_id: literal(EntityLayerId.TerrainL1),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
})

export type SnowEntity = InferOutput<typeof SnowEntitySchema>

export const SnowEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Snow,
  dname: 'Snow',
  layer_id: EntityLayerId.TerrainL1,
  defense: 1,
  entDefaults,
}

export function createSnowEntity(id: number, x: number, y: number): SnowEntity {
  return {
    ...entDefaults,
    heading: 0,
    id,
    x,
    y,
  }
}
