import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { floor } from "../../core/util/math"
import { GameIcons_Delapouite_WoodenCrate_d, GameIcons_Delapouite_WoodenCrate_h, GameIcons_Delapouite_WoodenCrate_w } from "../../svg/sprites_data_manual"

export const WOODEN_CRATE_SPRITE_NAME = 'WC'

export default function createWoodenCrateCanvas(
  outerWidth: number,
  outerHeight: number,
  padding: number = 32
): OffscreenCanvas {
  // console.log('createWoodenCrateCanvas')

  const canvas = createOffscreenCanvas(outerWidth, outerHeight)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    ctx.fillStyle = '#111'
    ctx.fillRect(0, 0, outerWidth, outerHeight)
    ctx.fillStyle = '#ddd'

    if (padding) {
      ctx.strokeStyle = '#ddd'
      ctx.lineWidth = 12
      const p2 = floor(padding / 2)
      ctx.strokeRect(
        p2,
        p2,
        outerWidth - padding,
        outerHeight - padding)
  
      ctx.translate(padding, padding)
    }

    ctx.scale(
      ((outerWidth - (padding * 2)) ) / GameIcons_Delapouite_WoodenCrate_w,
      ((outerHeight - (padding * 2)) ) / GameIcons_Delapouite_WoodenCrate_h)
  
    ctx.fill(GameIcons_Delapouite_WoodenCrate_d)
  }
  return canvas
}
