import type { Engine } from '../../core/engine/Engine.type'
import onWindowEvent from '../../onWindowEvent'

export default function registerContextMenuEvent(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine: Engine
) {
  // const { viewCtx } = engine
  // const dirty = engine.cache[1]
  function onContextMenu(/*event: MouseEvent*/) {
    // console.log('onContextMenu')

    // I still want right click to cancel,
    // but commenting this out because I don't want
    // right-click-camera panning to unselect
    // modifyMutable(
    //   engine,
    //   produce((engine) => {
    //     resetEngineToDefaultSelectedTool(engine)
    //   })
    // )
    // return false to prevent right click menu
    return false
  }

  onWindowEvent('contextmenu', onContextMenu)
}
