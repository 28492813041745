import type { Color } from "three"
import type { Engine } from "../../../core/engine/Engine.type"
import { EntityLayerId } from "../../../core/entity/entity_layer_id.enum"
import { isEntityRiver, isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import coord from "../../../core/tile_position_xy/coord"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import { VERTICES_PER_TILE } from "../../consts"
import { getTerrainMesh1TileColor } from "./colors"
import type { TerrainMesh1TileData } from "./createTerrainMesh1Mesh"
import getTerrainMesh1TileElevation from "./getTerrainMesh1TileElevation"

// this complicated code was written to be less polygons
// but that might have been pointless.
export default function addSimpleSurfacesV1(
  engine: Engine,
  data: Map<TilePositionXY, TerrainMesh1TileData>,
  indices: Array<number>,
  getVertexIndex: (x: number, y: number, z: number, color: Color) => number,
): void {
  
  const terrainEnts = engine.state.ents.filter((ent: Entity) => {
    return ent.layer_id === EntityLayerId.TerrainL1 && !isEntityShoal(ent) && !isEntityRiver(ent)
  })
  // console.log(terrainEnts.map(entityDisplayName).sort())

  const { width: maxX, height: maxY } = engine.state

  const tileSize = VERTICES_PER_TILE
  const halfTileSize = tileSize / 2

  // outer offset size
  const o1 = 1
  const o2 = (tileSize - (o1 + o1)) / 2

  for (let stateX = 0; stateX <= maxX; stateX++) {
    for (let stateY = 0; stateY <= maxY; stateY++) {
      const ent = terrainEnts.find(ent => ent.x === stateX && ent.y === stateY)
      if (ent) {
        const baseColor = getTerrainMesh1TileColor(ent)
        const elevation = getTerrainMesh1TileElevation(ent)

        const x1 = tileSize * stateX
        const y1 = tileSize * -stateY

        // for (const dir of dijkstraDirections.slice(0, 1)) {
        //   const a0 = getVertexIndex(
        //     x1 + (halfTileSize * dir.dy) + outerSquareSize * 2,
        //     y1 + (halfTileSize * dir.dx), elevation, baseColor)
        //   const a1 = getVertexIndex(
        //     x1 + (halfTileSize * dir.dy) + outerSquareSize,
        //     y1 + (halfTileSize * dir.dx), elevation, baseColor)
        //   const a2 = getVertexIndex(
        //     x1 + (halfTileSize * dir.dy) + outerSquareSize,
        //     y1 + (halfTileSize * dir.dx) + outerSquareSize, elevation, baseColor)
        //   const a3 = getVertexIndex(
        //     x1 + (halfTileSize * dir.dy),
        //     y1 + (halfTileSize * dir.dx) + outerSquareSize, elevation, baseColor)

        //   indices.push(a0, a1, a3)
        //   // indices.push(a3, a2, a1)
        // }

        // 8 outer vertices
        // const a0 = getVertexIndex(x1 - halfTileSize, y1 - halfTileSize, elevation, baseColor)
        // const a1 = getVertexIndex(x1, y1 - halfTileSize, elevation, baseColor)
        // const a2 = getVertexIndex(x1 + halfTileSize, y1 - halfTileSize, elevation, baseColor)
        // const a3 = getVertexIndex(x1 + halfTileSize, y1, elevation, baseColor)
        // const a4 = getVertexIndex(x1 + halfTileSize, y1 + halfTileSize, elevation, baseColor)
        // const a5 = getVertexIndex(x1, y1 + halfTileSize, elevation, baseColor)
        // const a6 = getVertexIndex(x1 - halfTileSize, y1 + halfTileSize, elevation, baseColor)
        // const a7 = getVertexIndex(x1 - halfTileSize, y1, elevation, baseColor)

        // 4 inner vertices
        const b0 = getVertexIndex(x1 - o2, y1 - o2, elevation, baseColor) // nw
        const b1 = getVertexIndex(x1 + o2, y1 - o2, elevation, baseColor) // ne
        const b2 = getVertexIndex(x1 + o2, y1 + o2, elevation, baseColor) // se
        const b3 = getVertexIndex(x1 - o2, y1 + o2, elevation, baseColor) // sw

        // North West Corner
        const nw0 = getVertexIndex(x1 - halfTileSize, y1 - halfTileSize, elevation, baseColor)
        const nw1 = getVertexIndex(x1 - halfTileSize + o1, y1 - halfTileSize, elevation, baseColor)
        const nw2 = getVertexIndex(x1 - halfTileSize, y1 - halfTileSize + o1, elevation, baseColor)
        indices.push(nw0, nw1, b0)
        indices.push(nw0, nw2, b0)

        // South West Corner
        const sw0 = getVertexIndex(x1 - halfTileSize, y1 + halfTileSize, elevation, baseColor)
        const sw1 = getVertexIndex(x1 - halfTileSize + o1, y1 + halfTileSize, elevation, baseColor)
        const sw2 = getVertexIndex(x1 - halfTileSize, y1 + halfTileSize - o1, elevation, baseColor)
        indices.push(sw0, sw1, b3)
        indices.push(sw0, sw2, b3)

        // North East Corner
        const ne0 = getVertexIndex(x1 + halfTileSize, y1 - halfTileSize, elevation, baseColor)
        const ne1 = getVertexIndex(x1 + halfTileSize - o1, y1 - halfTileSize, elevation, baseColor)
        const ne2 = getVertexIndex(x1 + halfTileSize, y1 - halfTileSize + o1, elevation, baseColor)
        indices.push(ne0, ne1, b1)
        indices.push(ne0, ne2, b1)

        // South East Corner
        const se0 = getVertexIndex(x1 + halfTileSize, y1 + halfTileSize, elevation, baseColor)
        const se1 = getVertexIndex(x1 + halfTileSize - o1, y1 + halfTileSize, elevation, baseColor)
        const se2 = getVertexIndex(x1 + halfTileSize, y1 + halfTileSize - o1, elevation, baseColor)
        indices.push(se0, se1, b2)
        indices.push(se0, se2, b2)

        // west edge
        indices.push(nw2, b0, b3)
        indices.push(sw2, nw2, b3)

        // south edge
        indices.push(sw1, b3, b2)
        indices.push(se1, b2, sw1)

        // north edge
        indices.push(ne1, b1, b0)
        indices.push(ne1, nw1, b0)

        // east edge
        indices.push(b1, ne2, se2)
        indices.push(b2, se2, b1)

        // Create 8 trapezoids
        // indices.push(a0, a1, b0)
        // indices.push(a1, b1, b0)

        // indices.push(a1, a2, b1)
        // indices.push(a2, a3, b1)

        // indices.push(a3, b1, b2)
        // indices.push(a3, a4, b2)

        // indices.push(a4, a5, b2)
        // indices.push(a5, b3, b2)

        // indices.push(a5, a6, b3)
        // indices.push(a6, a7, b3)

        // indices.push(a7, b0, b3)
        // indices.push(a7, a0, b0)

        // indices.push(a5, b2, b3)
        // indices.push(a7, b3, b0)

        // Create the inner quad
        indices.push(b0, b1, b2)
        indices.push(b0, b2, b3)

        data.set(coord(stateX, stateY), {
          ent,
          edgeIndexes: [
            // a0, a1, a2, a3, a4, a5, a6, a7
          ]
        })
      }
    }
  }
}
