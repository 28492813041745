import { GLTFLoader, type GLTF } from 'three/addons/loaders/GLTFLoader.js'

const loader = new GLTFLoader();

export default function GLTFSignalPromiseFactory(modelFileAsStringOrUrl: string): Promise<GLTF> {
  // console.log('GLTFSignalPromiseFactory')
  return new Promise((resolve, reject) => {
    // console.log('GLTFSignalPromiseFactory.createRoot')
    loader.load(modelFileAsStringOrUrl, function (ModelObj) {
      // console.log('GLTFSignalPromiseFactory.createRoot.loader.load')
      // console.log('setModelObj', ModelObj)
      resolve(ModelObj)
      // console.log(ModelObj.scene)
    }, undefined, function (error) {
      console.error(error)
      reject(error)
    })
  })
}
