import { now } from "@sg/shared/src/lib/Date"
import { modifyMutable, produce } from "solid-js/store"
import calculateMapWidthHeightPxTileSize from "./calculateMapWidthHeightPx"
import calculateTileSize from "./calculateTileSize"
import type { Engine } from "./engine/Engine.type"
import { abs, floor } from "./util/math"
import type { ZoomQueue } from "./ZoomQueue.type"

const MIN_DELTA_PER_MS_RATIO = 1

const zoomFactor = -0.05
const min_zoom = 25
const max_zoom = 400

function toSmallerPercent(carry: number, zq: ZoomQueue) {
  return carry + abs(zq.deltaY / 3)
}

/**
 * Processes the zoom queue of the engine to apply smooth zoom transitions frame by frame.
 * 
 * This function handles user input from the zoom queue and applies incremental zoom adjustments 
 * while ensuring smooth transitions. It calculates new zoom levels, adjusts tile sizes, updates 
 * map dimensions, and recalculates panning offsets to keep the zoom focused on the user's input 
 * position (clientX, clientY).
 * 
 * Key features:
 * - Applies zoom deltas from the queue within a frame time constraint.
 * - Clamps zoom levels to a specified minimum and maximum range.
 * - Dynamically adjusts the map dimensions and tile sizes.
 * - Smoothly adjusts the pan position to ensure the zoom is centered correctly.
 * 
 * @param {Engine} engine - The game engine object containing the `viewCtx` and state properties.
 * @param {number} elapsedMs - The duration of the current frame in milliseconds, used to control the amount of zoom applied.
 */
export default function progressZoomQueue(engine: Engine, elapsedMs: number) {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx } = engine
      const { zoomQueue } = viewCtx

      let totalFrameZoomDelta = 0 // Tracks the total zoom delta applied in this frame
      let zq: ZoomQueue

      if (zoomQueue[0]) {
        // Calculate the maximum allowable delta for this frame
        const limit = floor(zoomQueue.reduce(toSmallerPercent, MIN_DELTA_PER_MS_RATIO * elapsedMs))

        let delta: number = 0
        while ((zq = zoomQueue[0]) && limit > totalFrameZoomDelta) {
          const {
            clientX, clientY, // Coordinates of the zoom origin
            deltaY, // Zoom delta (positive or negative)
            prevMapWidthPx, prevMapHeightPx, // Previous map dimensions in pixels
            prevPanX, prevPanY // Previous panning offsets
          } = zq

          delta = deltaY

          if (delta === 0) {
            // Remove completed zoom events
            zoomQueue.shift()
            continue
          } else if (-delta > limit) {
            // Limit zoom-in if the delta exceeds the frame limit
            zq.deltaY += limit
            delta = -limit
          } else if (delta > limit) {
            // Limit zoom-out if the delta exceeds the frame limit
            zq.deltaY -= limit
            delta = limit
          } else {
            // Fully process and remove this zoom event
            zoomQueue.shift()
          }

          totalFrameZoomDelta += abs(delta)

          // Apply zoom factor and clamp within min/max bounds
          const zoomDelta = delta * zoomFactor
          let nextZoom = viewCtx.zoom + zoomDelta
          if (nextZoom > max_zoom) {
            nextZoom = max_zoom
          } else if (nextZoom < min_zoom) {
            nextZoom = min_zoom
          }
          viewCtx.zoom = nextZoom

          // Recalculate tile size and map dimensions
          viewCtx.tile_size = calculateTileSize(viewCtx)
          calculateMapWidthHeightPxTileSize(engine)

          const { mapWidthPx: nextMapWidthPx, mapHeightPx: nextMapHeightPx } = viewCtx

          // Calculate the new panning offsets to center the zoom on the correct position
          const ratioMapClientX = (clientX - prevPanX) / prevMapWidthPx
          const ratioMapClientY = (clientY - prevPanY) / prevMapHeightPx
          const nextPanX = -((nextMapWidthPx * ratioMapClientX) - clientX)
          const nextPanY = -((nextMapHeightPx * ratioMapClientY) - clientY)

          // Update panning values
          viewCtx.pan_x_f = nextPanX
          viewCtx.pan_y_f = nextPanY

          // Floor the values for pixel-perfect rendering
          viewCtx.pan_x = floor(nextPanX)
          viewCtx.pan_y = floor(nextPanY)

          // Update the timestamp for the last zoom event
          viewCtx.lastZoomAt = now()
        }
      }
    })
  )
}