import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { floor } from "../../core/util/math"

const GREEN_HP = 'rgba(0, 255, 0, 0.75)' // Green for high health
const YELLOW_HP = 'rgba(255, 255, 0, 0.75)' // Yellow for medium health
const RED_HP = 'rgba(255, 0, 0, 0.75)' // Red for low health

/**
 * @param hp: number a value from 0 to 10
 **/
export default function createVerticalHealthBarCanvas(hp: number): OffscreenCanvas {
  const width = 512
  const height = 64
  const canvas = createOffscreenCanvas(width, height)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    ctx.fillStyle = 'rgba(0,0,0,0.25)'
    ctx.fillRect(0, 0, width, height)

    // Draw the HP bar background
    const padding = 4
    const barHeight = height - (padding * 2)
    const barWidth = width - (padding * 2)
    
    // Draw decorative notches
    ctx.fillStyle = 'rgba(255,255,255,0.15)'
    // for (let i = 0; i < 10; i++) {
    //   const x = padding + (barWidth / 10) * i
    //   context.fillRect(x, padding, 2, barHeight)
    // }

    // Calculate current HP width
    const hpWidth = floor((barWidth / 10) * hp)

    // Draw main HP bar
    ctx.fillStyle = hp > 7 ? GREEN_HP : hp > 3 ? YELLOW_HP : RED_HP
    ctx.fillRect(padding, padding, hpWidth, barHeight)

    // Add a highlight effect at the top
    ctx.fillStyle = 'rgba(255,255,255,0.3)'
    ctx.fillRect(padding, padding, hpWidth, padding)
    // Add a shadow effect at the bottom
    ctx.fillStyle = 'rgba(0,0,0,0.3)'
    ctx.fillRect(padding, height - (2 * padding), hpWidth, padding)
  }

  return canvas
}