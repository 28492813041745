import type { EntityTypeId } from '../EntityTypeId.enum'
import type { UnitTypeWeaponTypeTargetTypePair } from './UnitTypeWeaponTypeTargetTypePair.type'
import unitTypeWeaponTypeTargetTypePairList from './unitTypeWeaponTypeTargetTypePairList.generated'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function getUnitTypeWeaponTypeTargetTypePair(
  unitTypeId: EntityTypeId,
  weaponTypeId: WeaponTypeId,
  targetTypeId: EntityTypeId
): UnitTypeWeaponTypeTargetTypePair {
  for (let index = 0; index < unitTypeWeaponTypeTargetTypePairList.length; index++) {
    const pair = unitTypeWeaponTypeTargetTypePairList[index]
    if (pair.ut === unitTypeId && pair.wt === weaponTypeId && pair.tt === targetTypeId) {
      return pair
    }
  }
  return {
    ut: unitTypeId,
    wt: weaponTypeId,
    tt: targetTypeId,
    v: 1,
  }
}
