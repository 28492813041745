import { modifyMutable, produce } from 'solid-js/store'
import { Vector2, type Vector3 } from 'three'
import type { Nullable } from 'vite-node'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition from '../../core/draft_move/setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition'
import { Engine } from '../../core/engine/Engine.type'
import { EngineMode } from '../../core/engine/EngineMode/EngineMode.enum'
import { createMoveUnitAction } from '../../core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { deepClone } from '../../deep_clone'
import onElementEvent from '../../onElementEvent'
import pointerVector3PointToPointerTilePosition from '../../three/fn/pointerVector3PointToPointerTilePosition'

type MightHaveSourceCapabilities = {
  sourceCapabilities?: {
    firesTouchEvents?: boolean
  }
}

export default function registerMouseDownEvent(
  engine: Engine,
  elem: HTMLElement,
  getPointerVector3Point: (pointerVector2: Vector2) => Nullable<Vector3>,
) {
  function onMouseDown(event: MouseEvent) {
    // alert('onMouseDown'+JSON.stringify({
    //   buttons: event.buttons,
    //   button: event.button,
    //   type: event.type,
    //   detail: event.detail,
    //   which: event.which,
    //   'sourceCapabilities.firesTouchEvents': event.sourceCapabilities.firesTouchEvents,
    // }))
    const wasNotTouchEvent = (event as MightHaveSourceCapabilities).sourceCapabilities?.firesTouchEvents === false
    // console.log('onMouseDown', event)
    const { offsetX, offsetY } = event
    // const isMap = wasEventTargetMap(event)
    // modifyMutable(
    //   engine,
    //   produce((engine: Engine) => {

    const pointerX = (offsetX / (elem as HTMLCanvasElement).clientWidth) * 2 - 1
    const pointerY = - (offsetY / (elem as HTMLCanvasElement).clientHeight) * 2 + 1

    const mouseDownVector3 = getPointerVector3Point(new Vector2(pointerX, pointerY))
    const mouseDownPosition = pointerVector3PointToPointerTilePosition(engine, mouseDownVector3)
    // console.log('onMouseDown.mouseDownPosition', mouseDownPosition, deepClone(mouseDownVector3), { offsetX, offsetY })
    // const mouseDownPositionOld = positionFromPixels(
    //   engine.state,
    //   engine.viewCtx,
    //   event.offsetX,
    //   event.offsetY
    // )
    const { draftMove } = engine
    if (mouseDownPosition && draftMove) {
      const { startPosition, destPosition, attackPosition, unload } = draftMove
      // console.log('onMouseDown.draftMove', deepClone(draftMove))
      if (engine.mode === EngineMode.MapPreview) {
        // do nothing
      } else if (samePosition(mouseDownPosition, startPosition)) {
        // console.log('onMouseDown.draftMove', 'samePosition(mouseDownPosition, draftMove.startPosition)')
      } else if (unload) {
        // console.log('onMouseDown.draftMove.unload')
        if (unload.paths.find((path) => samePosition(path, mouseDownPosition))) {
          dispatchClient(engine, createMoveUnitAction({
            ...draftMove,
            unloadPosition: mouseDownPosition,
          }))
          // modifyMutable(engine, produce(resetEngineToDefaultSelectedTool))
        }
      } else if (!destPosition) {
        // console.log('onMouseDown.draftMove.!destPosition')
        modifyMutable(engine, produce((engine) => {
          setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)
          calculateDraftMoveAttackEstimates(engine)
        }))
        // the hover gave the player a movement path and an attack estimate,
        // they clicked on an enemy,
        // the mouse click represents the user wanting to finalize an attack
        if (!attackPosition && draftMove.attackPosition && !draftMove.hoveredPositionPaths?.lastStep?.occupant) {
          dispatchClient(engine, createMoveUnitAction(draftMove))
          // modifyMutable(engine, produce(resetEngineToDefaultSelectedTool))
          ;(event as (typeof event & { dontSelect: boolean })).dontSelect = true
        }
        // else {
        // // else they were probably picking a specific tile to attack from
        //}
      } else if (destPosition) {
        // console.log('onMouseDown.draftMove.destPosition')
        const attackEstimate = draftMove.attackEstimates.find(ae => samePosition(ae.target, mouseDownPosition) && samePosition(ae.unitPosition, destPosition))
        // if it's an attack, they had the estimates already, let's move forward
        if (attackEstimate) {
          console.log('onMouseDown.draftMove.destPosition with attackEstimate', deepClone({
            destPosition, attackEstimate, wasNotTouchEvent
          }))
          if (wasNotTouchEvent || samePosition(draftMove.attackPosition, mouseDownPosition)) {
            // if we are 100% certain they used a mouse,
            // then they were able to use the hover to see the estimates
            dispatchClient(engine, createMoveUnitAction({
              ...draftMove,
              attackPosition: mouseDownPosition,
              target: attackEstimate.target,
            }))
            // dont let the following mouseUp evnet set a selectedPosition
            ;(event as (typeof event & { dontSelect: boolean })).dontSelect = true
          } else {
            // if we are not 100% certain they used a mouse,
            // then will assume they are attempting to see the estimates
            draftMove.attackPosition = mouseDownPosition
          }
        } else {
          // console.log('onMouseDown.draftMove.destPosition !attackEstimate')
          // else they were probably picking a difference destPosition tile
          modifyMutable(engine, produce((engine) => {
            setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)
            calculateDraftMoveAttackEstimates(engine)
          }))
        }
      } else {
        // console.log('onMouseDown.draftMove.else // do nothing')
        // draftMove.attackPosition = mouseDownPosition
      }
    } else {
      // console.log('onMouseDown.!draftMove')
      // moved to onMouseUp with maximum mouseDown duration
      // engine.selectedPosition = null
      // calculateSelectedPositionEnts(engine)
    }
    engine.mouseDownEvent = event
    if (!samePosition(engine.mouseDownPosition, mouseDownPosition)) {
      engine.mouseDownPosition = mouseDownPosition
    }
    engine.mouseUpEvent = null
    engine.mouseDragList = []
  }

  // onWindowEvent('mousedown', onMouseDown)
  onElementEvent(elem, 'mousedown', onMouseDown)
}
