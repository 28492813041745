import { type Object3D } from "three"
import createEngineForUI from "../../../core/engine/createEngineForUI"
import type { MountainEntity } from "../../../core/entity/EntityType/Mountain"
import createMountainTerrainMesh from "./createMountainTerrainMesh"


export default function makeSingleTileMountainObject3D (
  ent: MountainEntity,
  // pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  const engine = createEngineForUI()
  const { state } = engine
  const ents = state.ents = [{
    ...ent,
    x: 0,
    y: 0,
  }]
  state.width = state.height = 1
  createMountainTerrainMesh(ents).then(callback)
  // mesh.position.z -= VERTICES_PER_TILE
  // mesh.rotateX(PI / 2)
  // callback(mesh)
}
