import { BufferGeometry, NormalBufferAttributes } from 'three'

export default function setGeometryZValuesV3(
  geometry: BufferGeometry<NormalBufferAttributes>,
  fn: (x: number, y: number, z: number) => number
): void {
  const positionAttribute = geometry.getAttribute('position')
  
  // Direct access to the underlying array of the position attribute
  const positions = positionAttribute.array as Float32Array
  const len = positionAttribute.count
  
  // Loop over the array and update Z values in place
  for (let i = 0; i < len; i++) {
    const index = i * 3  // Each vertex has 3 components (x, y, z)
    const x = positions[index]
    const y = positions[index + 1]
    const oldZ = positions[index + 2]
    const z = fn(x, y, oldZ)

    positions[index + 2] = z  // Directly set the updated Z value
  }

  positionAttribute.needsUpdate = true  // Mark the attribute as needing an update
}
