import { Color, Group, Mesh, MeshStandardMaterial, QuadraticBezierCurve3, Quaternion, Vector3 } from "three"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import type { Nullable } from "../../../typescript"
import type { Engine } from "../../core/engine/Engine.type"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import getManhattanDistance from "../../core/tile_position_xy/getManhattanDistance"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import toCoord from "../../core/tile_position_xy/toCoord"
import { floor, max, min, range } from "../../core/util/math"
import { Cursor_Arrow2_GLTF_Model } from "../gltf_models.generated"
import { appGltfLoader } from "./appGltfLoader"
import disposeForTraverse from "./disposeForTraverse"
import onGltfLoaderError from "./onGltfLoaderError"
import setVectorV3Position from "./setVectorV3Position"

const scaleValue = 3
// const scaleValue = 1.5
const scale = new Vector3(scaleValue, 0.3, scaleValue)

const arrowPathDefaultY = 2
// const arrowPathDipOffsetY = 3

// const headRed = new Color(0xfe4f4f) // (Bright Red)
// const tailRed = new Color(0xff007b) // (Hot Pink)

const headRed = new Color(0xd9534f) // (Brick Red)
const tailRed = new Color(0xc82333) // (Crimson)

// const headRed = new Color(0xff6b6b) // (Fiery Red)
// const tailRed = new Color(0xff0000) // (Crimson Red)

export default class RedAttackArrowPathGroup extends Group {

  update(targetPosition: Nullable<TilePositionXY>,
    isPositionInRangedAttackRange: Nullable<(x: number, y: number) => boolean>,
    engine : Engine): void {
    // console.log('[RedAttackArrowPathGroup].update', deepClone(targetPosition), {
    //   isPositionInRangedAttackRange: (isPositionInRangedAttackRange && targetPosition)
    //     ? isPositionInRangedAttackRange(targetPosition.x, targetPosition.y)
    //     : null
    // })
    this.reset()
    const { draftMove } = engine
    if (targetPosition && draftMove) {
      const { startPosition } = draftMove
      // const manhattanDistance = getManhattanDistance(startPosition, targetPosition)
      // console.log('manhattanDistance', manhattanDistance)
      if (getManhattanDistance(startPosition, targetPosition) > 1) {
        appGltfLoader.load(Cursor_Arrow2_GLTF_Model, (ModelObj) => {
          this.reset()

          const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

          function stepToVector3 (step : TilePositionXY) {
            const stack = getEntityListAtXYMap().get(toCoord(step)) || []
            const value = getEntityRenderHeight(stack) + arrowPathDefaultY
            const dh = value + 2
            const v3 = new Vector3()
            setVectorV3Position(v3, step, dh)
            return v3
          }

          const ArrowGroup = ModelObj.scene
          const ArrowHeadMesh = ArrowGroup.getObjectByName('Head') as Mesh
          ArrowHeadMesh.scale.multiply(scale)

          const ArrowTailMesh = ArrowGroup.getObjectByName('Tail') as Mesh
          // console.log('ArrowTailMesh', ArrowTailMesh)
          ArrowTailMesh.scale.multiply(scale)

          const startV3 = stepToVector3(startPosition)
          const targetV3 = stepToVector3(targetPosition)
          const middleV3 = new Vector3().lerpVectors(targetV3, startV3, 0.5)

          const moveDistance = 5 // Move 5 units closer to the middle

          // Move startV3 closer to middleV3 by 5 units
          const startDirection = new Vector3().subVectors(middleV3, startV3).normalize()
          startV3.addScaledVector(startDirection, moveDistance)
          
          // Move targetV3 closer to middleV3 by 5 units
          const targetDirection = new Vector3().subVectors(middleV3, targetV3).normalize()
          targetV3.addScaledVector(targetDirection, moveDistance)
          middleV3.y += startV3.distanceTo(targetV3) * 0.6

          // const catmullRomCurve3List = [startV3, middleV3, targetV3]
          const curve = new QuadraticBezierCurve3(startV3, middleV3, targetV3)

          // const tubeGeometry = new TubeGeometry(curve, 100, 0.25, 8, false)
          // const tubeMaterial = new MeshBasicMaterial({
          //   color: 0xff00ff,
          //   // wireframe: true,
          // })
          // const tubeMesh = new Mesh(tubeGeometry, tubeMaterial)
          // this.add(tubeMesh)

          // console.log('curve.getLength()', curve.getLength())
          const curveLen = curve.getLength()
          const stepsLen = max(0.5, floor(curveLen / 20))
          // console.log('stepsLen', stepsLen)
          const doubleStepsLen = stepsLen * 2
          range(1, doubleStepsLen).forEach((num, index) => {
            const mesh = ArrowTailMesh.clone()
            const material = (mesh.material as MeshStandardMaterial).clone()
            material.transparent = true
            // console.log('material', material)
            material.color = tailRed
            // material.depthTest = false
            // material.depthWrite = false
            mesh.material = material
            // mesh.castShadow = true
            const point = curve.getPointAt(1)
            mesh.position.copy(point)
            this.add(mesh)

            let t = index / doubleStepsLen // Normalized progress along curve (0 to 1)
            const threshold1 = min(0.3, max(0.15, 5 / curveLen))
            const threshold2 = max(0.7, min(0.9, (curveLen - 10) / curveLen))
            const speed = (curveLen / stepsLen) / 2500
            // console.log(JSON.stringify({ curveLen, threshold1, threshold2, speed}).replaceAll(/"/g, ''))

            mesh.userData.update = () => {
              // Move the object along the curve
              const point = curve.getPointAt(t)
              mesh.position.copy(point)

              // Fade out at the end, fade in at the start
              if (t < threshold1) {
                // Fade in
                material.opacity = (t / threshold1) ** 3
              } else if (t > threshold2) {
                // Fade out
                material.opacity = ((1 - t) / (1 - threshold2)) ** 3
              } else {
                // Fully visible
                material.opacity = 1
              }

              applyQuaternionFromCurve(curve, t, mesh)

              // Loop animation
              t += speed
              if (t > 1) t = 0
            }
          })

          {
            const mesh = ArrowHeadMesh.clone()
            const material = (ArrowHeadMesh.material as MeshStandardMaterial).clone()
            material.color = headRed
            mesh.material = material
            // mesh.castShadow = true
            const point = curve.getPointAt(1)
            mesh.position.copy(point)
            applyQuaternionFromCurve(curve, 1, mesh)

            this.add(mesh)
          }
        }, undefined, onGltfLoaderError)
      }
    }
  }

  reset () {
    this.traverse(disposeForTraverse)
    this.clear()
  }
}

// // Default up direction
// const up = new Vector3(1, 0, 0)

// function applyQuaternionFromCurve(
//   curve: CatmullRomCurve3,
//   t: number,
//   mesh: Mesh): void {
//   const tangent = curve.getTangentAt(t).normalize()
//   // const v2 = new Vector3(tangent.x, 0, tangent.y)
//   // const v2 = new Vector3(tangent.x, tangent.y, 0)
//   // const v2 = new Vector3(0, tangent.x, tangent.y)
//   // const v2 = new Vector3(tangent.x, 0, tangent.y)
//   // const v2 = new Vector3(0, -tangent.y, 0)
//   // const v2 = new Vector3(0, -tangent.y, 0)
//   // const v2 = new Vector3(0, 0, -tangent.z)
//   const v2 = new Vector3(0, 0, tangent.x)
//   const quaternion = new Quaternion().setFromUnitVectors(up, v2)
//   mesh.quaternion.copy(quaternion)
// }

// const up = new Vector3(0, 1, 0) // Use Y-up to maintain level orientation

function applyQuaternionFromCurve(
  curve: QuadraticBezierCurve3,
  t: number,
  mesh: Mesh
): void {
  const tangent = curve.getTangentAt(t).normalize()

  // Project tangent onto the X-Z plane (remove Y component)
  // const projectedTangent = new Vector3(tangent.x, 0, tangent.z).normalize()
  const projectedTangent = tangent

  // Apply rotation using a stable 'up' direction
  const quaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 0, -1), projectedTangent)
  mesh.quaternion.copy(quaternion)
}
