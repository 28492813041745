import { Component, JSX, createEffect, onCleanup, onMount, useContext } from "solid-js"
import { ACESFilmicToneMapping, OrthographicCamera, WebGLRenderer } from "three"
import { createCanvasElement } from "../../lib/canvas/create_canvas"
import { loseWebGL2Context } from "../../lib/canvas/loseWebGL2Context"
import { Engine } from "../../lib/core/engine/Engine.type"
import getEntityRenderHeight from "../../lib/core/entity/getEntityRenderHeight"
import coord from "../../lib/core/tile_position_xy/coord"
import type { TilePositionXY } from "../../lib/core/tile_position_xy/TilePositionXY.type"
import { cos, max, sin } from "../../lib/core/util/math"
import setDuskAttributes from "../../lib/dom/setDuskAttribute"
import { VERTICES_PER_TILE } from "../../lib/three/consts"
import { onCleanupDispose } from "../../rx/cleanup/onCleanupDispose"
import { createSharedEntityXYGridMapMemo } from "../../rx/memo/createSharedEntityXYGridMapMemo"
import EngineContext from "../EngineContext"

interface Props {
  position: TilePositionXY,
  w: number,
  h: number,
  style?: JSX.CSSProperties,
}

// based on SingleTileCanvas
const PositionIcon3D: Component<Props> = (props: Props) => {
  // console.log('PositionIcon3D', props)
  const engine: Engine = useContext(EngineContext)

  const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

  const threeCtx = engine.threeCtx
  if (!threeCtx) {
    // eslint-disable-next-line solid/components-return-once
    return <div>No Render Context</div>
  }
  const { scene } = threeCtx

  const canvasElem = createCanvasElement()
  setDuskAttributes(canvasElem, 'PositionIcon3D')
  canvasElem.classList.add('sprite-icon')
  canvasElem.classList.add('stack-icon')

  // eslint-disable-next-line solid/reactivity
  const targetW = props.w
  // eslint-disable-next-line solid/reactivity
  const targetH = props.h

  canvasElem.width = targetW
  canvasElem.height = targetH

  const aspect = canvasElem.width / canvasElem.height
  const near = 0.1
  const far = 1000

  const frustumSize = 40 // Adjust based on your scene scale
  const left = (-frustumSize * aspect) / 2
  const right = (frustumSize * aspect) / 2
  const top = frustumSize / 2
  const bottom = -frustumSize / 2
  const camera = new OrthographicCamera(left, right, top, bottom, near, far)

  // const cameraSphere = new SphereGeometry(4, 32, 32)
  // const cameraMaterial = new MeshBasicMaterial({ color: 0x00ff00 })
  // const cameraMesh = new Mesh(cameraSphere, cameraMaterial)
  // scene.add(cameraMesh)
  // onCleanup(() => {
  //   cameraMesh.removeFromParent()
  //   disposeRecursive(cameraMesh)
  // })

  const renderer = new WebGLRenderer({
    canvas: canvasElem,
    antialias: true,
    // powerPreference: "high-performance", "low-power", "default",
    powerPreference: "low-power",
  })
  onCleanupDispose(renderer)
  renderer.toneMapping = ACESFilmicToneMapping
  renderer.toneMappingExposure = 0.5
  renderer.setSize(canvasElem.width, canvasElem.height)

  let lookAtX = 0
  let lookAtY = 0
  let lookAtZ = 0
  createEffect(() => {
    // eslint-disable-next-line solid/reactivity
    const { position } = props
    if (position) {
      const { x, y } = position
      const stack = getEntityListAtXYMap().get(coord(x, y)) || []

      lookAtX = x * VERTICES_PER_TILE
      lookAtY = max(0, getEntityRenderHeight(stack))
      lookAtZ = y * VERTICES_PER_TILE
      // console.log('PositionIcon3D.lookAtX', {
      //   x: lookAtX,
      //   y: lookAtY,
      //   z: lookAtZ,
      // })
    }
  })

  let angle: number = 0
  const radius = VERTICES_PER_TILE * 0.5
  function render() {
    camera.rotation.x += 0.01
    angle += 0.0025
    camera.position.x = lookAtX + (radius * cos(angle)) // Update camera position (x)
    camera.position.z = lookAtZ + (radius * sin(angle)) // Update camera position (z)
    camera.lookAt(lookAtX, lookAtY, lookAtZ) // Ensure the camera looks at the center
    camera.position.y = lookAtY + radius

    // cameraMesh.position.copy(camera.position)

    renderer.render(scene, camera)
    animationId = requestAnimationFrame(render)
  }

  let animationId: number = 0
  onMount(() => {
    animationId = requestAnimationFrame(render)
  })

  onCleanup(() => {
    // console.log('PositionIcon3D.cleanup')
    cancelAnimationFrame(animationId)
    // renderer.domElement.remove()
    loseWebGL2Context(canvasElem)
  })

  return canvasElem
}

export default PositionIcon3D
