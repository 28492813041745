import { DEV } from "solid-js"
import type { InstancedMesh, Mesh, MeshStandardMaterial } from "three"
import type { Nullable } from "../../../typescript"
import { min } from "../../core/util/math"
import Unexpected from "../../Exception/Unexpected.class"
import disposeRecursive from "./disposeRecursive"

enum FadeOutMaterialAndCleanupObject3DResult {
  MESH_WAS_NULL = 1,
  NO_OPACITY = 2,
  DONE = 3,
}

export default function fadeOutMaterialAndCleanupObject3D(
  mesh: Nullable<Mesh | InstancedMesh>,
  duration: number,
): Promise<FadeOutMaterialAndCleanupObject3DResult> {
  // console.log('fadeOutMaterialAndCleanupObject3D.init', performance.now())
  return new Promise((resolve) => {
    if (!mesh) {
      // console.warn('fadeOutMaterialAndCleanupObject3D.!mesh')
      resolve(FadeOutMaterialAndCleanupObject3DResult.MESH_WAS_NULL)
      return
    }

    const { material } = mesh
    if (!('opacity' in material)) {
      if (DEV) {
        throw new Unexpected('fadeOutMaterialAndCleanupObject3D.!opacity')
      }
      resolve(FadeOutMaterialAndCleanupObject3DResult.NO_OPACITY)
      return
    }

    material.transparent = true

    let opacity: number = min(material.opacity, 1)
    const start: number = performance.now()

    function animate(timestampMs: number) {
      const elapsedMs = timestampMs - start
      const progress = elapsedMs / duration // Normalize progress (0 to 1)
      opacity = min(1 - (progress), opacity)
      // console.log('opacity', opacity)
      ;(material as MeshStandardMaterial).opacity = opacity
      // ;(material as MeshStandardMaterial).opacity = 0.5
      ;(material as MeshStandardMaterial).needsUpdate = true

      if (opacity > 0) {
        requestAnimationFrame(animate)
      } else {
        mesh!.visible = false // Hide when fully transparent
        mesh!.removeFromParent()
        disposeRecursive(mesh)
        // console.log('fadeOutMaterialAndCleanupObject3D.done', performance.now())
        resolve(FadeOutMaterialAndCleanupObject3DResult.DONE)
      }
    }

    requestAnimationFrame(animate)
  })
}
