import { keyArray } from "@solid-primitives/keyed"
import { createEffect, createSignal, onCleanup } from "solid-js"
import { type Scene } from "three"
import type { Nullable } from "vite-node"
import isIdListSame from "../../../../rx/signal/equals/isIdListSame"
import type { Engine } from "../../../core/engine/Engine.type"
import type { MountainEntity } from "../../../core/entity/EntityType/Mountain"
import { isEntityMountain } from "../../../core/entity/entityTypeMetaList.generated"
import { deepClone } from "../../../deep_clone"
import { isListSameXYID } from "../../../isListSameXYID"
import nullIfEmptyArray from "../../../ldsh/nullIfEmptyArray"
import fadeOutMaterialAndCleanupObject3D from "../../fn/fadeOutMaterialAndCleanupObject3D"
import createInitialMountainClusters from "./createInitialMountainClusters"
import createMountainTerrainMesh from "./createMountainTerrainMesh"

const FADE_DURACTION = 2000

// pointing diagonally in the same direction
export default function createMountainTerrainEffectV2 (
  engine : Engine,
  scene : Scene,
): void {
  // return
  // console.log('createMountainTerrainEffect')

  // let lastId = 200
  // const mountainEnts = [
  //   coord(3, 4),
  //   coord(3, 1),
  //   coord(5, 6),
  //   coord(6, 6),
  // ].map(({x, y}) => createMountainEntity(lastId++, x, y, MountainKind.Island))

  const [getMountainEnts, setMountainEnts] = createSignal<Array<MountainEntity>>([], {
    equals: isIdListSame,
  })

  createEffect(() => {
    setMountainEnts(engine.state.ents.filter(isEntityMountain))
  })


  function getChunks() {
    return createInitialMountainClusters(getMountainEnts())
  }

  // createEffect(() => {
  //   console.log('createInitialMountainClusters', createInitialMountainClusters(getMountainEnts()))
  // })

  // we'll use the first ID as the chunk ID
  function getChunkId (ents: Array<MountainEntity>) {
    return ents[0].id
  }

  const mapped = keyArray(getChunks, getChunkId, (
    getList,
    // getIndex
  ) => {
    // console.log('mapped', {index: getIndex(), chunk: getChunkId(getList())}, getList().length)
    // we must deep clone, unwrap will leave nested objects as proxies
    let prevList : Nullable<Array<MountainEntity>> = null

    let mesh : Nullable<Awaited<ReturnType<typeof createMountainTerrainMesh>>>

    // onMount(() => {
    //   console.log('mounted', {chunk: getChunkId(getList()), len: getList().length})
    // })

    onCleanup(async () => {
      // console.log('cleanup', {chunk: getChunkId(getList()), len: getList().length})
      fadeOutMaterialAndCleanupObject3D(mesh, FADE_DURACTION)
    })

    createEffect(async () => {
      const nextList = getList()
      if (isListSameXYID(prevList, nextList)) {
        return
      }
      // console.log('updating', getChunkId(nextList), nextList.map(e => e.id))

      const prevMesh = mesh

      const nextMesh = await (nullIfEmptyArray(nextList) && createMountainTerrainMesh(nextList))
      if (nextMesh) {
        nextMesh.position.y += 1
        scene.add(nextMesh)
      }
      fadeOutMaterialAndCleanupObject3D(prevMesh, FADE_DURACTION)
      mesh = nextMesh
      prevList = deepClone(nextList)
    })

    return null
  })
  // we need this effect otherwise nothing calls the mapped function
  createEffect(() => {
    mapped()
    // console.log('mapped.effect', mapped())
  })
}
