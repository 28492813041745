
/**
 * This file is automatically generated.
 **/
import { EntityTypeId } from "../EntityTypeId.enum"
import type { UnitTypeWeaponTypeTargetTypePair } from './UnitTypeWeaponTypeTargetTypePair.type'
import { WeaponTypeId } from "./WeaponTypeId.enum"

const unitTypeWeaponTypeTargetTypePairList: Array<UnitTypeWeaponTypeTargetTypePair> = [
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.Infantry, v: 5.5 / 6.0003 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.LightRecon, v: 1.2 / 1.644518 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.LightTank, v: 0.5 / 0.949937 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.MediumTank, v: 0.1 / 0.395807 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.APC, v: 1.4 / 0.474968 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.MediumArtillary, v: 1.5 / 1.644518 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.MediumRockets, v: 2.5 / 1.644518 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.LightGunAntiAir, v: 0.5 / 0.949937 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.MediumMissileAntiAir, v: 2.5 / 1.644518 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.MediumCopter, v: 0.7 / 0.949937 },
  { ut: EntityTypeId.Infantry, wt: WeaponTypeId.InfantryMachineGun, tt: EntityTypeId.TransportCopter, v: 3 / 1.644518 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.Infantry, v: 7 / 5.714293 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightRecon, v: 3.5 / 8.571429 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightTank, v: 0.6 / 2.142857 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumTank, v: 0.1 / 0.892857 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.APC, v: 4.5 / 1.071429 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumArtillary, v: 4.5 / 8.571429 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumRockets, v: 5.5 / 8.571429 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightGunAntiAir, v: 0.4 / 2.142857 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumMissileAntiAir, v: 2.8 / 8.571429 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumCopter, v: 1 / 2.142857 },
  { ut: EntityTypeId.LightRecon, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.TransportCopter, v: 3.5 / 8.571429 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.Infantry, v: 3.5 / 1.2 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightRecon, v: 8.5 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightTank, v: 5.5 / 6 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumTank, v: 1.5 / 2.5 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.APC, v: 7.5 / 3 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumArtillary, v: 7 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumRockets, v: 8.5 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightGunAntiAir, v: 6.5 / 6 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumMissileAntiAir, v: 8.5 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumCopter, v: 0 / 6 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.TransportCopter, v: 0 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.Infantry, v: 7.5 / 8.00001 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightRecon, v: 4 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightTank, v: 0.6 / 3 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumTank, v: 0.1 / 1.25 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.APC, v: 4.5 / 1.5 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumArtillary, v: 4.5 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumRockets, v: 5.5 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightGunAntiAir, v: 0.5 / 3 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumMissileAntiAir, v: 3 / 12 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumCopter, v: 1 / 3 },
  { ut: EntityTypeId.LightTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.TransportCopter, v: 4 / 12 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.Infantry, v: 5 / 2.228571 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightRecon, v: 10.5 / 22.285714 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightTank, v: 8.5 / 11.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumTank, v: 5.5 / 4.642857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.APC, v: 10.5 / 5.571429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumArtillary, v: 10.5 / 22.285714 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumRockets, v: 10.5 / 22.285714 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.LightGunAntiAir, v: 10.5 / 11.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumMissileAntiAir, v: 10.5 / 22.285714 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.MediumCopter, v: 0 / 11.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.TankCannon, tt: EntityTypeId.TransportCopter, v: 0 / 22.285714 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.Infantry, v: 10.5 / 5.714293 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightRecon, v: 4.5 / 8.571429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightTank, v: 0.8 / 2.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumTank, v: 0.1 / 0.892857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.APC, v: 4.5 / 1.071429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumArtillary, v: 4.5 / 8.571429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumRockets, v: 5.5 / 8.571429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightGunAntiAir, v: 0.7 / 2.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumMissileAntiAir, v: 3.5 / 8.571429 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumCopter, v: 1.2 / 2.142857 },
  { ut: EntityTypeId.MediumTank, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.TransportCopter, v: 4.5 / 8.571429 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.Infantry, v: 9 / 18.857143 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.LightRecon, v: 8 / 18.857143 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.LightTank, v: 7 / 9.428571 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.MediumTank, v: 4.5 / 3.928571 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.APC, v: 7 / 4.714286 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.MediumArtillary, v: 7.5 / 18.857143 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.MediumRockets, v: 8 / 18.857143 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.LightGunAntiAir, v: 7.5 / 9.428571 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.MediumMissileAntiAir, v: 8 / 18.857143 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.MediumCopter, v: 0 / 9.428571 },
  { ut: EntityTypeId.MediumArtillary, wt: WeaponTypeId.ArtillaryCannon, tt: EntityTypeId.TransportCopter, v: 0 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.Infantry, v: 9.5 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.LightRecon, v: 9 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.LightTank, v: 8.5 / 9.428571 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.MediumTank, v: 5.5 / 3.928571 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.APC, v: 8 / 4.714286 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.MediumArtillary, v: 8 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.MediumRockets, v: 8.5 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.LightGunAntiAir, v: 8.5 / 9.428571 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.MediumMissileAntiAir, v: 9 / 18.857143 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.MediumCopter, v: 0 / 9.428571 },
  { ut: EntityTypeId.MediumRockets, wt: WeaponTypeId.Rocket, tt: EntityTypeId.TransportCopter, v: 0 / 18.857143 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.Infantry, v: 4.5 / 4.888889 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.LightRecon, v: 5 / 6.485261 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.LightTank, v: 2.5 / 7.031477 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.MediumTank, v: 1 / 2.929782 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.APC, v: 4 / 3.515738 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.MediumArtillary, v: 4.5 / 6.485261 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.MediumRockets, v: 4.5 / 6.485261 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.LightGunAntiAir, v: 4.5 / 7.031477 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.MediumMissileAntiAir, v: 4.5 / 6.485261 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.MediumCopter, v: 12 / 7.031477 },
  { ut: EntityTypeId.LightGunAntiAir, wt: WeaponTypeId.AntiAirCannon, tt: EntityTypeId.TransportCopter, v: 12 / 6.485261 },
  { ut: EntityTypeId.MediumMissileAntiAir, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumCopter, v: 12 / 9.428571 },
  { ut: EntityTypeId.MediumMissileAntiAir, wt: WeaponTypeId.Missile, tt: EntityTypeId.TransportCopter, v: 12 / 18.857143 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.Infantry, v: 4.5 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.LightRecon, v: 5.5 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.LightTank, v: 5.5 / 7.714286 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumTank, v: 2.5 / 3.214286 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.APC, v: 6.5 / 3.857143 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumArtillary, v: 6.5 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumRockets, v: 6.5 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.LightGunAntiAir, v: 2.5 / 7.714286 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumMissileAntiAir, v: 6.5 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.MediumCopter, v: 0 / 7.714286 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.Missile, tt: EntityTypeId.TransportCopter, v: 0 / 15.428571 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.Infantry, v: 7.5 / 5.714293 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightRecon, v: 3 / 8.571429 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightTank, v: 0.6 / 2.142857 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumTank, v: 0.1 / 0.892857 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.APC, v: 2 / 1.071429 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumArtillary, v: 2.5 / 8.571429 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumRockets, v: 3.5 / 8.571429 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.LightGunAntiAir, v: 0.6 / 2.142857 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumMissileAntiAir, v: 3.5 / 8.571429 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.MediumCopter, v: 6.5 / 2.142857 },
  { ut: EntityTypeId.MediumCopter, wt: WeaponTypeId.VehicleMachineGun, tt: EntityTypeId.TransportCopter, v: 9.5 / 8.571429 },
]

export default unitTypeWeaponTypeTargetTypePairList
