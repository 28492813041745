import { Group, Mesh, MeshBasicMaterial, PlaneGeometry, Texture } from "three"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import createRenderMoveTileLinearGradientCanvasBufferV2 from "../../canvas/draw/tile/createRenderMoveTileLinearGradientCanvasBufferV2"
import type { Engine } from "../../core/engine/Engine.type"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import coord from "../../core/tile_position_xy/coord"
import { PI } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"
import setObjPosition from "./setObjPosition"

const tileSize = VERTICES_PER_TILE

const defaultPositionY = 0.5

const moveTileBlueBuffer = createOffscreenCanvas(1, 1)
createRenderMoveTileLinearGradientCanvasBufferV2(moveTileBlueBuffer, 128)

export default function createIsPositionReachableInOneTurnGroup (
  engine: Engine,
  isPositionReachableInOneTurn: (x: number, y: number) => boolean
) {
  // console.log('createIsPositionReachableInOneTurnGroup')
  const { width: stateWidth, height: stateHeight } = engine.state
  const group = new Group()
  const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

  for (let stateX = 0; stateX < stateWidth; stateX++) {
    for (let stateY = 0; stateY < stateHeight; stateY++) {
      if (isPositionReachableInOneTurn(stateX, stateY)) {
        const statePosition = coord(stateX, stateY)
        const geometry = new PlaneGeometry(tileSize * 0.9, tileSize * 0.9)
        const colorMap = new Texture(moveTileBlueBuffer)
        colorMap.needsUpdate = true
        const material = new MeshBasicMaterial({
          // color: new Color(0x0000ff),
          map: colorMap,
          transparent: true,
          // transparent: 0.5,
          // alp
          // opacity: 0.75,
          alphaMap: colorMap,
          // aoMap: colorMap,
          // alph
          fog: false,
          reflectivity: 0,
        })
        const plane = new Mesh(geometry, material)
        // plane.receiveShadow = true
        plane.rotateX(-PI / 2)

        group.add(plane)

        const stack = getEntityListAtXYMap().get(statePosition) || []
        const rh = getEntityRenderHeight(stack, null) + defaultPositionY
        setObjPosition(plane, statePosition, rh)
      }
    }
  }

  return group
}