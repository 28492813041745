/*eslint @typescript-eslint/no-explicit-any: ["off"]*/

import type { Nullable } from "vite-node"

interface HasXYID {
  x: number
  y: number
  id: number
}

/**
 * @param input
 *
 * @returns true/false if x, y, id (and order) are the same
 */
export function isListSameXYID(
  list1: Nullable<Array<HasXYID>>,
  list2: Nullable<Array<HasXYID>>
): boolean {
  if (list1 === list2) return true // Handle reference equality

  if (list1?.length !== list2?.length) return false // Different number of list

  for (let index = 0; index < list1!.length; index++) {
    const e1 = list1![index]
    const e2 = list2![index]
    if (e1.id !== e2.id) return false // Different id
    if (e1.x !== e2.x) return false // Different x
    if (e1.y !== e2.y) return false // Different y
  }

  return true // All properties match
}

