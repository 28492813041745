import Unexpected from "../../Exception/Unexpected.class"
import type { Engine } from "../engine/Engine.type"
import findByPosition from "../findByPosition"
import createLazyAttackableUnitAtGrid from "../tile_position_xy/createLazyAttackableUnitAtGrid"
import createLazyEfficientPathGrid from "../tile_position_xy/createLazyEfficientPathGrid"
import entityListToGridXY from "../tile_position_xy/entityListToGridXY"
import type { TilePositionXY } from "../tile_position_xy/TilePositionXY.type"
import toCoord from "../tile_position_xy/toCoord"
import calculateDestPositionHoverPositionPaths from "./calculateDestPositionHoverPositionPaths"
import type { DraftMove } from "./DraftMove.type"
import updateDraftMoveUnloadableCargoPath from "./updateDraftMoveUnloadableCargoPath"
import type { WeaponEstimate } from "./WeaponEstimate.type"

export default function setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(
  engine: Engine,
  draftMove: DraftMove,
  targetPosition: TilePositionXY
): void {
  // console.log('setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition')
  // console.log('draftMove.destPosition(old)', deepClone(draftMove.destPosition))
  const { state } = engine
  const { ents, width, height } = state
  const { unit, rangedEstimates } = draftMove

  const entityGridXY = entityListToGridXY(ents, width, height)
  const getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, unit)

  if (rangedEstimates) {
    const rangedEstimate = findByPosition(rangedEstimates, targetPosition)
    if (rangedEstimate) {
      const { estimate } = rangedEstimate
      if (estimate) {
        const firstWeaponWithoutErrors = estimate.unitWeapons.find((we : WeaponEstimate) => we.errors.length === 0)
        if (firstWeaponWithoutErrors) {
          draftMove.destPosition = null
          draftMove.destHeading = null
          draftMove.attackPosition = targetPosition
          draftMove.target = estimate.target
          return
        }
      }
    }
    // else assume this is a choice to move,
    // or otherwise not a ranged attack
  }

  const eptt = getEfficientPathToTile(targetPosition.x, targetPosition.y)
  // console.log('eptt', deepClone(eptt))

  if (!eptt) {
    // not sure if bug or undefined behavior
    // throw new Unexpected('!eptt')
    return
  }
  const firstTurn = eptt.turns[0]
  if (firstTurn) {
    const lastStep = firstTurn.steps.at(-1)
    if (lastStep) {
      // console.log('draftMove.destPosition = toCoord(lastStep)', toCoord(lastStep))
      draftMove.destPosition = toCoord(lastStep)
      calculateDestPositionHoverPositionPaths(engine)
      updateDraftMoveUnloadableCargoPath(engine)
      // TODO should calculateDraftMoveAttackEstimates be called here?
    }
    // else {
    // // if there is not a lastStep,
    // // it is probably a ranged attack
    // // or the unit is already orthogonally adjacent
    //}
  }

  if (eptt.targetStep && eptt.turns.length <= 1) {
    // console.log('eptt', eptt)
    // console.log('draftMove.attackPosition = targetPosition', targetPosition)

    const attackableUnitGrid = createLazyAttackableUnitAtGrid(width, height, entityGridXY, unit)
    const attackableUnitsAtPosition = attackableUnitGrid(targetPosition.x, targetPosition.y)
  
    // console.log('attackableUnitsAtPosition', attackableUnitsAtPosition)
    if (attackableUnitsAtPosition.length === 1) {
      draftMove.target = attackableUnitsAtPosition[0]
    } else if (attackableUnitsAtPosition.length > 1) {
      // the user will need to pick their target
      throw new Unexpected('attackableUnitsAtPosition.length > 1')
    }

    draftMove.attackPosition = targetPosition
  } else {
    // console.log('draftMove.attackPosition = (no change)')
  }
  // console.log('draftMove.destPosition(new)', deepClone(draftMove.destPosition))
}
