import { createEffect, DEV } from "solid-js"
import { CanvasTexture, DoubleSide, Group, LinearFilter, Mesh, MeshBasicMaterial, PlaneGeometry, type Object3D, type Scene } from "three"
import { parse } from "valibot"
import type { Nullable } from "vite-node"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import createRenderBlockedTargetIconCanvasBufferV2 from "../../canvas/draw/tile/createRenderBlockedTargetIconCanvasBufferV2"
import createRenderUnloadTargetIconCanvasBufferV2 from "../../canvas/draw/tile/createRenderUnloadTargetIconCanvasBufferV2"
import getSelectedOrHoveredUnloadCargoTileForIcon from "../../core/draft_move/getSelectedOrHoveredUnloadCargoTileForIcon"
import type { Engine } from "../../core/engine/Engine.type"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import { EntityHeadingEastDeg, EntityHeadingWestDeg } from "../../core/entity/EntityHeading/consts"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import type { EntityHeading } from "../../core/entity/EntityHeading/EntityHeading.type"
import setHeadingToObject3DRotation from "../../core/entity/EntityHeading/setHeadingToObject3DRotation"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import { SelectedToolId } from "../../core/map_editor/SelectedToolId.enum"
import type { UnloadableCargoTile } from "../../core/tile_position_xy/findUnloadableCargoTileCollection"
import getMiddlePositionBetweenPositions from "../../core/tile_position_xy/getMiddlePositionBetweenPositions"
import toCoord from "../../core/tile_position_xy/toCoord"
import { PI } from "../../core/util/math"
import { POSTGRES_MAX_INT } from "../../core/util/number"
import { deepClone } from "../../deep_clone"
import { VERTICES_PER_TILE } from "../consts"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import createBlueUnloadCargoArrow from "./createBlueUnloadCargoArrow"
import type { EntityThreeModelCollection } from "./createEntityThreeModelCollection"
import setObjPosition from "./setObjPosition"

const OPACITY_NORMAL = 0.75

export default function createUnloadCargoTargetPositionObject3D(
  engine : Engine,
  scene : Scene,
  entityThreeModelCollection: EntityThreeModelCollection
): void {

  const {
    //group: unitEntitiesGroup,
    ents: entityThreeModelMap,
  } = entityThreeModelCollection

  const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

  const unloadTargetIconBuffer = createOffscreenCanvas(1, 1)
  const blockedTargetIconBuffer = createOffscreenCanvas(1, 1)

  const group : Group = new Group()

  createRenderBlockedTargetIconCanvasBufferV2(blockedTargetIconBuffer, 512)
  const blockedTargetIconTexture = new CanvasTexture(blockedTargetIconBuffer)
  blockedTargetIconTexture.minFilter = LinearFilter // Prevent mipmaps
  blockedTargetIconTexture.needsUpdate = true
  const blockedTargetIconMaterial = new MeshBasicMaterial({
    map: blockedTargetIconTexture,
    transparent: true,
    opacity: OPACITY_NORMAL,
    side: DoubleSide, // Ensure visibility from both sides if needed
  })
  const blockedIconSize = VERTICES_PER_TILE * 1.25
  const blockedTargetIconGeometry = new PlaneGeometry(blockedIconSize, blockedIconSize)
  const blockedTargetIconPlane = new Mesh(blockedTargetIconGeometry, blockedTargetIconMaterial)
  blockedTargetIconPlane.rotateX(PI/2)
  group.add(blockedTargetIconPlane)

  createRenderUnloadTargetIconCanvasBufferV2(unloadTargetIconBuffer, 512)
  const unloadTargetIconTexture = new CanvasTexture(unloadTargetIconBuffer)
  unloadTargetIconTexture.minFilter = LinearFilter // Prevent mipmaps
  unloadTargetIconTexture.needsUpdate = true
  const unloadTargetIconMaterial = new MeshBasicMaterial({
    map: unloadTargetIconTexture,
    transparent: true,
    opacity: OPACITY_NORMAL,
    side: DoubleSide, // Ensure visibility from both sides if needed
  })
  const unloadIconSize = VERTICES_PER_TILE * 1.25
  const unloadTargetIconGeometry = new PlaneGeometry(unloadIconSize, unloadIconSize)
  const unloadTargetIconPlane = new Mesh(unloadTargetIconGeometry, unloadTargetIconMaterial)
  unloadTargetIconPlane.rotateX(PI/2)
  group.add(unloadTargetIconPlane)

  let blueUnloadCargoArrow : Object3D | undefined
  createBlueUnloadCargoArrow(engine, (obj) => {
    blueUnloadCargoArrow = obj
    blueUnloadCargoArrow.visible = false
    group.add(blueUnloadCargoArrow)
  })

  createRenderUnloadTargetIconCanvasBufferV2(unloadTargetIconBuffer, 512)

  let cargoUnitObject3D : Nullable<EntityThreeModel['obj']>

  createEffect(() => {
    const { draftMove, hoveredPosition, selectedTool } = engine
    if (selectedTool === SelectedToolId.MoveUnit && draftMove) {
      const { attackPosition } = draftMove
      if (!attackPosition) {
        const ucpGroup : Nullable<UnloadableCargoTile> = getSelectedOrHoveredUnloadCargoTileForIcon(draftMove, hoveredPosition)

        if (ucpGroup) {
          console.log('[createHoverPositionMesh][effect] Unload', deepClone({
            draftMove,
            ucpGroup,
          }))

          const entityThreeModel = entityThreeModelMap.get(ucpGroup.cargo_id)
          if (entityThreeModel) {
            const { obj } = entityThreeModel
            cargoUnitObject3D = obj
            if (obj && blueUnloadCargoArrow) {
              const { unit, destPosition } = draftMove
              console.log('[createHoverPositionMesh][effect] Unload entityThreeModel', entityThreeModel)
              const stack = getEntityListAtXYMap().get(toCoord(ucpGroup)) || []
              const rh = 1 + getEntityRenderHeight(stack)
                + (ucpGroup.blocked ? 5 : 0)
              setObjPosition(obj, ucpGroup, rh)
              // console.log('[createHoverPositionMesh][effect]', deepClone({
              //   p1: toCoord(destPosition || unit),
              //   p2: toCoord(ucpGroup),
              // }))
              // let unloadHeading = getEntityHeadingFromAToB(destPosition || unit, ucpGroup)
              let unloadHeading = ucpGroup.heading
              console.log('ucpGroup', deepClone(ucpGroup))
              if (DEV) {
                parse(DegreeNumberSchema, unloadHeading)
              }
              if (unloadHeading === EntityHeadingEastDeg) {
                unloadHeading = EntityHeadingWestDeg
              } else if (unloadHeading === EntityHeadingWestDeg) {
                unloadHeading = EntityHeadingEastDeg
              }
              console.log('unloadHeading', unloadHeading)
          
              const middleTilePosition = getMiddlePositionBetweenPositions(destPosition || unit, ucpGroup)
              setObjPosition(blueUnloadCargoArrow, middleTilePosition, rh + 10)
              blueUnloadCargoArrow.userData.brh = rh + 10
              // obj.userData.targetHeading = unloadHeading - 180
              obj.userData.targetHeading = unloadHeading
              applyEntityRotationToNextObject3DFrame(obj, POSTGRES_MAX_INT)
              setHeadingToObject3DRotation(blueUnloadCargoArrow, (unloadHeading + 180) as EntityHeading)
              obj.visible = true
              blueUnloadCargoArrow.visible = true
              console.log('blueUnloadCargoArrow', middleTilePosition, blueUnloadCargoArrow, blueUnloadCargoArrow.visible)
              if (ucpGroup.blocked) {
                console.log('[createHoverPositionMesh][effect] Unload', 'BLOCKED')
                setObjPosition(blockedTargetIconPlane, ucpGroup, rh)
                unloadTargetIconPlane.visible = false
                blockedTargetIconPlane.visible = true
              } else {
                setObjPosition(unloadTargetIconPlane, ucpGroup, rh)
                blockedTargetIconPlane.visible = false
                unloadTargetIconPlane.visible = true
              }
            }
            return
          }
        }
      }
    }
    blockedTargetIconPlane.visible = false
    unloadTargetIconPlane.visible = false
    if (cargoUnitObject3D) {
      cargoUnitObject3D.visible = false
      cargoUnitObject3D = null
    }
    if (blueUnloadCargoArrow) {
      blueUnloadCargoArrow.visible = false
    }
  })

  scene.add(group)
}
