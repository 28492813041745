import { Mesh, MeshBasicMaterial, MeshStandardMaterial, RepeatWrapping } from 'three'
import type { Engine } from '../../../core/engine/Engine.type'
import type { ShoalEntity } from '../../../core/entity/EntityType/Shoal'
import tmpRng from '../../../core/rng/tmpRng'
import { PI } from '../../../core/util/math'
import applyVertexFunctionStandardMaterialV2 from '../../fn/applyVertexFunctionStandardMaterialV2'
import generateNoiseTexture from '../../fn/generateNoiseTexture'
import { useShoalSandColors } from './colors'
import createShoalTerrainGeometry from './createShoalTerrainGeometry'

const debugWireFrame = false

// a regular plane will have all it's triangles
// pointing diagonally in the same direction
export default function createShoalTerrainMesh (
  engine : Engine,
  shoalEnts : Array<ShoalEntity>,
) {
  const geometry = createShoalTerrainGeometry(engine, shoalEnts)
  geometry.rotateX(-PI/2)
  // console.log('geometry', geometry)

  // const geometry = new PlaneGeometry(10, 10, 10, 10)

  if (debugWireFrame) {
    const material = new MeshBasicMaterial({
      wireframe: true,
    })
    const mesh = new Mesh(geometry, material)
    mesh.position.y += 5

    return mesh
  } else {
    const material = new MeshStandardMaterial({
      // wireframe: true,
    })
 
    const noiseTexture1 = generateNoiseTexture(64, tmpRng, 127, 255)
    noiseTexture1.wrapS = noiseTexture1.wrapT = RepeatWrapping
    noiseTexture1.repeat.set(64, 64)
    material.bumpMap = noiseTexture1

    const noiseTexture2 = generateNoiseTexture(64, tmpRng, 127, 255)
    noiseTexture2.wrapS = noiseTexture2.wrapT = RepeatWrapping
    noiseTexture2.repeat.set(64, 64)
    material.lightMap = noiseTexture2
    material.lightMapIntensity = 1
    // material.wireframe = true

    const mesh = new Mesh(geometry, material)

    applyVertexFunctionStandardMaterialV2(mesh, useShoalSandColors)

    return mesh
  }
}
