import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { M1Abrams1_M1Abrams1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"

const totalLoopMs = 1000

export default function makeMediumTankScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(M1Abrams1_M1Abrams1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj
    
    // scene.scale.multiplyScalar(scale)

    // console.log('rootScene', rootScene)

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)

    const gun2Mesh = scene.getObjectByName('Gun2') as Mesh
    assertClassInDev(gun2Mesh, Mesh)

    const turretGroup = scene.getObjectByName('Turret') as Group
    assertClassInDev(turretGroup, Group)

    const defaultBodyY = bodyGroup.position.y
    // const defaultTurretY = turretGroup.position.y
    // const defaultGun2Y = gun2Mesh.position.y

    bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultBodyY
        // turretGroup.position.y = defaultTurretY
        // gun2Mesh.position.y = defaultGun2Y
      } else if (ent.player_id) {
        const bounceOffset =  (cubicEaseInOutBounce(mod / 500) / 7)
        bodyGroup.position.y = defaultBodyY + bounceOffset
        // turretGroup.position.y = defaultTurretY + bounceOffset
        // gun2Mesh.position.y = defaultGun2Y + bounceOffset
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    applyPlayerColors(scene, ent, pto)

    callback(scene)
  })
}
