import { A, useSearchParams } from '@solidjs/router'
import { FormLabel } from 'solid-bootstrap'
import { FaSolidCentSign, FaSolidGun } from 'solid-icons/fa'
import { createEffect, createMemo, createSignal, For, Show, type Accessor } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import FormGroup from '../../../components/Form/FormGroup'
import SingleEntityIcon3D from '../../../components/MapEditor/SingleEntityIcon3D'
import NA from '../../../components/Util/na'
import bindEngineToComponent from '../../../lib/canvas/bindEngineToComponentV1'
import createDraftMoveAttackEstimate from '../../../lib/core/draft_move/createDraftMoveAttackEstimate'
import type { DraftMove_AttackEstimate } from '../../../lib/core/draft_move/DraftMove_AttackEstimate.type'
import createEngineWithTwoPlayersForUI from '../../../lib/core/engine/createEngineWithTwoPlayersForUI'
import { Engine } from '../../../lib/core/engine/Engine.type'
import type { BaseEntity } from '../../../lib/core/entity/BaseEntity'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import type { EntityTypeId } from '../../../lib/core/entity/EntityTypeId.enum'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import type { Entity } from '../../../lib/core/entity/index'
import { EntityTypeMeta, isSpriteUnit } from '../../../lib/core/EntityTypeMeta'
import { HasAmmo } from '../../../lib/core/has_ammo'
import type { HasHP } from '../../../lib/core/has_hp'
import { HEALTH_POINTS_UI_STEP, MAX_HEALTH_POINTS, MIN_HEALTH_POINTS, type HP } from '../../../lib/core/hp.type'
import formatGameMoney from '../../../lib/core/player/formatGameMoney'
import coord from '../../../lib/core/tile_position_xy/coord'
import { max, min } from '../../../lib/core/util/math'
import { assign } from '../../../lib/core/util/Object'
import toFixed1 from '../../../lib/core/util/toFixed1'
import { deepClone } from '../../../lib/deep_clone'
import parseValidNumberFromInputEventOrNull from '../../../lib/dom/event/parseValidNumberFromInputEventOrNull'
import isNotNil from '../../../lib/ldsh/isNotNil'
import { toSlug } from '../../../lib/slug'
import { createFirstPlayerIdMemo } from '../../../rx/memo/createFirstPlayerIdMemo'
import { createSharedPlayerThreeObjectsMap } from '../../../rx/memo/createSharedPlayerThreeObjectsMap'
import { Nullable } from '../../../typescript'

type DamageCalculatorSearchParams = Record<'unit', string>

const CodexDamageCalculatorIndexPage = () => {
  // console.log('CodexDamageCalculatorIndexPage')

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteUnit(sprite))
  // console.table(entityTypeMetaList.filter((sprite: EntityType) => sprite))

  const xy0 = coord(0, 0)
  const xy1 = coord(1, 0)

  const engine: Engine = createMutable(createEngineWithTwoPlayersForUI())
  // need sample player for UI engine
  const samplePlayerOneId = createFirstPlayerIdMemo(engine)
  const samplePlayerTwoId = createMemo<number>(() => {
    console.log('player2', deepClone(engine.state.players[1]))
    return engine.state.players[1]?.id
  })

  const [selectedUnitEntityTypeMeta, setSelectedUnitEntityTypeMeta] = createSignal<Nullable<EntityTypeMeta>>(null)
  // const [selectedTargetEntityTypeMeta, setSelectedTargetEntityTypeMeta] = createSignal<Nullable<EntityTypeMeta>>(null)

  const [searchParams, setSearchParams] = useSearchParams<DamageCalculatorSearchParams>()
  createEffect(() => {
    const etm = entityTypeMetaList.find((entityTypeMeta: EntityTypeMeta) => entityTypeMeta && isSpriteUnit(entityTypeMeta) && toSlug(entityTypeMeta.dname) === toSlug(searchParams.unit))
    setSelectedUnitEntityTypeMeta(etm)
    const defaultHp = (etm?.entDefaults as HasHP)?.hp || 0
    if (unitHp() === 0 && defaultHp > 0) {
      setUnitHp(defaultHp)
    }
  })

  // const [hiddenList, setHiddenList] = createSignal<Array<EntityTypeId>>([])
  const configList = createMutable(createConfig(entityTypes))

  const [unitHp, setUnitHp] = createSignal<HP>(0)

  bindEngineToComponent(engine)

  // no idea what, but this page crashes
  // if you don't pre-fill the PTO cache
  createSharedPlayerThreeObjectsMap(engine)

  return (
    <EngineContext.Provider value={engine}>
      <div class="pagec">
        <h2>
          <A href="/codex">Codex</A>{' - '}
          <Show when={selectedUnitEntityTypeMeta()}
            fallback={'Damage Calculator'}
            children={(unitEntityTypeMeta: Accessor<EntityTypeMeta>) => {
              return <>
                <A href="/codex/damage-calculator">Damage Calculator</A>{' - '}
                {unitEntityTypeMeta()?.dname}
              </>
            }} />
        </h2>
        <Show when={selectedUnitEntityTypeMeta()}
          fallback={
            <div class="container text-center">
              <div class="row">
                <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
                  {(entityType: EntityTypeMeta) => {
                    return (
                      <div class="col">
                        <div class="card mx-auto my-2" style={{ 'width': '12rem' }}
                          onClick={() => {
                            setSearchParams({
                              unit: toSlug(entityType.dname)
                            })
                          }}
                        >
                          <div class="card-body">
                            <h5 class="card-title">
                              <A href={`/codex/unit/${toSlug(entityType.dname)}`}>
                                {entityType.dname}
                              </A>
                            </h5>
                            <button class="btn btn-secondary btn-sm">
                              <SingleEntityIcon3D
                                entity={createSampleEntity(entityType, samplePlayerOneId())}
                                size={40}
                              />
                              {' Select'}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </For>
              </div>
            </div>
          }
          children={(unitEntityTypeMeta: Accessor<EntityTypeMeta>) => {
            const sampleUnit = createMemo<Entity & HasHP>(() => {
              const specifiedHp = unitHp()
              const su = createSampleEntity(unitEntityTypeMeta(), samplePlayerOneId())
              if (specifiedHp > 0 && 'hp' in su) {
                su.hp = specifiedHp
              }
              assign(su, xy0)
              return su as Entity & HasHP
            })

            return (
              <div>
                <FormGroup>
                  <FormLabel>Unit HP: {unitHp()}</FormLabel>
                  <input class="form-control" name="unit_hp" type="number"
                    value={unitHp().toFixed(1) || ''}
                    onInput={(event: InputEvent) => {
                      const newValue = parseValidNumberFromInputEventOrNull(event)
                      if (isNotNil(newValue)) {
                        setUnitHp(min(MAX_HEALTH_POINTS, max(HEALTH_POINTS_UI_STEP, newValue)))
                      }
                    }}
                    step={HEALTH_POINTS_UI_STEP} min={MIN_HEALTH_POINTS} max={MAX_HEALTH_POINTS}
                    classList={{
                      'is-invalid': !(unitHp() > MIN_HEALTH_POINTS && unitHp() <= MAX_HEALTH_POINTS),
                    }}
                  />
                </FormGroup>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <td>DMG</td>
                      <td />
                      <td>DMG</td>
                      <td>Match</td>
                      <td class="text-end">Price</td>
                      <td class="text-end">HP</td>
                      <td class="text-end">Ammo</td>
                    </tr>
                  </thead>
                  <tbody>
                    <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
                      {(entityType: EntityTypeMeta) => {
                        const config = (): ConfigRow => configList.find(config => config.etype_id === entityType.id) || ({ hidden: true } as ConfigRow)

                        const sampleEntity = createMemo<Entity & HasHP>(() => {
                          const { hp } = config()
                          return {
                            ...createSampleEntity(entityType, samplePlayerTwoId()),
                            ...xy1,
                            hp,
                          } as Entity & HasHP
                        })

                        const ammo = () => (sampleEntity() as BaseEntity).weapons?.find(weapon => weapon.ammo)?.ammo

                        const daEstimate = createMemo<DraftMove_AttackEstimate>(() => {
                          const u = sampleUnit()
                          const v = createDraftMoveAttackEstimate(engine, u, coord(u.x, u.y), sampleEntity())
                          // console.log('daEstimate', entityType.dname, v)
                          return v
                        })

                        return <tr classList={{ 'd-none': config().hidden }}>
                          <td class="text-monospace text-danger">-{toFixed1(daEstimate().unitDmg)}</td>
                          <td>
                            <SingleEntityIcon3D entity={sampleUnit()} size={32} />
                            {' '}<FaSolidGun />{' '}
                            <SingleEntityIcon3D entity={sampleEntity()} size={32} />
                          </td>
                          <td class="text-monospace text-danger">-{toFixed1(daEstimate().targetDmg)}</td>
                          <td>
                            <A href={`/codex/unit/${toSlug(entityType.dname)}`}>
                              {entityType.dname}
                            </A>
                          </td>
                          <td class="text-end">
                            <span class="text-muted ps-1"><FaSolidCentSign /></span>
                            {formatGameMoney(entityType.price)}
                          </td>
                          <td class="text-end">
                            <input class="text-end" name={`ent_hp[${entityType.id}]`} type="number"
                              value={sampleEntity().hp && sampleEntity().hp.toFixed(1) || ''}
                              onInput={(event: InputEvent) => {
                                const newValue = parseValidNumberFromInputEventOrNull(event)
                                if (isNotNil(newValue)) {
                                  modifyMutable(config(), produce((config: ConfigRow) => {
                                    config.hp = min(MAX_HEALTH_POINTS, max(HEALTH_POINTS_UI_STEP, newValue))
                                  }))
                                }
                              }}
                              step={HEALTH_POINTS_UI_STEP} min={MIN_HEALTH_POINTS} max={MAX_HEALTH_POINTS}
                              classList={{
                                'is-invalid': !(unitHp() > MIN_HEALTH_POINTS && unitHp() <= MAX_HEALTH_POINTS),
                              }}
                            />
                          </td>
                          <td class="text-end">
                            {ammo() ?? NA()}
                          </td>
                        </tr>
                      }}
                    </For>
                  </tbody>
                </table>
              </div>
            )
          }}
        />
      </div>
    </EngineContext.Provider>
  )
}

export default CodexDamageCalculatorIndexPage

type ConfigRow = {
  etype_id: EntityTypeId,
  hidden: boolean,
  defaultHp: HP,
  hp: HP,
}

function createConfig(entityTypes: Array<EntityTypeMeta>): Array<ConfigRow> {
  return entityTypes.map((etm: EntityTypeMeta): ConfigRow => {
    const { hp: defaultHp } = etm.entDefaults as HasAmmo & HasHP
    return {
      etype_id: etm.id,
      hidden: false,
      defaultHp,
      hp: defaultHp,
    }
  })
}