import { InferOutput, literal, object } from 'valibot'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { HasAnnexPointsSchema } from '../../HasAnnexPoints.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { BASE_TILE_SIZE } from '../../view_ctx'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'

const entDefaults = {
  etype_id: EntityTypeId.Factory,
  layer_id: EntityLayerId.TerrainL2,
  ap: 20,
  ap_ent_id: null,
} as const

export const FactoryEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Factory),
  layer_id: literal(EntityLayerId.TerrainL2),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
  player_id: PlayerIdOrNullSchema,
  ...HasAnnexPointsSchema.entries,
})
export type FactoryEntity = InferOutput<typeof FactoryEntitySchema>

export const FactoryEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  h: 2 * BASE_TILE_SIZE,
  id: EntityTypeId.Factory,
  dname: 'Factory',
  layer_id: EntityLayerId.TerrainL2,
  defense: 3,
  income: 10,
  resupplies: true,
  entDefaults,
}

export function createFactoryEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): FactoryEntity {
  return {
    ...entDefaults,
    heading: 0,
    id,
    x,
    y,
    player_id,
  }
}
