import { DEV } from "solid-js"
import { Mesh, type MeshStandardMaterial, type Object3D } from "three"
import { parse } from "valibot"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import { createSharedPlayerThreeObjectsMap } from "../../../rx/memo/createSharedPlayerThreeObjectsMap"
import type { Nullable } from "../../../typescript"
import type { Engine } from "../../core/engine/Engine.type"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import toCoord from "../../core/tile_position_xy/toCoord"
import applyPlayerColors from "../fn/applyPlayerColors"
import setObjPosition from "../fn/setObjPosition"
import addEntityThreeModelUnitSprite from "./addEntityThreeModelUnitSprite"
import type { EntityThreeModel } from "./EntityThreeModel.type"
import getEntityExtraRenderHeight from "./getEntityExtraRenderHeight"

export default function updateEntityThreeModel(
  entityThreeModel: EntityThreeModel,
  ent: EntityThreeModel['ent'],
  engine: Engine,
): void {

  const {
    obj,
    // ent: oldEnt
  } = entityThreeModel
  entityThreeModel.ent = ent
  // console.log('[updateEntityThreeModel]', entityDisplayName(ent), deepClone(ent), obj)
  if (obj) {
    // console.log('oldEnt.player_id', oldEnt.player_id, 'ent.player_id', ent.player_id)
    obj.userData.id = ent.id
    obj.userData.e = ent
    // if (isEntityLightRecon(ent)) {
    //   console.log('[updateEntityThreeModel] obj.userData.targetHeading =', {
    //     newEntHeading: ent.heading,
    //     oldEntHeading: oldEnt.heading,
    //   }, obj, deepClone({ent, oldEnt}))
    // }
    if (DEV) {
      parse(DegreeNumberSchema, ent.heading)
    }
    
    obj.userData.targetHeading = ent.heading
    // console.log('[EntityGroup].update', entityDisplayName(ent), ent.id, deepClone(ent))
    const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

    const rh = getEntityRenderHeight(getEntityListAtXYMap().get(toCoord(ent)) || [], ent)
      + getEntityExtraRenderHeight(ent)

    setObjPosition(obj, ent, rh)
    // obj.position.set(x, rh, z)
    // console.log('newGroup.position.set', JSON.stringify(obj.position).replaceAll(/"/g, ''), entityDisplayName(ent), ent.id)

    const getPlayerThreeObject = createSharedPlayerThreeObjectsMap(engine)
    const pto = getPlayerThreeObject(ent.player_id)
    applyPlayerColors(obj, ent, pto)
    addEntityThreeModelUnitSprite(engine, obj, ent)
    // addEntityThreeModelVerticalHealthBarSprite(obj, ent)
    // addEntityThreeModelCargoIconSprite(obj, ent)

    let newVisible : boolean = true
    if (ent.taxi_id) {
      newVisible = false
    }
    obj.visible = newVisible

    if (shouldEntityShowFadedFrames(ent)) {
      obj.traverse(setFadedFrames)
    } else {
      obj.traverse(unsetFadedFrames)
    }
  }
}

function setFadedFrames(obj: Object3D): void {
  if (obj instanceof Mesh) {
    const material = obj.material as Nullable<MeshStandardMaterial>
    if (material && material.aoMap) {
      material.aoMapIntensity = 0.5
    }
  }
}
function unsetFadedFrames(obj: Object3D): void {
  if (obj instanceof Mesh) {
    const material = obj.material as Nullable<MeshStandardMaterial>
    if (material && material.aoMap) {
      material.aoMapIntensity = 0
    }
  }
}