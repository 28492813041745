import { createEffect } from "solid-js"
import { type Scene } from "three"
import type { Nullable } from "vite-node"
import type { Engine } from "../../../core/engine/Engine.type"
import { isEntityRiver } from "../../../core/entity/entityTypeMetaList.generated"
import nullIfEmptyArray from "../../../ldsh/nullIfEmptyArray"
import disposeRecursive from "../../fn/disposeRecursive"
import createRiverTerrainMesh from "./createRiverTerrainMesh"

// pointing diagonally in the same direction
export default function createRiverTerrainEffect (
  engine : Engine,
  scene : Scene,
): void {
  // return
  // console.log('createRiverTerrainEffect')
  let mesh : Nullable<ReturnType<typeof createRiverTerrainMesh>>

  // let lastId = 200
  // const riverEnts = [
  //   coord(3, 4),
  //   coord(3, 1),
  //   coord(5, 6),
  //   coord(6, 6),
  // ].map(({x, y}) => createRiverEntity(lastId++, x, y))

  createEffect(() => {
    // console.log('[createRiverTerrainEffect].effect')

    const riverEnts = engine.state.ents.filter(isEntityRiver)

    const prevMesh = mesh
    const nextMesh = nullIfEmptyArray(riverEnts) && createRiverTerrainMesh(engine, riverEnts)
    if (prevMesh) {
      prevMesh.removeFromParent()
      disposeRecursive(prevMesh)
    }
    // nextMesh.rotateX(-PI / 2)
    // nextMesh.position.y = 10
    // nextMesh.position.y = 20
    // nextMesh.scale.multiplyScalar(100)

    if (nextMesh) {
      scene.add(nextMesh)
    }

    // const position = coord(4, 4)
    // setObjPosition(nextMesh, position)

    mesh = nextMesh
  })
}
