import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { sin } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { CH47Chinook1_CH47Chinook1_GLTF_Model } from "../gltf_models.generated"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"

export default function makeTransportCopterScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(CH47Chinook1_CH47Chinook1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    // const group = new Group()

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)

    const frontRotorGroup = scene.getObjectByName('Rotor') as Mesh
    assertClassInDev(frontRotorGroup, Mesh)
    const rearRotorGroup = frontRotorGroup.clone()
    rearRotorGroup.position.y += 1.5
    rearRotorGroup.position.z -= 19.5

    bodyGroup.add(rearRotorGroup)

    // bodyGroup.rotateX(PI / 2)
    // bodyGroup.position.y += 10

    // turretGroup.position.y -= 2
    // frontRotorGroup.position.y = 2.5

    // bodyGroup.add(frontRotorGroup)
    // group.add(bodyGroup)

    // const leftTrackMesh = rootScene.getObjectByName('LeftTrack') as Mesh
    // assertClassInDev(barrelMesh, Mesh)

    // const rightTrackMesh = leftTrackMesh.clone()
    // rightTrackMesh.name = 'RightTrack'

    // group.add(leftTrackMesh)
    // group.add(rightTrackMesh)

    // rightTrackMesh.position.x = 9

    applyPlayerColors(scene, ent, pto)

    scene.position.y += 6
    const defaultGroupY = bodyGroup.position.y
    // const defaultHullX = 0

    scene.userData.update = (elapsedMs: number, frameAt: number): void => {
      // const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultGroupY
      } else if (ent.player_id) {
        bodyGroup.position.y = defaultGroupY + ((sin(frameAt / 1000)) + 1) / 2
        frontRotorGroup.rotation.y = (frameAt / 120)
        rearRotorGroup.rotation.y = (-frameAt / 120)
      }

      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    callback(scene)
  })
}

