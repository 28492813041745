import type { Nullable } from "vite-node"
import { isEntityDesert, isEntityPlain, isEntityRiver, isEntitySea, isEntityShoal, isEntitySnow } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"

export const DEFAULT_SEA_TILE_ELEVATION = -2
export const DEFAULT_LAND_TILE_ELEVATION = 1

export default function getTerrainMesh1TileElevation (ent: Nullable<Entity>): number {
  if (ent) {
    if (isEntityPlain(ent)) {
      return DEFAULT_LAND_TILE_ELEVATION
    }
    if (isEntityDesert(ent)) {
      return DEFAULT_LAND_TILE_ELEVATION
    }
    if (isEntitySnow(ent)) {
      return DEFAULT_LAND_TILE_ELEVATION
    }
    if (isEntitySea(ent)) {
      return DEFAULT_SEA_TILE_ELEVATION
    }
    if (isEntityRiver(ent)) {
      return DEFAULT_LAND_TILE_ELEVATION
    }
    // not the real elevation, but used for ridges
    if (isEntityShoal(ent)) {
      return DEFAULT_SEA_TILE_ELEVATION
    }
    return 3
  }
  return 2
}