import { onCleanup } from "solid-js"
import { createCanvasElement } from "three"
import { loseWebGL2Context } from "../../canvas/loseWebGL2Context"

export default function createMountedCanvasElement() {
  const canvasElem: HTMLCanvasElement = createCanvasElement()
  onCleanup(() => {
    loseWebGL2Context(canvasElem)
  })
  return canvasElem
}