import { CanvasTexture, type Scene } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"

function createGradientTexture(): CanvasTexture {
  const canvas = createOffscreenCanvas(256, 256)
  const ctx = getCanvas2dContext(canvas)

  if (ctx) {
    // Create gradient (top to bottom)
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height)
    gradient.addColorStop(0, "#00b5ff") // Light blue (top)
    // gradient.addColorStop(0, "#48caff") // Light blue (top)
    // gradient.addColorStop(0, "#48caff") // Light blue (top)
    // gradient.addColorStop(0, "#87CEEB") // Light blue (top)
    gradient.addColorStop(1, "#E0F6FF") // Soft white-blue (bottom)

    // Apply gradient to canvas
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }

  // Convert to Three.js texture
  const texture = new CanvasTexture(canvas)
  texture.needsUpdate = true
  return texture
}

export default function createGradientTextureSky (scene : Scene): void {
  scene.background = createGradientTexture()
}