import { FaSolidArrowsRotate } from 'solid-icons/fa'
import { Component, createMemo, createSignal, Match, Show, Switch, useContext, type Accessor } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getPerTurnIncomeFromPlayerEnts from '../../lib/core/entity/getPerTurnIncomeFromPlayerEnts'
import getPlayerEntities from '../../lib/core/entity/getPlayerEntities'
import findById from '../../lib/core/findById'
import type { Player } from '../../lib/core/player/Player.type'
import playerDisplayName from '../../lib/core/player/playerDisplayName'
import { PlayerGameStatus } from '../../lib/core/player/PlayerGameStatus'
import { createEndTurnAction } from '../../lib/core/state/flux/action/Game/EndTurnAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import { immutableEmptyArray } from '../../lib/lib'
import withBusy from '../../lib/withBusy'
import { createAuthPlayerMemo } from '../../rx/signal/createAuthPlayerSignal'
import type { Nullable } from '../../typescript'
import PlayerGameStatusBadge from '../Badge/PlayerGameStatusBadge'
import EngineContext from '../EngineContext'
import PerTurnIncome from './PerTurnIncome'
import PlayerMoneyCornerWidget from './PlayerMoneyCornerWidget'

const PlayerCornerWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)
  const authPlayer = createAuthPlayerMemo(engine)

  const isAuthTurn = createMemo<boolean>((): boolean => {
    const { authPlayerId } = engine
    return (authPlayerId && authPlayerId === engine.state.turnPlayerId) as boolean
  })

  const turnPlayer = createMemo<Nullable<Player>>((): Nullable<Player> => {
    const { players, turnPlayerId } = engine.state
    return findById(players, turnPlayerId)
  })
  const canEndTurn = createMemo<boolean>((): boolean => {
    return isAuthTurn() && true
  })

  const playerEnts = createMemo(() => {
    const { authPlayerId } = engine
    if (authPlayerId) {
      return getPlayerEntities(engine.state.ents, authPlayerId)
    }
    return immutableEmptyArray as unknown as ReturnType<typeof getPlayerEntities>
  })

  const perTurnIncome = createMemo<number>(() => {
    return getPerTurnIncomeFromPlayerEnts(playerEnts())
  })

  const [isBusy, setIsBusy] = createSignal<boolean>(false)
  const [isMoneyWidgetOpen, setIsMoneyWidgetOpen] = createSignal<boolean>(false)

  return (
    <Show when={!engine.draftMove && authPlayer()} children={(player: Accessor<Player>) => (
      <>
        <div class="cw cw-player cw-sm-br">
          <div class="text-break text-monospace">{playerDisplayName(player())}</div>
          <div class="d-flex justify-content-between">
            <Show when={player().game_status !== PlayerGameStatus.Active}>
              <PlayerGameStatusBadge player={player()} />
            </Show>
            <Switch>
              <Match when={engine.state.ended}>
                <span class="fs-6">GGHF</span>
              </Match>
              <Match when={isAuthTurn()}>
                <span class="fs-6">Your Turn</span>
                <Show when={canEndTurn()}>
                  <button
                    class="btn btn-danger btn-sm"
                    classList={{ 'disabled': isBusy() }}
                    disabled={isBusy()}
                    onClick={withBusy(isBusy, setIsBusy, async () => {
                      await dispatchClient(engine, createEndTurnAction((player()).id))
                    })}
                  ><FaSolidArrowsRotate />{' '}End Turn</button>
                </Show>
              </Match>
              <Match when={turnPlayer()}>
                <span class="fs-6 text-muted ellipsis-animated">Waiting on other player's turn</span>
              </Match>
            </Switch>
          </div>
          <PerTurnIncome value={perTurnIncome()} player={player()} onClick={() => {
            setIsMoneyWidgetOpen(!isMoneyWidgetOpen())
          }} />
        </div>
        <PlayerMoneyCornerWidget class="player1 cw-sm-br" when={isMoneyWidgetOpen()} setOpen={setIsMoneyWidgetOpen} player={player()} />
      </>
    )} />
  )
}

export default PlayerCornerWidget
