import { enum_ } from 'valibot'

export enum ArmorTypeId {
  None = 0,
  Infantry = 100,
  LightVehicle = 200,
  Tank = 300,
}

// Potential Classification Examples:
// LightVehicle → Lightly armored, fast units (Humvee, JLTV).
// HeavyVehicle (New) → Heavily armored but not tank-like (P4R Crotale, Stryker APC, HEMTT, M939 truck, NSM Coastal Battery).
// Tank → Fully armored, tracked combat vehicles (M1 Abrams, M10 Booker, M247 SgtYork).

export const ArmorTypeIdSchema = enum_(ArmorTypeId, 'Invalid Armor Type ID')
