import { trackDeep } from '@solid-primitives/deep'
import { createEffect, on } from 'solid-js'
import { modifyMutable, produce, unwrap } from 'solid-js/store'
import type { PathTurnSteps_Step } from 'src/lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type'
import type { Nullable } from '../../../typescript'
import createLayerMeta from '../../core/create_layer_meta'
import calculateDestPositionHoverPositionPaths from '../../core/draft_move/calculateDestPositionHoverPositionPaths'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import calculateDraftMoveRangedAttackEstimates from '../../core/draft_move/calculateDraftMoveRangedAttackEstimates'
import createDraftMove from '../../core/draft_move/createDraftMove'
import type { DraftMove } from '../../core/draft_move/DraftMove.type'
import getSelectedOrHoveredUnloadCargoTileForIcon from '../../core/draft_move/getSelectedOrHoveredUnloadCargoTileForIcon'
import { Engine } from '../../core/engine/Engine.type'
import type { Entity } from '../../core/entity/index'
import isEntityHasMovesLeft from '../../core/entity/isEntityHasMovesLeft'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import {
  renderSpriteByIdMap,
  type MakeRenderSpriteFunction,
  type RenderSpriteFunction,
} from '../../core/render_sprite'
import createCanUnitDirectAttackThisTurn from '../../core/tile_position_xy/createCanUnitDirectAttackThisTurn'
import createCanUnitRangedAttackThisTurn from '../../core/tile_position_xy/createCanUnitRangedAttackThisTurn'
import createIsPositionInDraftMoveRangedAttackRange from '../../core/tile_position_xy/createIsPositionInRangedAttackRange/createIsPositionInDraftMoveRangedAttackRange'
import createIsPositionReachableInOneTurnV2 from '../../core/tile_position_xy/createIsPositionReachableInOneTurnV2/createIsPositionReachableInOneTurnV2'
import createLazyEfficientPathGrid, { type LazyEfficientPathGrid_Result } from '../../core/tile_position_xy/createLazyEfficientPathGrid'
import entityListToGridXY from '../../core/tile_position_xy/entityListToGridXY'
import type { UnloadableCargoTile, UnloadableCargoTileGroup } from '../../core/tile_position_xy/findUnloadableCargoTileCollection'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import type { TilePositionXY } from '../../core/tile_position_xy/TilePositionXY.type'
import { floor, min } from '../../core/util/math'
import { deepClone } from '../../deep_clone'
import bindCanvasToEngine from '../bind_canvas_to_engine'
import { createCanvasElement, createOffscreenCanvas } from '../create_canvas'
import { disableCanvasSmoothing } from '../disable_canvas_smoothing'
import drawUnitPathArrowHeadToPosition from '../draw/cursor/drawUnitPathArrowHeadToPosition'
import defaultErrorRender from '../draw/default_render_error'
import createRenderRightToBracketIconCanvasBuffer from '../draw/entity_icons/createRenderRightToBracketIconCanvasBuffer'
import createRenderAttackTargetIconCanvasBuffer from '../draw/tile/createRenderAttackTargetIconCanvasBuffer'
import createRenderBlockedTargetIconCanvasBuffer from '../draw/tile/createRenderBlockedTargetIconCanvasBuffer'
import createRenderDirectAttackRedTileBuffer from '../draw/tile/createRenderDirectAttackRedTileBuffer'
import createRenderMoveTileLinearGradientCanvasBuffer from '../draw/tile/createRenderMoveTileLinearGradientCanvasBuffer'
import createRenderMoveTileRedCanvasBuffer from '../draw/tile/createRenderMoveTileRedCanvasBuffer'
import createRenderUnloadTargetIconCanvasBuffer from '../draw/tile/createRenderUnloadTargetIconCanvasBuffer'
import { getCanvas2dContext, type EitherRenderingContext2D } from '../get_canvas_2d_context'

const name = 'DrawMovementGrid'
const relZIndex = 0

// @link https://lospec.com/palette-list/eulbink
const MovementArrowBlue1 = '#0ce6f2'
const MovementArrowBlue2 = '#201533'

// const blue = 'rgba(0, 0, 255, 1)'
// const cyan = 'rgba(0, 255, 255, 1)'

/**
 * @deprecated
 */
export default function addDrawMovementGridCanvas(engine: Engine): void {
  // console.log(name)
  let isPositionReachableInOneTurn: Nullable<(x: number, y: number) => boolean> = null
  let isPositionInRangedAttackRange: Nullable<(x: number, y: number) => boolean> = null
  let canUnitDirectAttackThisTurn: Nullable<(x: number, y: number) => boolean> = null
  let canUnitRangedAttackThisTurn: Nullable<(x: number, y: number) => boolean> = null
  let getEfficientPathToTile: LazyEfficientPathGrid_Result | null = null
  let entityGridXY: ReturnType<typeof entityListToGridXY> | null = null
  let unloadableCargoPathGroup: Nullable<UnloadableCargoTileGroup> = null

  const lm = createLayerMeta()
  const elem = createCanvasElement()
  const buffer1 = createOffscreenCanvas(1, 1)
  const moveTileBlueBuffer = createOffscreenCanvas(1, 1)
  const redTileBlueBuffer = createOffscreenCanvas(1, 1)
  const directAttackRedTileBuffer = createOffscreenCanvas(1, 1)
  const arrowBuffer = createOffscreenCanvas(1, 1)
  const unitBuffer = createOffscreenCanvas(1, 1)
  // crosshair
  const attackTargetIconBuffer = createOffscreenCanvas(1, 1)
  const unloadTargetIconBuffer = createOffscreenCanvas(1, 1)
  const blockedTargetIconBuffer = createOffscreenCanvas(1, 1)
  const rightToBracketIconBuffer = createOffscreenCanvas(1, 1)
  elem.setAttribute('name', name)

  createEffect(on(() => engine.selectedPosition, (selectedPosition : TilePositionXY | null) => {
    // console.log('createEffect(on(() => engine.selectedPosition')
    selectedPosition = unwrap(selectedPosition)
    // console.log(
    //   `[${name}].effect.selectedPosition`,
    //   deepClone({ selectedPosition, draftMove: engine.draftMove })
    // )
    let draftUnitEntity: Entity | null = null
    const { authPlayerId, draftMove, selectedPositionEnts } = engine
    if (draftMove) {
      // console.log('already have draftMove')
      if (selectedPosition && !samePosition(selectedPosition, draftMove.startPosition)) {
        // console.warn('selectedPosition different than draftMove', {
        //   selectedPosition,
        //   draftMoveStartPosition: draftMove.startPosition,
        //   samePosition: samePosition(selectedPosition, draftMove.startPosition),
        // })
      }
    } else if (selectedPosition) {
      const selectedPositionEntsThatCanMove = unwrap(selectedPositionEnts).filter((ent) => {
        // player must select "unload" first
        if (ent.taxi_id) {
          return false
        }
        if (ent.player_id === authPlayerId) {
          return isEntityHasMovesLeft(ent)
        }
        return false
      })
      console.log('selectedPositionEntsThatCanMove', deepClone(selectedPositionEntsThatCanMove))
      // console.log('selectedPositionEntsThatBelongToAuthPlayer', selectedPositionEnts)
      if (selectedPositionEntsThatCanMove.length === 1) {
        draftUnitEntity = unwrap(selectedPositionEntsThatCanMove[0])
        // console.log(`[${name}].effect.draftUnitEntity`, deepClone(draftUnitEntity))

        // console.log(`[${name}].effect.isPositionReachableInOneTurn`, isPositionReachableInOneTurn)
      } else {
        // console.log(
        //   'selectedPositionEntsThatBelongToAuthPlayer',
        //   deepClone(selectedPositionEnts)
        // )
        entityGridXY = null
        isPositionReachableInOneTurn = null
        isPositionInRangedAttackRange = null
      }

      modifyMutable(
        engine,
        produce((engine: Engine) => {
          if (draftUnitEntity) {
            engine.selectedTool = SelectedToolId.MoveUnit
            // console.log('createDraftMove', deepClone({ draftUnitEntity }))
            // console.log('engine.draftMove = createDraftMove(draftUnitEntity)')
            engine.draftMove = createDraftMove(draftUnitEntity)
            calculateDestPositionHoverPositionPaths(engine)
            calculateDraftMoveAttackEstimates(engine)
            calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
          } else {
            engine.selectedTool = SelectedToolId.Inspect
          }
        })
      )

      // console.log({ hoveredPosition, entityGridXY, draftUnitEntity })
      lm.until = 0
    } else {
      isPositionReachableInOneTurn = null
      isPositionInRangedAttackRange = null
    }
  }))

  createEffect(on(() => engine.draftMove, (draftMove: DraftMove | null) => {
    // console.log('createEffect(on(() => engine.draftMove')
    if (draftMove) {
      const { width, height, ents } = engine.state
      entityGridXY = entityListToGridXY(ents, width, height)
      isPositionReachableInOneTurn = createIsPositionReachableInOneTurnV2(
        entityGridXY,
        width,
        height,
        draftMove.unit
      )
      isPositionInRangedAttackRange = createIsPositionInDraftMoveRangedAttackRange(
        draftMove,
        width,
        height
      )
    } else {
      entityGridXY = null
      isPositionReachableInOneTurn = null
      isPositionInRangedAttackRange = null
    }
    lm.until = 0
  }))

  createEffect(on(() => engine.draftMove?.unload, (ucPathGroup: Nullable<UnloadableCargoTileGroup>) => {
    unloadableCargoPathGroup = ucPathGroup
    lm.until = 0
  }))

  const getTargetPosition = () => engine.draftMove && (engine.draftMove?.destPosition || engine.hoveredPosition)
  createEffect(on(getTargetPosition, () => {
    // console.log('createEffect(on(getTargetPosition')
    lm.until = 0
  }))

  createEffect(on(() => engine.draftMove, (draftMove : Nullable<DraftMove>) => {
    // console.log('createEffect(on(() => engine.draftMove?.unit')
    const { state } = engine
    const { width, height, ents } = state
    if (draftMove) {
      entityGridXY = entityListToGridXY(ents, width, height)
      getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, draftMove.unit)
      canUnitDirectAttackThisTurn = createCanUnitDirectAttackThisTurn(getEfficientPathToTile)
      canUnitRangedAttackThisTurn = createCanUnitRangedAttackThisTurn(engine.draftMove)
    } else {
      getEfficientPathToTile = null
      canUnitDirectAttackThisTurn = null
      canUnitRangedAttackThisTurn = null
    }
    lm.until = 0
  }))

  // createEffect(on(() => engine.draftMove?.rangedEstimates, (rangedEstimates : Nullable<Array<DraftMove_RangedEstimate>>) => {
  //   // console.log('createEffect(on(() => engine.draftMove?.unit')
  //   const { draftMove } = engine
  //   if (draftMove) {
  //     canUnitRangedAttackThisTurn = createCanUnitRangedAttackThisTurn(draftMove)
  //   } else {
  //     canUnitRangedAttackThisTurn = null
  //   }
  //   lm.until = 0
  // }))

  let stateWidth = 1
  let stateHeight = 1
  createEffect(() => {
    // console.log('createEffect(zIndex,width,height)')
    elem.style.zIndex = (engine.viewCtx.zIndex + relZIndex).toString()
    stateWidth = engine.state.width
    stateHeight = engine.state.height
  })
  createEffect(on(() => engine.hoveredPosition, () => {
    lm.until = 0
  }))
  createEffect(on(() => engine.selectedTool, () => {
    lm.until = 0
  }))
  createEffect(() => {
    // console.log('createEffect(trackDeep(viewCtx))')
    trackDeep(engine.viewCtx)
    lm.until = 0
  })

  let draftMoveUnit: Entity | null = null
  let unitRenderFn: RenderSpriteFunction | null = null
  createEffect(() => {
    // console.log('createEffect(unitRenderFn)', deepClone(engine.draftMove))
    const { draftMove } = engine
    if (draftMove) {
      // draftMoveUnit = unwrap(draftMove.unit)
      draftMoveUnit = draftMove.unit
      const makeRenderSprite: Nullable<MakeRenderSpriteFunction> =
        renderSpriteByIdMap[draftMoveUnit.etype_id]
      unitRenderFn = makeRenderSprite ? makeRenderSprite(engine, draftMoveUnit) : defaultErrorRender
    } else {
      draftMoveUnit = null
      unitRenderFn = null
    }
    lm.until = 0
    // console.log('unitRenderFn', unitRenderFn)
  })
  let cargoUnloadUnit: Nullable<Entity> = null
  let cargoUnloadUnitRenderFn: RenderSpriteFunction | null = null
  createEffect(on(() => engine.draftMove?.unload?.cargo, (newCargoUnloadUnit? : UnloadableCargoTile['cargo']) => {
    // console.log('createEffect(cargoUnloadUnitRenderFn)', deepClone(engine.draftMove))
    if (newCargoUnloadUnit) {
      // cargoUnloadUnit = unwrap(newCargoUnloadUnit)
      cargoUnloadUnit = newCargoUnloadUnit
      const makeRenderSprite: Nullable<MakeRenderSpriteFunction> =
        renderSpriteByIdMap[cargoUnloadUnit.etype_id]
      cargoUnloadUnitRenderFn = makeRenderSprite ? makeRenderSprite(engine, cargoUnloadUnit) : defaultErrorRender
    } else {
      cargoUnloadUnit = null
      cargoUnloadUnitRenderFn = null
    }
    lm.until = 0
    // console.log('unitRenderFn', unitRenderFn)
  }))
  createRenderMoveTileLinearGradientCanvasBuffer(engine, lm, moveTileBlueBuffer)
  createRenderMoveTileRedCanvasBuffer(engine, lm, redTileBlueBuffer)
  createRenderDirectAttackRedTileBuffer(engine, lm, directAttackRedTileBuffer)
  createRenderAttackTargetIconCanvasBuffer(engine, lm, attackTargetIconBuffer)
  createRenderUnloadTargetIconCanvasBuffer(engine, lm, unloadTargetIconBuffer)
  createRenderBlockedTargetIconCanvasBuffer(engine, lm, blockedTargetIconBuffer)
  createRenderRightToBracketIconCanvasBuffer(engine, lm, rightToBracketIconBuffer)

  function getCrosshairPosition(draftMove : Nullable<DraftMove>, hoveredPosition: Nullable<TilePositionXY>) {
    if (draftMove?.attackPosition) {
      return draftMove.attackPosition
    }
    if (hoveredPosition) {
      if (canUnitDirectAttackThisTurn) {
        if (canUnitDirectAttackThisTurn(hoveredPosition.x, hoveredPosition.y)) {
          return hoveredPosition
        }
      }
      if (canUnitRangedAttackThisTurn) {
        if (canUnitRangedAttackThisTurn(hoveredPosition.x, hoveredPosition.y)) {
          return hoveredPosition
        }
      }
    }
    // return coord(6, 6)
    return null
  }

  function render(timeStamp: number) {
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image

    // if (!(timeStamp > 0)) console.log(`${name}[${entityLayerIdListText}]`, '!(timeStamp > 0)', timeStamp)

    if (lm.until > timeStamp) {
      return
    }
    // console.log(`${name}.render`)

    const { hoveredPosition, draftMove, selectedTool, viewCtx } = engine

    const {
      renderWidthPx,
      renderHeightPx,
      tile_size,
      tile_gap_px,
      canvas_edge_buffer,
      pan_x,
      pan_y,
    } = viewCtx
    const tile_size_plus_gap: number = tile_size + tile_gap_px

    // buffer1 will be the full map, unpanned
    const bufferWidthPx =
      tile_size * stateWidth + tile_gap_px * (stateWidth - 1) + 2 * canvas_edge_buffer
    const bufferHeightPx =
      tile_size * stateHeight + tile_gap_px * (stateHeight - 1) + 2 * canvas_edge_buffer

    const unitCtx = getCanvas2dContext(unitBuffer)
    if (!unitCtx) return
    const ctx1 = getCanvas2dContext(buffer1)
    if (!ctx1) return
    const ctx0 = getCanvas2dContext(elem)
    if (!ctx0) return
    const arrowCtx = getCanvas2dContext(arrowBuffer)
    if (!arrowCtx) return

    // IDK what this was for, but it caused the animation to stop running
    // lm.until = timeStamp + EXPIRE_NEVER
    lm.until = timeStamp + 500

    // reset buffer1
    buffer1.width = bufferWidthPx
    buffer1.height = bufferHeightPx
    ctx1.clearRect(0, 0, bufferWidthPx, bufferHeightPx)

    arrowBuffer.width = bufferWidthPx
    arrowBuffer.height = bufferHeightPx
    unitBuffer.width = bufferWidthPx
    unitBuffer.height = bufferHeightPx

    // <BlueMovementSquares>
    if (unloadableCargoPathGroup) {
      // ctx1.fillStyle = 'blue'
      for (let index = 0; index < unloadableCargoPathGroup.paths.length; index++) {
        const ucPath = unloadableCargoPathGroup.paths[index];
        const dx: number = canvas_edge_buffer + ucPath.x * tile_size_plus_gap
        const dy: number = canvas_edge_buffer + ucPath.y * tile_size_plus_gap

        if (ucPath.blocked) {
          // blocked!
        } else {
          ctx1.drawImage(moveTileBlueBuffer, dx, dy, tile_size, tile_size)
        }
      }
    } else if (isPositionReachableInOneTurn) {
      disableCanvasSmoothing(ctx1)

      // ctx1.fillStyle = 'blue'
      // ctx1.globalAlpha = 0.33
      // ctx1.globalCompositeOperation = LUMINOSITY

      // buffer1
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (isPositionReachableInOneTurn(x, y)) {
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap

            // ctx1.fillRect(dx, dy, tile_size, tile_size)
            ctx1.drawImage(moveTileBlueBuffer, dx, dy, tile_size, tile_size)
          }
        }
      }
    }
    // </BlueMovementSquares>

    // <RedRangedAttackSquares>
    if (isPositionInRangedAttackRange && selectedTool === SelectedToolId.RangedAttackRadius) {
      disableCanvasSmoothing(ctx1)

      // ctx1.fillStyle = 'red'
      // ctx1.globalAlpha = 0.33
      // ctx1.globalCompositeOperation = LUMINOSITY

      // buffer1
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (isPositionInRangedAttackRange(x, y)) {
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap

            // ctx1.fillRect(dx, dy, tile_size, tile_size)
            ctx1.drawImage(redTileBlueBuffer, dx, dy, tile_size, tile_size)
          }
        }
      }
    }
    // </RedRangedAttackSquares>

    const targetPosition = getTargetPosition()
    const upts = targetPosition && getEfficientPathToTile?.(targetPosition?.x, targetPosition.y)

    // <BlueArrowPath>
    if (isPositionReachableInOneTurn) {
      // arrowCtx (TODO make seperate buffer?)
      const arrowWidth = floor(tile_size * (3 / 5))
      // const halfArrowWidth = floor(arrowWidth / 2)
      const gap1 = floor((tile_size - arrowWidth) / 2)
      const rrRadii = floor(arrowWidth / 2)
      const halfTileSize = floor(tile_size / 2)
      arrowCtx.globalAlpha = 1
      arrowCtx.fillStyle = MovementArrowBlue1
      // V2 code

      // draw blue line with arrow head
      if (upts && upts.totalCost > 0) {
        // draw blue dots
        drawRedRoundRect(
          arrowCtx,
          arrowWidth,
          canvas_edge_buffer,
          tile_size_plus_gap,
          gap1,
          rrRadii,
          upts.start.x,
          upts.start.y
        )
        upts.turns.forEach((turn) => {
          turn.steps.forEach((step) => {
            drawRedRoundRect(
              arrowCtx,
              arrowWidth,
              canvas_edge_buffer,
              tile_size_plus_gap,
              gap1,
              rrRadii,
              step.x,
              step.y
            )
          })
        })

        // connect blue dots
        upts.turns.forEach((turn, turnIndex) => {
          let prevStep: PathTurnSteps_Step | null = null
          turn.steps.forEach((step) => {
            if (prevStep || turnIndex === 0) {
              const { x: x1, y: y1 } = step
              const { x: x2, y: y2 } = prevStep || upts!.start
              const minX = min(x1, x2)
              const minY = min(y1, y2)
              const dx: number = canvas_edge_buffer + minX * tile_size_plus_gap + gap1
              const dy: number = canvas_edge_buffer + minY * tile_size_plus_gap + gap1
              const w = x1 === x2 ? arrowWidth : tile_size_plus_gap + arrowWidth
              const h = y1 === y2 ? arrowWidth : tile_size_plus_gap + arrowWidth
              arrowCtx.beginPath()
              arrowCtx.roundRect(dx, dy, w, h, rrRadii)
              arrowCtx.fill()
            }
            //
            prevStep = step
          })
        })

        // draw arrow heads
        let prevTurnLastStep: Nullable<TilePositionXY> = upts.start
        upts.turns.forEach((turn) => {
          const lastStep = turn.steps.at(-1)
          const prevStep = turn.steps.at(-2) || prevTurnLastStep

          // if (turn.steps.length === 1 && turn.turn > 1) {
          //   // skip arrow?
          // } else
          if (lastStep && prevStep) {
            // arrowCtx.resetTransform()
            arrowCtx.fillStyle = MovementArrowBlue1
            drawUnitPathArrowHeadToPosition(
              prevStep,
              lastStep,
              arrowCtx,
              tile_size,
              tile_size_plus_gap,
              halfTileSize
            )

            arrowCtx.resetTransform()
          } else {
            // console.log('upts', { lastStep, prevStep }, upts)
          }
          prevTurnLastStep = lastStep
        })

        // // draw debug text
        // upts.turns.forEach((turn) => {
        //   turn.steps.forEach((step) => {
        //     const { x: x1, y: y1 } = step
        //     const dx1: number = canvas_edge_buffer + x1 * tile_size_plus_gap + gap1
        //     const dy1: number = canvas_edge_buffer + y1 * tile_size_plus_gap + gap1

        //     const lineHeight = 15
        //     let line = 0
        //     arrowCtx.font = 'bold 16px monospace'
        //     arrowCtx.fillStyle = 'black'
        //     ctx0.filter = 'drop-shadow(5px 5px 10px white)'
        //     arrowCtx.fillText(`[${step.x},${step.y}]`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     arrowCtx.fillText(`cost:${step.cost}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     // arrowCtx.fillText(`rema:${step.remain}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     arrowCtx.fillText(`turn:${turn.turn}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //   })
        // })

        if (unitRenderFn && draftMoveUnit) {
          const destPosition = draftMove?.destPosition
          if (destPosition) {
            const dx: number = canvas_edge_buffer + destPosition.x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + destPosition.y * tile_size_plus_gap
            unitRenderFn(unitCtx, lm, draftMoveUnit, dx, dy, tile_size)
          }
        }
      }
    }
    // </BlueArrowPath>

    // <RedAttackSquares>
    if (canUnitDirectAttackThisTurn) {
      ctx1.globalAlpha = 1
      // ctx1.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION

      disableCanvasSmoothing(ctx1)

      // ctx1.fillStyle = 'red'
      // ctx1.globalAlpha = 0.33
      // ctx1.globalCompositeOperation = LUMINOSITY

      // buffer1
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (canUnitDirectAttackThisTurn(x, y)) {
            // console.log('canUnitDirectAttack(x, y)', x, y)
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap
            ctx1.drawImage(directAttackRedTileBuffer, dx, dy, tile_size, tile_size)
          }
        }
      }
    }
    // </RedAttackSquares>

    // draw crosshairs
    const crosshairPosition : Nullable<TilePositionXY> = getCrosshairPosition(draftMove, hoveredPosition)

    if (crosshairPosition) {
      const {
        width: sWidth,
        height: sHeight,
      } = attackTargetIconBuffer
      const sWCut1 = (sWidth - tile_size) / 2
      const sHCut1 = (sHeight - tile_size) / 2

      const dx: number = canvas_edge_buffer + (crosshairPosition.x * tile_size_plus_gap) - sWCut1
      const dy: number = canvas_edge_buffer + (crosshairPosition.y * tile_size_plus_gap) - sHCut1

      ctx1.filter = 'drop-shadow(0px 0px 1px black)'
      ctx1.drawImage(
        attackTargetIconBuffer,
        dx,
        dy,
        attackTargetIconBuffer.width,
        attackTargetIconBuffer.height
      )
      ctx1.filter = 'none'
    }

    const selectedOrHoveredUnloadCargoTile : Nullable<UnloadableCargoTile> | false = (!crosshairPosition && draftMove)
      && getSelectedOrHoveredUnloadCargoTileForIcon(draftMove, hoveredPosition)
      // || {x: 6, y: 6, blocked: true, cargo_id: engine.state.ents[0].id, cargo: engine.state.ents[0] }
    // if (cargoUnloadUnit && !selectedOrHoveredUnloadCargoTile) {
    //   console.log('unloadableCargoPath', deepClone({draftMove, hoveredPosition, result: getSelectedOrHoveredUnloadCargoTileForIcon(draftMove!, hoveredPosition)}))
    // }
    if (selectedOrHoveredUnloadCargoTile) {
      const {
        width: sWidth,
        height: sHeight,
      } = unloadTargetIconBuffer
      const sWCut1 = (sWidth - tile_size) / 2
      const sHCut1 = (sHeight - tile_size) / 2

      const { cargo: cargoEntity } = selectedOrHoveredUnloadCargoTile

      const dx: number = canvas_edge_buffer + (selectedOrHoveredUnloadCargoTile.x * tile_size_plus_gap) - sWCut1
      const dy: number = canvas_edge_buffer + (selectedOrHoveredUnloadCargoTile.y * tile_size_plus_gap) - sHCut1

      if (selectedOrHoveredUnloadCargoTile.blocked) {
        ctx1.filter = 'drop-shadow(0px 0px 1px black)'
        ctx1.drawImage(
          blockedTargetIconBuffer,
          dx,
          dy,
          attackTargetIconBuffer.width,
          attackTargetIconBuffer.height
        )
      } else {
        ctx1.filter = 'drop-shadow(0px 0px 1px black)'
        ctx1.drawImage(
          unloadTargetIconBuffer,
          dx,
          dy,
          attackTargetIconBuffer.width,
          attackTargetIconBuffer.height
        )

        if (cargoUnloadUnitRenderFn) {
          const unloadPosition = draftMove?.unloadPosition || hoveredPosition
          if (unloadPosition) {
            const dx: number = canvas_edge_buffer + unloadPosition.x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + unloadPosition.y * tile_size_plus_gap
            cargoUnloadUnitRenderFn(unitCtx, lm, cargoEntity, dx, dy, tile_size)
          }
        }
      }
      ctx1.filter = 'none'
    }

    // reset screen
    elem.width = renderWidthPx
    elem.height = renderHeightPx
    ctx0.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx0)

    // write buffer1 to screen
    // console.log(`${name}[${entityLayerIdListText}] write buffer1 to screen`)
    ctx0.drawImage(buffer1, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    // ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    ctx0.globalAlpha = 0.75
    ctx0.filter = `drop-shadow(5px 5px 10px ${MovementArrowBlue2})`
    ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)

    // ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    ctx0.globalAlpha = 1
    ctx0.filter = 'none'
    ctx0.drawImage(unitBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
  }

  bindCanvasToEngine(engine, elem, render)
}

function drawRedRoundRect(
  ctx: EitherRenderingContext2D,
  arrowWidth: number,
  canvas_edge_buffer: number,
  tile_size_plus_gap: number,
  gap1: number,
  rrRadii: number,
  x1: number,
  y1: number
) {
  const dx1: number = canvas_edge_buffer + x1 * tile_size_plus_gap + gap1
  const dy1: number = canvas_edge_buffer + y1 * tile_size_plus_gap + gap1
  const w = arrowWidth
  const h = arrowWidth
  // if (step !== lastStep) {
  ctx.beginPath()
  ctx.roundRect(dx1, dy1, w, h, rrRadii)
  ctx.fill()
  // }
}


