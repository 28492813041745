import { Color } from "three"
import type { VertexRGBFunctionStandardMaterialArg1 } from "../../fn/applyVertexFunctionStandardMaterial"

// === River Water Colors ===
/* eslint-disable @typescript-eslint/no-unused-vars */
// Shallow water (near shore, light blue)
const riverShallow = new Color(0xAEEEEE)

// Mid-depth water (deeper but not deep, richer blue)
const riverMid = new Color(0x4D9DE0)

// Deep water (center of the river, dark blue)
const riverDeep = new Color(0x1B4F72)


// === River Basin Colors ===

// Riverbank (near water, wet soil/sand)
const riverBank = new Color(0xA67C52)

// Basin ground (general ground along the river basin)
const riverBasinGround = new Color(0x8B5A2B)

// Lower basin (deeper, muddy basin)
const riverMuddyBasin = new Color(0x5A3E2B)

/* eslint-enable @typescript-eslint/no-unused-vars */


export function getRiverBasinColor(y: number): Color {
  if (y >=1 ) return riverBasinGround
  if (y > -0.5) return riverBank
  return riverMuddyBasin
}

export function useRiverBasinColors (va: VertexRGBFunctionStandardMaterialArg1): void {
  const { y } = va

  const color = getRiverBasinColor(y)

  va.r = (color.r)
  va.g = (color.g)
  va.b = (color.b)
}