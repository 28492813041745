import { nullable, number, optional, type InferOutput } from "valibot"

export const DegreeNumberSchema = number()

export type DegreeNumber = InferOutput<typeof DegreeNumberSchema>

export const NullableDegreeNumberSchema = nullable(DegreeNumberSchema)

export type NullableDegreeNumber = InferOutput<typeof NullableDegreeNumberSchema>

export const OptionalNullableDegreeNumberSchema = optional(NullableDegreeNumberSchema)

export type OptionalNullableDegreeNumber = InferOutput<typeof OptionalNullableDegreeNumberSchema>