import { Key } from '@solid-primitives/keyed'
import { FaSolidFlag, FaSolidGasPump, FaSolidHammer, FaSolidHeart, FaSolidShield } from 'solid-icons/fa'
import { Component, createMemo, For, Show, type Accessor, type JSXElement } from 'solid-js'
import type { BaseEntity } from '../../lib/core/entity/BaseEntity'
import createSampleEntity from '../../lib/core/entity/create_sample_entity'
import entityDisplayName from '../../lib/core/entity/entityDisplayName'
import { FactoryEntityTypeMeta, type FactoryEntity } from '../../lib/core/entity/EntityType/Factory'
import { isEntityFactory } from '../../lib/core/entity/entityTypeMetaList.generated'
import getMoveTypeMetaById from '../../lib/core/entity/getMoveTypeMetaById'
import getUnitDefenseAtPosition from '../../lib/core/entity/getUnitDefenseAtPosition'
import type { HasTaxiID } from '../../lib/core/entity/HasTaxiID'
import type { Entity } from '../../lib/core/entity/index'
import type { Weapon } from '../../lib/core/entity/Weapon/Weapon.type'
import type { HasFuel } from '../../lib/core/has_fuel'
import type { HasHP } from '../../lib/core/has_hp'
import type { HasMobility } from '../../lib/core/has_mobility'
import { hasWasBuiltThisTurn } from '../../lib/core/hasWasBuiltThisTurn'
import type { TilePositionXY } from '../../lib/core/tile_position_xy/TilePositionXY.type'
import toFixed1 from '../../lib/core/util/toFixed1'
import toFloorMin1 from '../../lib/core/util/toFloorMin1'
import isNotNil from '../../lib/ldsh/isNotNil'
import nullIfEmptyArray from '../../lib/ldsh/nullIfEmptyArray'
import type { Nullable } from '../../typescript'
import PositionIcon3D from '../MapEditor/PositionIcon3D'
import SingleEntityIcon3D from '../MapEditor/SingleEntityIcon3D'
import SingleSpriteIcon from '../MapEditor/SingleSpriteIcon'

interface RowProps {
  when: boolean
  // x: expanded or not
  x: boolean
  red?: boolean
  icon: JSXElement
  text: string
  td: JSXElement
}

const Row: Component<RowProps> = (props) => {
  return (
    <Show when={props.when}>
      <tr title={props.text}>
        <th aria-label={props.text} classList={{ 'text-danger': props.red }}>
          {props.icon}
        </th>
        <th aria-label={props.text} classList={{ 'text-danger': props.red, 'd-none': !props.x }}>
          {props.text}:
        </th>
        <td classList={{ 'text-danger': props.red }}>{props.td}</td>
      </tr>
    </Show>
  )
}

type EntStackStats = {
  defense?: number,
  ap?: number | null,
  unit?: Entity,
}

interface Props {
  position: TilePositionXY
  ents: Array<Entity>
  // whether or not expanded
  x: boolean
}

const PositionStackStats: Component<Props> = (props) => {
  // const engine : Engine = useContext(EngineContext)

  const cargoEnts = createMemo<Nullable<Array<Entity & HasTaxiID>>>(() => {
    return nullIfEmptyArray(props.ents.filter((ent: Entity): ent is Entity & HasTaxiID => {
      return (ent as HasTaxiID).taxi_id as unknown as boolean
    }))
  })

  const mainEnts = createMemo<Array<Entity>>(() => {
    return props.ents.filter((ent: Entity): ent is Entity => {
      return !(ent as HasTaxiID).taxi_id as unknown as boolean
    })
  })

  const stats = createMemo(() => {
    const ents = mainEnts()
    const stats: EntStackStats = {
      defense: getUnitDefenseAtPosition(null, ents),
      ap: ents.find((ent: Entity) => 'ap' in ent)?.ap,
      unit: ents.find((ent: Entity) => {
        if ((ent as HasTaxiID).taxi_id) {
          return false
        }
        return (ent as HasHP).hp
      }),
    }
    // console.log('stats', deepClone(stats))
    return stats
  })

  const factoryEnt = createMemo<FactoryEntity>(() => {
    return props.ents.find(isEntityFactory)
      || (createSampleEntity(FactoryEntityTypeMeta, null) as FactoryEntity)
  })

  return (
    <div class="d-flex">
      <div class="d-flex flex-column">
        <PositionIcon3D position={props.position} w={120} h={120} />
      </div>
      <div class="ms-3" classList={{ 'flex-grow-1': props.x }}>
        <Show when={props.x}>
          <Key each={mainEnts()}
            fallback={<div class="entity">
              <span class="etm-dname text-monospace">Void</span>
            </div>}
            by="id"
            children={(entity: Accessor<Entity>) => {
              return <div class="entity">
                <span class="etm-dname text-monospace">{entityDisplayName(entity())}</span>
              </div>
            }}
          />
        </Show>
        <table class="float-end">
          <tbody>
            <Show when={stats()?.unit} children={(unit) => {
              return (<>
                <Row when={(unit() as HasHP)?.hp as number >= 0} x={props.x}
                  icon={<FaSolidHeart style={{ 'color': 'red' }} />} text="HP" td={toFixed1((unit() as HasHP)?.hp)}
                />
                <For each={(unit() as BaseEntity).weapons?.filter(w => isNotNil(w.ammo))} children={(weapon: Weapon) => {
                  // console.log('PositionStackStats.weapon', index(), deepClone(weapon))
                  return <Row when={weapon.ammo as number >= 0} x={props.x}
                    red={weapon.ammo as number === 0}
                    icon="⁌" text="Ammo" td={weapon.ammo}
                  />
                }} />
                <Row when={(unit() as HasMobility)?.mobility as number >= 0} x={props.x}
                  icon={<span class="small text-muted">{(getMoveTypeMetaById((unit() as HasMobility).mtype_id)?.dname || 'Error')}</span>} text="Moves"
                  td={<span class="w-100 d-flex justify-content" style={{ 'justify-content': 'space-between' }}>
                    <span>{(unit() as HasMobility).mobility}</span>
                  </span>}
                />
                <Row when={(unit() as HasFuel)?.fuel as number >= 0} x={props.x}
                  icon={<FaSolidGasPump />} text="Fuel" td={(unit() as HasFuel).fuel}
                />
                <Row when={(unit() as unknown as boolean) && hasWasBuiltThisTurn((unit() as Entity))} x={props.x}
                  icon={<SingleSpriteIcon entity={factoryEnt() as FactoryEntity} />} text="Built This Turn" td={<>
                    <FaSolidHammer />
                  </>}
                />
              </>)
            }} />

            <Row when={stats().defense as number >= 0} x={props.x}
              icon={<FaSolidShield />} text="Defense" td={stats().defense}
            />
            <Row when={stats().ap as number >= 0} x={props.x}
              icon={<FaSolidFlag />} text="Annex Points" td={toFloorMin1(stats().ap)}
            />
          </tbody>
        </table>
        <Show when={cargoEnts()} children={(cargoEnts: Accessor<Array<Entity & HasTaxiID>>) => {
          return (<div>
            <span class="text-muted" classList={{
              'small': !props.x,
            }}>Transporting:</span>
            <Key each={cargoEnts()} by="id" children={(cargoEnt: Accessor<Entity & HasTaxiID>) => {
              return <SingleEntityIcon3D entity={cargoEnt()} size={32} />
            }} />
          </div>)
        }} />
      </div>
    </div>
  )
}

export default PositionStackStats
