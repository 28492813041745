import { AmbientLight } from "three"

export function createAmbientLight() {
  const ambientLight = new AmbientLight(0xffffff, 1)

  // createMountedInverval(() => {
  //   ambientLight.visible = !ambientLight.visible
  //   // ambientLight.intensity = ambientLight.intensity === 1.2 ? 2 : 1.2
  // }, 1000)

  return ambientLight
}