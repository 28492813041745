import { array, InferOutput, literal, object } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { AttackedThisTurnSchema } from '../../AttackedThisTurn'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { WasBuiltThisTurnSchema } from '../../WasBuiltThisTurn'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'
import { MoveTypeId } from '../move_type_id.enum'
import createLightTankCannonWeapon from '../Weapon/createLightTankCannonWeapon'
import createVehicleMachineGunWeapon from '../Weapon/createVehicleMachineGunWeapon'
import { WeaponSchema } from '../Weapon/Weapon.type'

const entDefaults = {
  etype_id: EntityTypeId.LightTank,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 70,
  vision: 3, // maybe too high for tank?
  mtype_id: MoveTypeId.Treads,
  mobility: 6,
  armor: 5,
} as const

function defaultWeapons() {
  return [createVehicleMachineGunWeapon(6), createLightTankCannonWeapon(6)]
}

export const LightTankEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.LightTank),
  layer_id: literal(EntityLayerId.Unit2),
  id: EntityIdSchema,
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  vision: VisionSchema,
  armor: ArmorSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  weapons: array(WeaponSchema),
})

export type LightTankEntity = InferOutput<typeof LightTankEntitySchema>

export const LightTankEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.LightTank,
  dname: 'Light Tank',
  layer_id: EntityLayerId.Unit2,
  entDefaults,
  price: 70,
  defaultWeapons,
  armor_type: ArmorTypeId.Tank,
}

export function createLightTankEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): LightTankEntity {
  return {
    ...entDefaults,
    heading: 0,
    weapons: defaultWeapons(),
    id,
    x,
    y,
    player_id,
  }
}
