import { POSTGRES_MAX_INT } from '@sg/shared/src/util/numbers'
import { Mutex } from 'async-mutex'
import { createRoot } from 'solid-js'
import { Group, Mesh, OrthographicCamera, Scene, WebGLRenderer } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { loseWebGL2Context } from "../../canvas/loseWebGL2Context"
import type { Engine } from '../../core/engine/Engine.type'
import createSampleEntity from '../../core/entity/create_sample_entity'
import applyEntityRotationForMediumRocketsToNextObject3DFrame from '../../core/entity/EntityHeading/applyEntityRotationForMediumRocketsToNextObject3DFrame'
import applyEntityRotationToNextObject3DFrame from '../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame'
import { EntityHeadingNorthDeg, EntityHeadingSouthDeg } from '../../core/entity/EntityHeading/consts'
import { PlainEntityTypeMeta } from '../../core/entity/EntityType/Plain'
import { EntityTypeId } from '../../core/entity/EntityTypeId.enum'
import { isEntityForest, isEntityMountain, isEntityRiver, isEntityShoal } from '../../core/entity/entityTypeMetaList.generated'
import type { Entity } from "../../core/entity/index"
import isEntityWater from '../../core/entity/isEntityWater'
import coord from '../../core/tile_position_xy/coord'
import { PI } from '../../core/util/math'
import assertClassInDev from '../../ldsh/assertClassInDev'
import { VERTICES_PER_TILE } from '../consts'
import createEntityThreeModel from '../EntityThreeModel/createEntityThreeModel'
import loadEntityThreeModelGLTF from '../EntityThreeModel/loadEntityThreeModelGLTF'
import updateEntityThreeModel from '../EntityThreeModel/updateEntityThreeModel'
import makeSingleTileTerrainMesh1Object3D, { isEntityTerrainMesh1 } from '../objects/TerrainMesh1V1/makeSingleTileTerrainMesh1Object3D'
import type { PlayerThreeObject } from '../PlayerThreeObject/PlayerThreeObject.type'
import { createAmbientLight } from '../sky/createAmbientLight'
import { createDirectionalLight } from '../sky/createDirectionalLight'
import { createHemisphereLight } from '../sky/createHemisphereLight'
import { UNIT_SPRITE_NAME } from '../sprite/createUnitSprite'
import addProcedurallyGeneratedEntityTextures from './addProcedurallyGeneratedEntityTextures'
import callAllUserDataUpdatesRecursive from './callAllUserDataUpdates'
import createWaterPlaneMesh from './createWaterPlaneMesh'
import disposeRecursive from './disposeRecursive'
import setObjPosition from './setObjPosition'

const mutex = new Mutex()

export default async function makeCanvasIntoEntityIcon3D(
  engine: Engine,
  canvasElem: HTMLCanvasElement,
  ent: Entity,
  pto: PlayerThreeObject) {
  console.log('makeCanvasIntoEntityIcon3D.init')
  const ctx = getCanvas2dContext(canvasElem)
  if (ctx) {
    const entityTypeId = ent.etype_id
    mutex.runExclusive(async () => {
      // console.log('makeCanvasIntoEntityIcon3D.runExclusive')
      let offscreenCanvas: OffscreenCanvas | null = null
      let renderer: WebGLRenderer | null = null
      let scene: Scene | null = null
      let camera: OrthographicCamera | null = null
      try {
        offscreenCanvas = createOffscreenCanvas(canvasElem.width, canvasElem.height)
        ent = {
          ...ent,
          x: 0,
          y: 0,
        }
      
        renderer = new WebGLRenderer({
          canvas: offscreenCanvas,
          alpha: true,
          antialias: true,
        })
        renderer.setClearColor(0x000000, 0)
    
        // Utility scene and camera
        scene = new Scene()
        // set pink background
        // scene.background = new Color(0xffaaaa)

        // Define orthographic camera parameters
        // Controls how much of the scene is visible
        const frustumSize = getFrustumSize(entityTypeId)
        const aspect = canvasElem.width / canvasElem.height
        const left = -frustumSize * aspect / 2
        const right = frustumSize * aspect / 2
        const top = frustumSize / 2
        const bottom = -frustumSize / 2
        const near = 0.1
        const far = 1000

        camera = new OrthographicCamera(left, right, top, bottom, near, far)
        // camera.lookAt(0, 0, 0) // Ensure it looks at the scene center
    
        // Add objects to the scene (e.g., spinning cube)
        // const geometry = new BoxGeometry()
        // const material = new MeshBasicMaterial({ color: 0x00ff00 })
        // const cube = new Mesh(geometry, material)
        // scene.add(cube)

        scene.add(createAmbientLight())
        scene.add(createHemisphereLight())
        scene.add(createDirectionalLight())
        const loadingModelPromise = loadEntityThreeModelGLTF(engine, ent, pto)
        const obj = await loadingModelPromise
        const entityThreeModel = createEntityThreeModel(ent)
        entityThreeModel.obj = obj
        // console.log('ent', ent)

        // need createRoot because something is using a solid-js cached obj
        createRoot(() => updateEntityThreeModel(entityThreeModel, ent, engine))

        setObjPosition(obj, coord(0, 0))
        obj.userData.heading = obj.userData.targetHeading = EntityHeadingNorthDeg
        addProcedurallyGeneratedEntityTextures(obj, ent)
        applyEntityRotationToNextObject3DFrame(obj, POSTGRES_MAX_INT)
        if (isEntityForest(ent)) {
          await new Promise<void> (resolve => {
            makeSingleTileTerrainMesh1Object3D(createSampleEntity(PlainEntityTypeMeta, null), (plain) => {
              setObjPosition(plain, coord(0, 0), 2)
              const group = new Group()
              group.add(obj)
              group.add(plain)
              scene!.add(group)
              resolve()
            })
          })
        } else {
          scene.add(obj)
        }

        const HPBar = obj.getObjectByName(UNIT_SPRITE_NAME)
        if (HPBar) {
          HPBar.visible = false
        }

        if (isEntityRiver(ent) || isEntityShoal(ent)) {
          // scene.position.z += VERTICES_PER_TILE
          scene.position.z += VERTICES_PER_TILE * 4
          // scene.position.x += VERTICES_PER_TILE
          // scene.position.y -= VERTICES_PER_TILE
        }
        if (isEntityWater(ent) || isEntityShoal(ent)) {
          obj.position.y -= 4
          const geometrySize = VERTICES_PER_TILE
          const waterPlane = createWaterPlaneMesh(geometrySize)
          if (isEntityRiver(ent) || isEntityShoal(ent)) {
            waterPlane.position.z += VERTICES_PER_TILE
            waterPlane.position.x += VERTICES_PER_TILE
            waterPlane.position.y -= 5
          }
          scene.add(waterPlane)
        }

        if (isEntityForest(ent)) {
          camera.position.set(0, VERTICES_PER_TILE, VERTICES_PER_TILE)
          // scene.rotateX(0.5 + ((PI * 3) / 4))
          scene.rotateX(-PI / 16)
          scene.rotateY(((PI) / 4))
          camera.lookAt(scene.position)
        } else if (isEntityMountain(ent)) {
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          // obj.rotateY((PI * 3) / 4)
          scene.rotateX((PI * 1) / 12)
          camera.lookAt(scene.position)
        } else if (isEntityRiver(ent) || isEntityShoal(ent)) {
          // scene.position.z += VERTICES_PER_TILE * (1/2)
          // scene.position.z += VERTICES_PER_TILE
          scene.position.z += VERTICES_PER_TILE * (12/10)
          scene.position.y += 7

          camera.position.set(0, VERTICES_PER_TILE, VERTICES_PER_TILE)
          // scene.rotateX(PI + ((0.5 + ((PI * 3) / 4))))
          scene.rotateX(((7 * PI) + 2)/4)
          // scene.rotateZ(-((PI) / 4))
          scene.rotateY(-((PI) / 4))
          camera.lookAt(scene.position)
          camera.position.y += (VERTICES_PER_TILE) / 2
          // camera.position.y -= 10
        } else if (isEntityTerrainMesh1(ent)) {
          camera.position.set(0, VERTICES_PER_TILE, VERTICES_PER_TILE)
          // scene.rotateX(PI + ((0.5 + ((PI * 3) / 4))))
          scene.rotateX(((7 * PI) + 2)/4)
          // scene.rotateZ(-((PI) / 4))
          scene.rotateY(-((PI) / 4))
          camera.lookAt(scene.position)
        } else if (entityTypeId === EntityTypeId.Infantry) {
          // obj.userData.update(1,1)
          obj.rotateY((-PI * 1) / 8)
          obj.rotateX((PI * 1) / 32)
          obj.rotateZ((-PI * 1) / 32)
          // camera.lookAt(obj.position)
          // // obj.rotateY((PI * 3) / 4)
          // obj.rotateX((PI * 2) / 4)
          // // obj.rotateY((PI * 1) / 8)
          // // obj.rotateZ((PI * 1) / 4)
          camera.position.y += 10
          // camera.position.y += 20
          camera.position.z += 4
          // scene.userData.update(1,1)
          // scene.userData.update(1,1)
          callAllUserDataUpdatesRecursive(obj, 1, POSTGRES_MAX_INT)
        } else if (entityTypeId === EntityTypeId.MediumArtillary) {

          const barrelGroup = scene.getObjectByName('Barrel') as Group
          assertClassInDev(barrelGroup, Group)
          barrelGroup.rotateX(-PI / 16)
      
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          camera.position.y -= 16
          obj.rotateY((PI * 3) / 4)
          camera.lookAt(obj.position)
          camera.position.y += 6
        } else if (entityTypeId === EntityTypeId.LightTank) {

          const barrelMesh = scene.getObjectByName('Barrel') as Mesh
          assertClassInDev(barrelMesh, Mesh)
          barrelMesh.rotateX(-PI / 32)
      
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          camera.position.y -= 16
          obj.rotateY((PI * 3) / 4)
          camera.lookAt(obj.position)
          camera.position.y += 4.5
          camera.position.x -= 0.25
        } else if (entityTypeId === EntityTypeId.MediumGunAntiAir) {

          const barrelMesh = scene.getObjectByName('Barrel') as Mesh
          assertClassInDev(barrelMesh, Mesh)
          barrelMesh.rotateX(-PI / 16)
      
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          camera.position.y -= 16
          obj.rotateY((PI * 2.9) / 4)
          camera.lookAt(obj.position)
          camera.position.y += 7
        } else if (entityTypeId === EntityTypeId.MediumRockets) {
          const turretMesh = scene.getObjectByName('Turret') as Mesh
          assertClassInDev(turretMesh, Mesh)
      
          const rocketPodMesh = turretMesh.getObjectByName('RocketPod') as Mesh
          assertClassInDev(rocketPodMesh, Mesh)

          turretMesh.userData.targetHeading = EntityHeadingSouthDeg
          rocketPodMesh.userData.raise = true
          applyEntityRotationForMediumRocketsToNextObject3DFrame(turretMesh, rocketPodMesh, POSTGRES_MAX_INT)
          turretMesh.userData.targetHeading = 0
          applyEntityRotationForMediumRocketsToNextObject3DFrame(turretMesh, rocketPodMesh, POSTGRES_MAX_INT)
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          camera.position.y -= 12
          obj.rotateY((PI * 3) / 4)
          camera.lookAt(obj.position)
          camera.position.y += 5
        } else if (entityTypeId === EntityTypeId.MediumTank) {

          const turretGroup = scene.getObjectByName('Turret') as Group
          assertClassInDev(turretGroup, Group)
          turretGroup.rotateZ(PI * (2/8))

          obj.rotateX(PI * -(1/8))
          
          camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          camera.position.y -= 16
          obj.rotateY(PI * (7/8))
          camera.lookAt(obj.position)
          camera.position.y += 4.5
          camera.position.x -= 0.25
        } else {
          //   camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          //   camera.position.set(0, -10 + VERTICES_PER_TILE, -VERTICES_PER_TILE - 10) // Position the camera above
          // } else {
            camera.position.set(0, VERTICES_PER_TILE, -VERTICES_PER_TILE) // Position the camera above
          // }
          scene.rotateY((PI * 3) / 4)
          camera.lookAt(scene.position)
          if (entityTypeId === EntityTypeId.LightRecon) {
            camera.position.y += 3
          } else if (entityTypeId === EntityTypeId.APC) {
            camera.position.y += 3.5
          } else if (entityTypeId === EntityTypeId.TransportCopter) {
            camera.position.y += 8.5
          // } else if (entityTypeId === EntityTypeId.MediumArtillary) {
          //   camera.position.y += 5
          // } else if (entityTypeId === EntityTypeId.LightTank) {
          //   camera.position.y += 3
          // } else if (entityTypeId === EntityTypeId.MediumGunAntiAir) {
          //   camera.position.y += 6
          } else if (entityTypeId === EntityTypeId.MediumCopter) {
            camera.position.y += 4.5
          } else if (entityTypeId === EntityTypeId.MediumMissileAntiAir) {
            camera.position.y += 5
          // } else if (entityTypeId === EntityTypeId.MediumRockets) {
          //   camera.position.y += 3.5
          // } else if (entityTypeId === EntityTypeId.MediumTank) {
          //   camera.position.y += 4
          }
        }

        renderer.render(scene, camera)
    
        ctx.clearRect(0, 0, canvasElem.width, canvasElem.height)
        ctx.drawImage(offscreenCanvas, 0, 0)
        // console.log('makeCanvasIntoEntityIcon3D.runExclusive.EOF')
      } finally {
        // console.log('makeCanvasIntoEntityIcon3D.runExclusive.finally')
        if (renderer) {
          renderer.forceContextLoss()
        }
        disposeRecursive(renderer!)
        disposeRecursive(scene!)
        disposeRecursive(camera!)
        loseWebGL2Context(offscreenCanvas!)
      }
    })
  }
}

function getFrustumSize(entityTypeId: EntityTypeId): number {
  if (entityTypeId === EntityTypeId.Shoal) {
    return VERTICES_PER_TILE * 1
  }
  if (entityTypeId === EntityTypeId.River) {
    return VERTICES_PER_TILE * 1
  }
  if (entityTypeId === EntityTypeId.Infantry) {
    return VERTICES_PER_TILE * 0.3
  }
  if (entityTypeId === EntityTypeId.LightRecon) {
    return VERTICES_PER_TILE * 0.475
  }
  if (entityTypeId === EntityTypeId.APC) {
    return VERTICES_PER_TILE * 0.5
  }
  if (entityTypeId === EntityTypeId.TransportCopter) {
    return VERTICES_PER_TILE * 0.8
  }
  if (entityTypeId === EntityTypeId.MediumArtillary) {
    return VERTICES_PER_TILE * 0.65
  }
  if (entityTypeId === EntityTypeId.LightTank) {
    return VERTICES_PER_TILE * 0.7
  }
  if (entityTypeId === EntityTypeId.MediumCopter) {
    return VERTICES_PER_TILE * 0.8
  }
  if (entityTypeId === EntityTypeId.MediumGunAntiAir) {
    return VERTICES_PER_TILE * 0.9
  }
  if (entityTypeId === EntityTypeId.MediumMissileAntiAir) {
    return VERTICES_PER_TILE * 0.65
  }
  if (entityTypeId === EntityTypeId.MediumRockets) {
    return VERTICES_PER_TILE * 0.6
  }
  if (entityTypeId === EntityTypeId.MediumTank) {
    return VERTICES_PER_TILE * 0.7
  }
  if (isEntityTerrainMesh1({etype_id: entityTypeId} as Entity)) {
    return VERTICES_PER_TILE * 1.1
  }
  return VERTICES_PER_TILE
}