import Forest1Option from './Forest1/Forest1Option'
import { MapScriptOption } from './MapScriptOption.type'
import Plains1Option from './Plains1/Plains1Option'

const mapScriptOptionsList: Array<MapScriptOption> = [
  Plains1Option,
  Forest1Option,
]

export default mapScriptOptionsList
