import { type Object3D } from "three"
import disposeForTraverse from "./disposeForTraverse"

export default function removeObject3DByName(obj: Object3D, name: string): void {
  const list = new Set<Object3D>()
  obj.traverse((child) => {
    if (child.name === name) {
      list.add(child)
    }
  })
  list.forEach(child => {
    child.traverse(disposeForTraverse)
    child.removeFromParent()
  })
}
