import { Component, createMemo, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import type { PaintTileOption } from "../../lib/core/map_editor/paint_tile_option"
import { deepClone } from "../../lib/deep_clone"
import EngineContext from "../EngineContext"
import SingleEntityIcon3D from "./SingleEntityIcon3D"

interface Props {
  option: PaintTileOption,
  // size: number,
  // style?: JSX.CSSProperties,
}

// based on SingleTileCanvas
const SingleEntityPreviewIcon3D: Component<Props> = (props: Props) => {
  // console.log('SingleEntityPreviewIcon3D', props)

  const engine: Engine = useContext(EngineContext)

  const getEntity = createMemo(() => {
    const entity = deepClone(props.option.entity)

    const player_id = engine.selectedPlayerId
    if (entity && ('player_id' in entity)) {
      entity.player_id = player_id
    }

    return entity
  })

  return <SingleEntityIcon3D entity={getEntity()} size={128} />
}

export default SingleEntityPreviewIcon3D
