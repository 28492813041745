import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import type { Nullable } from "vite-node"
import createEntityHeadingWithTargetTextCanvas from "./createEntityHeadingWithTargetTextCanvas"

/**
 * @param heading: Nullable<number> 0, 90, 180, 270, null, undefined
 * @param targetHeading: Nullable<number> 0, 90, 180, 270, null, undefined
 * @param entityHeading: Nullable<number> 0, 90, 180, 270, null, undefined
 **/
export default function createEntityHeadingWithTargetTextSprite(
  heading: Nullable<number>,
  targetHeading: Nullable<number>,
  entityHeading: Nullable<number>,
): Sprite {

  const canvas = createEntityHeadingWithTargetTextCanvas(heading, targetHeading, entityHeading)
  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({
    map: texture,
    transparent: true,
    // opacity: OPACITY_NORMAL,
  })
  const sprite = new Sprite(material)

  // sprite.userData.vampire = true

  sprite.name = 'EntityHeadingText'
  sprite.userData.heading = heading
  sprite.userData.targetHeading = targetHeading
  sprite.userData.entityHeading = entityHeading
  sprite.center.set(0.5, 0)

  // sprite.userData.update = () => {
  //   sprite.material.opacity = shouldEntityShowFadedFrames(ent) ? OPACITY_FADED : OPACITY_NORMAL
  // }

  return sprite
}