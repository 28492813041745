import { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindowV2 from '../lib/bindViewCtxToWindowV2'
import bindEngineToComponentV2 from '../lib/canvas/bindEngineToComponentV2'
import createEngineForMapEditor from '../lib/core/engine/createEngineForMapEditor'
import { Engine } from '../lib/core/engine/Engine.type'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import bindCameraToUserPreferences from '../lib/three/camera/bindCameraToUserPreferences'
import createMainCamera from '../lib/three/fn/createMainCamera'
import createMountedMainCanvasElement from '../lib/three/fn/createMountedMainCanvasElement'
import createMountedMainRenderer from '../lib/three/fn/createMountedMainRenderer'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import EngineContext from './EngineContext'
import MapEditorToolbar from './Hud/MapEditorToolbar'

interface Props {
  // session: BackendSessionPayload,
  row: MapRowValue,
}

// const componentStateType = StateType.Map

const MapEditThreeJsCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForMapEditor())

  onMount(() => {
    syncEngineWithPropsRow(engine, props.row)
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  // createCenterPanOnMount(engine)

  // addTerrainCursorCanvasOld(engine)
  // addCursorCanvas(engine)

  // registerTouchEventsV2(engine)

  // registerServerSentEvents(engine)

  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
      //     // engine.selectedPosition = { x: 13, y: 9 }
      //     // calculateSelectedPositionEnts(engine)
      //     // engine.selectedTool = SelectedToolId.File
      //     engine.selectedTool = SelectedToolId.Inspect
      engine.selectedTool = SelectedToolId.Paint
      //     // engine.selectedTool = SelectedToolId.Erase
      //     engine.viewCtx.zoom = 200
      //     engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      //     calculateMapWidthHeightPxTileSize(engine)
      //     centerPan(engine)
    }))
  })

  const canvasElem = createMountedMainCanvasElement()
  const renderer = createMountedMainRenderer(canvasElem)
  const camera = createMainCamera(canvasElem)

  bindViewCtxToWindowV2(engine, canvasElem, renderer, camera)
  bindEngineToComponentV2(engine, canvasElem, renderer, camera)
  bindCameraToUserPreferences(camera)

  return <EngineContext.Provider value={engine}>
    <div id="gpc">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          // registerTouchEventsV2(engine, ref)
          // registerMouseMoveEvent(engine, ref)
        }
      }}>
        {canvasElem}
      </div>
    </div>
    <MapEditorToolbar />
  </EngineContext.Provider>
}

export default MapEditThreeJsCanvas
