import { nextIntRange } from '../rng'
import { RNGConfig } from '../rng/RNGConfig.type'
import type { HasId } from './id'
import { floor, random } from './math'
import { MAX_I16 } from './number'

// a collision will probably never happen
// better safe than sorry
export function randomId(objectPool: Array<HasId>, rng: RNGConfig): number {
  let id = 0
  do {
    id = nextIntRange(1, MAX_I16, rng)
    if (!objectPool.find(whereSameId)) {
      return id
    }
  } while (true) // eslint-disable-line
  function whereSameId(object: HasId) {
    return object.id === id
  }
}

export function randomAZaz09(size: number): string {
  let result = ''
  const pool = 'abcedfghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const pool_len = pool.length
  for (let i = 0; i < size; i++) {
    result += pool.charAt(floor(random() * pool_len))
  }
  return result
}
