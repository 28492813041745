import createLayerMeta from '../../core/create_layer_meta'
import { Engine } from '../../core/engine/Engine.type'
import { EXPIRE_NEVER } from '../../core/layer_meta'
import bindCanvasToEngine from '../bind_canvas_to_engine'
import { createCanvasElement } from '../create_canvas'
import { disableCanvasSmoothing } from '../disable_canvas_smoothing'
import { getCanvas2dContext } from '../get_canvas_2d_context'

const name = 'BgGradient'
const relZIndex = 0
const bottomColor = '#101010'
const topColor = '#505050'

// this canvas is not animated, therefor does not need a buffer
/**
 * @deprecated
 */
export default function addBgGradientCanvas(engine: Engine): void {
  // console.log(`${name}.add`)
  const lm = createLayerMeta()
  const elem = createCanvasElement()
  elem.setAttribute('name', name)
  const { viewCtx } = engine
  elem.style.zIndex = (viewCtx.zIndex + relZIndex).toString()

  let prev_renderWidthPx = 0
  let prev_renderHeightPx = 0

  function render(timeStamp: number) {
    // console.log(`${name}.render1`)
    lm.ts = timeStamp
    const { renderWidthPx, renderHeightPx } = viewCtx
    if (prev_renderWidthPx === renderWidthPx && prev_renderHeightPx === renderHeightPx) {
      // no need to re-render
      // console.log(`${name}.render`, 'skipped1')
      return
    }
    prev_renderWidthPx = renderWidthPx
    prev_renderHeightPx = renderHeightPx
    // console.log(`${name}.render`)

    elem.width = renderWidthPx
    elem.height = renderHeightPx
    const ctx = getCanvas2dContext(elem)

    // frames have indicated they
    // plan on rendering the same image
    if (lm.until > timeStamp) {
      // console.log(`${name}.render`, 'skipped2')
      return
    }
    // console.log(`${name}.render2`)
    lm.until = timeStamp + EXPIRE_NEVER

    if (ctx) {
      ctx.clearRect(0, 0, renderWidthPx, renderHeightPx)
      disableCanvasSmoothing(ctx)

      const grd = ctx.createLinearGradient(0, renderHeightPx, 0, 1)
      grd.addColorStop(0, bottomColor)
      grd.addColorStop(1, topColor)
      ctx.fillStyle = grd
      ctx.fillRect(0, 0, renderWidthPx, renderHeightPx)
    }
  }

  bindCanvasToEngine(engine, elem, render)
}
