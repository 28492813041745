import type { MountainEntity } from "../../../core/entity/EntityType/Mountain"
import getManhattanDistance from "../../../core/tile_position_xy/getManhattanDistance"

type MountainEntityList = Array<MountainEntity>
type Chunk = Array<MountainEntityList>

// Step 1: Create initial clusters based on adjacency
export default function createInitialMountainClusters(
  entities: MountainEntityList
): Chunk {
  const visited = new Set<number>()
  const chunks: Chunk = []

  function floodFill(start: MountainEntity, cluster: MountainEntityList) {
    const stack = [start]
    visited.add(start.id)

    while (stack.length > 0) {
      const current = stack.pop()!
      cluster.push(current)

      for (const entity of entities) {
        if (!visited.has(entity.id) && getManhattanDistance(current, entity) === 1) {
          visited.add(entity.id)
          stack.push(entity)
        }
      }
    }
  }

  for (const entity of entities) {
    if (!visited.has(entity.id)) {
      const cluster: MountainEntityList = []
      floodFill(entity, cluster)
      chunks.push(cluster)
    }
  }
  return chunks
}