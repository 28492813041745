import { type Vector3 } from "three"
import type { Nullable } from "../../../typescript"
import type { Engine } from "../../core/engine/Engine.type"
import coord from "../../core/tile_position_xy/coord"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import { floor } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"

export default function pointerVector3PointToPointerTilePosition(
  engine : Engine,
  pointerVector3Point : Nullable<Vector3>
): TilePositionXY | null {
    if (pointerVector3Point) {
      // const {
      //   width: stateWidth,
      //   height: stateHeight,
      // } = engine.state
      // const halfGeometryWidth = (stateWidth * VERTICES_PER_TILE / 2)
      // const halfGeometryHeight = (stateHeight * VERTICES_PER_TILE / 2)
      // console.log('plainGeometry.parameters.height', plainGeometry.parameters.height, 'sizePerTile', plainGeometry.parameters.height / stateHeight)
      // const halfPlainGeometryParametersHeight = plainGeometry.parameters.height / 2
      // const halfPlainGeometryParametersWidth = plainGeometry.parameters.width / 2
      // const halfPlainGeometryParametersHeight = halfGeometryWidth / 2
      // const halfPlainGeometryParametersWidth = halfGeometryHeight / 2
      // const halfPlainGeometryParametersHeight = 0
      // const halfPlainGeometryParametersWidth = 0

      const { x: rawX, z: rawY } = pointerVector3Point
      // console.log('pointer{x,z}', parseInt(x), parseInt(z))

      // const stateX = floor((x + halfPlainGeometryParametersWidth) / VERTICES_PER_TILE)
      // const stateY = floor((halfPlainGeometryParametersHeight + halfPlainGeometryParametersHeight - z) / VERTICES_PER_TILE)
      // const stateX = floor((x - halfPlainGeometryParametersWidth) / VERTICES_PER_TILE)
      // const stateY = floor((-z) / VERTICES_PER_TILE)

      // const stateX = floor((rawX + halfGeometryWidth) / VERTICES_PER_TILE)

      // const stateY = floor((rawY + halfGeometryHeight) / -VERTICES_PER_TILE)
      const stateX = floor((rawX) / VERTICES_PER_TILE)
      const stateY = floor((rawY) / VERTICES_PER_TILE)

      // console.log('pointer{x,y}', parseInt(rawX), parseInt(rawY), {stateX, stateY})

      return coord(stateX, stateY)
    } else {
      return null
    }
}
