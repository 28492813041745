import type { EntityHeading } from "./EntityHeading.type"

export default function rotateEntityDirectionClockwise90degrees (value : EntityHeading): EntityHeading {
  // console.log('rotateEntityDirectionClockwise90degrees.input', value)
  value += 90
  if (value >= 360) {
    value -= 360
  }
  // console.log('rotateEntityDirectionClockwise90degrees.output', value)
  return value as EntityHeading
}