import { RequiredProps } from '../../../../../typescript'
import { EntityTypeId } from '../../../entity/EntityTypeId.enum'
import { MapScript_FormData } from '../FormData/FormData.type'
import { Clobber } from '../FormData/run/Clobber.enum'
import { createStageEntWeight } from '../FormData/StageEntRule.type'
import { MapScriptOption } from '../MapScriptOption.type'

export type Forest1OptionFormData = RequiredProps<MapScript_FormData, 'seed' | 'stages'>

// TODO
// the first map scripts will be plains, forest, snow, desert, sea.

const Forest1Option: MapScriptOption = {
  dname: 'Forest',
  makeFormData(): Forest1OptionFormData {
    return {
      seed: -1,
      stages: [
        {
          clobber: Clobber.Yes,
          coverageRatio: 1,
          entWeights: [createStageEntWeight(EntityTypeId.Plain)],
        },
        {
          clobber: Clobber.No,
          coverageRatio: 0.09,
          entWeights: [createStageEntWeight(EntityTypeId.Mountain)],
        },
        {
          clobber: Clobber.No,
          coverageRatio: 0.55,
          entWeights: [createStageEntWeight(EntityTypeId.Forest)],
        },
      ],
    }
  },
}

export default Forest1Option
