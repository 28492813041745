import { Color, ConeGeometry, InstancedMesh, MeshLambertMaterial } from "three"

const mediumBarkColor = new Color(0x5D4037); // Medium brown bark

export default function createBrownTreeTrunkInstancedMesh (count: number) {
  const w = 0.5
  const h = 4
  const geometry = new ConeGeometry(w, h, 6, 1)

  const material = new MeshLambertMaterial({
    color: mediumBarkColor,
    // roughness: 0.9,
    // metalness: 0.0,
    // flatShading: true,
    // // wireframe: true,

  })
  const mesh = new InstancedMesh(geometry, material, count)
  mesh.position.y += (h / 2)
  return mesh
}