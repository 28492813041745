export type DirectionDxDy = {
  dx: number,
  dy: number,
}

export const dijkstraDirections : Array<DirectionDxDy> = [
  { dx: 0, dy: 1 }, // Up
  { dx: 1, dy: 0 }, // Right
  { dx: 0, dy: -1 }, // Down
  { dx: -1, dy: 0 }, // Left
] as const

export const diagonalDirections : Array<DirectionDxDy> = [
  { dx: 1, dy: 1 }, //  Up Right
  { dx: 1, dy: -1 }, // Down Right
  { dx: -1, dy: 1 }, //  Up Left
  { dx: -1, dy: -1 }, // Down Left
] as const

export const DIRECTION_DXDY_UP = dijkstraDirections[0]
export const DIRECTION_DXDY_RIGHT = dijkstraDirections[1]
export const DIRECTION_DXDY_DOWN = dijkstraDirections[2]
export const DIRECTION_DXDY_LEFT = dijkstraDirections[3]

export const DIRECTION_DXDY_UP_RIGHT = diagonalDirections[0]
export const DIRECTION_DXDY_DOWN_RIGHT = diagonalDirections[1]
export const DIRECTION_DXDY_UP_LEFT = diagonalDirections[2]
export const DIRECTION_DXDY_DOWN_LEFT = diagonalDirections[3]

export const eightDirections : Array<DirectionDxDy> = [
  DIRECTION_DXDY_UP,
  DIRECTION_DXDY_UP_RIGHT,
  DIRECTION_DXDY_RIGHT,
  DIRECTION_DXDY_DOWN_RIGHT,
  DIRECTION_DXDY_DOWN,
  DIRECTION_DXDY_DOWN_LEFT,
  DIRECTION_DXDY_LEFT,
  DIRECTION_DXDY_UP_LEFT,
] as const

// dir0 the relative origin direction
// offset: number: how many 45 degree directional turns
export function getNextEightDirection(dir0 : DirectionDxDy, offset: number): DirectionDxDy {
  const index0 = eightDirections.indexOf(dir0)
  const index1 = (index0 + offset) % 8
  return eightDirections[index1]
}

export function isDirectionDxDyDiagonal(dir: DirectionDxDy) {
  return diagonalDirections.indexOf(dir) !== -1
}
