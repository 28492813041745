import { onCleanupDispose } from "../../../rx/cleanup/onCleanupDispose"
import createMainRenderer from "./createMainRenderer"

export default function createMountedMainRenderer(
  canvasElem: HTMLCanvasElement | OffscreenCanvas,
) {
  const renderer = createMainRenderer(canvasElem)

  onCleanupDispose(renderer)

  // handled in bindToWindow
  // renderer.setPixelRatio(window.devicePixelRatio)
  // renderer.setSize(window.innerWidth, window.innerHeight)

  return renderer
}