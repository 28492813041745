import type { RoadEntity } from './EntityType/Road'
import { EntityTypeId } from './EntityTypeId.enum'
import type { Entity } from './index'

export default function findRoad(ents: Array<Entity>): RoadEntity | null {
  for(let index = ents.length; index--;) {
    const item = ents[index]
    if (item.etype_id === EntityTypeId.Road) {
      return item
    }
  }
  return null
}
