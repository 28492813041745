import { Group, Object3D } from 'three'
import type { ForestEntity } from '../../core/entity/EntityType/Forest'
import tmpRngWith from '../../core/rng/tmpRngWith'
import { VERTICES_PER_TILE } from '../consts'
import createBrownTreeTrunkInstancedMesh from '../objects/Trees/createBrownTreeTrunkInstancedMesh'
import createGreenTreeLeavesInstancedMesh from '../objects/Trees/createGreenTreeLeavesInstancedMesh'
import generateTreePositions from './generateTreePositions'

export default function createForestGroup(
  ent: ForestEntity
): Group {
  const rng = tmpRngWith(ent.id)
  const forest = new Group()

  const spaceSize = VERTICES_PER_TILE
  const minTrees = 20
  const maxTrees = 32
  const margin = 1.4
  const minSpacing = 1

  const dummy = new Object3D()

  const positions = generateTreePositions(spaceSize, minTrees, maxTrees, margin, minSpacing, rng)
  const count = positions.length
  const trunkIM = createBrownTreeTrunkInstancedMesh(count)
  const leavesIM = createGreenTreeLeavesInstancedMesh(count)

  positions.forEach(({ x, y }, index: number) => {
    dummy.position.x = x
    dummy.position.z = y
    const { matrix } = dummy
    dummy.updateMatrix()

    trunkIM.setMatrixAt(index, matrix)
    leavesIM.setMatrixAt(index, matrix)
  })

  forest.add(trunkIM)
  forest.add(leavesIM)

  trunkIM.frustumCulled = true
  leavesIM.frustumCulled = true

  return forest
}
