import { modifyMutable, produce } from 'solid-js/store'
import { Vector2 } from 'three'
import calculateDestPositionHoverPositionPaths from '../../core/draft_move/calculateDestPositionHoverPositionPaths'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import { Engine } from '../../core/engine/Engine.type'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import onElementEvent from '../../onElementEvent'

export default function registerPointerMoveEvent(
  engine: Engine,
  elem: HTMLElement,
  pointerRef : Vector2
) {

  // const velocity = { x: 0, z: 0 }

  function onPointerMove(event: MouseEvent) {
    // console.log('onPointerMove', event, window.innerWidth, window.innerHeight, elem)

    const {
      offsetX, offsetY,
      // buttons,
      // movementX, movementY,
    } = event

    // const pointerX = (clientX / window.innerWidth) * 2 - 1
    // const pointerY = - (clientY / window.innerHeight) * 2 + 1
    // const pointerX = ((offsetX / (elem as HTMLCanvasElement).width) * 2) - 1
    // const pointerY = -((offsetY / (elem as HTMLCanvasElement).height) * 2) + 1
    // console.table([{offsetX, offsetY, pointerX, pointerY}])
    const pointerX = (offsetX / (elem as HTMLCanvasElement).clientWidth) * 2 - 1
    const pointerY = - (offsetY / (elem as HTMLCanvasElement).clientHeight) * 2 + 1

    pointerRef.x = pointerX
    pointerRef.y = pointerY

    modifyMutable(
      engine,
      produce((engine: Engine) => {
        const { viewCtx, state } = engine
        // const { pointerPanRatio } = viewCtx

        // const isMap = wasEventTargetMap(event)
        // const devicePixelRatio = window.devicePixelRatio || 1
        engine.lastPointerMoveEvent = event

        if (engine.threeCtx) {
          // we use the raycaster for this
        } else {
          const position = positionFromPixels(state, viewCtx, offsetX, offsetY)
          if (!samePosition(engine.hoveredPosition, position)) {
            engine.hoveredPosition = position
            calculateDestPositionHoverPositionPaths(engine)
            calculateDraftMoveAttackEstimates(engine)
          }
        }
        // console.log('hoveredPosition', engine.hoveredPosition)
        // if (buttons === 1) {
        //   viewCtx.lastPanAt = now()

        //   // camera.position.x -= pointerPanRatio * (movementX / devicePixelRatio)
        //   // camera.position.z -= pointerPanRatio * (movementY / devicePixelRatio)
        //   // clampCameraToState(camera, state)

        //   // console.log('camera.position.x', camera.position.x)
        //   // velocity.x -= movementX / devicePixelRatio
        //   // velocity.z -= movementY / devicePixelRatio
        // }
        if (engine.mouseDownEvent && engine.mouseDragList) {
          engine.mouseDragList.push(event)
          // triggerRef(engine.mouseDragList)
        }
        // if (isMap && engine.mouseDownEvent) {
        // dirty(EngineCache.Cursor)
        // }
      })
    )
  }

  // onWindowEvent('pointermove', onPointerMove)
  onElementEvent(elem, 'pointermove', onPointerMove)

  // const names = ['touchstart', 'touchmove', 'touchend']
  // function logEventWithName(event: TouchEvent) {
  //   console.log(event.type, event)
  // }
  // names.forEach((name: string) => onWindowEvent(name, logEventWithName))

  // return function applyMousePanVelocityToCamera() {
  //   // velocity.x *= 0.33; // Decay factor
  //   // velocity.z *= 0.33;
  //   camera.position.x += velocity.x;
  //   camera.position.z += velocity.z;

  //   // // Stop updating when velocity is very small
  //   // if (abs(velocity.x) < 0.01 && abs(velocity.z) < 0.01) {
  //   //   velocity.x = 0;
  //   //   velocity.z = 0;
  //   // }
  // }
}
