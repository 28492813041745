import { PlaneGeometry } from 'three'
import { mergeGeometries, mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import { PI } from '../../core/util/math'

// a regular plane will have all it's triangles
// pointing diagonally in the same direction
export default function createInwardPointedPlaneGeometry (
  width: number,
  height: number,
  widthSegments: number,
  heightSegments: number) {

  const g1 = new PlaneGeometry(width, height, widthSegments, heightSegments)
  const g2 = new PlaneGeometry(width, height, widthSegments, heightSegments)
  const g3 = new PlaneGeometry(width, height, widthSegments, heightSegments)
  const g4 = new PlaneGeometry(width, height, widthSegments, heightSegments)
  g2.rotateZ(PI  / 2)
  g3.rotateZ(PI  / 2)
  const ow = width / 2
  const oh = height / 2
  g1.translate(ow, oh, 0)
  g2.translate(-ow, oh, 0)
  g3.translate(ow, -oh, 0)
  g4.translate(-ow, -oh, 0)

  const g5 = mergeGeometries([
    g1, g2, g3, g4,
  ], false)
  mergeVertices(g5)

  return g5
}