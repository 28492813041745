import { createRoot, createSignal } from 'solid-js'
import { parse, safeParse } from 'valibot'
import { type DeviceUserPreference, DeviceUserPreferenceSchema } from './DeviceUserPreferenceSchema.type'

const DEVICE_USER_PREFERENCE_LS_KEY = 'dup'

function createDeviceUserPreference(): DeviceUserPreference {
  return parse(DeviceUserPreferenceSchema, {})
}

const deviceUserPreferencesSignal = createRoot(() => {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal(createDeviceUserPreference())
  // const [getDeviceUserPreference, setDeviceUserPreference] = signal

  window.addEventListener('storage', function (event) {
    if (event.key === DEVICE_USER_PREFERENCE_LS_KEY) {
      const newValue = event.newValue ? JSON.parse(event.newValue) : null
      const result = safeParse(DeviceUserPreferenceSchema, newValue)
      if (result.success) {
        signal[1](result.output)
      }
    }
  })

  return signal
})

export default deviceUserPreferencesSignal

export const [getDeviceUserPreference, setDeviceUserPreference] = deviceUserPreferencesSignal
