import { Object3D } from "three"
import type { HasDisposeOrTraverse, Nullable } from "../../../typescript"
import disposeForTraverse from "./disposeForTraverse"

export default function disposeRecursive (obj : Nullable<HasDisposeOrTraverse>): void {
  if (obj) {
    if (obj instanceof Object3D) {
      obj.traverse(disposeForTraverse)
      disposeForTraverse(obj)
    }
    if ('dispose' in obj) {
      obj.dispose()
    }
  }
}