import createDefaultWait from "../../../createDefaultWait"
import { deepClone } from "../../../deep_clone"
import type { Engine } from "../../engine/Engine.type"
import getFactoryBuildUnitsStatus, { FactoryBuildUnitsStatus } from "../../entity/canFactoryBuildUnits"
import type { FactoryEntity } from "../../entity/EntityType/Factory"
import { EntityTypeId } from "../../entity/EntityTypeId.enum"
import entityTypeMetaForFactoryList from "../../entity/entityTypeMetaForFactoryList"
import { isEntityFactory } from "../../entity/entityTypeMetaList.generated"
import getEntitiesAtPosition from "../../entity/getEntitiesAtPosition"
import getPlayerEntities from "../../entity/getPlayerEntities"
import type { EntityTypeMeta } from "../../EntityTypeMeta"
import findByIdOrThrow from "../../findByIdOrThrow"
import { byPriceAsc, type HasPrice } from "../../HasPrice"
import { popSample, sample } from "../../rng/index"
import tmpRng from "../../rng/tmpRng"
import { createPlaceFactoryOrderAction } from "../../state/flux/action/Game/PlaceFactoryOrderAction"
import dispatchClient from "../../state/flux/dispatchClient"
import type { Bot } from "../Bot.type"
import botShouldContinue from "./botShouldContinue"

export default async function botPlaceFactoryOrders(
  engine: Engine,
  bot: Bot
) {
  console.log('%c[botPlaceFactoryOrders]', 'color: grey; font-weight: lighter; font-size: 10px;')
  const { player, stepDelay } = bot
  const playerId = player.id
  const allPlayerEnts = getPlayerEntities(engine.state.ents, playerId)
  // console.log('allPlayerEnts', deepClone(allPlayerEnts))

  const factoryEntList = allPlayerEnts.filter((ent): ent is FactoryEntity => {
    if (isEntityFactory(ent)) {
      const entsAtPosition = getEntitiesAtPosition(engine.state.ents, ent)
      return getFactoryBuildUnitsStatus(ent, entsAtPosition) === FactoryBuildUnitsStatus.WaitingForOrder
    }
    return false
  })
  console.log('building units', deepClone(factoryEntList))

  const budget = player.money
  const options = entityTypeMetaForFactoryList.filter(etm => {
    if (etm.price > budget) {
      // not enough money
      return false
    }
    if (etm.transports) {
      // not implemented
      return false
    }
    if (etm.id === EntityTypeId.MediumMissileAntiAir) {
      // not implemented
      return false
    }
    return true
  }).sort(byPriceAsc)

  const unitComposition = new Map<string, number>()
  options.forEach(etm => {
    unitComposition.set(EntityTypeId[etm.id], allPlayerEnts.filter(ent => ent.etype_id === etm.id).length)
  })
  console.log('unitComposition', unitComposition)

  const infantryEtm = findByIdOrThrow(entityTypeMetaForFactoryList, EntityTypeId.Infantry)

  // by default, build infantry
  const shoppingList : Array<EntityTypeMeta & HasPrice> = factoryEntList.map(() => infantryEtm)

  // function getRemaingBudget(): number {
  //   const totalPrice = shoppingList.reduce(toTotalPrice, 0)
  //   return budget - totalPrice
  // }

  let remainingBudget = budget - shoppingList.reduce(toTotalPrice, 0)

  // TODO if there's extra money, swap an infantry for a more expensive unit

  shoppingList.forEach((item : EntityTypeMeta & HasPrice, index : number): void => {
    const affordableOptions = options.filter(option => option.price <= item.price + remainingBudget);
    if (affordableOptions.length > 0) {
      // Pick a random option from affordableOptions
      const randomUpgrade = sample(affordableOptions, tmpRng)
      if (randomUpgrade) {
        shoppingList[index] = randomUpgrade
        remainingBudget -= (randomUpgrade.price - item.price)
      }
    }
  })

  do {
    const factory = popSample(factoryEntList, tmpRng)
    if (!factory) {
      console.log('ran out of factory')
      break
    }
    const etm = popSample(shoppingList, tmpRng)
    if (!etm) {
      console.log('shopping list empty')
      break
    }


    // console.log('factory', deepClone(factory))

    // TODO compare enemy unit composition to bot unit composition
    // TODO check what's on enemy factories
    // TODO check counters

    await dispatchClient(engine, createPlaceFactoryOrderAction(factory, etm.id))

    await createDefaultWait(stepDelay)
  } while (botShouldContinue(engine, bot))
}

function toTotalPrice (carry: number, etm: EntityTypeMeta & HasPrice): number {
  return carry + etm.price
}
