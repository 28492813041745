import { enum_ } from 'valibot'

export enum RoadKind {
  // None = 0, // not a valid road

  Straight = 1,
  Corner = 2,
  ThreeWay = 3,
  FourWay = 4,

  // Each variant could also be blocked
  // UpBlocked = 1 << 4,
  // DownBlocked = 1 << 5,
  // LeftBlocked = 1 << 6,
  // RightBlocked = 1 << 7,

  // directionless variants
  // Potholes = 1 << 8,
  // Gravel = 1 << 9,
  // Dirt = 1 << 10,
  // RightBlocked = 1 << 11,
}

// export const RoadKindSchema = pipe(number(), minValue(0), maxValue(calculateMaxBitFlagSum(3)))
export const RoadKindSchema = enum_(RoadKind, 'Invalid Road Type')

// export const HasRoadKindSchema = object({
//   road: RoadKindSchema,
// })

// export type HasRoadKind = InferOutput<typeof HasRoadKindSchema>
