import { DEV } from "solid-js"
import type { Engine } from "../../core/engine/Engine.type"
import entityDisplayName from "../../core/entity/entityDisplayName"
import isEntityUnit from "../../core/entity/isEntityUnit"
import disposeRecursive from "../fn/disposeRecursive"
import removeObject3DByName from "../fn/removeObject3DByName"
import createEntityHeadingWithTargetTextSprite from "../sprite/createEntityHeadingWithTargetTextSprite"
import createUnitSprite, { UNIT_SPRITE_NAME } from "../sprite/createUnitSprite"
import type { EntityThreeModel } from "./EntityThreeModel.type"

export default function addEntityThreeModelUnitSprite(
  engine: Engine,
  obj: NonNullable<EntityThreeModel['obj']>,
  ent: EntityThreeModel['ent']
): void {
  const HPBarAnchor = obj.getObjectByName('HPBA')
  if (DEV && !HPBarAnchor && isEntityUnit(ent)) {
    console.error(new Error(`!HPBA (${entityDisplayName(ent)})`))
  }
  if (HPBarAnchor) {
    const oldSprite = HPBarAnchor.getObjectByName(UNIT_SPRITE_NAME)
    if (oldSprite) {
      // we'll remove it anyways, to simply code for moving it.
      // if (oldSprite.userData.hp === hp) {
      //   // no change required
      //   return
      // }
      // remove old one
      oldSprite.removeFromParent()
      disposeRecursive(oldSprite)
    }
    // obj.updateMatrix()
    // obj.updateMatrixWorld(true)
    // const groupSize = getComputedBoundingBoxSizeVector3(obj)
    // const groupSize2 = getComputedBoundingBoxSizeVector3V2(obj)
    // console.log(entityDisplayName(ent))
    // console.log('groupSize', toFixed1(groupSize.x), toFixed1(groupSize.y), toFixed1(groupSize.z), entityDisplayName(ent))
    // console.log('groupSize2', toFixed1(groupSize2.x), toFixed1(groupSize2.y), toFixed1(groupSize2.z))

    if (HPBarAnchor) {

      let HeadingSprite = createEntityHeadingWithTargetTextSprite(-1, -1, -1)
      HPBarAnchor.userData.update = () => {
        const { heading, targetHeading } = obj.userData
        const entityHeading = obj.userData.e.heading

        // if (DEV) {
        //   try {
        //     parse(DegreeNumberSchema, heading)
        //     parse(DegreeNumberSchema, targetHeading)
        //     parse(DegreeNumberSchema, entityHeading)
        //   } catch (err) {
        //     console.log(entityDisplayName(obj.userData.e), deepClone(obj.userData.e), { heading, targetHeading, entityHeading })
        //     throw err
        //   }
        // }

        if (HeadingSprite.userData.heading !== heading
          || HeadingSprite.userData.targetHeading !== targetHeading
          || HeadingSprite.userData.entityHeading !== entityHeading) {
          if (HeadingSprite) {
            HeadingSprite.removeFromParent()
          }
          HeadingSprite = createEntityHeadingWithTargetTextSprite(heading, targetHeading, entityHeading)
          HeadingSprite.position.y += 10
          HeadingSprite.scale.set((512 + 256), (256 + 128), 0)
          HeadingSprite.scale.divideScalar(30)
          removeObject3DByName(HPBarAnchor, HeadingSprite.name)
          HeadingSprite.visible = false
          HPBarAnchor.add(HeadingSprite)
        }
      }

      const sprite = createUnitSprite(engine, ent)
      if (sprite) {
        // const spriteWidth = max(3, 2 * max(groupSize.x, groupSize.z))
        // console.log('height', getGroupHeight(newGroup))
        HPBarAnchor.add(sprite)

        const scale = 1/32
        const { width, height } = sprite.userData
        sprite.scale.set(
          scale * width,
          scale * height,
          1)

        // const parentScale = new Vector3()
        // obj.getWorldScale(parentScale)
        // console.log('parentScale', toFixed1(parentScale.x), toFixed1(parentScale.y), toFixed1(parentScale.z))
        // console.log('obj.position', toFixed1(obj.position.x), toFixed1(obj.position.y), toFixed1(obj.position.z))
        // if (!HPBarAnchor) {
        //   const rh = 
        //     (obj.position.y)
        //     // + (groupSize.y)
        //     + 8
        //     + getHealthBarRenderHeight(ent)
        //     // + (height * scale)
        //     // + (sprite.material.map!.source.data.height / 32)
        //     // + (sprite.material.map!.source.data.height / 64)
        //     // + (sprite.userData.h / 64)
    
        //   if (isEntityAPC(ent)) {
        //     console.log('sprite', { width, height,
        //       'obj.y': obj.position.y,
        //       'height*scale': height*scale,
        //       hbrh: getHealthBarRenderHeight(ent),
        //     }, sprite)
        //     console.log('rh', rh, entityDisplayName(ent))
        //   }

        //   // sprite.scale.divide(parentScale)
        //   // setObjPosition(sprite, ent, rh)
        //   // sprite.position.set(0, rh / parentScale.y, 0) // Position the text above the box
        //   sprite.position.y = rh
        // }
      }
      // newGroup.renderOrder = RenderOrder.UnitEntity
      // console.log(newGroup)
    }
  }
}
