import type { Material, Object3D } from "three"
import { isArray } from "../../core/util/Array"
import isMesh from "./isMesh"

export default function findMaterialByName(group: Object3D, materialName: string) : Material | undefined {
  let foundMaterial : Material | undefined

  group.traverse((child) => {
    // Check if the child is a Mesh and has a material
    if (isMesh(child) && child.material) {
      // Handle arrays of materials (e.g., multi-material Meshes)
      if (isArray(child.material)) {
        foundMaterial = child.material.find((mat) => mat.name === materialName)
      } else if (child.material.name === materialName) {
        foundMaterial = child.material
      }
    }
  })

  return foundMaterial
}