import createEngineRoot from './createEngineRoot'
import { EngineMode } from './EngineMode/EngineMode.enum'

// placeholder for declaring intent
const createEngineForGamePlay = function () {
  return {
    ...createEngineRoot(),
    mode: EngineMode.Game,
  }
}

export default createEngineForGamePlay
