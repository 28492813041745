import { Mesh, type MeshStandardMaterial } from "three"
import { EntityTypeId } from "../../core/entity/EntityTypeId.enum"
import type { Entity } from "../../core/entity/index"
import tmpRngWith from "../../core/rng/tmpRngWith"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import createCamoMapTexture from "./createCamoMapTexture"
import createFadedAOMapTexture from "./createFadedAOMapTexture"

const entityTypeIdIgnoreList : Array<EntityTypeId> = [
  EntityTypeId.Building,
  EntityTypeId.Factory,
  EntityTypeId.HQ,
  EntityTypeId.Road,
  EntityTypeId.Shoal,
  EntityTypeId.Sea,
  EntityTypeId.River,
]

export default function addProcedurallyGeneratedEntityTextures (
  object: NonNullable<EntityThreeModel['obj']>,
  ent: Entity): void {
  // console.log('addProcedurallyGeneratedEntityTextures')
  // if (isEntityMediumTank(ent)) {
  //   console.log('addProcedurallyGeneratedEntityTextures', entityDisplayName(ent), object)
  // }
  if (entityTypeIdIgnoreList.includes(ent.etype_id)) {
    return
  }

  object.traverse((child) => {
    if (child instanceof Mesh) {
      const material = child.material as MeshStandardMaterial
      if (material.name == 'Window' || material.userData.W) {
        // do nothing
      } else if (material.name === 'Black') {
        // do nothing
      } else if (child.name === 'TrackWheelRim1') {
        // don't camo a wheel (MediumRockets)
      } else if (child.name === 'WheelRim2') {
        // don't camo a wheel (LightRecon)
      } else if (child.name === 'Tracks_1' || child.name === 'Tracks_2') {
        // don't camo a wheel (APC)
      } else {
        const texture = createCamoMapTexture(tmpRngWith(ent.id), 256)
        material.map = texture
        // material.map
      }

      {
        // we'll use this for the "unit already moved effect"
        const texture = createFadedAOMapTexture(tmpRngWith(ent.id), 16)
        material.aoMap = texture
        material.aoMapIntensity = 0
      }

      material.needsUpdate = true
    }
  })
}
