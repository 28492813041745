import type { TilePositionXY } from "./TilePositionXY.type"

/**
 * Note: it is expected that non-integers will be returned.
 * 
 * @param p1 TilePositionXY
 * @param p2 TilePositionXY
 * @returns TilePositionXY
 */
export default function getMiddlePositionBetweenPositions (
  p1: TilePositionXY,
  p2: TilePositionXY,
): TilePositionXY {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  }
}