import { Component, For, createEffect, createMemo, createSignal, onMount, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import { PaintTileOption } from "../../lib/core/map_editor/paint_tile_option"
import { PaintTileOptionFolder, PaintTileOptionFolderIndex, paintTileOptionFolders } from "../../lib/core/map_editor/paint_tile_option_folder"
import { defaultPaintTileOption, paintTileOptionList } from "../../lib/core/map_editor/paint_tile_option_list"
import { SelectedToolId } from "../../lib/core/map_editor/SelectedToolId.enum"
import { BASE_TILE_SIZE } from "../../lib/core/view_ctx"
import { deepClone } from "../../lib/deep_clone"
import EngineContext from "../EngineContext"
import SelectPlayerInput from "./SelectPlayerInput"
import SingleEntityPreviewIcon3D from "./SingleEntityTypeIcon3D"

const PaintTileSelect: Component = () => {
  const engine: Engine = useContext(EngineContext)
  const isPaintTool = createMemo<boolean>(() => {
    // console.log('isPaintTool.memo', engine.selectedTool, SelectedToolId.Paint, engine.selectedTool === SelectedToolId.Paint)
    // track(EngineCache.SelectedToolId)
    return engine.selectedTool === SelectedToolId.Paint
  })
  const [selectedPaintTileOptionFolderIndex, setSelectedPaintTileOptionFolderIndex] = createSignal<PaintTileOptionFolderIndex>(0)
  const selectedPaintFileOptionFolder = createMemo<PaintTileOptionFolder>((): PaintTileOptionFolder => {
    // console.log('paintTileOptionFolders', paintTileOptionFolders)
    const index: PaintTileOptionFolderIndex = selectedPaintTileOptionFolderIndex()
    const folder: PaintTileOptionFolder = (paintTileOptionFolders[index]) as PaintTileOptionFolder
    return folder
  })
  const optionsInFolder = createMemo(() => paintTileOptionList.filter(s => s.folder_id === selectedPaintFileOptionFolder()?.id)/*.sort(byIdAsc)*/)

  onMount(() => {
    // setSelectedPaintTileOptionFolderIndex(1)
    setSelectedPaintTileOptionFolderIndex(2)
    // setSelectedEntityTypeId(EntityTypeId.Forest)
    engine.paintToolOption = defaultPaintTileOption()
  })

  createEffect(() => {
    if (engine.paintToolOption) {
      console.log('engine.paintToolOption', deepClone(engine.paintToolOption))
    }
  })

  const optionsPerRow = 3

  function needsBr2(option: PaintTileOption, optionIndex: number) {
    if (optionIndex > 0 && optionIndex % optionsPerRow === 0) {
      return true
    }
    return false
  }

  return <>
    {isPaintTool() && <div id="MapEditorPaintTileSelect" class="card-body">
      <h5 class="card-title">PaintTileSelect</h5>
      {/* <p class="card-text">PaintTileSelect</p> */}
      <SelectPlayerInput />
      <nav>
        <div class="nav nav-tabs" role="tablist">
          <For each={paintTileOptionFolders}>
            {(folder, folderIndex) => (
              <button class={(folderIndex() === selectedPaintTileOptionFolderIndex()) ? 'nav-link active' : 'nav-link'}
                type="button"
                role="tab"
                aria-controls={`EntityType Folder ${folder.dname}`}
                onClick={() => {
                  // console.log('SpriteFolder.onClick', event)
                  setSelectedPaintTileOptionFolderIndex(folderIndex())
                }}>
                {folder.dname}
              </button>
            )}
          </For>
        </div>
      </nav>
      <ul class="option-list" style={{ 'padding-top': `${BASE_TILE_SIZE}px` }}>
        <For each={optionsInFolder()}>
          {(option, optionIndex) => (<>
            {needsBr2(option, optionIndex()) && <br />}
            <li
              class={(option === engine.paintToolOption) ? 'option-item active' : 'option-item'}
              title={option.dname}
              onClick={() => {
                // console.log('SpriteLi.onClick', event)
                // setSelectedEntityTypeId(option.id)
                engine.paintToolOption = option
              }}>
              {option.dname}
              <SingleEntityPreviewIcon3D option={option} />
            </li>
          </>)}
        </For>
      </ul>
    </div>}
  </>
}

export default PaintTileSelect

// function getPreviousByIndex(spriteIndex: number, optionsInFolder: Array<PaintTileOption>): PaintTileOption | null {
//   if (spriteIndex === 0) return null
//   return optionsInFolder[spriteIndex - 1]
// }