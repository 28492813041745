import { PI } from "../../util/math"

export const CLOCKWISE = 0
export const COUNTER_CLOCKWISE = 1

export type CLOCKWISE_OR_COUNTER_CLOCKWISE = typeof CLOCKWISE | typeof COUNTER_CLOCKWISE

export const EntityHeadingNorthDeg = 0
export const EntityHeadingEastDeg = 90
export const EntityHeadingSouthDeg = 180
export const EntityHeadingWestDeg = 270

export const EntityHeadingNorthRad = 0
export const EntityHeadingEastRad = PI / 2
export const EntityHeadingSouthRad = PI
export const EntityHeadingWestRad = PI * 1.5
