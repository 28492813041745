import { createEffect, DEV } from 'solid-js'
import { radToDeg } from 'three/src/math/MathUtils.js'
import { parse } from 'valibot'
import type { Engine } from '../../lib/core/engine/Engine.type'
import { EntityHeadingNorthDeg } from '../../lib/core/entity/EntityHeading/consts'
import { DegreeNumberSchema } from '../../lib/core/entity/EntityHeading/DegreeNumber.type'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import getAngleBetweenPositions from '../../lib/core/tile_position_xy/getAngleBetweenPositions'
import normalizeDeg from '../../lib/core/util/normalizeDeg'
import type { EntityThreeModelCollection } from '../../lib/three/fn/createEntityThreeModelCollection'

export default function createDraftUnitRotatesTurretEffect(
  engine: Engine,
  entityThreeModelCollection: EntityThreeModelCollection,
): void {
  const getTargetPosition = () => engine.draftMove && (engine.draftMove?.destPosition || engine.hoveredPosition)
  const { group, ents: entityThreeModelMap } = entityThreeModelCollection
  createEffect(() => {
    const { selectedTool, draftMove } = engine
    if (draftMove && selectedTool === SelectedToolId.RangedAttackRadius) {
      const { unit } = draftMove
      const targetPosition = getTargetPosition()
      if (targetPosition) {
        const entityThreeModel = entityThreeModelMap.get(unit.id)
        if (entityThreeModel) {
          const { obj } = entityThreeModel
          // console.log('unitObj', entityDisplayName(unit), obj)
          if (obj) {
            const turretObj = obj.getObjectByName('Turret')
            // console.log('Turret', turretObj)
            if (turretObj) {
              turretObj.userData.heading = EntityHeadingNorthDeg
              const relTurretHeading = radToDeg(getAngleBetweenPositions(unit, targetPosition))
              const targetHeading = normalizeDeg((unit.heading + 90) - relTurretHeading)
              if (DEV) {
                parse(DegreeNumberSchema, targetHeading)
              }

              // console.log(
              //   'currentHeading', parseIntOrNull(turretObj.userData.heading),
              //   'oldTrgtHeading', parseIntOrNull(turretObj.userData.targetHeading),
              //   'targetHeading', parseIntOrNull(targetHeading),
              //   // 'unitHeading', parseIntOrNull(unit.heading),
              //   'relTurretHeading', parseIntOrNull(relTurretHeading))
              turretObj.userData.targetHeading = targetHeading
              // turretObj.userData.ent = ent

              const rocketPodObj = turretObj.getObjectByName('RocketPod')
              if (rocketPodObj) {
                rocketPodObj.userData.raise = 1
              }

              // const targetV3 = new Vector3(
              //   targetPosition.x * VERTICES_PER_TILE,
              //   turretObj.position.y,
              //   targetPosition.y * VERTICES_PER_TILE
              // )
              // turretObj.lookAt(targetV3)
              return
            }
          }
        }
      }
    }

    // console.log('Reset Turrent.targetHeading')
    // we didn't return early, so reset
    group.traverse((obj) => {
      // const { turret } = obj.userData
      if (obj.name === 'Turret') {
        // console.log('[createDraftUnitRotatesTurretEffect][Reset] Turret.userData.targetHeading = 0', obj.userData.e ? entityDisplayName(obj.userData.e): 'unknown', obj)
        obj.userData.targetHeading = 0
      }
      if (obj.name === 'RocketPod') {
        // console.log('[createDraftUnitRotatesTurretEffect][Reset] RocketPod.userData.raise = 0', obj.userData.e ? entityDisplayName(obj.userData.e): 'unknown', obj)
        obj.userData.raise = 0
      }
    })
  })
}
