import { modifyMutable, produce } from 'solid-js/store'
import { Vector2, type Vector3 } from 'three'
import type { Nullable } from 'vite-node'
import { Engine } from '../../core/engine/Engine.type'
import { EngineMode } from '../../core/engine/EngineMode/EngineMode.enum'
import resetEngineToDefaultSelectedTool from '../../core/engine/resetEngineToDefaultSelectedTool'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createEraseTileAction } from '../../core/state/flux/action/Map/EraseTileAction'
import { createPaintTileAction } from '../../core/state/flux/action/Map/PaintTileAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import { abs } from '../../core/util/math'
import MouseDragAgg from '../../core/view_ctx/MouseDragAgg'
import onElementEvent from '../../onElementEvent'
import pointerVector3PointToPointerTilePosition from '../../three/fn/pointerVector3PointToPointerTilePosition'
import isRightClick from './isRightClick'

export default function registerMouseUpEvent(
  engine: Engine,
  elem: HTMLElement,
  getPointerVector3Point: (pointerVector2: Vector2) => Nullable<Vector3>,
) {
  // console.log('registerMouseUpEvent')
  function onMouseUp(event: MouseEvent) {
    const { viewCtx } = engine
    // console.log('onMouseUp', event)
    engine.mouseUpEvent = event
    const { offsetX, offsetY } = event
    const { draftMove, mouseDownEvent, mouseDownPosition } = engine

    // const isMap = wasEventTargetMap(event)
    const isDrag: boolean = mouseDragMetThreshold(engine.mouseDragList, viewCtx.tile_size)

    // a long press should be treated like a right click
    // right click is undefined behavior
    // @todo make isLongPress not true for pans
    const clickDuration = mouseDownEvent ? event.timeStamp - mouseDownEvent.timeStamp : null
    const isLongPress = clickDuration ? clickDuration > 500 : false
    // a click is a mouse down + mouse up event that occurs in less than 500 ms
    const isClick = mouseDownEvent && !isLongPress && !isDrag
    // console.log('isClick', isClick)

    const { selectedTool } = engine

    const pointerX = (offsetX / (elem as HTMLCanvasElement).clientWidth) * 2 - 1
    const pointerY = - (offsetY / (elem as HTMLCanvasElement).clientHeight) * 2 + 1

    const mouseUpVector3 = getPointerVector3Point(new Vector2(pointerX, pointerY))
    const mouseUpPosition = pointerVector3PointToPointerTilePosition(engine, mouseUpVector3)
    // const mouseUpPositionOld = positionFromPixels(state, viewCtx, offsetX, offsetY)
    // console.log('onMouseUp.position', mouseUpPosition, mouseUpVector3, {offsetX, offsetY, isClick})
    if (isClick) {
      // console.log('onMouseUp.isClick')
      if (isRightClick(event)) {
        // console.log('onMouseUp.isRightClick', true)
        modifyMutable(engine, produce(resetEngineToDefaultSelectedTool))
      } else if (engine.mode === EngineMode.MapPreview) {
        // do nothing
      } else if (draftMove) {
        // console.log('isClick.draftMove', deepClone(draftMove))
        // if (draftMove.destPosition)
      } else if (selectedTool === SelectedToolId.Inspect) {
        // console.log('isClick.SelectedToolId.Inspect')
        if ((engine.mouseDownEvent as (typeof engine.mouseDownEvent & { dontSelect: boolean })).dontSelect) {
          // dont set a selectedPosition if the mouseDown was an attack
        } else {
          modifyMutable(
            engine,
            produce((engine: Engine) => {
              engine.selectedPosition = mouseUpPosition
              calculateSelectedPositionEnts(engine)
            })
          )
        }
      } else if (selectedTool === SelectedToolId.Paint) {
        // console.log('isClick.SelectedToolId.Paint')
        // console.log('mouseUpPosition', {
        //   result: mouseUpPosition && samePosition(mouseDownPosition, mouseUpPosition),
        //   mouseUpPosition,
        //   mouseDownPosition,
        // })
        if (mouseUpPosition && samePosition(mouseDownPosition, mouseUpPosition)) {
          dispatchClient(
            engine,
            createPaintTileAction(engine.paintToolOption, mouseUpPosition, engine.selectedPlayerId)
          )
        }
      } else if (selectedTool === SelectedToolId.Erase) {
        // console.log('isClick.SelectedToolId.Erase')
        if (mouseUpPosition && samePosition(mouseDownPosition, mouseUpPosition)) {
          dispatchClient(engine, createEraseTileAction(engine.eraseToolOption, mouseUpPosition))
        }
      } else {
        // console.log('isClick.SelectedToolId.Else', selectedTool)
      }
    } else {
      // console.log('onMouseUp.!isClick')
    }

    modifyMutable(
      engine,
      produce((engine: Engine) => {
        engine.viewCtx.lastPanAt = 0

        engine.mouseDownEvent = null
        engine.mouseDownPosition = null
        engine.mouseDragList = null
      })
    )
  }

  // onWindowEvent('mouseup', onMouseUp)
  onElementEvent(elem, 'mouseup', onMouseUp)
}

function mouseDragMetThreshold(
  mouseDragList: Readonly<Array<MouseEvent>> | null,
  tile_size: number
) {
  const threshMult = 6
  const agg = mouseDragListToAgg(mouseDragList)
  if (!agg) return false
  if (abs(agg.x * threshMult) > tile_size) return true
  if (abs(agg.y * threshMult) > tile_size) return true
  return false
}

function mouseDragListToAgg(
  mouseDragList: Readonly<Array<MouseEvent>> | null
): Readonly<MouseDragAgg | null> {
  const list = mouseDragList
  if (!(list && list.length > 0)) return null
  let pan_x = 0,
    pan_y = 0
  for (let index = 0; index < list.length; index++) {
    const mmEvent = list[index]
    pan_x += mmEvent.movementX
    pan_y += mmEvent.movementY
  }
  return { x: pan_x, y: pan_y }
}
