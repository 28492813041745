import { getCanvas2dContext } from "../../get_canvas_2d_context"

export default function createRenderMoveTileRedCanvasBufferV2 (
  buffer: OffscreenCanvas,
  texture_size: number): void {
  buffer.width = texture_size
  buffer.height = texture_size
  
  const ctx = getCanvas2dContext(buffer)
  if (ctx) {

    ctx.clearRect(0, 0, texture_size, texture_size)

    {
      const gradient = ctx.createLinearGradient(0, 0, texture_size, texture_size)
      addColorStops1(gradient)
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, texture_size, texture_size)
    }

    {
      const gradient = ctx.createLinearGradient(0, texture_size, texture_size, 0)
      addColorStops1(gradient)
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, texture_size, texture_size)
    }
  }
}

function addColorStops1(gradient: CanvasGradient) {
  gradient.addColorStop(0, `rgba(255, 0, 0, 0.8)`)
  gradient.addColorStop(0.1, `rgba(255, 0, 127, 0.6)`)
  gradient.addColorStop(0.38, `rgba(255, 127, 127, 0.2)`)
  gradient.addColorStop(0.42, `rgba(255, 127, 127, 0.05)`)
  gradient.addColorStop(0.6, `rgba(255, 127, 127, 0.2)`)
  gradient.addColorStop(0.1, `rgba(255, 0, 127, 0.6)`)
  gradient.addColorStop(1, `rgba(255, 0, 0, 0.8)`)
}