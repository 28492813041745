import { Color } from "three"
import type { Nullable } from "../../../../typescript"
import { EntityTypeId } from "../../../core/entity/EntityTypeId.enum"
import { isEntityDesert, isEntityPlain, isEntitySea, isEntitySnow } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import { max, min } from "../../../core/util/math"

/* eslint-disable @typescript-eslint/no-unused-vars */

const desert = new Color(0xf4a460) // Sandy brown
const grass = new Color(0x228b22) // Green // first green

// from the wargroove mockup
// const grass = new Color(0x83b243) // Green
// const grass = new Color(0x8ab141) // Green
// const grass = new Color(0x99c34a) // Light Green
// const grass = new Color(0x789f39) // Dark Green
// const grass3 = new Color(0x64892f) // Shadow Green

const sea = new Color(0x1e90ff) // Blue
const debugPink = new Color(0xFF69B4) // Pink

// Ocean floor variations
const oceanFloorDarkSand = new Color(0x8B7765) // Dark sandy brown
const oceanFloorMuddyGrayBlue = new Color(0x5E676D) // Muddy gray-blue
const oceanFloorGreenishBrown = new Color(0x4A5E50) // Dark greenish-brown (Algae-covered seabed)
const oceanFloorDeepSlate = new Color(0x3E4C59) // Deep slate gray (Rocky, deep-sea terrain)

// Tropical shallow ocean floors
const oceanFloorLightSand = new Color(0xC2B280) // Light sandy beige
const oceanFloorMutedSeaGreen = new Color(0x5D737E) // Muted sea green

const ridgeRockGray = new Color(0x6B6B6B); // Medium gray (Standard rock)
const ridgeDarkRock = new Color(0x4B4B4B); // Dark, shadowed rock
const ridgeSlate = new Color(0x556B6F); // Slate gray with a hint of blue
const ridgeBasalt = new Color(0x3C3C3C); // Basalt (dark volcanic rock)
const ridgeBrownRock = new Color(0x8B7355); // Weathered brown rock
const ridgeSandstone = new Color(0xA99171); // Light sandstone

const ridgeSnowCap = new Color(0xE0E0E0); // Light snow/ice
const ridgeFrostGray = new Color(0xB0C4DE); // Frosty gray-blue
const ridgeColdStone = new Color(0x6A7F8B); // Cold, high-altitude stone

const ridgeClay = new Color(0x9E7B6B); // Clay brown
const ridgeRedEarth = new Color(0x8B0000); // Deep red rock (like in deserts)
const ridgeDirt = new Color(0x6B4F3F); // Dark brown dirt

const ridgeAsh = new Color(0x505050); // Volcanic ash gray
const ridgeLavaRock = new Color(0x3B2F2F); // Dark basalt or lava rock
const ridgeCharred = new Color(0x2B2B2B); // Charred rock, almost black

// const ridgeColor = debugPink
// const ridgeColor = ridgeDirt

/* eslint-enable @typescript-eslint/no-unused-vars */

export function getTerrainMesh1TileColor(ent: Nullable<Entity>) {
  if (ent) {
    if (isEntitySnow(ent)) {
      return ridgeSnowCap
    }
    if (isEntityPlain(ent)) {
      return grass
    }
    if (isEntityDesert(ent)) {
      return desert
    }
    if (isEntitySea(ent)) {
      return oceanFloorMuddyGrayBlue
    }
    return oceanFloorMuddyGrayBlue
  }
  return debugPink
}

export function getTerrainMesh1RidgeColor(
  ent1: Nullable<Entity>,
  ent2: Nullable<Entity>,
): Color {
  if (ent1 && ent2) {
    const t1 : EntityTypeId = min(ent1.etype_id, ent2.etype_id)
    const t2 : EntityTypeId = max(ent1.etype_id, ent2.etype_id)
    // console.log({t1, t2})
    if (t1 === EntityTypeId.Plain) {
      if (t2 === EntityTypeId.Plain) {
        return ridgeDirt
      }
      if (t2 === EntityTypeId.Snow) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.Desert) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.Sea) {
        return ridgeDirt
      }
      if (t2 === EntityTypeId.River) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.Shoal) {
        return ridgeRedEarth
      }
    }
    if (t1 === EntityTypeId.Snow) {
      if (t2 === EntityTypeId.Snow) {
        return ridgeFrostGray
      }
      if (t2 === EntityTypeId.Desert) {
        return ridgeFrostGray
      }
      if (t2 === EntityTypeId.Sea) {
        return ridgeFrostGray
      }
      if (t2 === EntityTypeId.River) {
        return ridgeFrostGray
      }
      if (t2 === EntityTypeId.Shoal) {
        return ridgeFrostGray
      }
    }
    if (t1 === EntityTypeId.Desert) {
      if (t2 === EntityTypeId.Desert) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.Sea) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.River) {
        return ridgeRedEarth
      }
      if (t2 === EntityTypeId.Shoal) {
        return ridgeRedEarth
      }
    }
    return sea
  }
  return debugPink
}