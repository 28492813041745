import { createEffect, createRoot, createSignal } from 'solid-js'

export default function createDebugPrintOnChangeEffect(name: string) {
  return createRoot(() => {
    const [getter, setter] = createSignal<unknown>(null, {
      equals: (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
    })
    createEffect(() => {
      console.log(`[${name}]`, getter())
    })
    return setter
  })
}

