import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { M10Booker1_M10Booker1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"
import onGltfLoaderError from "./onGltfLoaderError"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeLightTankObject3D (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(M10Booker1_M10Booker1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)

    const turretGroup = scene.getObjectByName('Turret') as Group
    assertClassInDev(turretGroup, Group)

    const barrelMesh = scene.getObjectByName('Barrel') as Mesh
    assertClassInDev(barrelMesh, Mesh)

    // turretGroup.position.y -= 2
    // turretGroup.position.y = 4
    // barrelMesh.position.y = -0.5

    const leftTrackMesh = scene.getObjectByName('LeftTrack') as Mesh
    assertClassInDev(leftTrackMesh, Mesh)

    const rightTrackMesh = leftTrackMesh.clone()
    rightTrackMesh.name = 'RightTrack'

    // scene.add(leftTrackMesh)
    scene.add(rightTrackMesh)

    rightTrackMesh.position.x = 9

    applyPlayerColors(scene, ent, pto)

    const defaultHullY = bodyGroup.position.y

    bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyGroup.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    callback(scene)
  }, undefined, onGltfLoaderError)
}
