import { type Mesh, BufferAttribute, MeshStandardMaterial } from "three"

export type VertexRGBFunctionStandardMaterialArg1 = {
  x: number,
  y: number,
  z: number,
  i: number,
  r: number,
  g: number,
  b: number,
  // ALPHA NOT SUPPORTED
  // https://discourse.threejs.org/t/transparent-vertex-colors-in-bufferattribute/16317/3
  // maybe one day
  // a: number,
}

export type VertexRGBFunctionStandardMaterialCallback = (va: VertexRGBFunctionStandardMaterialArg1) => void

/**
 * 
 * V2 has the normalization removed
 * to make it easier to correctly shade shoals
 */
export default function applyVertexFunctionStandardMaterialV2(
  mesh: Mesh,
  cb: VertexRGBFunctionStandardMaterialCallback
): void {
  // 3 for RBG
  // 4 for RGBA
  const size = 3

  // Ensure geometry is non-indexed (so we can modify vertex colors easily)
  const geometry = mesh.geometry
  geometry.computeBoundingBox() // Get min/max Y for normalization

  const position = geometry.attributes.position
  // const colors = new Float32Array(position.count * size) // RGB per vertex
  const colors = geometry.getAttribute('color')?.array
    || new Float32Array(position.count * size)

  // const boundingBox = geometry.boundingBox!

  // if (DEV && !boundingBox) {
  //   throw new Unexpected('!boundingBox')
  // }

  // const minX = boundingBox.min.x
  // const maxX = boundingBox.max.x
  // const minY = boundingBox.min.y
  // const maxY = boundingBox.max.y
  // const minZ = boundingBox.min.z
  // const maxZ = boundingBox.max.z

  const va : VertexRGBFunctionStandardMaterialArg1 = {
    x: 0,
    y: 0,
    z: 0,
    i: 0,
    r: 0,
    g: 0,
    b: 0,
    // a: 0,
  }

  // Assign grayscale colors based on Y position
  for (let i = 0; i < position.count; i++) {
    const x0 = position.getX(i)
    const y0 = position.getY(i)
    const z0 = position.getZ(i)

    // // normalize
    // const y1 =  ((y0 - minY) / (maxY - minY))
    // const x1 =  ((x0 - minX) / (maxX - minX))
    // const z1 =  ((z0 - minZ) / (maxZ - minZ))

    va.x = x0
    va.y = y0
    va.z = z0
    va.i = i
    va.r = colors[i * 3]
    va.g = colors[i * 3 + 1]
    va.b = colors[i * 3 + 2]
    // va.a = colors[i * 3 + 3]

    cb(va)

    // Apply callback result
    colors[i * 3]     = va.r
    colors[i * 3 + 1] = va.g
    colors[i * 3 + 2] = va.b
    // colors[i * 3 + 3] = va.a
  }

  // Set color attribute
  geometry.setAttribute('color', new BufferAttribute(colors, size))

  // // Use a material that supports vertex colors
  // mesh.material = new MeshStandardMaterial({ 
  //   vertexColors: true, // Enable vertex colors
  //   // transparent: true,
  // })

  // const { material: m0 } = mesh

  const m1 = new MeshStandardMaterial({ 
    vertexColors: true, // Enable vertex colors
    // transparent: true,
  })
  mesh.material = m1
}