import { type MeshBasicMaterial, MeshStandardMaterial } from "three"

export default function createMeshStandardMaterialFromMeshBasicMaterial(
  basicMaterial: MeshBasicMaterial
): MeshStandardMaterial {
  // Create a new MeshStandardMaterial and copy relevant properties
  const standardMaterial = new MeshStandardMaterial({
    color: basicMaterial.color, // Copy color
    map: basicMaterial.map, // Copy texture (if any)
    transparent: basicMaterial.transparent,
    opacity: basicMaterial.opacity,
    side: basicMaterial.side,
  })

  // Set metalness and roughness to reasonable defaults
  standardMaterial.metalness = 0.0
  standardMaterial.roughness = 1.0

  return standardMaterial
}
