import { DEV } from "solid-js"
import { type Camera, MathUtils } from "three"
import type { MapControls } from "three/examples/jsm/controls/MapControls.js"
import { parse } from "valibot"
import { atan2 } from "../../util/math"
import normalizeAngle from "../../util/normalizeDeg"
import { DegreeNumberSchema } from "./DegreeNumber.type"

// Helper: Snap angle to the nearest 45-degree increment
function snapToNearest45(angleRad: number): number {
  const angleDeg = MathUtils.radToDeg(angleRad)
  const snappedDeg = Math.round(angleDeg / 45) * 45 // Snap to nearest 45 degrees
  return MathUtils.degToRad(snappedDeg)
}

const { degToRad } = MathUtils

// Apply rotation per frame (smooth transition)
export default function rotateCamera(
  camera: Camera,
  mapControls: MapControls,
  deltaAngle: number
): void {
  // Get current heading (or calculate it if not stored)
  const prevTargetHeading =
    camera.userData.targetHeading ??
    atan2(camera.position.z - mapControls.target.z, camera.position.x - mapControls.target.x)

  // Snap to the nearest 45 degrees before applying deltaAngle
  const snappedHeading = snapToNearest45(prevTargetHeading)
  const nextTargetHeading = normalizeAngle(snappedHeading + degToRad(deltaAngle))

  if (DEV) {
    parse(DegreeNumberSchema, nextTargetHeading)
  }
  camera.userData.targetHeading = nextTargetHeading

  // Store initial heading if first time rotating
  if (camera.userData.heading === undefined) {
    camera.userData.heading = prevTargetHeading
  }
}
