import type { Entity } from '../entity/index'
import toCoord from '../tile_position_xy/toCoord'
import type { DraftMove } from './DraftMove.type'

export default function createDraftMove(unit: Entity): DraftMove {
  // console.log('createDraftMove')
  return {
    unit,
    target: null,
    startPosition: toCoord(unit),
    destPosition: null,
    attackPosition: null,
    annex: null,
    attackEstimates: [],
  }
}
