import { type Camera, MathUtils, Spherical, Vector3 } from "three"
import type { MapControls } from "three/examples/jsm/controls/MapControls.js"
import getMapControlsPolarAngle from "../../../three/fn/getMapControlsPolarAngle"

export default function applyCameraRotationInFrame(
  camera: Camera,
  mapControls: MapControls,
  elapsed: number
) {
  const { targetHeading, heading: prevHeading } = camera.userData
  if (targetHeading === undefined || prevHeading === undefined) return
  if (targetHeading === prevHeading) return

  const rotationSpeed = 1 // Adjust speed (higher is faster)
  const t = 1 - Math.exp(-rotationSpeed * elapsed) // Smooth interpolation factor

  // Interpolate current heading toward target heading
  const nextHeading = MathUtils.lerp(prevHeading, targetHeading, t)

  // Get the camera's current polar angle (tilt)
  const polarAngle = getMapControlsPolarAngle(mapControls) // Preserve tilt!

  // Get original distance from camera to target
  const originalRadius = camera.position.distanceTo(mapControls.target)

  // Use spherical coordinates to compute new position
  const spherical = new Spherical(originalRadius, polarAngle, nextHeading)
  const offset = new Vector3().setFromSpherical(spherical)

  // Apply new position
  camera.position.copy(mapControls.target).add(offset)

  // Update the heading
  camera.userData.heading = nextHeading

  // Ensure the camera is still looking at the target
  camera.lookAt(mapControls.target)
}
