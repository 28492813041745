import type { Nullable } from "../../typescript"
import { entries } from "../core/util/Object"

/**
 * Recursively flattens a nested object into a dot-notated object (similar to Laravel's `array_dot`).
 * @param input - The nested object to flatten.
 * @param prefix - The prefix for nested keys (used in recursion).
 * @returns A flattened object with dot-separated keys.
 */
export default function arrayDot(
  input: Nullable<Record<string, unknown>>,
  prefix = ''
): Record<string, unknown> | undefined {
  const output: Record<string, unknown> = {}

  if (input) {
    if (typeof input === 'object') {
      for (const [key, value] of entries(input)) {
        const newKey = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'object') {
            // assign(output, arrayDot(value, newKey))
            const x = arrayDot(value as Parameters<typeof arrayDot>[0], newKey)
            for (const l in x) {
              output[`${newKey}.${l}`] = x[l]
            }
          } else {
            output[newKey] = value
        }
      }
    }
    return output
  }
}
