import type { Engine } from "../../engine/Engine.type"
import calculateSelectedPositionEnts from "../../entity/calculateSelectedPositionEnts"
import entityDisplayName from "../../entity/entityDisplayName"
import type { CLOCKWISE_OR_COUNTER_CLOCKWISE } from "../../entity/EntityHeading/consts"
import rotateEntityDirectionClockwise90degrees from "../../entity/EntityHeading/rotateEntityDirectionClockwise90degrees"
import { SelectedToolId } from "../../map_editor/SelectedToolId.enum"
import { createRotateEntityAction } from "../../state/flux/action/Map/RotateEntityAction"
import dispatchClient from "../../state/flux/dispatchClient"

// the user has a tile selected,
// we will rotate the highest entity on the stack
export default function handleRotateTileEntityHeading(
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clockwise: CLOCKWISE_OR_COUNTER_CLOCKWISE
): void {
  // console.log('handleRotateTileEntityDirection')
  const { selectedTool } = engine

  if (selectedTool === SelectedToolId.Inspect) {
    const { selectedPositionEnts } = engine
    // get highest ent by layer_id
    const ent = selectedPositionEnts[0]
    if (ent) {
      console.log('selectedEntity', entityDisplayName(ent), ent.id, ent.heading, ent)
      dispatchClient(engine, createRotateEntityAction(ent.id, rotateEntityDirectionClockwise90degrees(ent.heading)))
      // because we technically mutated it
      calculateSelectedPositionEnts(engine)
    }
  } else if (selectedTool === SelectedToolId.Paint) {
    console.warn('TODO rotate entity in cursor')
  }
}
