import { parse } from 'valibot'
import type { HasWeapons } from '../HasWeapons'
import type { EntityId } from './EntityId.type'
import { EntityForFactorySchema } from './entitySchemaForFactoryList'
import { type FactoryEntity } from './EntityType/Factory'
import type { EntityTypeMetaForFactory } from './entityTypeMetaForFactoryList'
import type { EntityUnionForFactory } from './EntityUnionForFactory.type'
import type { Entity } from './index'

// type FactoryUnitEntity = Extract<Entity, HasWasBuiltThisTurn>
// type FactoryUnitEntityTypeMeta = Extract<EntityTypeMeta, { price: number }>

export default function createFactoryUnitEntity(
  factoryEntity: FactoryEntity,
  entityMeta: EntityTypeMetaForFactory,
  id: EntityId
): EntityUnionForFactory {
  const draftEntity: Partial<Entity> = {
    ...entityMeta.entDefaults,
    etype_id: entityMeta.id as Entity['etype_id'],
    player_id: factoryEntity.player_id,
    x: factoryEntity.x,
    y: factoryEntity.y,
    id,
    heading: factoryEntity.heading,
    builtThisTurn: true,
  }
  if (entityMeta.defaultWeapons) {
    ;(draftEntity as HasWeapons).weapons = entityMeta.defaultWeapons()
  }
  const entity = parse(EntityForFactorySchema, draftEntity) as EntityUnionForFactory
  return entity
}
