import { DEV } from "solid-js"
import type { Object3D } from "three"
import { MathUtils } from "three"
import { parse } from "valibot"
import { DegreeNumberSchema } from "./DegreeNumber.type"
import { type EntityHeading } from "./EntityHeading.type"

const { degToRad } = MathUtils

export default function setHeadingToObject3DRotation(
  obj: Object3D,
  heading: EntityHeading,
) {
  obj.rotation.y = degToRad(heading)
  if (DEV) {
    parse(DegreeNumberSchema, heading)
  }

  obj.userData.targetHeading = heading
  obj.userData.heading = heading
}
