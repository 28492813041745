import { EntityTypeId } from './EntityTypeId.enum'
import { MoveTypeId } from './move_type_id.enum'
import type { MoveCostPair } from './MoveCostPair.type'

export const moveCostList: Array<MoveCostPair> = [
  a(EntityTypeId.Plain, MoveTypeId.Foot, 1),
  a(EntityTypeId.Snow, MoveTypeId.Foot, 1),
  a(EntityTypeId.Desert, MoveTypeId.Foot, 1),
  // a(EntityTypeId.Sea, MoveTypeId.Foot, 0), // cant
  a(EntityTypeId.Shoal, MoveTypeId.Foot, 0),
  a(EntityTypeId.River, MoveTypeId.Foot, 2),
  // a(EntityTypeId.Barren, MoveTypeId.Foot, 2), // not in this game
  a(EntityTypeId.Forest, MoveTypeId.Foot, 2),
  a(EntityTypeId.Mountain, MoveTypeId.Foot, 3),
  a(EntityTypeId.Road, MoveTypeId.Foot, 1),
  a(EntityTypeId.Building, MoveTypeId.Foot, 1),
  a(EntityTypeId.HQ, MoveTypeId.Foot, 1),
  a(EntityTypeId.Factory, MoveTypeId.Foot, 1),

  deferRoad(a(EntityTypeId.Plain, MoveTypeId.Tires, 2)),
  deferRoad(a(EntityTypeId.Snow, MoveTypeId.Tires, 2)),
  deferRoad(a(EntityTypeId.Desert, MoveTypeId.Tires, 2)),
  // a(EntityTypeId.Sea, MoveTypeId.Tires, 0), // can't
  a(EntityTypeId.Shoal, MoveTypeId.Tires, 1),
  // a(EntityTypeId.River, MoveTypeId.Foot, 0), // can't
  // a(EntityTypeId.Barren, MoveTypeId.Tires, 3), // not in this game
  a(EntityTypeId.Forest, MoveTypeId.Tires, 3),
  b(EntityTypeId.Mountain, MoveTypeId.Tires), // can't
  a(EntityTypeId.Road, MoveTypeId.Tires, 1),
  a(EntityTypeId.Building, MoveTypeId.Tires, 1),
  a(EntityTypeId.HQ, MoveTypeId.Tires, 1),
  a(EntityTypeId.Factory, MoveTypeId.Tires, 1),

  a(EntityTypeId.Plain, MoveTypeId.Treads, 1),
  a(EntityTypeId.Snow, MoveTypeId.Treads, 1),
  a(EntityTypeId.Desert, MoveTypeId.Treads, 1),
  // a(EntityTypeId.Sea, MoveTypeId.Treads, 0), // can't
  a(EntityTypeId.Shoal, MoveTypeId.Treads, 1),
  // a(EntityTypeId.River, MoveTypeId.Foot, 0), // can't
  // a(EntityTypeId.Barren, MoveTypeId.Treads, 2), // not in this game
  a(EntityTypeId.Forest, MoveTypeId.Treads, 2),
  b(EntityTypeId.Mountain, MoveTypeId.Treads), // can't
  a(EntityTypeId.Road, MoveTypeId.Treads, 1),
  a(EntityTypeId.Building, MoveTypeId.Treads, 1),
  a(EntityTypeId.HQ, MoveTypeId.Treads, 1),
  a(EntityTypeId.Factory, MoveTypeId.Treads, 1),

  a(EntityTypeId.Plain, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Snow, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Desert, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Sea, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Shoal, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.River, MoveTypeId.Helicopter, 1),
  // a(EntityTypeId.Barren, MoveTypeId.Helicopter, 1), // not in this game
  a(EntityTypeId.Forest, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Mountain, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Road, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Building, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.HQ, MoveTypeId.Helicopter, 1),
  a(EntityTypeId.Factory, MoveTypeId.Helicopter, 1),
]

function a(etype_id: EntityTypeId, mtype_id: MoveTypeId, value: number): MoveCostPair {
  return { etype_id, mtype_id, value }
}
function b(etype_id: EntityTypeId, mtype_id: MoveTypeId): MoveCostPair {
  return { etype_id, mtype_id, block: true }
}

function deferRoad(mcp: MoveCostPair): MoveCostPair {
  mcp.deferToRoad = true
  return mcp
}
