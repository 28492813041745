import { Component, createMemo, Show, useContext, type Accessor } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import { Entity } from "../../lib/core/entity/index"
import { SelectedToolId } from "../../lib/core/map_editor/SelectedToolId.enum"
import { samePosition } from "../../lib/core/tile_position_xy/samePosition"
import type { TilePositionXY } from "../../lib/core/tile_position_xy/TilePositionXY.type"
import { byEntityTypeIdAsc, byLayerIdDesc } from "../../lib/core/util/id"
import EngineContext from "../EngineContext"
import PositionIcon3D from "./PositionIcon3D"

/**
 * @deprecated
 */
const InspectTileWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)
  // const track = engine.cache[0]
  const isInspectTool = createMemo<boolean>(() => {
    // console.log('isInspectTool.memo', engine.selectedTool, SelectedToolId.Inspect, engine.selectedTool === SelectedToolId.Inspect)
    // track(EngineCache.SelectedToolId)
    return engine.selectedTool === SelectedToolId.Inspect
  })
  const entityList = createMemo<Array<Entity>>(() => {
    const sp = engine.selectedPosition
    if (sp) {
      return engine.state.ents.filter((ent: Entity) => samePosition(ent, sp)).sort(byEntityTypeIdAsc).sort(byLayerIdDesc)
    }
    return []
  })

  return <>
    <Show when={isInspectTool()}>
      <div id="MapEditorInspectTileWidget" class="card-body">
        <Show when={engine.selectedTool === SelectedToolId.Inspect && engine.selectedPosition} children={(position: Accessor<TilePositionXY>) => {
          return <div class="float-start" style={{ 'padding-right': '1rem' }}>
            <PositionIcon3D position={position()} h={120} w={120} />
          </div>
        }} />
        <div>
          InspectTileWidget (deprecated)
          <Show
            when={engine.selectedPosition}
            children={(selectedPosition) => {
              return (<div>
                <p class="text-monospace">
                  <span class="badge bg-secondary">[{selectedPosition().x}, {selectedPosition().y}]</span>
                  <span class="badge bg-secondary">[Entities: {entityList().length}]</span>
                </p>
                {/* <For each={entityList()}>{(entity: Entity) => {
                const entType: EntityTypeMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
                return <p>
                  {entType.dname}
                  <br />
                  <SingleSpriteInspect entity={entity} />
                  {'hp' in entity && <p>HP: {entity.hp}</p>}
                  {'fuel' in entity && <p>Fuel: {entity.fuel}</p>}
                  {'ap' in entity && <p>Annex Points: {entity.ap}</p>}
                </p>
              }}</For> */}
              </div>)
            }}
            fallback={<div>Select a tile to inspect.</div>}
          />
        </div>
      </div>
    </Show>
  </>
}

export default InspectTileWidget
