import { Color } from "three"

export default function applyLuminanceFromGreyscale(greyScale: Color, baseColor: Color): Color {
  if (greyScale.equals(new Color(0xffffff))) {
    return baseColor.clone()
  }
  // Convert greyscale color to HSL to extract its luminance
  const greyHSL = { h: 0, s: 0, l: 0 }
  greyScale.getHSL(greyHSL)

  // Convert base color to HSL to modify its luminance
  const baseHSL = { h: 0, s: 0, l: 0 }
  baseColor.getHSL(baseHSL)

  // Apply the luminance of the greyscale color to the base color
  const finalColor = new Color()
  finalColor.setHSL(baseHSL.h, baseHSL.s, greyHSL.l)

  return finalColor
}