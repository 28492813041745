import { literal, object, optional, pipe, rawCheck, type InferOutput } from 'valibot'
import { deepClone } from '../../../../../deep_clone'
import NotYourTurn from '../../../../../Exception/NotYourTurn.class'
import Unexpected from '../../../../../Exception/Unexpected.class'
import isNil from '../../../../../ldsh/isNil'
import isNotNil from '../../../../../ldsh/isNotNil'
import createDraftMoveAttackEstimate from '../../../../draft_move/createDraftMoveAttackEstimate'
import type { DraftMove } from '../../../../draft_move/DraftMove.type'
import { Engine } from '../../../../engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../../../engine/resetEngineToDefaultSelectedTool'
import type { AnnexableEntityUnion } from '../../../../entity/AnnexableEntityUnion.type'
import canUnitAnnexEntity from '../../../../entity/canUnitAnnexEntity'
import { OptionalNullableEntityHeadingSchema } from '../../../../entity/EntityHeading/EntityHeading.type'
import { EntityIdSchema, NullableEntityIdSchema } from '../../../../entity/EntityId.type'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'
import findEntityById from '../../../../entity/findEntityById'
import getEntitiesAtPosition from '../../../../entity/getEntitiesAtPosition'
import getUnitMoveCostOntoPositionEntityStack from '../../../../entity/getUnitMoveCostOntoPositionEntityStack'
import type { Entity } from '../../../../entity/index'
import isEntityOutOfMoves from '../../../../entity/isEntityOutOfMoves'
import isEntityUnit from '../../../../entity/isEntityUnit'
import resetAllAnnexingByUnit from '../../../../entity/resetAllAnnexingByUnit'
import decrementAmmo from '../../../../entity/Weapon/decrementAmmo'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import type { HasFuel } from '../../../../has_fuel'
import type { HasMobility } from '../../../../has_mobility'
import type { HasAnnexPoints } from '../../../../HasAnnexPoints.type'
import type { HasPrice } from '../../../../HasPrice'
import { NullableLiteralTrueSchema, OptionalLiteralTrueSchema } from '../../../../LiteralTrue'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import entityListToGridXY from '../../../../tile_position_xy/entityListToGridXY'
import findMostEfficientPathToTileV2 from '../../../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2'
import isOrthogonallyAdjacent from '../../../../tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../../../../tile_position_xy/samePosition'
import { NullableTilePositionXYSchema, TilePositionXYSchema } from '../../../../tile_position_xy/TilePositionXY.type'
import toCoord from '../../../../tile_position_xy/toCoord'
import { max, min } from '../../../../util/math'
import type { ActionLog } from '../../ActionLog.type'
import checkIfGameShouldEnd from '../../winCondition/checkIfGameShouldEnd'
import checkLostHqWinCondition from '../../winCondition/checkLostHqWinCondition'
import checkLostLastEntityWinCondition from '../../winCondition/checkLostLastEntityWinCondition'
import checkMapControlWinCondition from '../../winCondition/checkMapControlWinCondition'
import { ActionType } from '../ActionType'

export const MoveUnitActionSchema = pipe(object({
  type: literal(ActionType.Game.MoveUnit),
  unit_id: EntityIdSchema,
  target_id: NullableEntityIdSchema,
  pickup_id: optional(NullableEntityIdSchema),
  start_position: TilePositionXYSchema,
  dest_position: NullableTilePositionXYSchema,
  dest_heading: OptionalNullableEntityHeadingSchema,
  attack_position: NullableTilePositionXYSchema,
  annex: NullableLiteralTrueSchema,
  wait: OptionalLiteralTrueSchema,
  taxi_id: optional(NullableEntityIdSchema),
  cargo_id: optional(NullableEntityIdSchema),
  unload_position: optional(NullableTilePositionXYSchema),
  merge_id: optional(NullableEntityIdSchema),
}), rawCheck(({ dataset, addIssue }) => {
  if (dataset.typed) {
    // console.log('dataset.value', dataset.value)
    const {
      target_id,
      dest_position,
      attack_position,
      annex,
      wait,
      pickup_id,
      taxi_id,
      unload_position,
      cargo_id,
      merge_id,
    } = dataset.value
    if (!(target_id||dest_position||attack_position||annex||wait||pickup_id||unload_position)) {
      addIssue({
        message: 'Blank Move is Invalid',
      })
    }
    if (attack_position && !target_id) {
      addIssue({
        message: 'attack_position requires target_id',
      })
    }
    if (pickup_id) {
      if (attack_position) {
        addIssue({
          message: 'cannot combine pickup and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine pickup and annex',
        })
      }
      if (unload_position) {
        addIssue({
          message: 'cannot combine pickup and unload_position',
        })
      }
    }
    if (taxi_id) {
      if (attack_position) {
        addIssue({
          message: 'cannot combine taxi and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine taxi and annex',
        })
      }
      if (pickup_id) {
        addIssue({
          message: 'cannot combine taxi and pickup',
        })
      }
    }
    if (unload_position) {
      if (!cargo_id) {
        addIssue({
          message: 'unload_position requires cargo_id',
        })
      }
      if (attack_position) {
        addIssue({
          message: 'cannot combine unload and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine unload and annex',
        })
      }
    }
    if (merge_id) {
      if (!dest_position) {
        addIssue({
          message: 'merge requires dest_position',
        })
      }
      if (attack_position) {
        addIssue({
          message: 'cannot combine merge and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine merge and annex',
        })
      }
    }
  }
}))

export type MoveUnitAction = InferOutput<typeof MoveUnitActionSchema>

export function createMoveUnitAction(draftMove: DraftMove): MoveUnitAction {
  console.log('createMoveUnitAction', deepClone(draftMove))
  return {
    type: ActionType.Game.MoveUnit,
    unit_id: draftMove.unit.id,
    target_id: draftMove.target?.id || null,
    pickup_id: draftMove.pickup?.id,
    taxi_id: draftMove.taxi?.id,
    start_position: draftMove.startPosition,
    dest_position: draftMove.destPosition,
    dest_heading: draftMove.destHeading,
    attack_position: draftMove.attackPosition,
    annex: draftMove.annex,
    wait: draftMove.wait,
    cargo_id: draftMove.unload?.cargo_id,
    unload_position: draftMove.unloadPosition,
    merge_id: draftMove.merge?.id,
  }
}

export async function handleMoveUnitAction(
  engine: Engine,
  action: MoveUnitAction,
  actionLog: ActionLog
): Promise<void> {
  // console.log('handleMoveUnitAction', deepClone(action))
  const {
    annex,
    unit_id,
    target_id,
    pickup_id,
    start_position,
    dest_position,
    attack_position,
    dest_heading,
    wait,
    taxi_id,
    cargo_id,
    unload_position,
    merge_id,
  } = action
  const { state } = engine
  const { ents, width, height } = state
  const entityGridXY = entityListToGridXY(ents, width, height)
  const unit = findEntityById(ents, unit_id)
  if (!unit) {
    console.log(deepClone({unit_id, unit}))
    throw new Unexpected('!unit')
  }
  if (!isEntityUnit(unit)) {
    throw new Unexpected('!isUnit')
  }
  // actionLog.unitStack = deepClone(getEntityGridXYStack(entityGridXY, unit.x, unit.y))
  if (!samePosition(unit, start_position)) {
    throw new Unexpected('!samePosition(unit, start_position)')
  }
  actionLog.unit0 = deepClone(unit)
  const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  const {
    mobility: unitDefaultMobility,
    price: unitDefaultPrice,
    fuel: unitDefaultFuel,
  } = unitEntityTypeMeta.entDefaults as HasMobility & HasPrice & HasFuel
  const unitPosition = dest_position || toCoord(unit)

  const unitPlayer = findByIdOrThrow(state.players, unit.player_id)
  if (unitPlayer.turn_status !== PlayerTurnStatus.Playing) {
    throw new NotYourTurn
  }

  if (annex && attack_position) {
    throw new Unexpected('annex && attack_position')
  }

  if (dest_position) {
    console.log('MoveUnitAction', deepClone(action))
    // console.log('the unit is going somewhere')
    if (samePosition(unit, dest_position)) {
      throw new Unexpected('samePosition(unit, dest_position)')
    }
    if (!('mobility' in unit)) {
      throw new Error('Unit cant move')
    }
    const eptt = findMostEfficientPathToTileV2(
      entityGridXY,
      unit,
      width,
      height,
      dest_position.x,
      dest_position.y
    )
    if (!eptt) {
      throw new Error('!eptt')
    }
    console.log('eptt', deepClone(eptt))
    const turn = eptt.turns[0]
    if (!turn) {
      throw new Error('!eptt.turn0')
    }
    actionLog.destPositionSteps = turn.steps.map(toCoord)
    const stepsMobilityCost = turn.steps.reduce((sum: number, step) => sum + (step.cost || 0), 0)
    if (isEntityOutOfMoves(unit)) {
      throw new Error('Unit out of moves')
    }
    const lastStep = turn.steps.at(-1)
    console.log('lastStep', deepClone(lastStep))
    if (!lastStep) {
      throw new Unexpected('!lastStep')
    }

    if (lastStep.impass) {
      throw new Unexpected('dest_position: impass')
    }
    const occupantId = lastStep.occupant?.id
    if (occupantId) {
      if (occupantId === pickup_id) {
        // the moving unit will pick up the destPosition occupant
      } else if (occupantId === taxi_id) {
        // the moving unit will load into the destPosition occupant
      } else if (occupantId === merge_id) {
        // the moving unit will merge into the destPosition occupant
      } else {
        throw new Unexpected('dest_position: occupant')
      }
    }

    unit.x = lastStep.x
    unit.y = lastStep.y
    const lastStepHeading = eptt.lastStep?.heading
    if (isNotNil(lastStepHeading)) {
      console.log('[MoveUnitAction].heading', unit.heading, '->', lastStepHeading, '(lastStepHeading)')
      unit.heading = lastStepHeading
    }
    if (isNotNil(dest_heading)) {
      console.log('[MoveUnitAction].heading', unit.heading, '->', dest_heading, '(dest_heading')
      unit.heading = dest_heading
    }


    unit.mobility = max(0, unit.mobility - stepsMobilityCost)
    const stepsFuelCost = stepsMobilityCost
    unit.fuel = max(0, unit.fuel - stepsFuelCost)

    const { cargo } = unit
    if (cargo) {
      for (let index = 0; index < ents.length; index++) {
        const ent2 = ents[index];
        if (ent2.taxi_id === unit.id) {
          ent2.x = unit.x
          ent2.y = unit.y
        }
      }
    }
    resetAllAnnexingByUnit(ents, unit)
  }

  if (annex) {
    const annexPosition = dest_position || start_position
    // console.log('the unit is annexing something')

    if (!annexPosition) {
      throw new Unexpected('!annexPosition')
    }
    if (!samePosition(unit, annexPosition)) {
      throw new Unexpected('!samePosition(unit, annexPosition)')
    }
    const annexableEnt = engine.state.ents.find(
      (entity: Entity): entity is AnnexableEntityUnion => {
        if (samePosition(entity, annexPosition)) {
          return canUnitAnnexEntity(unit, entity)
        }
        return false
      }
    )
    if (!annexableEnt) {
      throw new Unexpected('!annexableEnt')
    }
    actionLog.annexed0 = deepClone(annexableEnt)
    const annexDelta = unit.hp
    if (!annexDelta) {
      throw new Unexpected('!annexDelta')
    }
    const annexableEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, annexableEnt.etype_id)
    const defaultAnnexPoints = (annexableEntityTypeMeta.entDefaults as HasAnnexPoints).ap
    const annexableEntPlayerId = annexableEnt.player_id

    // TODO also cleanup orphaned annexations
    if (annexableEnt.ap_ent_id !== unit.id) {
      annexableEnt.ap = defaultAnnexPoints
      annexableEnt.ap_ent_id = unit.id
    }
    annexableEnt.ap -= annexDelta
    if (annexableEnt.ap <= 0) {
      annexableEnt.player_id = unit.player_id
      annexableEnt.ap = defaultAnnexPoints
      annexableEnt.ap_ent_id = null

      checkLostLastEntityWinCondition(engine, annexableEntPlayerId)
      checkMapControlWinCondition(engine, annexableEntPlayerId)
      checkLostHqWinCondition(engine, annexableEntPlayerId, annexableEnt)
      annexableEnt.annexed = true
    }
    unit.mobility = 0
    actionLog.annexed1 = deepClone(annexableEnt)
  }

  if (attack_position) {
    if (samePosition(unit, attack_position)) {
      throw new Unexpected('samePosition(unit, attack_position)')
    }
    if (!target_id) {
      throw new Unexpected('!target_id')
    }
    const target = findEntityById(ents, target_id)
    if (!target) {
      throw new Unexpected('!target')
    }
    if (!isEntityUnit(target)) {
      throw new Unexpected('!target')
    }
    if (target.taxi_id) {
      throw new Unexpected('target.taxi_id')
    }
    if (!samePosition(target, attack_position)) {
      throw new Unexpected('!samePosition(target, attack_position)')
    }
    actionLog.target0 = deepClone(target)
    // actionLog.targetStack = deepClone(getEntityGridXYStack(entityGridXY, target.x, target.y))

    const isRangedAttack = !isOrthogonallyAdjacent(attack_position, dest_position || start_position)

    if (isRangedAttack) {
      const unitHasFullMobilityPoints = unitDefaultMobility && unitDefaultMobility === unit.mobility
      if (!unitHasFullMobilityPoints) {
        throw new Unexpected('unitDefaultMobility!==unit.mobility')
      }
    }

    const weaponEstimate = createDraftMoveAttackEstimate(engine, unit, unitPosition, target)
    // if (!weaponEstimate) {
    //   throw new Unexpected('!weaponEstimate')
    // }
    const { unitWeaponEstimate, targetWeaponEstimate } = weaponEstimate
    if (!unitWeaponEstimate) {
      throw new Unexpected('!unitWeaponEstimate')
    }
    const { weapon: unitWeapon } = unitWeaponEstimate
    // the initial attack
    if (!isNil(weaponEstimate.targetDmg)) {
      decrementAmmo(unitWeapon)
      target.hp -= weaponEstimate.targetDmg
    } else {
      throw new Unexpected('!(unitWeapon && !isNil(weaponEstimate.targetDmg))')
    }
    
    // is target still alive?
    if (target.hp >= 0) {
      // the counter attack
      const targetWeapon = targetWeaponEstimate?.weapon 
      if (targetWeapon && !isNil(weaponEstimate.unitDmg) && target.hp >= 0) {
        decrementAmmo(targetWeapon)
        unit.hp -= weaponEstimate.unitDmg
      }

      // did the unit die in the counter attack?
      if (!(unit.hp >= 0)) {
        unit.destroyed = true
        // the unit is dead
        state.ents = ents.filter(ent => {
          // the unit is dead
          if (ent.id == unit_id) {
            return false
          }
          // the unit's cargo is destroyed
          if (ent.taxi_id == unit_id) {
            return false
          }
          return true
        })
        checkLostLastEntityWinCondition(engine, unit.player_id)
      }
    } else {
      // the target is dead
      state.ents = ents.filter(ent => {
        // the unit is dead
        if (ent.id == target_id) {
          return false
        }
        // the unit's cargo is destroyed
        if (ent.taxi_id == target_id) {
          return false
        }
        return true
      })
      resetAllAnnexingByUnit(ents, target)
      checkLostLastEntityWinCondition(engine, target.player_id)
    }

    unit.mobility = 0
    unit.attackedThisTurn = true
    resetAllAnnexingByUnit(ents, unit)
    actionLog.target1 = deepClone(target)
  }

  if (pickup_id) {
    const cargoEnt = findEntityById(ents, pickup_id)
    if (!cargoEnt) {
      throw new Unexpected('!cargoEnt')
    }
    if (!isEntityUnit(cargoEnt)) {
      throw new Unexpected('!isUnit(cargo)')
    }
    if (cargoEnt.taxi_id) {
      throw new Unexpected('cargoEnt already in taxi')
    }
    // easiest way to stop a player from double building
    // from a factory tile
    if (cargoEnt.builtThisTurn) {
      throw new Unexpected('cargoEnt.builtThisTurn')
    }
    const { transports } = unitEntityTypeMeta
    if (!(transports && transports.includes(cargoEnt.etype_id))) {
      throw new Unexpected('!unit.canTransport(cargoEnt)')
    }
    actionLog.cargo0 = deepClone(cargoEnt)
    const { cargo } = unit
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!(cargo.length < (unitEntityTypeMeta.cargoLimit || 0))) {
      throw new Error('transport full')
    }
    cargo.push(cargoEnt.id)
    cargoEnt.taxi_id = unit.id
    actionLog.cargo1 = deepClone(cargoEnt)
  }

  if (taxi_id) {
    const taxiEnt = findEntityById(ents, taxi_id)
    if (!taxiEnt) {
      throw new Unexpected('!taxiEnt')
    }
    if (!isEntityUnit(taxiEnt)) {
      throw new Unexpected('!isUnit(taxi)')
    }
    if (unit.taxi_id) {
      throw new Unexpected('unit already in taxi')
    }
    actionLog.taxi0 = deepClone(taxiEnt)
    const taxiEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, taxiEnt.etype_id)
    const { transports } = taxiEntityTypeMeta
    if (!(transports && transports.includes(unit.etype_id))) {
      throw new Unexpected('!taxi.canTransport(unit)')
    }
    const { cargo } = taxiEnt
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!(cargo.length < (taxiEntityTypeMeta.cargoLimit || 0))) {
      throw new Error('transport full')
    }
    cargo.push(unit.id)
    unit.taxi_id = taxiEnt.id
    actionLog.taxi1 = deepClone(taxiEnt)
  }

  if (unload_position && cargo_id) {
    const cargoEnt = findEntityById(ents, cargo_id)
    if (!cargoEnt) {
      throw new Unexpected('!cargoEnt')
    }
    if (!isEntityUnit(cargoEnt)) {
      throw new Unexpected('!isUnit(cargo)')
    }
    if (cargoEnt.taxi_id !== unit.id) {
      throw new Unexpected('cargoEnt.taxi_id !== unit.id')
    }
    actionLog.cargo0 = deepClone(cargoEnt)
    const { cargo } = unit
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!cargo.includes(cargoEnt.id)) {
      throw new Unexpected('!unit.cargo.has(cargoEnt)')
    }
    const entsAtUnloadPosition = getEntitiesAtPosition(ents, unload_position)
    const mc = getUnitMoveCostOntoPositionEntityStack(cargoEnt, entsAtUnloadPosition)
    if (isNil(mc)) {
      throw new Error('nontraversible')
    }
    unit.cargo = [...cargo.filter((id) => id !== cargo_id)]
    delete cargoEnt.taxi_id
    cargoEnt.x = unload_position.x
    cargoEnt.y = unload_position.y
    if (cargoEnt.mobility > 0) {
      cargoEnt.mobility = 0
    }
    actionLog.cargo1 = deepClone(cargoEnt)
  }

  if (dest_position && merge_id) {
    const mergeEnt = findEntityById(ents, merge_id)
    if (!mergeEnt) {
      throw new Unexpected('!mergeEnt')
    }
    if (!isEntityUnit(mergeEnt)) {
      throw new Unexpected('!isUnit(merge)')
    }
    if (mergeEnt.taxi_id) {
      throw new Unexpected('mergeEnt.taxi_id')
    }
    if (mergeEnt.etype_id !== unit.etype_id) {
      throw new Unexpected('merge entity type mismatch')
    }
    actionLog.merge0 = deepClone(mergeEnt)
    const { cargo: unitCargo } = unit
    if (unitCargo?.length as number > 0) {
      throw new Unexpected('merge unitCargo.length')
    }
    const { cargo: mergeEntCargo } = unit
    if (mergeEntCargo?.length as number > 0) {
      throw new Unexpected('merge mergeEntCargo.length')
    }
    const entsAtDestPosition = getEntitiesAtPosition(ents, dest_position)
    const mc = getUnitMoveCostOntoPositionEntityStack(mergeEnt, entsAtDestPosition)
    if (isNil(mc)) {
      throw new Error('nontraversible')
    }
    if (mergeEnt.mobility > 0) {
      mergeEnt.mobility = 0
    }

    const totalHp = unit.hp + mergeEnt.hp
    const newHp = min(10, totalHp)
    const surplusHp = totalHp - newHp
    if (surplusHp >= 0 && unitDefaultPrice) {
      unitPlayer.money += (surplusHp * unitDefaultPrice) / 10
    }
    mergeEnt.hp = newHp

    if (isNotNil(mergeEnt.fuel) && isNotNil(unit.fuel)) {
      mergeEnt.fuel = min(unitDefaultFuel, mergeEnt.fuel + unit.fuel)
    }

    mergeEnt.weapons?.forEach?.(w1 => {
      if ('ammo' in w1) {
        unit.weapons?.find(w2 => {
          if ('ammo' in w2 && w1.wt_id === w2.wt_id) {
            w1.ammo = (w1.ammo ?? 0) + (w2.ammo ?? 0)
            w2.ammo = 0
          }
        })
      }
    })

    resetAllAnnexingByUnit(ents, unit)

    // remove unit after merging
    engine.state.ents = ents.filter(ent => ent.id !== unit_id)
    actionLog.merge1 = deepClone(mergeEnt)
  }

  if (wait) {
    unit.waitedThisTurn = true
  }

  actionLog.unit1 = deepClone(unit)

  checkIfGameShouldEnd(engine, actionLog)

  resetEngineToDefaultSelectedTool(engine)
}
