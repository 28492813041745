import { DESCRIPTION_MAXIMUM, DESCRIPTION_MINIMUM, DNAME_MAXIMUM, DNAME_MINIMUM } from "@sg/shared/src/valibot/schema/DisplayName.type"
import { A } from "@solidjs/router"
import { Button, FormGroup, FormLabel, FormText } from 'solid-bootstrap'
import { FaRegularFloppyDisk, FaSolidTrashCan } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, useContext } from "solid-js"
import { safeParse } from "valibot"
import { Engine } from '../../lib/core/engine/Engine.type'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import { createPatchMapAction, PatchMapActionDataSchema, type PatchMapActionData } from "../../lib/core/state/flux/action/Map/PatchMapAction"
import dispatchClient from "../../lib/core/state/flux/dispatchClient"
import createIsDotKeyInvalidDerivedSignal from "../../rx/derived_signal/createIsDotKeyInvalidDerivedSignal"
import { createAlertBagStore } from '../../rx/store/create_alert_bag_store'
import AlertBag from "../AlertBag"
import EngineContext from "../EngineContext"
import InvalidFeedback from "../Form/InvalidFeedback"
import LoadingIcon from "../LoadingIcon"
import NewGameIcon from "./NewGameIcon"
import SelectStateMapDisk from "./SelectStateMapDisk"

function createFormData(engine: Engine): PatchMapActionData {
  return {
    dname: engine.state.map.dname || '',
    bio: engine.state.map.bio || '',
  }
}

const FileWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)

  const ab = createAlertBagStore()

  const [isSaving, setIsSaving] = createSignal<boolean>(false)

  const [formData, setFormData] = createSignal<PatchMapActionData>(createFormData(engine))

  const formDataValidation = createMemo(() => safeParse(PatchMapActionDataSchema, formData()))
  // eslint-disable-next-line solid/reactivity
  const isDotPathInvalid = createIsDotKeyInvalidDerivedSignal(formDataValidation)

  const isFormDirty = createMemo(() => JSON.stringify(createFormData(engine)) !== JSON.stringify(formData()))

  // createEffect(() => {
  //   console.log('formDataValidation', formDataValidation())
  // })

  // createEffect(() => {
  //   console.log('formData.effect', formData())
  // })
  createEffect(() => {
    setFormData(createFormData(engine))
  })

  const handleTextChange = (event: InputEvent) => {
    const target = (event.target as HTMLInputElement | HTMLTextAreaElement)
    const { name, value } = target
    setFormData((prevFormData: PatchMapActionData): PatchMapActionData => ({
      ...prevFormData, [name]: value,
    }))
  }

  return <>
    {engine.selectedTool === SelectedToolId.File && <div id="MapEditorFileWidget" class="card-body">
      {/* <h5 class="card-title clearfix">
        File
      </h5> */}
      <AlertBag store={ab} />
      <FormGroup>
        <FormLabel>Disk</FormLabel>
        <SelectStateMapDisk
          value={engine.state.map.disk}
          onChange={(/*newValue: StateMapDisk*/) => {
            // engine.state.map.disk = newValue
            // await dispatchClient(engine, createPatchMapAction(formData()))
            throw new Error('Not Implemented')
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Map Name</FormLabel>
        <input class="form-control" name="dname" type="text"
          value={formData().dname || ''}
          onInput={handleTextChange}
          minLength={DNAME_MINIMUM} maxLength={DNAME_MAXIMUM}
          classList={{ 'is-invalid': isDotPathInvalid('dname') }}
        />
        <InvalidFeedback name="dname" v={formDataValidation()} />
        <FormText>optional short description</FormText>
      </FormGroup>
      <FormGroup>
        <FormLabel>Description</FormLabel>
        <textarea class="form-control" name="bio"
          value={formData().bio || ''}
          onInput={handleTextChange}
          minLength={DESCRIPTION_MINIMUM} maxLength={DESCRIPTION_MAXIMUM}
          classList={{ 'is-invalid': isDotPathInvalid('bio') }}
        />
        <InvalidFeedback name="bio" v={formDataValidation()} />
        <FormText>optional long description</FormText>
      </FormGroup>
      <hr />
      <div class="btn-group text-nowrap">
        <Button name="save_changes"
          class="btn btn-secondary w-100"
          disabled={!isFormDirty() || !formDataValidation().success}
          // eslint-disable-next-line solid/reactivity
          onClick={async () => {
            if (isSaving() || !formDataValidation().success) {
              return
            }
            try {
              setIsSaving(true)
              await dispatchClient(engine, createPatchMapAction(formData()))
            } finally {
              setIsSaving(false)
            }
          }}
          aria-label="Save Changes"
        >
          {isSaving() ? <>
            <LoadingIcon /> Saving Changes
          </> : <>
            <FaRegularFloppyDisk /> Save Changes
          </>}
        </Button>
        <Button name="discard_changes"
          class="btn btn-secondary w-100"
          disabled={!isFormDirty()}
          onClick={() => setFormData(createFormData(engine))}
          aria-label="Discard Changes"
        ><FaSolidTrashCan /> Reset Form</Button>
        <A href={`/lobby/create?map_id=${engine.state.online?.id}`}
          class="btn btn-success w-100"
          classList={{
            'disabled': isFormDirty()
          }}
          onClick={() => {
            return !isFormDirty()
            // navigate(`/lobby/create?map_id=${engine.state.id}`)
          }}
          aria-label="New Game"
        ><NewGameIcon /> New Game</A>
      </div>
    </div>}
  </>
}

export default FileWidget
