import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { RoadKind, RoadKindSchema } from '../../RoadKind.enum'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'

const entDefaults = {
  etype_id: EntityTypeId.Road,
  layer_id: EntityLayerId.TerrainL2,
} as const

export const RoadEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Road),
  layer_id: literal(EntityLayerId.TerrainL2),
  id: EntityIdSchema,
  road: RoadKindSchema,
  ...HasXYSchema.entries,
})

export type RoadEntity = InferOutput<typeof RoadEntitySchema>

export const RoadEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Road,
  dname: 'Road',
  layer_id: EntityLayerId.TerrainL2,
  defense: 0,
  entDefaults,
}

export function createRoadEntity(
  id: number,
  x: number,
  y: number,
  road: RoadKind
): RoadEntity {
  return {
    ...entDefaults,
    heading: 0,
    id,
    x,
    y,
    road,
  }
}
