import { Component, For, createMemo, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import createSampleEntity from "../../lib/core/entity/create_sample_entity"
import entityTypeMetaList from "../../lib/core/entity/entityTypeMetaList.generated"
import { Entity } from "../../lib/core/entity/index"
import { EntityTypeMeta } from "../../lib/core/EntityTypeMeta"
import findByIdOrThrow from "../../lib/core/findByIdOrThrow"
import { HasPlayerId } from "../../lib/core/player/has_player_id"
import { Player } from "../../lib/core/player/Player.type"
import { sortMapWhereKeyHasIdAsc } from "../../lib/core/util/sortMapWhereKeyHasId"
import EngineContext from "../EngineContext"
import SingleEntityIcon3D from "./SingleEntityIcon3D"

interface Props {
  player: Player | null,
}

// const d_tile_size = BASE_TILE_SIZE * 2

type SpriteCounts = Map<EntityTypeMeta, number>

// function bySelfAsc(a: SpriteCounts, b: SpriteCounts) {
//   return a[0] - b[0]
// }
// function bySelfDesc(a: SpriteCounts, b: SpriteCounts) {
//   return b[0] - a[0]
// }

// export function sortMapAsc(data: SpriteCounts) {
//   return new Map<K, V>([...data].sort(bySelfAsc))
// }

// export function sortMapDesc(data: SpriteCounts) {
//   return new Map<K, V>([...data].sort(bySelfDesc))
// }


const SpriteCountTable: Component<Props> = (props: Props) => {
  // const player_id = props.player?.id || null
  const engine: Engine = useContext(EngineContext)
  // const { player } = props
  // const [track, dirty] = engine.cache

  const data = createMemo<SpriteCounts>(() => {
    // console.log('SpriteCountTable.memo.data')

    const playerId = props.player?.id

    function toSpriteCountMap(carry: SpriteCounts, entity: Entity): SpriteCounts {
      const entityType: EntityTypeMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
      if ((entity as HasPlayerId).player_id === playerId) {
        carry.set(entityType, (carry.get(entityType) || 0) + 1)
      }
      return carry
    }

    const data = engine.state.ents.reduce(toSpriteCountMap, new Map())
    // console.log('SpriteCountTable.data', sortMapAsc(data))
    // console.log('SpriteCountTable.data', [...data.entries()])
    // console.log('SpriteCountTable.data', [...data.values()])
    return sortMapWhereKeyHasIdAsc<EntityTypeMeta, number>(data)
  })

  return <table class="table table-sm">
    <tbody>
      <For each={[...data().entries()]}>
        {([sprite, count]) => <tr>
          <td><SingleEntityIcon3D size={32} entity={createSampleEntity(sprite, props.player?.id || null)} /></td>
          <td>{sprite.dname}</td>
          <td class="text-end">{count}</td>
        </tr>}
      </For>
    </tbody>
  </table>
}

export default SpriteCountTable
