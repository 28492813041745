import { createEffect, on } from "solid-js"
import { modifyMutable, produce } from "solid-js/store"
import { Group, Object3D, type Mesh, type MeshStandardMaterial, type Scene } from "three"
import calculateDestPositionHoverPositionPaths from "../../core/draft_move/calculateDestPositionHoverPositionPaths"
import calculateDraftMoveAttackEstimates from "../../core/draft_move/calculateDraftMoveAttackEstimates"
import type { Engine } from "../../core/engine/Engine.type"
import { SelectedToolId } from "../../core/map_editor/SelectedToolId.enum"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import { samePosition } from "../../core/tile_position_xy/samePosition"
import { sin } from "../../core/util/math"
import { Cursor_HoveredTileCursor1_GLTF_Model } from "../gltf_models.generated"
import createEraseCursorSprite from "../sprite/createEraseCursorSprite"
import createPaintCursorSprite from "../sprite/createPaintCursorSprite"
import { appGltfLoader } from "./appGltfLoader"
import createDebugTextSprite from "./createDebugTextSprite"
import disposeForTraverse from "./disposeForTraverse"
import onGltfLoaderError from "./onGltfLoaderError"
import removeDebugTextObject from "./removeDebugTextObject"
import setObjPosition from "./setObjPosition"

// const hoverPositionGeometry = new BoxGeometry(1, 1, 1)
// const hoverPositionMaterial = new MeshBasicMaterial({
//   // color: 0x777777,
//   color: 0xcccccc,
//   transparent: true,
//   opacity: 0.25,
// })

type UpdateHoverTileFunction = (
  nextHoverTilePosition : TilePositionXY | null,
  // pointerVector3Point : Nullable<Vector3>
) => void

const cursorIconScale = 8

export default function createHoverPositionMesh(
  engine : Engine,
  scene : Scene
): UpdateHoverTileFunction {

  const group : Group = new Group()

  const defaultPositionY = 1.25
  const baseRenderHeight = defaultPositionY

  appGltfLoader.load(Cursor_HoveredTileCursor1_GLTF_Model, function (ModelObj) {
    // group.children = ModelObj.scene.children
    group.add(ModelObj.scene)

    const cursor = group.getObjectByName('Cursor') as Mesh
    const material = cursor.material as MeshStandardMaterial
    material.transparent = true
    material.opacity = 0.25

    group.userData.update = function (elapsedMs: number, frameAt: number) {
      const s1 = sin(frameAt / 500)
      group.position.y = baseRenderHeight + (1 + (s1 * s1) / 2)

      const s2 = sin(frameAt / 800)
      material.opacity = 0.5 + ((1 + s2*s2) / 32)
    }
  }, undefined, onGltfLoaderError)

  group.visible = false

  scene.add(group)

  let prevHoverPosition : TilePositionXY | null = null

  group.position.set(0, defaultPositionY, 0) // Position the cursor above the ground

  let cursorIcon : Object3D | null = null
  // console.log('engine.selectedTool', engine.selectedTool)
  createEffect(on(() => engine.selectedTool, (selectedTool: SelectedToolId) => {
    // console.log('engine.selectedTool.effect', selectedTool)
    if (cursorIcon) {
      cursorIcon.removeFromParent()
      disposeForTraverse(cursorIcon)
      cursorIcon = null
    }
    if (selectedTool === SelectedToolId.Inspect) {
      // cursorIcon = createInspectCursorSprite()
    } else if (selectedTool === SelectedToolId.MoveUnit) {
      // cursorIcon = createMoveUnitCursorSprite()
    } else if (selectedTool === SelectedToolId.RangedAttackRadius) {
      // cursorIcon = createRangedAttackRadiusCursorSprite()
    } else if (selectedTool === SelectedToolId.Paint) {
      cursorIcon = createPaintCursorSprite()
    } else if (selectedTool === SelectedToolId.Erase) {
      cursorIcon = createEraseCursorSprite()
    // } else if (selectedTool === SelectedToolId.Players) {
    //   cursorIcon = createPlayersCursorSprite()
    // } else if (selectedTool === SelectedToolId.File) {
    //   cursorIcon = createFileCursorSprite()
    }
    if (cursorIcon) {
      group.add(cursorIcon)
      cursorIcon.scale.set(cursorIconScale, cursorIconScale, 0)
      cursorIcon.position.y += 5
      cursorIcon.position.x += 12
    }
  }))

  createEffect(() => {
    const { draftMove, hoveredPosition, selectedPosition, selectedTool } = engine
    const toolDoesNotHaveItsOwnCursor = selectedTool !== SelectedToolId.RangedAttackRadius
    let newValue : boolean = false
    if (hoveredPosition && toolDoesNotHaveItsOwnCursor) {
      if (!samePosition(hoveredPosition, selectedPosition)) {
        newValue = true
        if (draftMove) {
          const { hoveredPositionPaths } = draftMove
          if (hoveredPositionPaths) {
            const { targetStep } = hoveredPositionPaths
            if (targetStep?.enemies.find((enemy) => enemy.canDirectAttack)) {
              newValue = false
            }
          }
        }
      }
    }
    group.visible = newValue
  })

  return function updateHoverTilePosition(
    hoverPosition : TilePositionXY | null,
    // pointerVector3Point: Nullable<Vector3>
  ): void {
    // console.log('updateHoverTilePosition', JSON.stringify(hoverPosition))
    if (hoverPosition) {
      const hoverPositionChanged = !samePosition(prevHoverPosition, hoverPosition)
      if (hoverPositionChanged) {
        // console.log('updateHoverTilePosition.hoverPositionChanged')
        // group.clear()
        removeDebugTextObject(group)

        modifyMutable(engine, produce((engine: Engine) => {
          engine.hoveredPosition = prevHoverPosition = hoverPosition
          calculateDestPositionHoverPositionPaths(engine)
          calculateDraftMoveAttackEstimates(engine)
        }))

        const stateX = hoverPosition.x
        const stateY = hoverPosition.y

        // pointerVector3Point = pointerVector3Point || {x : NaN, z: NaN} as Vector3

        const debugText = createDebugTextSprite(`(${stateX}, ${stateY})`)
  //       const debugText = createDebugTextSprite(`(${stateX}, ${stateY})
  // (${toFixed1(pointerVector3Point!.x)}, ${toFixed1(pointerVector3Point!.z)})`)
  //       const debugText = createDebugTextSprite(`(${stateX}, ${stateY})
  // ${SelectedToolId[engine.selectedTool]}`)
        // debugText.scale.set(2, 2, 2) // Scale the text
        // const rh = getEntityRenderHeight(getEntityListAtXYMap().get(toCoord(hoverPosition)) || [], null)
        // console.log('hoverPosition.rh', rh)
        debugText.position.set(0, 25, 0) // Position the text above the box
        debugText.scale.set(16, 16, 0.1) // Adjust size to match box
        // group.clear()
        // group.traverse(removeDebugTextForTraverse)
        group.add(debugText)
        // console.log('debugText', debugText)

        setObjPosition(group, hoverPosition, baseRenderHeight)

        // debugText.visible = true
      }
    } else {
      engine.hoveredPosition = null
    }
    prevHoverPosition = hoverPosition
  }
}
