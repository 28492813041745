import { array, boolean, number, object, type InferOutput } from 'valibot'
import type { Nullable } from '../../../typescript'
import Unexpected from '../../Exception/Unexpected.class'
import type { DraftMove } from '../draft_move/DraftMove.type'
import type { Engine } from '../engine/Engine.type'
import type { BaseEntity } from '../entity/BaseEntity'
import { EntityHeadingSchema } from '../entity/EntityHeading/EntityHeading.type'
import { EntityIdSchema, type EntityId } from '../entity/EntityId.type'
import findEntityById from '../entity/findEntityById'
import { CargoEntitySchema, type HasTaxiID } from '../entity/HasTaxiID'
import { EntitySchema, type Entity } from '../entity/index'
import coord from './coord'
import createLazyBlockedValueGrid from './createLazyBlockedValueGrid'
import { dijkstraDirections } from './dijkstraDirections'
import entityListToGridXY from './entityListToGridXY'
import getEntityHeadingDegFromAToB from './findMostEfficientPathToTileV2/getEntityHeadingDegFromAToB'
import isOrthogonallyAdjacent from './isOrthogonallyAdjacent'
import { TilePositionXYSchema } from './TilePositionXY.type'
import toCoord from './toCoord'

export const UnloadableCargoTileSchema = object({
  cargo_id: EntityIdSchema,
  cargo: CargoEntitySchema,
  heading: EntityHeadingSchema,
  x: number(),
  y: number(),
  blocked: boolean(),
})
export type UnloadableCargoTile = InferOutput<typeof UnloadableCargoTileSchema>

export const UnloadableCargoTileCollectionSchema = object({
  taxi: EntitySchema,
  taxiPosition: TilePositionXYSchema,
  cargo_id: EntityIdSchema,
  cargo: CargoEntitySchema,
  paths: array(UnloadableCargoTileSchema),
})
export type UnloadableCargoTileGroup = InferOutput<typeof UnloadableCargoTileCollectionSchema>

export default function findUnloadableCargoTileCollection(
  engine: Engine,
  draftMove: Nullable<DraftMove>
): Array<UnloadableCargoTileGroup> {
  // console.log('findUnloadableCargoTileCollection', deepClone({ draftMove, hoveredPosition: engine.hoveredPosition }))
  const collection : Array<UnloadableCargoTileGroup> = []
  if (draftMove) {
 
    const { hoveredPosition, state } = engine
    const { ents, width, height } = state
    const { unit: taxi, destPosition } = draftMove
    // the position that the taxi will unload from
    const taxiTargetPosition = destPosition || hoveredPosition || toCoord(taxi)
    // console.log('findUnloadableCargoTileCollection.taxiTargetPosition', deepClone(taxiTargetPosition))
    const grid = entityListToGridXY(ents, width, height)
    ;(taxi as BaseEntity).cargo?.forEach?.((cargoEntId : EntityId) => {
      const cargoEnt = findEntityById(ents, cargoEntId)
      if (!cargoEnt) {
        throw new Unexpected('!cargoEnt')
      }
      // console.log('findUnloadableCargoTileCollection', deepClone({cargoEnt, unit}))
      if ((cargoEnt as BaseEntity).taxi_id !== taxi.id) {
        throw new Unexpected('cargoEnt.taxi_id !== unit.id')
      }
      const blockedAt = createLazyBlockedValueGrid(width, height, grid, cargoEnt)
      const group : UnloadableCargoTileGroup = {
        cargo_id: cargoEntId,
        cargo: cargoEnt as Entity & HasTaxiID,
        paths: [],
        taxiPosition: taxiTargetPosition,
        taxi,
      }
      for (const { dx, dy } of dijkstraDirections) {
        const nextX = taxiTargetPosition.x + dx
        const nextY = taxiTargetPosition.y + dy
        const nextPosition = coord(nextX, nextY)
        const inBounds = nextX >= 0 && nextY >= 0 && nextX < width && nextY < height
        // console.log('findUnloadableCargoTileCollection.dijkstraDirections', deepClone({nextX, nextY, dx, dy,
        //   inBounds, isOrthogonallyAdjacentToTarget: isOrthogonallyAdjacent(nextPosition, taxiTargetPosition),
        // }))
        if (inBounds) {
          const isOrthogonallyAdjacentToTarget = isOrthogonallyAdjacent(nextPosition, taxiTargetPosition)
          if (isOrthogonallyAdjacentToTarget) {
            const heading = getEntityHeadingDegFromAToB(taxiTargetPosition, nextPosition)
            group.paths.push({
              x: nextX,
              y: nextY,
              heading,
              cargo_id: cargoEntId,
              cargo: cargoEnt as Entity & HasTaxiID,
              blocked: blockedAt(nextX, nextY),
            })
          }
        }
      }
      console.table(group.paths)
      collection.push(group)
    })
  }

  // console.log('findUnloadableCargoTileCollection.collection', deepClone(collection))
  return collection
}
