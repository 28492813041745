import { enum_ } from 'valibot'

export enum PaintTileOptionFolderId {
  None = 0,
  T1 = 1,
  T2 = 2,
  Building = 3,
  Road = 4,
  Water = 5,
  Unit = 6,
}

export const PaintTileOptionFolderIdSchema = enum_(
  PaintTileOptionFolderId,
  'Invalid PaintTileOptionFolderId'
)
