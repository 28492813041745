import { type Object3D } from "three"
import createEngineForUI from "../../../core/engine/createEngineForUI"
import { isEntityDesert, isEntityPlain, isEntitySea, isEntitySnow } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import { PI } from "../../../core/util/math"
import createTerrainMesh1Mesh from "./createTerrainMesh1Mesh"

export function isEntityTerrainMesh1 (ent: Entity) {
  return isEntityPlain(ent)
    || isEntitySnow(ent)
    || isEntityDesert(ent)
    || isEntitySea(ent)
}

export default function makeSingleTileTerrainMesh1Object3D (
  ent: Entity,
  // pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  const engine = createEngineForUI()
  const { state } = engine
  const ents = state.ents = [{
    ...ent,
    x: 0,
    y: 0,
  }]
  state.width = state.height = 1
  const mesh = createTerrainMesh1Mesh(engine, ents)
  // mesh.position.z -= VERTICES_PER_TILE
  mesh.rotateX(PI / 2)
  callback(mesh)
}
