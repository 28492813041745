import { DEV } from "solid-js"
import { Group, Mesh, type Object3D } from "three"
import { parse } from "valibot"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { PI } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { M109Paladin1_M109Paladin1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeMediumArtilleryScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(M109Paladin1_M109Paladin1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)

    const turretGroup = scene.getObjectByName('Turret') as Group
    assertClassInDev(turretGroup, Group)

    const barrelGroup = scene.getObjectByName('Barrel') as Group
    assertClassInDev(barrelGroup, Group)

    // turretGroup.position.y -= 2
    // turretGroup.position.y = 2.5
    // barrelGroup.position.y = 0.7
    // barrelGroup.position.z = 5
    barrelGroup.rotateX(-PI / 8)

    const leftTrackMesh = scene.getObjectByName('LeftTrack') as Mesh
    assertClassInDev(leftTrackMesh, Mesh)

    const rightTrackMesh = leftTrackMesh.clone()
    rightTrackMesh.name = 'RightTrack'

    // scene.add(leftTrackMesh)
    scene.add(rightTrackMesh)

    rightTrackMesh.position.x = 9

    applyPlayerColors(scene, ent, pto)

    const defaultHullY = bodyGroup.position.y
    // const defaultHullX = 0

    bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyGroup.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    turretGroup.userData.e = ent

    if (DEV) {
      parse(DegreeNumberSchema, ent.heading)
    }

    turretGroup.userData.targetHeading = ent.heading
    turretGroup.userData.heading = ent.heading
    turretGroup.userData.update = (elapsedMs: number): void => {
      applyEntityRotationToNextObject3DFrame(turretGroup, elapsedMs)
    }

    callback(scene)
  })
}
