import { createEffect, onCleanup } from "solid-js"
import { Color, Group, Mesh, PointLight, Vector3, type MeshStandardMaterial, type Scene } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { sin } from "../../core/util/math"
import { Cursor_Arrow1_GLTF_Model, Cursor_TileCursorStyle1_GLTF_Model } from "../gltf_models.generated"
import { appGltfLoader } from "./appGltfLoader"
import createDebugTextSprite from "./createDebugTextSprite"
import disposeForTraverse from "./disposeForTraverse"
import onGltfLoaderError from "./onGltfLoaderError"
import removeDebugTextObject from "./removeDebugTextObject"
import setObjPosition from "./setObjPosition"

// const selectedTileGeometry = new BoxGeometry(1, 1, 1)
// const selectedTileMaterial = new MeshBasicMaterial({
//   color: 0xfffd67,
//   transparent: true,
//   opacity: 0.25,
// })

const selectedPositionColorHex = 0xfffd67

export default function createSelectedPositionMesh(
  engine : Engine,
  scene : Scene
): void {
  const group : Group = new Group()

  const defaultPositionY = 1.5

  appGltfLoader.load(Cursor_TileCursorStyle1_GLTF_Model, function (ModelObj) {
    const { scene } = ModelObj
    // console.log('ModelObj', ModelObj)
    // const group = ModelObj.scene
    // group.children = ModelObj.scene.children
    group.add(scene)
    // group.rotateY(PI/2)
    // group.scale.multiplyScalar(VERTICES_PER_TILE/32)
    // group.scale.multiplyScalar(VERTICES_PER_TILE)

    const cursor = group.getObjectByName('Cursor') as Mesh
    // console.log('cursor', cursor)
    const material = cursor.material as MeshStandardMaterial
    material.transparent = true
    material.opacity = 0.25
    material.color = new Color(selectedPositionColorHex)

    // console.log('selected.group', ModelObj.scene)
    // transparent: true,
    // opacity: 0.25,
    group.userData.update = function (elapsedMs: number, frameAt: number) {
      const s1 = sin(frameAt / 500)
      group.position.y = defaultPositionY + (1 + (s1 * s1) / 2)

      const s2 = sin(frameAt / 800)
      material.opacity = 0.5 + ((1 + s2*s2) / 32)

      group.rotateY(elapsedMs / -600)
    }
  }, undefined, onGltfLoaderError)

  appGltfLoader.load(Cursor_Arrow1_GLTF_Model, function (ModelObj) {
    const { scene } = ModelObj
    // console.log('ModelObj', ModelObj)
    // const group = ModelObj.scene
    // group.children = ModelObj.scene.children
    // group.add(ModelObj.scene)
    // group.rotateY(PI/2)
    // group.scale.multiplyScalar(VERTICES_PER_TILE/32)
    // group.scale.multiplyScalar(VERTICES_PER_TILE)

    const ArrowGroup = scene
    // const ArrowHeadMesh = scene.getObjectByName('Head') as Mesh
    const ArrowTailMesh = scene.getObjectByName('Tail') as Mesh
    const ArrowTailMesh2 = ArrowTailMesh.clone()
    ArrowTailMesh2.position.y += 2
    ArrowGroup.add(ArrowTailMesh2)

    ArrowGroup.scale.multiply(new Vector3(2, 2, 0.25))

    group.add(ArrowGroup)
    ArrowGroup.position.y += 20
    // console.log('cursor', cursor)
    // const material = ArrowHeadMesh.material as MeshStandardMaterial
    // material.transparent = true
    // material.opacity = 0.25
    // material.color = new Color(selectedPositionColorHex)

    // console.log('selected.group', ModelObj.scene)
    // transparent: true,
    // opacity: 0.25,
    // ArrowHeadMesh.userData.update = function (elapsedMs: number, frameAt: number) {
    //   const s1 = sin(frameAt / 500)
    //   group.position.y = defaultPositionY + (1 + (s1 * s1) / 2)

    //   // const s2 = sin(frameAt / 800)
    //   // material.opacity = 0.5 + ((1 + s2*s2) / 32)

    //   // group.rotateY(elapsedMs / -600)
    // }
  }, undefined, onGltfLoaderError)

  // const stateWidth = engine.state.width
  // const stateHeight = engine.state.height

  // const group = new Mesh(selectedTileGeometry, selectedTileMaterial)
  // group.scale.set(8, 1, 8)
  // group.scale.set(9, 9, 9)
  // group.scale.set(10, 10, 10)
  // group.position.set(0, 5, 0) // Position the text above the box
  group.visible = false

  scene.add(group)

  const lightPointingDown = new PointLight(0xffffff, 20)
  lightPointingDown.castShadow = false
  lightPointingDown.position.y = defaultPositionY + 20
  // lightPointingDown.lookAt(group.position)
  lightPointingDown.lookAt(0,defaultPositionY + 15,0)
  // lightPointingDown.lookAt(0,-1,0)
  group.add(lightPointingDown)

  // const lightPointingUp = new PointLight(0xffffff, 10)
  // lightPointingUp.castShadow = false
  // lightPointingUp.position.set(0, 0, 0)
  // // lightPointingUp.lookAt(group.position)
  // lightPointingUp.lookAt(0,1,0)
  // // lightPointingUp.lookAt(0,-1,0)
  // group.add(lightPointingUp)

  createEffect(() => {
    const { selectedPosition } = engine
    let newValue : boolean = false
  
    if (selectedPosition) {
      const stateX = selectedPosition.x
      const stateY = selectedPosition.y

      // const debugText = createDebugTextSprite(`(${stateX}, ${stateY})`)
      const debugText = createDebugTextSprite(`Selected\n(${stateX}, ${stateY})`)
      // debugText.scale.set(2, 2, 2) // Scale the text
      debugText.position.set(0, 9, 0) // Position the text above the box
      debugText.scale.set(16, 16, 0.1) // Adjust size to match box
      removeDebugTextObject(group)
      group.add(debugText)

      setObjPosition(group, selectedPosition, defaultPositionY)

      newValue = true
    }
    group.visible = newValue
  })

  onCleanup(() => {
    group.traverse(disposeForTraverse)
  })
}
