import { createEffect } from "solid-js"
import { PerspectiveCamera, type Camera } from "three"
import { getDeviceUserPreference } from "../../../rx/shared/device/userPreference/deviceUserPreferencesSignal"

export default function bindCameraToUserPreferences(
  camera: Camera,
) {
  createEffect(() => {
    if (camera instanceof PerspectiveCamera) {
      const prefs = getDeviceUserPreference()
      camera.far = prefs.camera_far
      camera.fov = prefs.camera_fov
      camera.updateProjectionMatrix()
    }
  })
  // createMountedInverval(() => {
  //   const newValue = PERSPECTIVE_CAMERA_FAR_MIN
  //     + (Math.random() * (PERSPECTIVE_CAMERA_FAR_MAX - PERSPECTIVE_CAMERA_FAR_MIN))
  //   updateCameraFar(floor(newValue))
  // }, 3000)
}