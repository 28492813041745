import { HemisphereLight } from "three"

export function createHemisphereLight() {
  const hemiLight = new HemisphereLight(0xffffbb, 0x080820, 1.5)

  // createMountedInverval(() => {
  //   // hemiLight.visible = !hemiLight.visible
  //   hemiLight.intensity = hemiLight.intensity === 1.2 ? 2 : 1.2
  // }, 1000)

  return hemiLight
}