import { BufferAttribute, BufferGeometry } from "three"

export default function truncateTrianglesBelowZ(
  geometry: Readonly<BufferGeometry>,
  zThreshold: number
): BufferGeometry {
  const positionAttr = geometry.getAttribute("position");
  const indexAttr = geometry.getIndex();

  if (!positionAttr) {
    console.warn("Geometry has no position attribute.");
    return new BufferGeometry();
  }

  const positions = positionAttr.array as Float32Array;
  const indices = indexAttr ? indexAttr.array as Uint16Array : null;

  const newPositions: number[] = [];
  const newIndices: number[] = [];
  const vertexMap = new Map<string, number>(); // To track reused vertices
  let newIndex = 0;

  const getOrAddVertex = (i: number) => {
    const key = `${positions[i * 3]},${positions[i * 3 + 1]},${positions[i * 3 + 2]}`;
    if (vertexMap.has(key)) return vertexMap.get(key)!;

    newPositions.push(positions[i * 3], positions[i * 3 + 1], positions[i * 3 + 2]);
    vertexMap.set(key, newIndex);
    return newIndex++;
  };

  // Process triangles
  const triangleCount = indices ? indices.length / 3 : positions.length / 9;
  for (let i = 0; i < triangleCount; i++) {
    const idxA = indices ? indices[i * 3] : i * 3;
    const idxB = indices ? indices[i * 3 + 1] : i * 3 + 1;
    const idxC = indices ? indices[i * 3 + 2] : i * 3 + 2;

    const zA = positions[idxA * 3 + 2];
    const zB = positions[idxB * 3 + 2];
    const zC = positions[idxC * 3 + 2];

    // Keep triangles where at least one vertex is above the threshold
    if (zA > zThreshold || zB > zThreshold || zC > zThreshold) {
      newIndices.push(getOrAddVertex(idxA), getOrAddVertex(idxB), getOrAddVertex(idxC));
    }
  }

  // Create new geometry
  const newGeometry = new BufferGeometry();
  newGeometry.setAttribute(
    "position",
    new BufferAttribute(new Float32Array(newPositions), 3)
  );
  newGeometry.setIndex(newIndices);

  // console.log('truncateTrianglesBelowZ', positions.length,  '=>', newPositions.length)

  return newGeometry;
}
