import { createEffect, createSignal } from "solid-js"
import { Group, type Scene } from "three"
import isIdListSame from "../../../../rx/signal/equals/isIdListSame"
import type { Nullable } from "../../../../typescript"
import type { Engine } from "../../../core/engine/Engine.type"
import type { EntityId } from "../../../core/entity/EntityId.type"
import type { Entity } from "../../../core/entity/index"
import isEntityUnit from "../../../core/entity/isEntityUnit"
import { PI } from "../../../core/util/math"
import type { EntityThreeModel } from "../../EntityThreeModel/EntityThreeModel.type"
import disposeRecursive from "../../fn/disposeRecursive"
import createTerrainMesh1Mesh from "./createTerrainMesh1Mesh"

export type EntityThreeModelCollection = {
  group: Group,
  ents: Map<EntityId, EntityThreeModel>
}

export default function createTerrainMesh1Effect(
  engine : Engine,
  scene : Scene,
): void {

  const [getTerrainEnts, setTerrgetTerrainEnts] = createSignal<Array<Entity>>([], {
    equals: isIdListSame,
  })

  createEffect(() => {
    setTerrgetTerrainEnts(engine.state.ents.filter((ent : Entity) => {
      return !isEntityUnit(ent)
    }))
  })

  let mesh : Nullable<ReturnType<typeof createTerrainMesh1Mesh>>
  createEffect(() => {
    const prevMesh = mesh
    const nextMesh = createTerrainMesh1Mesh(engine, getTerrainEnts())
    if (prevMesh) {
      prevMesh.removeFromParent()
      disposeRecursive(prevMesh)
    }
    nextMesh.rotateX(-PI / 2)
    // nextMesh.position.y = 10
    scene.add(nextMesh)
    mesh = nextMesh
  })
}
