import type { Camera } from 'three'
import { MapControls } from 'three/addons/controls/MapControls.js'
import { PI } from '../../core/util/math'

export default function createThreeMapControls(
  elem: HTMLElement,
  camera: Camera
): MapControls {
  const controls = new MapControls(camera, elem)

  // call this only in static scenes (i.e., if there is no animation loop)
  //controls.addEventListener( 'change', render )

  // an animation loop is required when either damping or auto-rotation are enabled
  // controls.enableDamping = true
  // controls.dampingFactor = 0.01

  // controls.mouseButtons = {
  //   LEFT: THREE.MOUSE.PAN,
  //   MIDDLE: THREE.MOUSE.DOLLY,
  //   RIGHT: THREE.MOUSE.ROTATE
  // }

  // controls.screenSpacePanning = false

  controls.minDistance = 50
  controls.maxDistance = 800

  // allow top-down viewing angle
  controls.minPolarAngle = 0

  // prevent moving camera below floor
  controls.maxPolarAngle = (0.9) * (PI/2)

  controls.zoomToCursor = true
  // controls.screenSpacePanning = true
  // controls.update()

  // controls.listenToKeyEvents(window)

  // controls.autoRotate = false

  // controls.keyPanSpeed = 50
  // controls.panSpeed = 50

  // controls.addEventListener('change', () => {
  //   // console.log('camera rotation', toFixed1(camera.rotation.x), toFixed1(camera.rotation.y), toFixed1(camera.rotation.z))
  //   console.log('camera position', toFixed1(camera.position.x), toFixed1(camera.position.y), toFixed1(camera.position.z))
  //   console.log('controls.getPolarAngle()', toFixed1(controls.getPolarAngle()))
  // })

  // setMapControlsPolarAngle(controls, 8)
  // setMapControlsPolarAngle(controls, PI * (1/4))

  return controls
}


