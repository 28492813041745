import { A, useParams } from '@solidjs/router'
import { createMemo, Show } from 'solid-js'
import { createMutable } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import SingleEntityIcon3D from '../../../components/MapEditor/SingleEntityIcon3D'
import bindEngineToComponentV1 from '../../../lib/canvas/bindEngineToComponentV1'
import createEngineForUI from '../../../lib/core/engine/createEngineForUI'
import type { Engine } from '../../../lib/core/engine/Engine.type'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import { EntityTypeMeta, isSpriteTerrain } from '../../../lib/core/EntityTypeMeta'
import { toSlug } from '../../../lib/slug'
import { createFirstPlayerIdMemo } from '../../../rx/memo/createFirstPlayerIdMemo'
import { Nullable } from '../../../typescript'
import NotFound from '../../NotFound'
import { createSharedPlayerThreeObjectsMap } from '../../../rx/memo/createSharedPlayerThreeObjectsMap'

const CodexTerrainShowPage = () => {

  // console.log('CodexTerrainShowPage')

  const params = useParams()

  const terrain = createMemo<Nullable<EntityTypeMeta>>(() => {
    return entityTypeMetaList.find((sprite: EntityTypeMeta) => sprite && isSpriteTerrain(sprite) && toSlug(sprite.dname) === toSlug(params.terrain))
  })
  const engine: Engine = createMutable(createEngineForUI())
  const samplePlayerId = createFirstPlayerIdMemo(engine)
  bindEngineToComponentV1(engine)
  // no idea what, but this page crashes
  // if you don't pre-fill the PTO cache
  createSharedPlayerThreeObjectsMap(engine)

  return <EngineContext.Provider value={engine}>
    <Show when={terrain()} fallback={NotFound()} children={(terrain) => {
      return <div class="pagec">
        <h2>
          <A href="/codex">Codex</A>{' - '}
          <A href="/codex/terrain">Terrain</A>{' - '}
          Terrain: {terrain().dname}
        </h2>
        <A href={`/codex/terrain/${toSlug(terrain().dname)}`}>
          {terrain().dname}
        </A>
        <div class="float-end">
          <SingleEntityIcon3D entity={createSampleEntity(terrain(), samplePlayerId())} size={128} />
        </div>
      </div>
    }} />
  </EngineContext.Provider>
}

export default CodexTerrainShowPage