import { DEV } from "solid-js"
import { AnimationClip, AnimationMixer, Bone, Group, type AnimationAction, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { InfantryEntity } from "../../core/entity/EntityType/Infantry"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { PI } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import Quaternius_AssaultRifle2_1_ModelValueModelGLTF from '../models/Quaternius/AssaultRifle2_1/AssaultRifle2_1.gltf?url'
import Quaternius_BlueSoldier_Male1_ModelValueModelGLTF from '../models/Quaternius/BlueSoldier_Male1/BlueSoldier_Male1.gltf?url'
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import applyPlayerColors from "./applyPlayerColors"
import getBoneByName from "./getBoneByName"
import GLTFSignalPromiseFactory from './GLTFSignalPromiseFactory'
const totalLoopMs = 4000

const scale = 4

// const heights = [1, 2, 2, 3, 3, 4]

function getAnimationAction (mixer : AnimationMixer, animations : Array<AnimationClip>, name: string): AnimationAction {
  const clip : AnimationClip = animations.find(a => a.name === name) as AnimationClip
  if (DEV && !clip) {
    throw new Error('clip is null')
  }
  return mixer.clipAction(clip)
}

export default function makeInfantryScene (
  ent: InfantryEntity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  GLTFSignalPromiseFactory(Quaternius_BlueSoldier_Male1_ModelValueModelGLTF).then((ModelObj) => {
    tmpRng.seed = ent.id
    const { scene, animations } = ModelObj
    // console.log('Infantry.scene', scene)
    // console.log('animations', animations.map(a => a.name))

    const head = scene.getObjectByName('Head')
    assertClassInDev(head, Bone)
    const HPBarAnchor = new Group()
    HPBarAnchor.name = 'HPBA'
    head.add(HPBarAnchor)
    HPBarAnchor.position.y += 1.5
    HPBarAnchor.scale.divideScalar(scale * 0.9)

    const mixer = new AnimationMixer(scene)

    // const DeathAction = getAnimationAction(mixer, animations, 'Death')
    // const DefeatAction = getAnimationAction(mixer, animations, 'Defeat')
    const IdleAction = getAnimationAction(mixer, animations, 'Idle')
    // const JumpAction = getAnimationAction(mixer, animations, 'Jump')
    // const PickUpAction = getAnimationAction(mixer, animations, 'PickUp')
    // const PunchAction = getAnimationAction(mixer, animations, 'Punch')
    // const RecieveHitAction = getAnimationAction(mixer, animations, 'RecieveHit')
    // const RollAction = getAnimationAction(mixer, animations, 'Roll')
    // const RunAction = getAnimationAction(mixer, animations, 'Run')
    // const Run_CarryAction = getAnimationAction(mixer, animations, 'Run_Carry')
    // const Shoot_OneHandedAction = getAnimationAction(mixer, animations, 'Shoot_OneHanded')
    // const SitDownAction = getAnimationAction(mixer, animations, 'SitDown')
    // const StandUpAction = getAnimationAction(mixer, animations, 'StandUp')
    // const SwordSlashAction = getAnimationAction(mixer, animations, 'SwordSlash')
    // const VictoryAction = getAnimationAction(mixer, animations, 'Victory')
    const WalkAction = getAnimationAction(mixer, animations, 'Walk')
    // const Walk_CarryAction = getAnimationAction(mixer, animations, 'Walk_Carry')
  
    scene.scale.multiplyScalar(scale)
    // console.log('Infantry', InfantryScene)

    let currentAction = IdleAction
    currentAction.play()

    scene.userData.update = (elapsedMs: number, frameAt : number) => {
      const ent = scene.userData.e as InfantryEntity
      if (shouldEntityShowFadedFrames(ent)) {
        if (currentAction !== IdleAction) {
          currentAction.fadeOut(0.2)
          IdleAction.reset().fadeIn(0.2).play()
          currentAction = IdleAction
        }
      } else {
        const shouldWalk = (frameAt % totalLoopMs > (totalLoopMs / 2))
        if (shouldWalk && currentAction !== WalkAction) {
          currentAction.fadeOut(0.2)
          WalkAction.reset().fadeIn(0.2).play()
          currentAction = WalkAction
        } else if (!shouldWalk && currentAction !== IdleAction) {
          currentAction.fadeOut(0.2)
          IdleAction.reset().fadeIn(0.2).play()
          currentAction = IdleAction
        }
      }
      mixer.update(elapsedMs / 900)
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }
  
    // scene.add(scene)
    applyPlayerColors(scene, ent, pto)

    GLTFSignalPromiseFactory(Quaternius_AssaultRifle2_1_ModelValueModelGLTF).then((gltf) => {
      const AssaultRifleScene = gltf.scene
      scene.add(AssaultRifleScene)
      // console.log('AssaultRifleScene', AssaultRifleScene)

      // console.log('FistR', InfantryScene.getObjectByName('FistR'))
      // console.log('FistL', InfantryScene.getObjectByName('FistL'))
      // console.log('FistL', InfantryScene.get('FistL'))

      const FistL = getBoneByName(scene, 'FistL')
      assertClassInDev(FistL, Bone)
      const FistR = getBoneByName(scene, 'FistR')
      assertClassInDev(FistR, Bone)

      AssaultRifleScene.rotateX(PI / 2)
      AssaultRifleScene.rotateY(-PI / 2)
      AssaultRifleScene.scale.multiplyScalar(1/2)

      // console.log(getBoneNameSet(AssaultRifleScene))
      // console.log((AssaultRifleScene))

      // "Bone",
      // "FootL",
      // "Body_1",
      // "Hips",
      // "Abdomen",
      // "Torso",
      // "Neck",
      // "Head",
      // "ShoulderL",
      // "UpperArmL",
      // "LowerArmL",
      // "FistL",
      // "ShoulderR",
      // "UpperArmR",
      // "LowerArmR",
      // "FistR",
      // "UpperLegL",
      // "LowerLegL",
      // "UpperLegR",
      // "LowerLegR",
      // "PoleTargetL",
      // "FootR",
      // "PoleTargetR",

      // const gripPoint = new Object3D()
      // gripPoint.position.set(2, 0, 2)
      // gripPoint.rotation.set(0, 0, 0)
      // AssaultRifleScene.add(gripPoint)

      FistR!.add(AssaultRifleScene)

      // const gripWorldPosition = new Vector3()
      // const gripWorldQuaternion = new Quaternion()

      // // Get grip point's world position and rotation
      // gripPoint.getWorldPosition(gripWorldPosition)
      // gripPoint.getWorldQuaternion(gripWorldQuaternion)

      // // Set left hand bone to the grip point's position
      // FistL!.position.copy(gripWorldPosition)
      // FistL!.quaternion.copy(gripWorldQuaternion)
      callback(scene)
    })
  })
}

