import { type Component, createEffect, type JSX } from "solid-js"
import { createMutable, modifyMutable, produce, unwrap } from "solid-js/store"
import bindViewCtxToElementV2 from "../lib/bindViewCtxToElementV2"
import bindEngineToComponentV2 from "../lib/canvas/bindEngineToComponentV2"
import createEngineForUI from "../lib/core/engine/createEngineForUI"
import type { Engine } from "../lib/core/engine/Engine.type"
import type { State } from "../lib/core/state/State.type"
import setDuskAttributes from "../lib/dom/setDuskAttribute"
import bindCameraToUserPreferences from "../lib/three/camera/bindCameraToUserPreferences"
import createMainCamera from "../lib/three/fn/createMainCamera"
import createMountedCanvasElement from "../lib/three/fn/createMountedCanvasElement"
import createMountedMainRenderer from "../lib/three/fn/createMountedMainRenderer"
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from "../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect"
import createAuthProfileSignal from "../rx/shared/profile/createAuthProfileSignal"

interface Props {
  state: State,
  width: number,
  height: number,
  style?: JSX.CSSProperties,
}

const MapShowCanvas3D: Component<Props> = (props: Props) => {
  // console.log('MapShowCanvas3D', props)

  const engine: Engine = createMutable(createEngineForUI())
  createEffect(() => {
    // console.log('MapShowCanvas.props.state', 'updated')
    const newState = unwrap(props.state)
    // console.log('newState', newState)
    modifyMutable(engine, produce((engine: Engine) => {
      engine.prevState = engine.state = newState
      engine.actionLogs = []
    }))
  })

  // onMount(() => {
  //   syncEngineWithPropsRow(engine, props.state)
  // })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  const canvasElem = createMountedCanvasElement()
  setDuskAttributes(canvasElem, 'MapShowCanvas3D')
  canvasElem.classList.add('map-show-canvas-3d')
  const renderer = createMountedMainRenderer(canvasElem)
  const camera = createMainCamera(canvasElem)

  bindEngineToComponentV2(engine, canvasElem, renderer, camera)
  bindViewCtxToElementV2(engine, canvasElem, renderer, camera, props)
  bindCameraToUserPreferences(camera)

  return canvasElem
}

export default MapShowCanvas3D
