import { BufferGeometry, DoubleSide, Float32BufferAttribute, Mesh, MeshStandardMaterial, type Color } from "three"
import type { Nullable } from "../../../../typescript"
import type { Engine } from "../../../core/engine/Engine.type"
import { isEntityRiver, isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import addRidges from "./addRidges"
import addSimpleSurfacesV1 from "./addSimpleSurfacesV1"
import addTerrainMesh1TileDataPlaceholder from "./addTerrainMesh1TileDataPlaceholder"
import addVoidSurfaces from "./addVoidSurfaces"
import averageMutualVertices from "./averageMutualVertices"
import createTerrainMesh1TileDataColorTexture from "./createTerrainMesh1TileDataColorTexture"

export type TerrainMesh1TileData = {
  ent: Nullable<Entity>
  edgeIndexes: Array<number>
}

const useVertexColors = true

export default function createTerrainMesh1Mesh(
  engine : Engine,
  terrainEnts: Array<Entity>
) {
  // console.log('createTerrainMesh1Mesh')

  const geometry = new BufferGeometry()
  const vertices: number[] = []
  const colors: number[] = []
  const indices: number[] = []

  const vertexCache = new Map<string, number>()

  function getVertexIndex(x: number, y: number, z: number, color: Color): number {
    const key = `${x}:${y}:${z}`
    if (vertexCache.has(key)) {
      return vertexCache.get(key)!
    }

    const index = vertices.length / 3
    vertices.push(x, y, z)
    colors.push(color.r, color.g, color.b)
    vertexCache.set(key, index)
    return index
  }

  const data = new Map<TilePositionXY, TerrainMesh1TileData>()

  addSimpleSurfacesV1(engine, data, indices, getVertexIndex)
  // addSimpleSurfacesV2(engine, data, indices, getVertexIndex)
  // addSimpleSurfacesV3(engine, data, indices, getVertexIndex)
  // the shoals will share with ocean floor,
  // but not land
  const entsWeWillFillInLater = terrainEnts.filter((ent : Entity) => {
    return isEntityShoal(ent) || isEntityRiver(ent)
  })
  addTerrainMesh1TileDataPlaceholder(engine, data, entsWeWillFillInLater)

  averageMutualVertices(data, indices, colors)

  // clearing the vertex cache so that ridges do not share vertices
  // this prevents color mixing
  vertexCache.clear()
  const ridges = new Set<TilePositionXY>()
  addRidges(ridges, data, indices, getVertexIndex)

  // void surfaces inform the map builder that they missed a spot
  // or maybe a special map wants impassible tiles

  // clearing the vertex cache so that void tiles do not mix colors
  vertexCache.clear()
  addVoidSurfaces(engine, data, indices, getVertexIndex)

  geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
  if (useVertexColors) {
    geometry.setAttribute('color', new Float32BufferAttribute(colors, 3))
  }
  geometry.setIndex(indices)
  geometry.computeVertexNormals()

  if (useVertexColors) {
    const material = new MeshStandardMaterial({
      vertexColors: true,
      side: DoubleSide,
      flatShading: true,
      // wireframe: true,
    })
    material.needsUpdate = true
    return new Mesh(geometry, material)
  } else {
    // const { width: stateWidth, height: stateHeight } = engine.state
    // const w = stateWidth * VERTICES_PER_TILE
    // const h = stateHeight * VERTICES_PER_TILE
    // geometry.setAttribute("uv", new BufferAttribute(new Float32Array([
    //   0, 0,  // Bottom-left
    //   h, 0,  // Bottom-right
    //   0, w,  // Top-left
    //   h, w   // Top-right
    // ]), 2))
    const mapTexture = createTerrainMesh1TileDataColorTexture(engine, data)
    const material = new MeshStandardMaterial({
      map: mapTexture,
      // vertexColors: false,
      side: DoubleSide,
      // flatShading: true,
      // wireframe: true,
    })
    const mesh = new Mesh(geometry, material)
    return mesh
  }
}
