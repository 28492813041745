import { BufferGeometry, NormalBufferAttributes } from 'three'

export type PositionXYZFunctionArg1 = {
  x: number,
  y: number,
  z: number,
  i: number,
}

export default function setGeometryPositionAttributeValues(
  geometry: BufferGeometry<NormalBufferAttributes>,
  fn: (va: PositionXYZFunctionArg1) => void
) {
  const positionAttribute = geometry.getAttribute('position')

  const va : PositionXYZFunctionArg1 = {
    x: 0,
    y: 0,
    z: 0,
    i: 0,
  }

  for (let i = 0; i < positionAttribute.count; i++) {
    va.i = i
    va.x = positionAttribute.getX(i)
    va.y = positionAttribute.getY(i)
    va.z = positionAttribute.getZ(i)
    fn(va)
    positionAttribute.setX(i, va.x)
    positionAttribute.setY(i, va.y)
    positionAttribute.setZ(i, va.z)
  }

  positionAttribute.needsUpdate = true
}
