// export default function onNextTick(callback: (...args) => void, ...args): void {
//   setTimeout(callback, 0, ...args)
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function onNextTick<Args extends any[]>(
  callback: (...args: Args) => void,
  ...args: Args
): void {
  setTimeout(callback, 1, ...args);
}