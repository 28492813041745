import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { ClassicRightToBracket_viewBox_d, ClassicRightToBracket_viewBox_h, ClassicRightToBracket_viewBox_w } from "../../svg/sprites_data_manual"

export const LOAD_CARGO_INTO_TAXI_SPRITE = 'Load'

export default function createLoadCargoIntoTaxiSprite(): Sprite {
  console.log('createLoadCargoIntoTaxiSprite')

  const padding = 32

  // const size = 512 // Size of the canvas
  const outerWidth = ClassicRightToBracket_viewBox_w + (2 * padding)
  const outerHeight =  ClassicRightToBracket_viewBox_h + (2 * padding)

  const canvas = createOffscreenCanvas(outerWidth, outerHeight)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    ctx.fillStyle = '#111'
    ctx.fillRect(0, 0, outerWidth, outerHeight)
    ctx.fillStyle = '#ddd'
    ctx.strokeStyle = '#ddd'
    ctx.lineWidth = 12
    const p2 = padding / 2
    ctx.strokeRect(
      p2,
      p2,
      ClassicRightToBracket_viewBox_w + padding,
      ClassicRightToBracket_viewBox_h + padding)
  
    ctx.translate(padding, padding)
    ctx.fill(ClassicRightToBracket_viewBox_d)
  }

  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({
    map: texture,
    transparent: true,
    opacity: 0.75,
  })
  const sprite = new Sprite(material)
  // sprite.scale.set(5, 5, 1) // Adjust size to match box

  // sprite.userData.vampire = true

  sprite.name = LOAD_CARGO_INTO_TAXI_SPRITE

  return sprite
}
