import type { MyProfilePayload } from '@sg/backend/src/api/profile/handleGetMe'
import { Component, createEffect, JSX, on } from 'solid-js'
import { backendAxios } from '../axios'
import toastError from '../lib/AlertBag/toastError'
import { createCanvasElement } from '../lib/canvas/create_canvas'
import { getCanvas2dContext } from '../lib/canvas/get_canvas_2d_context'
import Unexpected from '../lib/Exception/Unexpected.class'
import createFileIdToObjectUrlSignal from '../lib/sb/storage/createFileIdToObjectUrlSignal'
import { updateAuthProfileValue } from '../rx/shared/profile/authProfileValue'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import { createLoadingSignal } from '../rx/signal/create_loading_signal'
// import { supabase } from '../supabase'

interface Props {
  size: number
  // url: Nullable<string>
  onUpload: (event: Event, filePath: string) => void
}

const Avatar: Component<Props> = (props) => {
  const loading = createLoadingSignal()
  const [authProfile] = createAuthProfileSignal()
  function getAvatarId() {
    return authProfile()?.avatar_id
  }
  const [avatarBlob, setAvatarId] = createFileIdToObjectUrlSignal(getAvatarId())

  createEffect(on(getAvatarId, (newAvatarId) => setAvatarId(newAvatarId)))

  const uploadAvatar: JSX.EventHandler<HTMLInputElement, Event> = async (event) => {
    try {
      loading.start()

      const target = event.currentTarget
      const inputFile = target.files?.[0]
      if (!inputFile) {
        throw new Error('You must select an image to upload.')
      }

      const resizedFile = await fileToImage(inputFile)

      const formData = new FormData()
      formData.append('file', resizedFile)
      formData.append('type', 'avatar')
      const response = await backendAxios.post(`/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })

      const fileId = response.data.id
      if (!fileId) {
        throw new Unexpected('!fileId')
      }

      const payload = {
        avatar_id: fileId,
      }
      await backendAxios.post(`/profile/me`, payload)

      updateAuthProfileValue({
        ...authProfile(),
        ...payload as MyProfilePayload,
      })

      props.onUpload(event, fileId)
    } catch (error) {
      console.error(error)
      toastError(error)
    } finally {
      loading.end()
    }
  }

  return (
    <div style={{ width: `${props.size}px` }}>
      {avatarBlob() ? (
        <img
          src={avatarBlob()!}
          alt={avatarBlob() ? 'Avatar' : 'No image'}
          class="img-thumbnail"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      ) : (
        <div
          class="avatar no-image"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      )}
      <div style={{ width: `${props.size}px` }}>
        <label class="btn btn-primary w-100" for="avatar-upload">
          {loading() ? 'Uploading ...' : 'Upload Avatar'}
        </label>
        <input
          type="file"
          id="avatar-upload"
          accept="image/*"
          onChange={uploadAvatar}
          disabled={loading()}
          hidden
        />
      </div>
    </div>
  )
}

export default Avatar

const maxWidth = 256
const maxHeight = 256

async function fileToImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {

    // this is to stop the user from stubbing their own toe
    if (file && file.size > 25 * 1024 * 1024) { // 25 MB limit
      reject(new Error('File too big.'))
      return
    }

    const img = new Image()
    const url = URL.createObjectURL(file)
    img.src = url

    img.onload = () => {
      const { width, height } = img

      // // Check if image is smaller than required dimensions
      // if (width < maxWidth || height < maxHeight) {
      //   reject(new Error('The image is too small.'))
      // }

      // Create a canvas to resize and crop
      const aspectRatio = width / height
      const targetAspectRatio = 1
      const canvas = createCanvasElement()
      const ctx = getCanvas2dContext(canvas)

      if (!ctx) {
        reject(new Unexpected('!ctx'))
        return
      }

      // Determine the crop dimensions
      let sourceWidth = width
      let sourceHeight = height
      let offsetX = 0
      let offsetY = 0

      if (aspectRatio > targetAspectRatio) {
        // Wider than target aspect ratio
        sourceWidth = height * targetAspectRatio
        offsetX = (width - sourceWidth) / 2
      } else if (aspectRatio < targetAspectRatio) {
        // Taller than target aspect ratio
        sourceHeight = width / targetAspectRatio
        offsetY = (height - sourceHeight) / 2
      }

      // Resize and center crop
      canvas.width = maxWidth
      canvas.height = maxHeight
      ctx.drawImage(
        img,
        offsetX, offsetY, sourceWidth, sourceHeight, // Source
        0, 0, maxWidth, maxHeight // Destination
      )

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to process the image.'))
          return
        }

        // Convert the blob back to a file
        const processedFile = new File([blob], file.name, { type: file.type })

        if (processedFile && processedFile.size > 150 * 1024) {
          reject(new Error('File too big.'))
          return
        }

        resolve(processedFile)
      }, file.type)
    }

    img.onerror = () => reject(new Error('Failed to load the image.'))
  })
}