import { next } from "../../core/rng/index"
import type { RNGConfig } from "../../core/rng/RNGConfig.type"
import type { TilePositionXYList } from "../../core/tile_position_xy/TilePositionXYList.type"
import { floor, hypot } from "../../core/util/math"

export default function generateTreePositions(
  spaceSize: number,
  minTrees: number,
  maxTrees: number,
  margin: number,
  minSpacing: number,
  rng: RNGConfig
): TilePositionXYList {
  const positions: TilePositionXYList = []
  const treeCount = floor(next(rng) * (maxTrees - minTrees + 1)) + minTrees

  const ss2m = (spaceSize - (2 * margin))
  const halfss2m = ss2m / 2

  let maxAttempts = 32

  while (positions.length < treeCount && maxAttempts --> 0) {
    // Generate random position within margin
    const x = (next(rng) * ss2m) - halfss2m
    const y = (next(rng) * ss2m) - halfss2m

    // Check spacing constraint
    const isTooClose = positions.some(
      (pos) => hypot(pos.x - x, pos.y - y) < minSpacing
    )

    if (!isTooClose) {
      positions.push({ x, y })
    }
  }

  // console.table(positions)

  return positions
}