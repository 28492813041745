import { DEV } from "solid-js"
import { Color } from "three"
import Unexpected from "../../../Exception/Unexpected.class"
import type { VertexRGBFunctionStandardMaterialArg1 } from "../../fn/applyVertexFunctionStandardMaterial"

// const debugPinks = [
//   // new Color(0xffc0cb), // Light Pink
//   // new Color(0xff69b4), // Hot Pink
//   // new Color(0xff1493), // Deep Pink
//   // new Color(0xc71585), // Medium Violet Red
//   // new Color(0xb60474), // Medium Violet Red darker

//   [new Color(0xffffff)], // Peak - Snow White
//   [new Color(0xc0c0c0)], // Mid-High - Gray (Exposed Rock)
//   [new Color(0x8d6e63)], // Mid - Rocky Surface
//   new Color(0x6d4c41), // Mid-Low - Lighter Earthy Brown
//   new Color(0x3e2723), // Base - Dark Brown (rock/soil)
// ] as const

type MountainColorPair = {
  // height/elevation
  h: number,
  c: Color,
}

function makeMountainColor(hexCode: number, height: number): MountainColorPair {
  return {
    h: height,
    c: new Color(hexCode),
  }
}

const mountainColors = [
  // makeMountainColor(0xfff8e1, 13),
  // makeMountainColor(0xfdd835, 10),
  // makeMountainColor(0xf9a825, 7),
  // makeMountainColor(0xf57f17, 0),

  // Peak - Snow White
  makeMountainColor(0xffffff, 15),
  // Mid-High - Gray (Exposed Rock)
  makeMountainColor(0xc0c0c0, 14),
  // Mid - Rocky Surface
  makeMountainColor(0x8d6e63, 7),
  // Mid-Low - Lighter Earthy Brown
  makeMountainColor(0x6d4c41, 1),
  // Base - Dark Brown (rock/soil)
  makeMountainColor(0x3e2723, -4),
]

export function getMountainSandColor(height: number): Color {
  let h1 = 0
  let h2 = 0
  let c1 : Color | undefined
  let c2 : Color | undefined
  for (let i = 0; i < mountainColors.length; i++) {
    const { h, c } = mountainColors[i]
    h1 = h
    c1 = c
    if (height >= h) {
      if (c2) {
        return c1.clone().lerp(c2, (height - h1) / (h2 - h1))
      }
      return c1
    }
    h2 = h1
    c2 = c1
  }
  if (DEV && !c2) {
    throw new Unexpected('!c2')
  }
  return c2!
}

// {
//   const d = []
//   range(-5, 20, 0.5).forEach((h) => {
//     d.push({h, c: getMountainSandColor(h).getHexString()})
//   })
//   console.table(d)
// }

export function useMountainSandColors (va: VertexRGBFunctionStandardMaterialArg1): void {
  const { y } = va
  // const { x, y, z } = va
  // const r = sqrt(x * x + z*z)

  // console.log({x: toFixed1(x), y: toFixed1(y), z: toFixed1(z), r: toFixed1(r)}, va)

  const color = getMountainSandColor(y)

  va.r = (color.r)
  va.g = (color.g)
  va.b = (color.b)
}