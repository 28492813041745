import { array, InferOutput, literal, object } from 'valibot'
import { ArmorSchema } from '../../Armor.type'
import { AttackedThisTurnSchema } from '../../AttackedThisTurn'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { FuelSchema } from '../../fuel.type'
import { HasMobilitySchema } from '../../has_mobility'
import { HasXYSchema } from '../../has_xy'
import { HPSchema } from '../../hp.type'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { VisionSchema } from '../../vision.type'
import { WasBuiltThisTurnSchema } from '../../WasBuiltThisTurn'
import { ArmorTypeId } from '../Armor/ArmorTypeId.enum'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'
import { MoveTypeId } from '../move_type_id.enum'
import createAntiAirMissilesWeapon from '../Weapon/createAntiAirMissilesWeapon'
import { WeaponSchema } from '../Weapon/Weapon.type'

const entDefaults = {
  etype_id: EntityTypeId.MediumMissileAntiAir,
  layer_id: EntityLayerId.Unit2,
  hp: 10,
  fuel: 50,
  vision: 2,
  // TODO radar
  // radar acts as vision points
  // that detect helicopters and planes
  // beyound normal vision
  mtype_id: MoveTypeId.Tires,
  mobility: 4,
  // range: 3-5
  armor: 5,
} as const

function defaultWeapons() {
  return [createAntiAirMissilesWeapon(10)]
}

export const MediumMissileAntiAirEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.MediumMissileAntiAir),
  layer_id: literal(EntityLayerId.Unit2),
  id: EntityIdSchema,
  player_id: PlayerIdOrNullSchema,
  hp: HPSchema,
  fuel: FuelSchema,
  vision: VisionSchema,
  armor: ArmorSchema,
  ...HasXYSchema.entries,
  ...HasMobilitySchema.entries,
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  weapons: array(WeaponSchema),
})

export type MediumMissileAntiAirEntity = InferOutput<typeof MediumMissileAntiAirEntitySchema>

export const MediumMissileAntiAirEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.MediumMissileAntiAir,
  dname: 'Medium Missile Anti-Air',
  layer_id: EntityLayerId.Unit2,
  entDefaults,
  price: 120,
  defaultWeapons,
  armor_type: ArmorTypeId.LightVehicle,
}

// AKA Missles
export function createMediumMissileAntiAirEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): MediumMissileAntiAirEntity {
  return {
    ...entDefaults,
    heading: 0,
    weapons: defaultWeapons(),
    id,
    x,
    y,
    player_id,
  }
}
