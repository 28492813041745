import { modifyMutable, produce } from "solid-js/store"
import calculateDraftMoveRangedAttackEstimates from "../../draft_move/calculateDraftMoveRangedAttackEstimates"
import type { DraftMove } from "../../draft_move/DraftMove.type"
import type { Engine } from "../../engine/Engine.type"
import isEntityAtFullMobility from "../../entity/isEntityAtFullMobility"
import unitHasRangedAttackWeapon from "../../entity/unitHasRangedAttackWeapon"
import { SelectedToolId } from "../../map_editor/SelectedToolId.enum"
import { samePosition } from "../../tile_position_xy/samePosition"

export default function handlePreviewRangedAttackOptions(engine: Engine) {
  // console.log('handlePreviewRangedAttackOptions')
  const { draftMove } = engine
  if (!draftMove) {
    return null
  }
  if (shouldOfferPreviewRangedAttackOptions(engine)) {
    modifyMutable(engine, produce((engine : Engine) => {
      if (engine.selectedTool === SelectedToolId.RangedAttackRadius) {
        engine.selectedTool = SelectedToolId.MoveUnit
      } else {
        const newDraftMove : DraftMove = {
          ...draftMove,
          destPosition: null,
          attackPosition: null,
          target: null,
        }
        engine.selectedTool = SelectedToolId.RangedAttackRadius
        engine.draftMove = newDraftMove
        calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
      }
    }))
  }
}

export function shouldOfferPreviewRangedAttackOptions(engine: Engine) {
  // console.log('handlePreviewRangedAttackOptions')
  const { draftMove } = engine
  if (draftMove) {
    const { attackPosition, destPosition, unit } = draftMove

    if (attackPosition) {
      return
    }
    if (engine.selectedTool !== SelectedToolId.MoveUnit) {
      return
    }
    if (!samePosition(destPosition || unit, unit)) {
      return
    }
    if (!isEntityAtFullMobility(unit)) {
      return
    }
    if (!unitHasRangedAttackWeapon(unit)) {
      return
    }
    return true
  }
}