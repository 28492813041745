import { Component } from "solid-js"
import { createMutable } from "solid-js/store"
import bindEngineToComponent from "../../lib/canvas/bindEngineToComponentV1"
import createEngineForUI from "../../lib/core/engine/createEngineForUI"
import { Engine } from "../../lib/core/engine/Engine.type"
import createSampleEntity from "../../lib/core/entity/create_sample_entity"
import { LightTankEntityTypeMeta } from "../../lib/core/entity/EntityType/LightTank"
import { createFirstPlayerIdMemo } from "../../rx/memo/createFirstPlayerIdMemo"
import EngineContext from "../EngineContext"
import SingleSpriteIcon from "./SingleSpriteIcon"

const NewGameIcon: Component = () => {
  const engine: Engine = createMutable(createEngineForUI())
  // need sample player for UI engine
  const samplePlayerId = createFirstPlayerIdMemo(engine)

  bindEngineToComponent(engine)

  return <EngineContext.Provider value={engine}>
    <SingleSpriteIcon
      entity={createSampleEntity(LightTankEntityTypeMeta, samplePlayerId())}
      tileSize={32}
      style={({ "margin-top": `-48px`, "margin-bottom": `-8px` })}
      noBg
    />
  </EngineContext.Provider>
}

export default NewGameIcon
