import type { Vector3 } from "three"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import { VERTICES_PER_TILE } from "../consts"

export default function setVectorV3Position(
  position: Vector3,
  p0: TilePositionXY,
  height: number | null = null,
): void {
  position.x = p0.x * VERTICES_PER_TILE
  position.z = p0.y * VERTICES_PER_TILE
  if (height !== null) {
    position.y = height
  }
}
