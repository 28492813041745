import { DEV } from "solid-js"
import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationForMediumRocketsToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationForMediumRocketsToNextObject3DFrame"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { MediumRockets1_MediumRockets1_GLTF_Model, TrackWheel1_TrackWheel1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"
import onGltfLoaderError from "./onGltfLoaderError"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeMediumRocketsScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(MediumRockets1_MediumRockets1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    // scene.scale.multiplyScalar(0.75)

    const bodyGroup = scene.getObjectByName('Body') as Group
    // console.log('group', group)
    // console.log('bodyMesh', bodyGroup)
    if (DEV && !(bodyGroup instanceof Group)) {
      throw new Error('group.getObjectByName(Body) is null')
    }
    // bodyGroup.position.y += bodyOffsetY

    const turretMesh = scene.getObjectByName('Turret') as Mesh
    assertClassInDev(turretMesh, Mesh)

    const rocketPodMesh = turretMesh.getObjectByName('RocketPod') as Mesh
    if (DEV && !(rocketPodMesh instanceof Mesh)) {
      throw new Error('group.getObjectByName(RocketPod) is null')
    }
    // rocketPodMesh.position.y += bodyOffsetY
    const leftTrackMesh = scene.getObjectByName('LeftTrack') as Mesh
    if (DEV && !(leftTrackMesh instanceof Mesh)) {
      throw new Error('group.getObjectByName(LeftTrack) is null')
    }
    // leftTrackMesh.position.y += bodyOffsetY

    const wheelPositionOffsetY1 = -1.75
    const wheelPositionOffsetY2 = -2.45
    const wheelPositions = [
      [6, wheelPositionOffsetY1, -14.1],

      [6, wheelPositionOffsetY2, -10.55],
      [6, wheelPositionOffsetY2, -7.3],

      [6, wheelPositionOffsetY2, -1.75],
      [6, wheelPositionOffsetY2, 1.75],

      [6, wheelPositionOffsetY2, 7.4],
      [6, wheelPositionOffsetY2, 10.55],

      [6, wheelPositionOffsetY1, 14.1],
    ]

    appGltfLoader.load(TrackWheel1_TrackWheel1_GLTF_Model, (WheelModelObj) => {

      const wheelScaleMult = 1.25
      wheelPositions.forEach(([x, y, z]) => {
        const wheel = WheelModelObj.scene.clone()
        wheel.scale.y *= wheelScaleMult
        wheel.scale.z *= wheelScaleMult
        wheel.position.set(x, y, z)
        leftTrackMesh.add(wheel)
      })
  
      const rightTrackMesh = leftTrackMesh.clone()
      rightTrackMesh.name = 'RightTrack'
      rightTrackMesh.scale.x *= -1
      rightTrackMesh.position.x *= -1
  
      // scene.add(leftTrackMesh)
      scene.add(rightTrackMesh)
  
      scene.position.y += 1

      applyPlayerColors(scene, ent, pto)

      const defaultBodyY = bodyGroup.position.y + 0.2
      const defaultRocketPodY = rocketPodMesh.position.y
      const defaultTurretY = turretMesh.position.y

      bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
        const mod = frameAt % totalLoopMs
        if (shouldEntityShowFadedFrames(ent)) {
          bodyGroup.position.y = defaultBodyY
          rocketPodMesh.position.y = defaultRocketPodY
          turretMesh.position.y = defaultTurretY
        } else if (ent.player_id) {
          const bounceOffset = (cubicEaseInOutBounce(mod / 500) / 7)
          bodyGroup.position.y = defaultBodyY + bounceOffset
          rocketPodMesh.position.y = defaultRocketPodY + bounceOffset
          turretMesh.position.y = defaultTurretY + bounceOffset
        }
        applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
        applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
      }

      turretMesh.userData.update = (elapsedMs: number): void => {
        applyEntityRotationForMediumRocketsToNextObject3DFrame(turretMesh, rocketPodMesh, elapsedMs)
      }

      callback(scene)

    }, undefined, onGltfLoaderError)
  })
}
