import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { PI } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { M247SgtYork1_M247SgtYork1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeMediumGunAntiAirScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(M247SgtYork1_M247SgtYork1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    const bodyMesh = scene.getObjectByName('Body') as Mesh
    assertClassInDev(bodyMesh, Mesh)

    const turretGroup = scene.getObjectByName('Turret') as Group
    assertClassInDev(turretGroup, Group)

    const barrelMesh = scene.getObjectByName('Barrel') as Mesh
    assertClassInDev(barrelMesh, Mesh)

    // turretGroup.position.y -= 2
    // turretGroup.position.y = 2.5
    // barrelMesh.position.y = 0.7
    // const landToLandAttackBarrelRotationX = barrelMesh.rotation.x
    // console.log('landToLandAttackBarrelRotationX', landToLandAttackBarrelRotationX)
    barrelMesh.rotateX(-PI / 7)
    // const landToAirttackBarrelRotationX = barrelMesh.rotation.x
    // console.log('landToAirttackBarrelRotationX', landToAirttackBarrelRotationX)

    // bodyMesh.add(turretGroup)
    // turretGroup.add(barrelMesh)
    // scene.add(bodyMesh)

    const leftTrackMesh = scene.getObjectByName('LeftTrack') as Mesh
    assertClassInDev(leftTrackMesh, Mesh)
    // console.log('leftTrackMesh', leftTrackMesh)

    const rightTrackMesh = leftTrackMesh.clone()
    rightTrackMesh.name = 'RightTrack'

    // scene.add(leftTrackMesh)
    scene.add(rightTrackMesh)

    // rightTrackMesh.position.x = 10
    // rightTrackMesh.position.x *= -1
    rightTrackMesh.scale.x *= -1

    applyPlayerColors(scene, ent, pto)

    const defaultHullY = bodyMesh.position.y
    // const defaultHullX = 0

    bodyMesh.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyMesh.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyMesh.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }

      // barrelMesh.rotation.x = Math.random() > 0.5 ? landToAirttackBarrelRotationX
      //   : landToLandAttackBarrelRotationX

      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    callback(scene)
  })
}
