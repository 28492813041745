import { Component, createEffect, createRoot, DEV, JSX, useContext } from "solid-js"
import { unwrap } from "solid-js/store"
import type { Nullable } from "vite-node"
import { createCanvasElement } from "../../lib/canvas/create_canvas"
import { makeCanvasBlankAndTransparent } from "../../lib/canvas/makeCanvasBlankAndTransparent"
import { Engine } from "../../lib/core/engine/Engine.type"
import type { Entity } from "../../lib/core/entity/index"
import findById from "../../lib/core/findById"
import createRequestAnimationFramePromise from "../../lib/createRequestAnimationFramePromise"
import setDuskAttributes from "../../lib/dom/setDuskAttribute"
import setStyleAttributes from "../../lib/dom/setStyleAttributes"
import Unexpected from "../../lib/Exception/Unexpected.class"
import makeCanvasIntoEntityIcon3D from "../../lib/three/fn/makeCanvasIntoEntityIcon3D"
import createPlayerThreeObject from "../../lib/three/PlayerThreeObject/createPlayerThreeObject"
import { onCleanupDispose } from "../../rx/cleanup/onCleanupDispose"
import EngineContext from "../EngineContext"

interface Props {
  entity: Entity,
  size: number,
  style?: JSX.CSSProperties,
}

// based on SingleTileCanvas
const SingleEntityIcon3D: Component<Props> = (props: Props) => {
  // console.log('SingleEntityIcon3D', props)

  const engine: Engine = useContext(EngineContext)

  // const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)
  // const getPlayerThreeObject = createSharedPlayerThreeObjectsMap(engine)

  // const threeCtx = engine.threeCtx
  // if (!threeCtx) {
  //   // eslint-disable-next-line solid/components-return-once
  //   return <div>No Render Context</div>
  // }
  // const { scene } = threeCtx

  const canvasElem = createCanvasElement()
  setDuskAttributes(canvasElem, 'SingleEntityIcon3D')
  canvasElem.classList.add('sprite-icon')
  canvasElem.classList.add('entity-icon')

  let prevRender: Nullable<ReturnType<typeof makeCanvasIntoEntityIcon3D>> = null
  createEffect(() => {
    // eslint-disable-next-line solid/reactivity
    const { size, entity, style } = props
    const player = findById(engine.state.players, entity.player_id)

    const p = (prevRender || createRequestAnimationFramePromise())
    p.then(() => {
      const dpr = window.devicePixelRatio || 1
      if (DEV) {
        if (!size) {
          throw new Unexpected('!size')
        }
        if (!(size % 2 === 0)) {
          throw new Unexpected('size must be even because dpr might not be whole number')
        }
        if (!entity) {
          throw new Unexpected('!entity')
        }
      }
      const ent = unwrap(entity)
      createRoot(() => {
        const pto = createPlayerThreeObject(player)
        onCleanupDispose(pto.material)
        const sizeHiDPI = size * dpr
        canvasElem.width = canvasElem.height = sizeHiDPI
        makeCanvasBlankAndTransparent(canvasElem)

        const sizepx = `${size}px`
        const styles = {
          ...style,
          width: sizepx,
          height: sizepx,
        }
        setStyleAttributes(canvasElem, styles)
        prevRender = makeCanvasIntoEntityIcon3D(engine, canvasElem, ent, pto)
      })
    })
  })

  return canvasElem
}

export default SingleEntityIcon3D
