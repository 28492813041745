import { Color, Group, Mesh, MeshBasicMaterial, PlaneGeometry, Texture } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import createRenderMoveTileRedCanvasBufferV2 from "../../canvas/draw/tile/createRenderMoveTileRedCanvasBufferV2"
import type { Engine } from "../../core/engine/Engine.type"
import coord from "../../core/tile_position_xy/coord"
import { PI } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"
import setObjPosition from "./setObjPosition"

const tileSize = VERTICES_PER_TILE

const defaultPositionY = 2.1

const attackTileRedBuffer = createOffscreenCanvas(1, 1)
createRenderMoveTileRedCanvasBufferV2(attackTileRedBuffer, 128)

export default function createIsPositionInRangedAttackRangeGroup (
  engine: Engine,
  isPositionInRangedAttackRange: (x: number, y: number) => boolean
) {
  // console.log('createIsPositionInRangedAttackRangeGroup')
  const { width: stateWidth, height: stateHeight } = engine.state
  const group = new Group()

  for (let stateX = 0; stateX < stateWidth; stateX++) {
    for (let stateY = 0; stateY < stateHeight; stateY++) {
      if (isPositionInRangedAttackRange(stateX, stateY)) {
        const statePosition = coord(stateX, stateY)
        // console.log('isPositionInRangedAttackRange(stateX, stateY)', statePosition)
        const geometry = new PlaneGeometry(tileSize * 0.9, tileSize * 0.9)
        const colorMap = new Texture(attackTileRedBuffer)
        colorMap.needsUpdate = true
        const material = new MeshBasicMaterial({
          color: new Color(0xff0000),
          map: colorMap,
          // alphaMap: colorMap,
          // alphaTest: 0.5,
          transparent: true,
          // transparent: 0.5,
          opacity: 0.65,
          fog: false,
          reflectivity: 0,
        })
        const plane = new Mesh(geometry, material)
        // plane.receiveShadow = true
        plane.rotateX(-PI / 2)

        group.add(plane)

        setObjPosition(plane, statePosition, defaultPositionY)
      }
    }
  }

  return group
}