import { Mesh, MeshBasicMaterial, PlaneGeometry, Scene } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { PI } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"

// this did not work lol
export default function createRayCastTargetPlaneMesh(
  engine: Engine,
  scene: Scene
) {
  const { width: stateWidth, height: stateHeight } = engine.state
  const geometry = new PlaneGeometry(1, 1,
    stateWidth,
    stateHeight,
  )

  const material = new MeshBasicMaterial()
    // transparent: true,
    // opacity: 0.2,
  // })
  material.visible = false

  // Create the terrain plane
  const mesh = new Mesh(geometry, material)
  mesh.rotateX(-PI / 2) // Rotate the plane to lay flat
  scene.add(mesh)

  mesh.scale.x = stateWidth * VERTICES_PER_TILE
  mesh.scale.y = stateHeight * VERTICES_PER_TILE

  // mesh.position.x -= stateWidth / 2
  mesh.position.x = ((stateWidth-1) * VERTICES_PER_TILE) / 2
  mesh.position.z = ((stateHeight-1) * VERTICES_PER_TILE) / 2
  mesh.position.y = 0

  mesh.name = 'RayCastTarget'
  return mesh
}