import { isEntityRiver, isEntitySea } from "./entityTypeMetaList.generated"
import type { Entity } from "./index"
import type { WaterEntityUnion } from "./WaterEntityUnion.type"

/**
 * requirements
 * - A "water" entity is one that which a shoal entity will "dip" into.
 * 
 * @param entity 
 * @returns boolean
 */
export default function isEntityWater (entity: Entity): entity is WaterEntityUnion {
  return isEntitySea(entity) || isEntityRiver(entity)
}