import { A } from '@solidjs/router'
import { FaSolidCentSign } from 'solid-icons/fa'
import { createMemo, For, Show } from 'solid-js'
import { createMutable } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import SingleEntityIcon3D from '../../../components/MapEditor/SingleEntityIcon3D'
import NA from '../../../components/Util/na'
import bindEngineToComponent from '../../../lib/canvas/bindEngineToComponentV1'
import createEngineForUI from '../../../lib/core/engine/createEngineForUI'
import { Engine } from '../../../lib/core/engine/Engine.type'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import getMoveTypeMetaById from '../../../lib/core/entity/getMoveTypeMetaById'
import hasAtLeastOneWeapon from '../../../lib/core/entity/hasAtLeastOneWeapon'
import { HasMoveTypeId } from '../../../lib/core/entity/move_type_id.enum'
import type { MoveTypeMeta } from '../../../lib/core/entity/MoveTypeMeta.type'
import getWeaponTypeMeta from '../../../lib/core/entity/Weapon/getWeaponTypeMeta'
import { EntityTypeMeta, isSpriteUnit } from '../../../lib/core/EntityTypeMeta'
import type { HasArmor } from '../../../lib/core/HasArmor'
import type { HasWeapons } from '../../../lib/core/HasWeapons'
import formatGameMoney from '../../../lib/core/player/formatGameMoney'
import { toSlug } from '../../../lib/slug'
import { createFirstPlayerIdMemo } from '../../../rx/memo/createFirstPlayerIdMemo'
import { Nullable } from '../../../typescript'
import { createSharedPlayerThreeObjectsMap } from '../../../rx/memo/createSharedPlayerThreeObjectsMap'

const CodexUnitIndexPage = () => {

  // console.log('CodexUnitIndexPage')

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteUnit(sprite))

  const engine: Engine = createMutable(createEngineForUI())
  const samplePlayerId = createFirstPlayerIdMemo(engine)

  bindEngineToComponent(engine)
  // no idea what, but this page crashes
  // if you don't pre-fill the PTO cache
  createSharedPlayerThreeObjectsMap(engine)

  return (
    <EngineContext.Provider value={engine}>
      <div class="pagec">
        <h2>
          <A href="/codex">Codex</A>{' - '}
          Units
        </h2>
        <table class="table table-sm">
          <thead>
            <tr>
              <td />
              <td>Unit</td>
              <td class="text-end">Price</td>
              <td class="text-end">Armor</td>
              <td class="text-end">Movement</td>
              <td class="small text-muted">Type</td>
              <td class="text-end">Fuel</td>
              <td class="text-end">Vision</td>
              <th class="text-end">Transport</th>
              <td class="text-center">Weapon</td>
            </tr>
          </thead>
          <tbody>
            <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
              {(entityType: EntityTypeMeta) => {
                const entDefaults = entityType.entDefaults
                const mtype: Nullable<MoveTypeMeta> = getMoveTypeMetaById((entDefaults as HasMoveTypeId).mtype_id)
                const isTransport = (entityType?.transports?.length as number) > 0

                const sampleEntity = createMemo(() => {
                  return createSampleEntity(entityType, samplePlayerId())
                })

                return <tr>
                  <td>
                    <SingleEntityIcon3D entity={sampleEntity()} size={32} />
                  </td>
                  <td>
                    <A href={`/codex/unit/${toSlug(entityType.dname)}`}>
                      {entityType.dname}
                    </A>
                  </td>
                  <td class="text-end">
                    <span class="text-muted ps-1"><FaSolidCentSign /></span>
                    {formatGameMoney(entityType.price)}
                  </td>
                  <td class="text-end">
                    {((entDefaults as HasArmor).armor > 0)
                      ? (entDefaults as HasArmor).armor : NA()}
                  </td>
                  <td class="text-end">
                    {('mobility' in entDefaults) ? (entDefaults.mobility as number) : NA()}
                  </td>
                  <td class="small text-muted">
                    {mtype && mtype?.dname}
                  </td>
                  <td class="text-end">
                    {('fuel' in entDefaults) ? (entDefaults.fuel as number) : NA()}
                  </td>
                  <td class="text-end">
                    {('vision' in entDefaults) ? (entDefaults.vision as number) : NA()}
                  </td>
                  <td class="text-end">
                    {isTransport ? 'Yes' : NA()}
                  </td>
                  <td class="">
                    <Show when={hasAtLeastOneWeapon(sampleEntity()) && (sampleEntity() as HasWeapons).weapons}
                      children={(weapons) => {
                        return (
                          <ul><For each={weapons()} children={(weapon) => {
                            console.log('weapon', weapon.wt_id, weapon)
                            const weaponTypeMeta = getWeaponTypeMeta(weapon.wt_id)
                            return (<div class="small">
                              {weaponTypeMeta.dname}
                              <Show when={weapon.minRange || weapon.maxRange}
                                children={<span class="ps-1 text-monospace text-muted" title="range">[{weapon.minRange},{weapon.maxRange}]</span>}
                              />
                            </div>)
                          }} /></ul>)
                      }}
                    />
                  </td>
                </tr>
              }}
            </For>
          </tbody>
        </table>
      </div>
    </EngineContext.Provider>
  )
}

export default CodexUnitIndexPage