import setMapControlsPolarAngle from "../../../three/fn/setMapControlsPolarAngle"
import type { Engine } from "../../engine/Engine.type"

export const SET_CAMERA_DEFAULT_POLAR_ANGLE_TOP_DOWN = 0

export default function handleSetCameraPolarAngleTopDown(
  engine: Engine,
) {
  // console.log('handleSetCameraPolarAngleTopDown')
  const { threeCtx } = engine
  if (threeCtx) {
    const { mc: mapControls } = threeCtx
    setMapControlsPolarAngle(mapControls, SET_CAMERA_DEFAULT_POLAR_ANGLE_TOP_DOWN)
    mapControls.update()
  }
}

export function shouldOfferSetCameraPolarAngleDefault(
  // engine: Engine
) {
  return false
}