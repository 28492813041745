import { Mesh } from "three"
import type { Entity } from "../../core/entity/index"
import tmpRng from "../../core/rng/tmpRng"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { lightReconWheelModelValue } from "./lightReconWheelModelValue"

export default function makeLightReconWheelScene (
  ent: Entity,
  pto: PlayerThreeObject
): typeof lightReconWheelModelValue['scene'] {
  const { material: baseMaterial } = pto
  tmpRng.seed = ent.id

  const lightReconWheelScene = lightReconWheelModelValue.scene.clone()

  // console.log('lightReconWheelScene', lightReconWheelScene)
  // const model = lightReconWheelScene.getObjectByName('LightRecon') as Mesh
  lightReconWheelScene.traverse((obj) => {
    if (obj instanceof Mesh) {
      if (obj.material) {
        const PC = obj.material.userData.PC
        if (PC) {
          // console.log('obj.material', obj.material)
          obj.material.color.set(baseMaterial.color)
        }
      }
    }
  })

  return lightReconWheelScene
}
