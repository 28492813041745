import { Group, type Object3D, type Object3DEventMap } from "three"
import type { EntityId } from "../../core/entity/EntityId.type"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import type { Nullable } from "vite-node"
import type { FrameUpdateCallback } from "./FrameUpdateCallback.type"

export type EntityThreeModelCollection = {
  group: Group,
  ents: Map<EntityId, EntityThreeModel>
}

let elapsedMs: number = 0
let timestampMs : number = 0

function callAllUserDataUpdates (child : Object3D<Object3DEventMap>) {
  const update : Nullable<FrameUpdateCallback> = child.userData.update
  if (update) {
    update(elapsedMs, timestampMs)
  }
}

export default function callAllUserDataUpdatesRecursive(
  obj : Object3D,
  inputElapsedMs: number,
  inputTimestampMs : number,
): void {
  elapsedMs = inputElapsedMs
  timestampMs = inputTimestampMs
  obj.traverse(callAllUserDataUpdates)
}
