import { Color } from "three"
import type { VertexRGBFunctionStandardMaterialArg1 } from "../../fn/applyVertexFunctionStandardMaterial"

// Light sand at the top of sand dunes (sunlit, dry)
const sandDuneTop = new Color(0xF7DC8A)

// General beach sand (typical warm golden sand)
const beachSand = new Color(0xF4D03F)

// Wet sand near the waterline (darker due to moisture)
const wetSand = new Color(0xD4AC0D)

// Undersea sand (submerged, slightly muted in color)
const underseaSand = new Color(0xB7950B)

// Deeper sea sand (darker, less light penetration)
const deepSeaSand = new Color(0x7E5109)

export function getShoalSandColor(y: number): Color {
  if (y > 1) return sandDuneTop
  if (y > 0) return beachSand
  if (y > -0.5) return wetSand
  if (y > -1) return underseaSand
  return deepSeaSand
}

export function useShoalSandColors (va: VertexRGBFunctionStandardMaterialArg1): void {
  const { y } = va

  const color = getShoalSandColor(y)

  va.r = (color.r)
  va.g = (color.g)
  va.b = (color.b)
}