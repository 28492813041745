import type { Nullable } from "../../typescript"
import { deepClone } from "../deep_clone"
import arrayDot from "./arrayDot"
import prettyJsonStringifyText from "./prettyJsonStringifyText"
import shallowObjectDiff from "./shallowObjectDiff"

/**
 * Compares two objects shallowly and returns their differences as a string.
 * @param obj1 - The first object.
 * @param obj2 - The second object.
 * @returns An string for dumping to console.
 */
export default function prettyPlainObjectDiffText<T extends Nullable<Record<string, unknown>>>(
  obj1: T, obj2: T
) {
  const ad1 = arrayDot(deepClone(obj1)) || {}
  const ad2 = arrayDot(deepClone(obj2)) || {}
  const diff = shallowObjectDiff(ad1, ad2)

  return prettyJsonStringifyText(diff)
}