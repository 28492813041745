import { Color, ConeGeometry, InstancedMesh, MeshLambertMaterial } from "three"

const darkGreenLeafColor = new Color(0x1B5E20); // Dark green foliage
// const mediumGreenLeafColor = new Color(0x2E7D32) // Medium green foliage 

export default function createGreenTreeLeavesInstancedMesh (count: number) {
  const w = 2
  const h = 5
  const geometry = new ConeGeometry(w, h, 7, 1)

  const material = new MeshLambertMaterial({
    color: darkGreenLeafColor,
    // color: mediumGreenLeafColor,
    // roughness: 0.7,
    // metalness: 0.0,
    // flatShading: true,
    // wireframe: true,
  })
  const mesh = new InstancedMesh(geometry, material, count)
  mesh.position.y += 2 + (h / 2)
  return mesh
}