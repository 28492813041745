import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { EntityHeadingSchema, type EntityHeading } from '../../../../entity/EntityHeading/EntityHeading.type'
import { EntityIdSchema, type EntityId } from '../../../../entity/EntityId.type'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import type { ActionLog } from '../../ActionLog.type'
import { ActionType } from '../ActionType'

export const RotateEntityActionSchema = object({
  type: literal(ActionType.Map.RotateEntity),
  entityId: EntityIdSchema,
  heading: EntityHeadingSchema,
})

export type RotateEntityAction = InferOutput<typeof RotateEntityActionSchema>

export function createRotateEntityAction(
  entityId: EntityId,
  heading: EntityHeading,
): RotateEntityAction {
  return {
    type: ActionType.Map.RotateEntity,
    entityId,
    heading,
  }
}

export async function handleRotateEntityAction(
  engine: Engine,
  action: RotateEntityAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionLog: ActionLog
): Promise<void> {
  const { entityId, heading } = action

  const ent = findByIdOrThrow(engine.state.ents, entityId)

  console.log('[handleRotateEntityAction] ent.heading = heading', ent.heading, heading)
  ent.heading = heading
}
