import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { SolidPaintBrush_d, SolidPaintBrush_h, SolidPaintBrush_w } from "../../svg/sprites_data_manual"

export const INSPECT_CURSOR_OBJECT3D_NAME = 'Paint'

export default function createPaintCursorSprite(): Sprite {
  console.log('createPaintCursorSprite')

  // const size = 512 // Size of the canvas
  const canvas = createOffscreenCanvas(SolidPaintBrush_w, SolidPaintBrush_h)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    ctx.fillStyle = 'white'

    // // Set text properties
    // const text = 'Paint'
    // context.font = '48px Arial'
    // context.textAlign = 'center'
    // context.textBaseline = 'middle'
    // context.fillStyle = 'rgba(0,0,0,0.25)'
    // context.fillRect(0, 0, size, size)
    // const lines = text.split('\n')
    // const lineHeight = floor(size / (lines.length + 1))
    // lines.forEach((line, index) => {
    //   context.fillText(line, size / 2, (index + 1) * lineHeight)
    // })

    ctx.fill(SolidPaintBrush_d)
  }

  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({ map: texture, transparent: true })
  const sprite = new Sprite(material)
  // sprite.scale.set(5, 5, 1) // Adjust size to match box

  // sprite.userData.vampire = true

  sprite.name = INSPECT_CURSOR_OBJECT3D_NAME

  return sprite
}
