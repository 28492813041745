// TODO convert this to not have global state

import isNil from "../../ldsh/isNil"
import hashXYToNumber from "./hashXYToNumber"
import { next } from "./index"
import type { RNGConfig } from "./RNGConfig.type"

type XY = {x: number, y: number}

function rand_vect(rng : RNGConfig): XY {
  const theta = next(rng) * 2 * Math.PI
  return {x: Math.cos(theta), y: Math.sin(theta)}
}
function dot_prod_grid(
  gradients: PerlinNoiseGradientMap,
  x: number, y: number,
  vx: number, vy: number,
  rng : RNGConfig): number {
  const d_vect = {x: x - vx, y: y - vy}
  // const key : string = [vx, vy].join(',')
  const key = hashXYToNumber(vx, vy)
  let g_vect = gradients.get(key)
  if (!g_vect) {
      g_vect = rand_vect(rng)
      gradients.set(key, g_vect)
  }
  return d_vect.x * g_vect.x + d_vect.y * g_vect.y
}
function smootherstep(x: number): number {
  return 6*x**5 - 15*x**4 + 10*x**3
}
function interp(x: number, a: number, b: number): number {
  return a + smootherstep(x) * (b-a)
}
// export function resetPerlinNoiseMemory():void {
//   gradients.clear()
//   memory.clear()
// }

type PerlinNoiseGradientMap = Map<number, XY>
type PerlinNoiseMemoryMap = Map<number, number>

export function createPerlinNoise2D(rng : RNGConfig) {
  const gradients : PerlinNoiseGradientMap = new Map()
  const memory : PerlinNoiseMemoryMap = new Map()
  
  return function getPerlinNoise2D(x: number, y: number): number {
    // const key : string = [x, y].join(',')
    const key = hashXYToNumber(x, y)
  
    let value = memory.get(key)
  
    if (isNil(value)) {
      const xf = Math.floor(x)
      const yf = Math.floor(y)
      //interpolate
      const tl = dot_prod_grid(gradients, x, y, xf,   yf, rng)
      const tr = dot_prod_grid(gradients, x, y, xf+1, yf, rng)
      const bl = dot_prod_grid(gradients, x, y, xf,   yf+1, rng)
      const br = dot_prod_grid(gradients, x, y, xf+1, yf+1, rng)
      const xt = interp(x-xf, tl, tr)
      const xb = interp(x-xf, bl, br)
      value = interp(y-yf, xt, xb)
      memory.set(key, value)
    }
  
    return value
  }
}