import { type Texture, CanvasTexture, RepeatWrapping, UVMapping } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import type { RNGConfig } from "../../core/rng/RNGConfig.type"
import { nextNumberRange } from "../../core/rng/index"

export default function createFadedAOMapTexture(rng: RNGConfig, size: number = 16): Texture {
  const canvas = createOffscreenCanvas(size, size)
  canvas.width = size
  canvas.height = size
  const ctx = getCanvas2dContext(canvas)!
  
  const imageData = ctx.createImageData(size, size)
  for (let y = 0; y < size; y++) {
    for (let x = 0; x < size; x++) {
      const index = (y * size + x) * 4;
      // const noise = Math.random() * 50 + 100 // Random shades of gray
      // const noise = nextNumberRange(0, 50, rng) + 80 // Random shades of gray
      const noise = nextNumberRange(0, 127, rng) // Random shades of gray
      imageData.data[index] = noise      // Red
      imageData.data[index + 1] = noise  // Green
      imageData.data[index + 2] = noise  // Blue
      imageData.data[index + 3] = 255    // Alpha
    }
  }
  ctx.putImageData(imageData, 0, 0)
  const texture = new CanvasTexture(canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    // LinearFilter, // default
    // NearestFilter, // pixelated
    // LinearMipmapLinearFilter,
  )
  texture.name = 'FadedAOMap'
  return texture
}
