import type { EntityThreeModel } from "./EntityThreeModel.type"

export default function createEntityThreeModel(
  ent: EntityThreeModel['ent'],
  obj: EntityThreeModel['obj'] = null
): EntityThreeModel {
  return {
    h: 0,
    g: 0,
    ent,
    obj,
  }
}