import type { Color, MeshStandardMaterial } from "three"

export default function setEmissiveWindowMaterial(
  material: MeshStandardMaterial,
  emissiveColor: Color
): void {
  // console.log('expecting MeshStandardMaterial', material)
  material.emissive = emissiveColor // Emissive color
  material.emissiveIntensity = 10 // Controls brightness
  material.roughness = 0.5
  material.metalness = 0.1
}
