import { Key } from "@solid-primitives/keyed"
import { FaSolidTrashCan, FaSolidUserPlus } from 'solid-icons/fa'
import { Accessor, Component, createMemo, useContext } from "solid-js"
import { Engine } from '../../lib/core/engine/Engine.type'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import { MAX_PLAYERS } from '../../lib/core/player/consts'
import createMapEditorPlayer from '../../lib/core/player/createMapEditorPlayer'
import { Player } from '../../lib/core/player/Player.type'
import playerDisplayName from "../../lib/core/player/playerDisplayName"
import { createAddPlayerAction } from '../../lib/core/state/flux/action/Map/AddPlayerAction'
import { createDeletePlayerAction } from '../../lib/core/state/flux/action/Map/DeletePlayerAction'
import { createPatchPlayerAction } from '../../lib/core/state/flux/action/Map/PatchPlayerAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import Button from "../../lib/jsx/Button"
import { createOneOrZeroSignal } from '../../rx/signal/create_one_or_zero_signal'
import EngineContext from '../EngineContext'
import SpriteCountTable from './SpriteCountTable'

const PlayersWidget: Component = () => {
  const engine: Engine = useContext(EngineContext)

  // const [track, dirty] = engine.cache
  const isPlayersTool = createMemo<boolean>(() => {
    // console.log('isPlayersTool.memo', engine.selectedTool, SelectedToolId.Players, engine.selectedTool === SelectedToolId.Players)
    // track(EngineCache.SelectedToolId)
    // console.log('engine.state.players', engine.state.players)
    return engine.selectedTool === SelectedToolId.Players
  })
  const atMaxPlayerCount = createMemo<boolean>(() => {
    return engine.state.players.length >= MAX_PLAYERS
  })

  function addPlayer(): void {
    // console.log('PlayersWidget.addPlayer()')
    if (!atMaxPlayerCount()) {
      dispatchClient(engine, createAddPlayerAction(createMapEditorPlayer(engine.state, engine.state.rng)))
    }
  }

  // const collapsingAnimationMs = 350
  function togglePlayer(player: Player): void {
    // console.log('PlayersWidget.setPlayer()')
    if (engine.selectedPlayerId === player.id) {
      engine.selectedPlayerId = null
    } else {
      engine.selectedPlayerId = player.id
    }
  }

  return <>
    {isPlayersTool() && <div id="MapEditorPlayersWidget" class="card-body">
      <h5 class="card-title clearfix">
        Players
        <Button class="btn btn-sm btn-secondary float-end" name="add_player" onClick={addPlayer} disabled={atMaxPlayerCount()}
        >
          <FaSolidUserPlus />&nbsp;Add Player
        </Button>
      </h5>

      <div class="accordion" id="accordionExample">
        <Key each={engine.state.players} by="id">
          {(player: Accessor<Player>) => {
            const player_id_string = `player_${player()?.id || 'null'}`
            const [isCollapsing] = createOneOrZeroSignal(0)
            const accordianCollapseClass = createMemo<string>(() => {
              // need to call both for tracking to work
              const isCollapsingValue = isCollapsing()
              if (engine.selectedPlayerId === player()?.id) {
                if (isCollapsingValue) {
                  return "accordion-collapse collapsing show"
                }
                return "accordion-collapse collapse show"
              } else if (isCollapsingValue) {
                return "accordion-collapse collapsing"
              }
              return "accordion-collapse collapse"
            })

            return <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class={engine.selectedPlayerId === player()?.id ? "accordion-button" : "accordion-button collapsed"}
                  type="button"
                  aria-expanded={engine.selectedPlayerId === player()?.id ? "true" : "false"}
                  aria-controls={player_id_string}
                  onClick={() => { togglePlayer(player()) }}
                >
                  {playerDisplayName(player()) || 'Unknown'}&nbsp;
                  {player()?.color1 && <span style={{ color: `${player()?.color1} !important` }}>&#9632;</span>}
                </button>
              </h2>
              <div
                id={player_id_string}
                class={accordianCollapseClass()}
                data-bs-parent={"#accordionExample"}
              >
                <div class="accordion-body">
                  <p><span class="small muted">ID:</span> <span class="small font-monospace">{player()?.id}</span></p>
                  <input value={player()?.dname as string | undefined} type="text"
                    onInput={(event: InputEvent) => {
                      dispatchClient(engine, createPatchPlayerAction(player()?.id, {
                        dname: (event.target as HTMLInputElement)?.value
                      }))
                    }}
                    placeholder="Display Name" class="form-control" />
                  <SpriteCountTable player={player()} />

                  <Button class="btn btn-sm btn-outline-danger" name="del_player" onClick={() => dispatchClient(engine, createDeletePlayerAction(player().id))}>
                    Delete Player&nbsp;<FaSolidTrashCan />
                  </Button>

                </div>
              </div>
            </div>
          }}
        </Key>
      </div>
    </div>}
  </>
}

export default PlayersWidget

