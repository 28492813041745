import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { APC_M113_APC_M113_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"
const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeAPCScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(APC_M113_APC_M113_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj
    // console.log('scene', scene)

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)
    // bodyGroup.scale.multiplyScalar(1)

    const tracksMesh = scene.getObjectByName('Tracks') as Mesh
    assertClassInDev(tracksMesh, Mesh)
    // tracksMesh.scale.multiplyScalar(1)

    // const wheelScaleX = 3.8
    // const wheelFrontOffsetY = 9.2
    // const wheelBackOffsetY = 8.4

    const defaultHullY = bodyGroup.position.y
    // const defaultHullX = 0

    bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyGroup.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    applyPlayerColors(scene, ent, pto)

    callback(scene)
  })
}
