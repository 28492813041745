import { Group, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { diagonalDirections } from "../../core/tile_position_xy/dijkstraDirections"
import { PI } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { LightRecon1_LightRecon1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"
import makeLightReconWheelScene from "./makeLightReconWheelScene"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeLightReconScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  // const { material: baseMaterial } = pto
  return appGltfLoader.load(LightRecon1_LightRecon1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj

    const bodyGroup = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyGroup, Group)

    const wheelScaleX = 2.4
    const wheelFrontOffsetY = 5.5
    const wheelBackOffsetY = 5

    const wheelRH = 0.2

    diagonalDirections.forEach((direction) => {
      const wheelScene = makeLightReconWheelScene(ent, pto)
      const isFrontWheel = direction.dy > 0
      const isLeftWheel = direction.dx < 0
      wheelScene.scale.divideScalar(1.8)

      if (isLeftWheel) {
        wheelScene.rotation.y = PI
      }
      const wheelPosition = wheelScene.position

      wheelPosition.set(wheelScaleX * direction.dx, wheelRH, (isFrontWheel ? wheelFrontOffsetY : wheelBackOffsetY) * direction.dy)

      scene.add(wheelScene)
    })

    const defaultHullY = bodyGroup.position.y
    // const defaultHullX = 0

    bodyGroup.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyGroup.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyGroup.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    applyPlayerColors(scene, ent, pto)

    callback(scene)
  })
}
