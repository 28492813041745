import { fallback, maxValue, minValue, number, parse, pipe } from "valibot"
import clamp from "../../../../lib/core/util/clamp"
import { getDeviceUserPreference, setDeviceUserPreference } from "./deviceUserPreferencesSignal"

export const PERSPECTIVE_CAMERA_FOV_STEP : number = 5
export const PERSPECTIVE_CAMERA_FOV_MIN : number = 10
export const PERSPECTIVE_CAMERA_FOV_MAX : number = 175
export const PERSPECTIVE_CAMERA_FOV_DEFAULT : number = 40

export const CameraFovSchema = fallback(pipe(number(),
  minValue(PERSPECTIVE_CAMERA_FOV_MIN),
  maxValue(PERSPECTIVE_CAMERA_FOV_MAX),
  ), PERSPECTIVE_CAMERA_FOV_DEFAULT)

export function updateCameraFov(newValue : number): void {
  newValue = clamp(newValue,
    PERSPECTIVE_CAMERA_FOV_MIN,
    PERSPECTIVE_CAMERA_FOV_MAX)

  // console.log('updateFov', getDeviceUserPreference().fov, '->', newValue)

  const parsedNewValue = parse(CameraFovSchema, newValue)

  setDeviceUserPreference({
    ...getDeviceUserPreference(),
    fov: parsedNewValue,
  })
}
  