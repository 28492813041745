import type { Nullable } from "../../../typescript"
import type { EntityList } from "../state/EntityList.type"
import { POSTGRES_MAX_INT } from "../util/number"
import { EntityLayerId } from "./entity_layer_id.enum"
import { isEntityBuilding, isEntityFactory, isEntityForest, isEntityHQ, isEntityMountain, isEntityRoad } from "./entityTypeMetaList.generated"
import type { Entity } from "./index"

export default function getEntityRenderHeight (stack : EntityList, ent1: Nullable<Entity> = null): number {
  const ent1LayerId = ent1?.layer_id || POSTGRES_MAX_INT
  for (const ent2 of stack) {
    if (ent2.layer_id < ent1LayerId) {
      if (isEntityMountain(ent2)) {
        return 12
      }
      if (isEntityForest(ent2)) {
        return 2
      }
      if (isEntityHQ(ent2)) {
        return 17
      }
      if (isEntityBuilding(ent2)) {
        return 10
      }
      if (isEntityFactory(ent2)) {
        return 10
      }
      if (isEntityRoad(ent2)) {
        return 2
      }
    }
  }
  if ((ent1?.layer_id as number) > EntityLayerId.TerrainL1) {
    return 1
  }
  return 0
}