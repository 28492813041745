/**
 * This file is automatically generated.
 **/
import type { EntityTypeMeta } from '../EntityTypeMeta'
import { EntityTypeId } from './EntityTypeId.enum'
import type { Entity } from './index'

import {
  APCEntityTypeMeta,
  APCEntitySchema,
  APCEntity,
} from './EntityType/APC'
import {
  BuildingEntityTypeMeta,
  BuildingEntitySchema,
  BuildingEntity,
} from './EntityType/Building'
import {
  DesertEntityTypeMeta,
  DesertEntitySchema,
  DesertEntity,
} from './EntityType/Desert'
import {
  FactoryEntityTypeMeta,
  FactoryEntitySchema,
  FactoryEntity,
} from './EntityType/Factory'
import {
  ForestEntityTypeMeta,
  ForestEntitySchema,
  ForestEntity,
} from './EntityType/Forest'
import {
  HQEntityTypeMeta,
  HQEntitySchema,
  HQEntity,
} from './EntityType/HQ'
import {
  InfantryEntityTypeMeta,
  InfantryEntitySchema,
  InfantryEntity,
} from './EntityType/Infantry'
import {
  LightReconEntityTypeMeta,
  LightReconEntitySchema,
  LightReconEntity,
} from './EntityType/LightRecon'
import {
  LightTankEntityTypeMeta,
  LightTankEntitySchema,
  LightTankEntity,
} from './EntityType/LightTank'
import {
  MediumArtillaryEntityTypeMeta,
  MediumArtillaryEntitySchema,
  MediumArtillaryEntity,
} from './EntityType/MediumArtillary'
import {
  MediumCopterEntityTypeMeta,
  MediumCopterEntitySchema,
  MediumCopterEntity,
} from './EntityType/MediumCopter'
import {
  MediumGunAntiAirEntityTypeMeta,
  MediumGunAntiAirEntitySchema,
  MediumGunAntiAirEntity,
} from './EntityType/MediumGunAntiAir'
import {
  MediumMissileAntiAirEntityTypeMeta,
  MediumMissileAntiAirEntitySchema,
  MediumMissileAntiAirEntity,
} from './EntityType/MediumMissileAntiAir'
import {
  MediumRocketsEntityTypeMeta,
  MediumRocketsEntitySchema,
  MediumRocketsEntity,
} from './EntityType/MediumRockets'
import {
  MediumTankEntityTypeMeta,
  MediumTankEntitySchema,
  MediumTankEntity,
} from './EntityType/MediumTank'
import {
  MountainEntityTypeMeta,
  MountainEntitySchema,
  MountainEntity,
} from './EntityType/Mountain'
import {
  PlainEntityTypeMeta,
  PlainEntitySchema,
  PlainEntity,
} from './EntityType/Plain'
import {
  RiverEntityTypeMeta,
  RiverEntitySchema,
  RiverEntity,
} from './EntityType/River'
import {
  RoadEntityTypeMeta,
  RoadEntitySchema,
  RoadEntity,
} from './EntityType/Road'
import {
  SeaEntityTypeMeta,
  SeaEntitySchema,
  SeaEntity,
} from './EntityType/Sea'
import {
  ShoalEntityTypeMeta,
  ShoalEntitySchema,
  ShoalEntity,
} from './EntityType/Shoal'
import {
  SnowEntityTypeMeta,
  SnowEntitySchema,
  SnowEntity,
} from './EntityType/Snow'
import {
  TransportCopterEntityTypeMeta,
  TransportCopterEntitySchema,
  TransportCopterEntity,
} from './EntityType/TransportCopter'

const entityTypeMetaList : Array<EntityTypeMeta> = [
  APCEntityTypeMeta,
  BuildingEntityTypeMeta,
  DesertEntityTypeMeta,
  FactoryEntityTypeMeta,
  ForestEntityTypeMeta,
  HQEntityTypeMeta,
  InfantryEntityTypeMeta,
  LightReconEntityTypeMeta,
  LightTankEntityTypeMeta,
  MediumArtillaryEntityTypeMeta,
  MediumCopterEntityTypeMeta,
  MediumGunAntiAirEntityTypeMeta,
  MediumMissileAntiAirEntityTypeMeta,
  MediumRocketsEntityTypeMeta,
  MediumTankEntityTypeMeta,
  MountainEntityTypeMeta,
  PlainEntityTypeMeta,
  RiverEntityTypeMeta,
  RoadEntityTypeMeta,
  SeaEntityTypeMeta,
  ShoalEntityTypeMeta,
  SnowEntityTypeMeta,
  TransportCopterEntityTypeMeta,
]

export const entitySchemaList = [
  APCEntitySchema,
  BuildingEntitySchema,
  DesertEntitySchema,
  FactoryEntitySchema,
  ForestEntitySchema,
  HQEntitySchema,
  InfantryEntitySchema,
  LightReconEntitySchema,
  LightTankEntitySchema,
  MediumArtillaryEntitySchema,
  MediumCopterEntitySchema,
  MediumGunAntiAirEntitySchema,
  MediumMissileAntiAirEntitySchema,
  MediumRocketsEntitySchema,
  MediumTankEntitySchema,
  MountainEntitySchema,
  PlainEntitySchema,
  RiverEntitySchema,
  RoadEntitySchema,
  SeaEntitySchema,
  ShoalEntitySchema,
  SnowEntitySchema,
  TransportCopterEntitySchema,
]

export type EntitySchemaUnion = (typeof entitySchemaList)[number]


export type EntityUnion = APCEntity | BuildingEntity | DesertEntity | FactoryEntity | ForestEntity | HQEntity | InfantryEntity | LightReconEntity | LightTankEntity | MediumArtillaryEntity | MediumCopterEntity | MediumGunAntiAirEntity | MediumMissileAntiAirEntity | MediumRocketsEntity | MediumTankEntity | MountainEntity | PlainEntity | RiverEntity | RoadEntity | SeaEntity | ShoalEntity | SnowEntity | TransportCopterEntity


export function isEntityAPC (entity: Entity): entity is APCEntity {
  return entity.etype_id === EntityTypeId.APC
}

export function isEntityBuilding (entity: Entity): entity is BuildingEntity {
  return entity.etype_id === EntityTypeId.Building
}

export function isEntityDesert (entity: Entity): entity is DesertEntity {
  return entity.etype_id === EntityTypeId.Desert
}

export function isEntityFactory (entity: Entity): entity is FactoryEntity {
  return entity.etype_id === EntityTypeId.Factory
}

export function isEntityForest (entity: Entity): entity is ForestEntity {
  return entity.etype_id === EntityTypeId.Forest
}

export function isEntityHQ (entity: Entity): entity is HQEntity {
  return entity.etype_id === EntityTypeId.HQ
}

export function isEntityInfantry (entity: Entity): entity is InfantryEntity {
  return entity.etype_id === EntityTypeId.Infantry
}

export function isEntityLightRecon (entity: Entity): entity is LightReconEntity {
  return entity.etype_id === EntityTypeId.LightRecon
}

export function isEntityLightTank (entity: Entity): entity is LightTankEntity {
  return entity.etype_id === EntityTypeId.LightTank
}

export function isEntityMediumArtillary (entity: Entity): entity is MediumArtillaryEntity {
  return entity.etype_id === EntityTypeId.MediumArtillary
}

export function isEntityMediumCopter (entity: Entity): entity is MediumCopterEntity {
  return entity.etype_id === EntityTypeId.MediumCopter
}

export function isEntityMediumGunAntiAir (entity: Entity): entity is MediumGunAntiAirEntity {
  return entity.etype_id === EntityTypeId.MediumGunAntiAir
}

export function isEntityMediumMissileAntiAir (entity: Entity): entity is MediumMissileAntiAirEntity {
  return entity.etype_id === EntityTypeId.MediumMissileAntiAir
}

export function isEntityMediumRockets (entity: Entity): entity is MediumRocketsEntity {
  return entity.etype_id === EntityTypeId.MediumRockets
}

export function isEntityMediumTank (entity: Entity): entity is MediumTankEntity {
  return entity.etype_id === EntityTypeId.MediumTank
}

export function isEntityMountain (entity: Entity): entity is MountainEntity {
  return entity.etype_id === EntityTypeId.Mountain
}

export function isEntityPlain (entity: Entity): entity is PlainEntity {
  return entity.etype_id === EntityTypeId.Plain
}

export function isEntityRiver (entity: Entity): entity is RiverEntity {
  return entity.etype_id === EntityTypeId.River
}

export function isEntityRoad (entity: Entity): entity is RoadEntity {
  return entity.etype_id === EntityTypeId.Road
}

export function isEntitySea (entity: Entity): entity is SeaEntity {
  return entity.etype_id === EntityTypeId.Sea
}

export function isEntityShoal (entity: Entity): entity is ShoalEntity {
  return entity.etype_id === EntityTypeId.Shoal
}

export function isEntitySnow (entity: Entity): entity is SnowEntity {
  return entity.etype_id === EntityTypeId.Snow
}

export function isEntityTransportCopter (entity: Entity): entity is TransportCopterEntity {
  return entity.etype_id === EntityTypeId.TransportCopter
}


export default entityTypeMetaList