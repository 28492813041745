import { DEV } from "solid-js"
import { type Object3D } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import entityDisplayName from "../../core/entity/entityDisplayName"
import { isEntityAPC, isEntityBuilding, isEntityFactory, isEntityForest, isEntityHQ, isEntityInfantry, isEntityLightRecon, isEntityLightTank, isEntityMediumArtillary, isEntityMediumCopter, isEntityMediumGunAntiAir, isEntityMediumMissileAntiAir, isEntityMediumRockets, isEntityMediumTank, isEntityMountain, isEntityRiver, isEntityRoad, isEntityShoal, isEntityTransportCopter } from "../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../core/entity/index"
import Unexpected from "../../Exception/Unexpected.class"
import makeSingleTileMountainObject3D from "../objects/Mountain/makeSingleTileMountainObject3D"
import makeRiverForIconObject3D from "../objects/River/makeRiverForIconObject3D"
import makeShoalForIconObject3D from "../objects/Shoal/makeShoalForIconObject3D"
import makeSingleTileTerrainMesh1Object3D, { isEntityTerrainMesh1 } from "../objects/TerrainMesh1V1/makeSingleTileTerrainMesh1Object3D"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import createForestGroup from "./createForestGroup"
import makeAPCScene from "./makeAPCScene"
import makeBuildingObject3D from "./makeBuildingObject3D"
import makeFactoryObject3D from "./makeFactoryObject3D"
import makeHQObject3D from "./makeHQObject3D"
import makeInfantryScene from "./makeInfantryScene"
import makeLightReconScene from "./makeLightReconScene"
import makeLightTankObject3D from "./makeLightTankObject3D"
import makeMediumArtilleryScene from "./makeMediumArtilleryScene"
import makeMediumCopterScene from "./makeMediumCopterScene"
import makeMediumGunAntiAirScene from "./makeMediumGunAntiAirScene"
import makeMediumMissileAntiAirScene from "./makeMediumMissileAntiAirScene"
import makeMediumRocketsScene from "./makeMediumRocketsScene"
import makeMediumTankScene from "./makeMediumTankScene"
import makePlaceHolderUnitGroup from "./makePlaceHolderUnitGroup"
import makeRoadMeshFromRoadEntity from "./makeRoadMeshFromRoadEntity"
import makeTransportCopterScene from "./makeTransportCopterScene"

export default function makeEntityGroup (
  engine: Engine,
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  if (isEntityInfantry(ent)) {
    return makeInfantryScene(ent, pto, callback)
  }
  if (isEntityLightRecon(ent)) {
    return makeLightReconScene(ent, pto, callback)
  }
  if (isEntityLightTank(ent)) {
    return makeLightTankObject3D(ent, pto, callback)
  }
  if (isEntityMediumTank(ent)) {
    return makeMediumTankScene(ent, pto, callback)
  }
  if (isEntityMediumArtillary(ent)) {
    return makeMediumArtilleryScene(ent, pto, callback)
  }
  if (isEntityMediumGunAntiAir(ent)) {
    return makeMediumGunAntiAirScene(ent, pto, callback)
  }
  if (isEntityAPC(ent)) {
    return makeAPCScene(ent, pto, callback)
  }
  if (isEntityTransportCopter(ent)) {
    return makeTransportCopterScene(ent, pto, callback)
  }
  if (isEntityMediumCopter(ent)) {
    return makeMediumCopterScene(ent, pto, callback)
  }
  if (isEntityMediumRockets(ent)) {
    return makeMediumRocketsScene(ent, pto, callback)
  }
  if (isEntityMediumMissileAntiAir(ent)) {
    return makeMediumMissileAntiAirScene(ent, pto, callback)
  }
  if (isEntityRoad(ent)) {
    return makeRoadMeshFromRoadEntity(ent, callback)
  }
  if (isEntityBuilding(ent)) {
    return makeBuildingObject3D(ent, pto, callback)
  }
  if (isEntityFactory(ent)) {
    return makeFactoryObject3D(ent, pto, callback)
  }
  if (isEntityHQ(ent)) {
    return makeHQObject3D(ent, pto, callback)
  }

  if (isEntityForest(ent)) {
    return callback(createForestGroup(ent))
  }

  if (isEntityShoal(ent)) {
    return makeShoalForIconObject3D(callback)
  }

  // these are intended for icons only
  if (isEntityTerrainMesh1(ent)) {
    return makeSingleTileTerrainMesh1Object3D(ent, callback)
  }
  if (isEntityMountain(ent)) {
    return makeSingleTileMountainObject3D(ent, callback)
  }
  if (isEntityRiver(ent)) {
    return makeRiverForIconObject3D(callback)
  }

  if (DEV) {
    throw new Unexpected(`Unknown entity type: ${(ent as Entity).etype_id} ${entityDisplayName(ent)}`)
  }
  return makePlaceHolderUnitGroup(ent, pto, callback)
}

