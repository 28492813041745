import { DEV } from "solid-js"
import { Quaternion, Vector3, type CatmullRomCurve3, type Object3D } from "three"
import { radToDeg } from "three/src/math/MathUtils.js"
import { parse } from "valibot"
import type { Nullable } from "../../../typescript"
import type { Engine } from "../../core/engine/Engine.type"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import type { PathTurnSteps_Step } from "../../core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type"
import normalizeDeg from "../../core/util/normalizeDeg"
import isNotNil from "../../ldsh/isNotNil"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import updateEntityThreeModel from "../EntityThreeModel/updateEntityThreeModel"

export type Object3DWalkFn = {
  next: (elapsedMs: number) => void,
  reset: () => void,
}

export default function createObject3DWalk(
  engine: Engine,
  entityThreeModel: EntityThreeModel,
  curve: CatmullRomCurve3,
  lastStep: Nullable<PathTurnSteps_Step>,
  stepsLen: number,
  speed: number,
): Nullable<Object3DWalkFn> {
  // console.log('[createObject3DWalk] createObject3DWalk()')
  const { obj, ent } = entityThreeModel
  if (obj) {
    const curveLen = curve.getLength()
    // const points = curve.getPoints()
    let t = 0
    let arrived = false
    const originalPosition = obj.position.clone()
    const originalRotation = obj.rotation.clone()

    const speedMod = (3 + curveLen) / (5000 * (stepsLen + 1))
    // console.log({ curveLen, stepsLen, speedMod })

    // the next function will override obj.rotation,
    // this is to preserve the heading after the animation
    const destHeading = lastStep?.heading
    // console.log('[createObject3DWalk] destHeading = ', destHeading)
    if (isNotNil(destHeading)) {
      // console.log('[createObject3DWalk] obj.userData.targetHeading =', destHeading)
      obj.userData.targetHeading = destHeading
    }

    const walk = {
      reset(): void {
        // only reset if the current walk is still active
        if (obj.userData.walk === walk) {
          // console.log('[createObject3DWalk].reset() obj.position', {
          //   x: obj.position.x,
          //   y: obj.position.z,
          // }, '->', {
          //   x: originalPosition.x,
          //   y: originalPosition.z,
          // })
          obj.position.copy(originalPosition)
          // console.log('[createObject3DWalk].reset() obj.rotation', radToDeg(obj.rotation.y), '->', radToDeg(originalRotation.y))
          // obj.rotation.copy(originalRotation)
          obj.rotation.y = originalRotation.y
          obj.userData.walk = null
          updateEntityThreeModel(entityThreeModel, ent, engine)
        }
      },
      next(elapsedMs: number): void {
        // console.log('walk()', t)
        if (arrived) {
          // we're done, but need to leave the function
          // so walk.reset can be called later.
          // obj.userData.walk = null
        } else {
          if (t > 1) {
            arrived = true
            t = 1
          }
          const point = curve.getPointAt(t)
          obj.position.copy(point)

          applyQuaternionFromCurve(curve, t, obj)
          const nextHeading = normalizeDeg(radToDeg(obj.rotation.y))
          // console.log('nextHeading', nextHeading)
          if (DEV) {
            parse(DegreeNumberSchema, nextHeading)
          }
  
          // console.log('[createObject3DWalk] walk.next', {
          //   prevHeading: obj.userData.heading,
          //   nextHeading,
          //   destHeading,
          // })
          obj.userData.targetHeading = nextHeading
    
          // cleanup
          t += (speed * speedMod) * elapsedMs
        }
      },
    }
    return walk
  }
}

// const up = new Vector3(0, 1, 0) // Use Y-up to maintain level orientation

function applyQuaternionFromCurve(
  curve: CatmullRomCurve3,
  t: number,
  obj: Object3D
): void {
  const tangent = curve.getTangentAt(t).normalize()

  // Project tangent onto the X-Z plane (remove Y component)
  const projectedTangent = new Vector3(tangent.x, 0, tangent.z).normalize()

  // Apply rotation using a stable 'up' direction
  const quaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 0, 1), projectedTangent)
  obj.quaternion.copy(quaternion)
  // quaternion.
  // obj.quaternion.normalize()
  // obj.rotation.setFromQuaternion(quaternion)
}
