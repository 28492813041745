import type { Object3D } from "three"
import type { Nullable } from "../../../typescript"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import isNotNil from "../../ldsh/isNotNil"
import { VERTICES_PER_TILE } from "../consts"

export default function setObjPosition(
  obj: Object3D,
  p0: TilePositionXY,
  height: Nullable<number> = null
): void {
  obj.position.x = p0.x * VERTICES_PER_TILE
  obj.position.z = p0.y * VERTICES_PER_TILE
  if (isNotNil(height)) {
    obj.userData.y = height
    obj.position.y = height
  }
}
