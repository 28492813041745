import { object, type InferOutput } from "valibot"
import { AntiAliasSchema } from "./AntiAlias"
import { CameraFarSchema } from "./CameraFar"
import { CameraFovSchema } from "./CameraFov"
import { HiDPISchema } from "./HiDPI"
import { PowerPreferenceSchema } from "./PowerPreference"

export const DeviceUserPreferenceSchema = object({
  antialias: AntiAliasSchema,
  camera_far: CameraFarSchema,
  camera_fov: CameraFovSchema,
  powerPreference: PowerPreferenceSchema,
  HiDPI: HiDPISchema,
})

export type DeviceUserPreference = InferOutput<typeof DeviceUserPreferenceSchema>
