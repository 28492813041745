import { createNoise2D } from "simplex-noise"
import { type Texture, CanvasTexture, LinearMipmapLinearFilter, RepeatWrapping, UVMapping } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { next } from "../../core/rng/index"
import type { RNGConfig } from "../../core/rng/RNGConfig.type"
import { floor } from "../../core/util/math"

// Define a color palette for the camouflage
const camoColors = [
  // 34,  // Dark grey
  // 85,  // Medium grey
  // 136, // Light grey
  // 187, // Very light grey

  127,
  191,
  255,
]

export default function createCamoMapTexture(
  rng: RNGConfig,
  size: number = 256,
  noiseScale: number = 0.025): Texture {
  const canvas = createOffscreenCanvas(size, size)
  canvas.width = size
  canvas.height = size
  const ctx = getCanvas2dContext(canvas)!

  // Initialize Simplex noise with the RNG seed
  const noise2D = createNoise2D(() => next(rng))

  // Define the scale of the noise (controls the size of the splotches)
  // const noiseScale = 0.025

  // Generate the camouflage pattern
  const imageData = ctx.createImageData(size, size)
  for (let y = 0; y < size; y++) {
    for (let x = 0; x < size; x++) {
      // Calculate noise value for this pixel
      const noiseValue = noise2D(x * noiseScale, y * noiseScale)

      // Map the noise value to a color index
      const colorIndex = floor((noiseValue + 1) * 0.5 * camoColors.length)
      const color = camoColors[colorIndex % camoColors.length]

      // Set the pixel color
      const index = (y * size + x) * 4
      imageData.data[index] = color     // Red
      imageData.data[index + 1] = color // Green
      imageData.data[index + 2] = color // Blue
      imageData.data[index + 3] = 255   // Alpha
    }
  }

  // Draw the image data onto the canvas
  ctx.putImageData(imageData, 0, 0)

  // Create and return the texture
  const texture = new CanvasTexture(canvas, UVMapping, RepeatWrapping, RepeatWrapping)
  texture.name = 'CamoMap'
  texture.minFilter = LinearMipmapLinearFilter
  return texture
}