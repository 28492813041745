import { DirectionalLight } from "three"

export function createDirectionalLight() {
  // const directionalLight = new DirectionalLight(0xffffff, 1)
  // const directionalLight = new DirectionalLight(0xffe08a, 0.1)
  const directionalLight = new DirectionalLight(0xffe08a, 1)
  directionalLight.position.set(5, 10, -5) // Simulates the sun angle
  directionalLight.castShadow = true // Enable shadows
  directionalLight.shadow.mapSize.width = 2048
  directionalLight.shadow.mapSize.height = 2048

  // createMountedInverval(() => {
  //   directionalLight.visible = !directionalLight.visible
  //   // directionalLight.intensity = directionalLight.intensity === 1.2 ? 2 : 1.2
  // }, 1000)

  return directionalLight
}