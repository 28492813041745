import { Group, Vector3, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { PI } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { CrotaleADMS1_CrotaleADMS1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import cubicEaseInOutBounce from "./cubicEaseInOutBounce"
import makeLightReconWheelScene from "./makeLightReconWheelScene"

const totalLoopMs = 1000

// const heights = [1, 2, 2, 3, 3, 4]

export default function makeMediumMissileAntiAirScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(CrotaleADMS1_CrotaleADMS1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj
    // scene.scale.multiplyScalar(0.9)

    const bodyMesh = scene.getObjectByName('Body') as Group
    assertClassInDev(bodyMesh, Group)

    const turretGroup = scene.getObjectByName('Turret') as Group
    assertClassInDev(turretGroup, Group)

    const barrelGroup = scene.getObjectByName('Barrel') as Group
    assertClassInDev(barrelGroup, Group)

    // bodyMesh.position.y += 0.5

    barrelGroup.rotateX(-PI / 7)

    const wheelX = 3.4
    const wheelZ = 0.8
    const wheelVectorsList : Array<Vector3> = [
      new Vector3(wheelX, wheelZ, -6.5),
      new Vector3(wheelX, wheelZ, -1.05),
      new Vector3(wheelX, wheelZ, 4.5),
      new Vector3(-wheelX, wheelZ, -6.5),
      new Vector3(-wheelX, wheelZ, -1.05),
      new Vector3(-wheelX, wheelZ, 4.5),
    ]

    const wheelScaleX = 0.6

    wheelVectorsList.forEach((v) => {
      const isLeftWheel = v.x < 0
      const wheelScene = makeLightReconWheelScene(ent, pto)

      wheelScene.scale.multiplyScalar(wheelScaleX)

      if (isLeftWheel) {
        wheelScene.rotation.y = PI
      }

      wheelScene.position.copy(v)
      // wheelScene.position = v
      // const wheelPosition = wheelScene.position

      // wheelPosition.set(v.x, 0, v.z)

      scene.add(wheelScene)
    })

    applyPlayerColors(scene, ent, pto)

    const defaultHullY = bodyMesh.position.y
    // const defaultHullX = 0

    bodyMesh.userData.update = (elapsedMs: number, frameAt: number): void => {
      const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        bodyMesh.position.y = defaultHullY
      } else if (ent.player_id) {
        bodyMesh.position.y = defaultHullY + (cubicEaseInOutBounce(mod / 500) / 7)
      }

      // barrelMesh.rotation.x = Math.random() > 0.5 ? landToAirttackBarrelRotationX
      //   : landToLandAttackBarrelRotationX
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    callback(scene)
  })
}

