import type { Color } from "three"
import { isEntityRiver, isEntitySea, isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import coord from "../../../core/tile_position_xy/coord"
import { dijkstraDirections } from "../../../core/tile_position_xy/dijkstraDirections"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import { VERTICES_PER_TILE } from "../../consts"
import { getTerrainMesh1RidgeColor } from "./colors"
import type { TerrainMesh1TileData } from "./createTerrainMesh1Mesh"
import getTerrainMesh1TileElevation from "./getTerrainMesh1TileElevation"

function isEntitySeaOrShoalOrRiver(ent: Entity) {
  return isEntitySea(ent)
  || isEntityShoal(ent)
  || isEntityRiver(ent)
}


export default function addRidges(
  ridges: Set<TilePositionXY>,
  data : Map<TilePositionXY, TerrainMesh1TileData>,
  indices: Array<number>,
  getVertexIndex: (x: number, y: number, z: number, color: Color) => number,
): void {
  const tileSize = VERTICES_PER_TILE
  const halfSize = tileSize / 2
  data.forEach((data0, p0: TilePositionXY) => {
    const { ent: ent0 } = data0
    if (ent0) {
      for (const dir of dijkstraDirections) {
        const { dx, dy } = dir
        const p1 = coord(p0.x + dx, p0.y + dy)
        const data1 = data.get(p1)
        if (data1) {
          const { ent: ent1 } = data1

          if (ent1 && isEntitySeaOrShoalOrRiver(ent0) && isEntitySeaOrShoalOrRiver(ent1)) {
            continue
          }

          const e0 = getTerrainMesh1TileElevation(ent0)
          const e1 = getTerrainMesh1TileElevation(ent1)
          if (e0 !== e1) {
            const x0 = (p0.x + p1.x) / 2
            const y0 = (p0.y + p1.y) / 2
            const ridgePosition = coord(x0, y0)
            if (!ridges.has(ridgePosition)) {
              ridges.add(ridgePosition)

              const ridgeColor = getTerrainMesh1RidgeColor(ent0, ent1)

              const i0 = getVertexIndex((tileSize * x0) - (halfSize * dy), (tileSize * -y0) - (halfSize * dx), e0, ridgeColor)
              const i1 = getVertexIndex((tileSize * x0) + (halfSize * dy), (tileSize * -y0) + (halfSize * dx), e0, ridgeColor)
              const i2 = getVertexIndex((tileSize * x0) + (halfSize * dy), (tileSize * -y0) + (halfSize * dx), e1, ridgeColor)
              const i3 = getVertexIndex((tileSize * x0) - (halfSize * dy), (tileSize * -y0) - (halfSize * dx), e1, ridgeColor)

              indices.push(i0, i1, i2)
              indices.push(i0, i2, i3)
            }
          }
        }
      }
    }
  })
}
