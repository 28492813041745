import { Scene, type Camera } from 'three'
import type { EngineThreeContext } from './ThreeContext'
import createThreeMapControls from './createThreeMapControls'

export default function createThreeContext(
  canvasRef: HTMLCanvasElement,
  camera: Camera
): EngineThreeContext {

  const mapControls = createThreeMapControls(canvasRef, camera)

  const threeCtx : EngineThreeContext = {
    scene: new Scene(),
    camera,
    mc: mapControls,
    // grse: null as unknown as EngineThreeContext['grse'],
  }

  return threeCtx
}
