import { array, fallback, number, object, optional, type InferOutput } from "valibot"
import { ArmorSchema } from "../Armor.type"
import { AttackedThisTurnSchema } from "../AttackedThisTurn"
import { FuelSchema } from "../fuel.type"
import { HPSchema } from "../hp.type"
import { OptionalLiteralTrueSchema } from "../LiteralTrue"
import { MobilityNumberSchema } from "../MobilityNumber.type"
import { PlayerIdOrNullSchema } from "../player/PlayerIdOrNull"
import { VisionSchema } from "../vision.type"
import { WaitedThisTurnSchema } from "../WaitedThisTurn"
import { WasBuiltThisTurnSchema } from "../WasBuiltThisTurn"
import { EntityLayerIdSchema } from "./entity_layer_id.enum"
import { EntityHeadingSchema } from "./EntityHeading/EntityHeading.type"
import { EntityIdSchema, OptionalNullableEntityIdSchema } from "./EntityId.type"
import { EntityIdListSchema } from "./EntityIdList.type"
import { EntityTypeIdSchema } from "./EntityTypeId.enum"
import { MoveTypeIdSchema } from "./move_type_id.enum"
import { WeaponSchema } from "./Weapon/Weapon.type"

export const BaseEntitySchema = object({
  etype_id: EntityTypeIdSchema,
  layer_id: EntityLayerIdSchema,
  id: EntityIdSchema,
  player_id: optional(PlayerIdOrNullSchema),
  hp: optional(HPSchema),
  fuel: optional(FuelSchema),
  vision: optional(VisionSchema),
  armor: optional(ArmorSchema),
  x: optional(number()),
  y: optional(number()),
  mobility: optional(MobilityNumberSchema),
  mtype_id: optional(MoveTypeIdSchema),
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  waitedThisTurn: WaitedThisTurnSchema,
  weapons: optional(array(WeaponSchema)),
  // transport and cargo
  cargo: optional(EntityIdListSchema),
  taxi_id: OptionalNullableEntityIdSchema,
  destroyed: OptionalLiteralTrueSchema,
  annexed: OptionalLiteralTrueSchema,
  hasError: OptionalLiteralTrueSchema,
  // heading: fallback(EntityHeadingSchema, 0),
  heading: fallback(EntityHeadingSchema, () => {
    // const r = Math.random()
    // if (r > 0.75) {
    //   return 270
    // }
    // if (r > 0.5) {
    //   return 180
    // }
    // if (r > 0.25) {
    //   return 90
    // }
    return 0
  }),
})

export type BaseEntity = InferOutput<typeof BaseEntitySchema>
