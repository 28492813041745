import { isEntityMediumCopter, isEntityTransportCopter } from "../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../core/entity/index"

export default function getEntityExtraRenderHeight(ent: Entity) {
  if (isEntityMediumCopter(ent)) {
    return 2
  }
  if (isEntityTransportCopter(ent)) {
    return 2
  }
  return 0
}
