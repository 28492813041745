import type { Color, MeshBasicMaterial, MeshStandardMaterial } from "three"
import entityDisplayName from "../../core/entity/entityDisplayName"
import { isEntityInfantry } from "../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../core/entity/index"

export default function logEntityMaterialColorChange(
  ent: Entity,
  material: MeshStandardMaterial | MeshBasicMaterial,
  newColor: Color
): void {

  if (!isEntityInfantry(ent)) {
    return
  }

  const newColorHex = newColor.getHexString()
  const oldColorHex = material.color.getHexString()

  console.log(
    `${entityDisplayName(ent)} ${material.name} oldColor ${oldColorHex} %c ■%c newColor ${newColorHex} %c■`,
    `color: #${oldColorHex};`,
    `color: unset;`,
    `color: #${newColorHex};`,
  )
}