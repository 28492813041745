import { DEV, onCleanup, onMount } from 'solid-js'
import Unexpected from './Exception/Unexpected.class'

export default function onElementEvent<K extends keyof HTMLElementEventMap>(
  elem: HTMLElement,
  name: K,
  fn: (this: HTMLElement, ev: HTMLElementEventMap[K]) => void | null | boolean,
  options?: boolean | AddEventListenerOptions
): void {
  onMount(() => {
    if (elem) {
      elem.addEventListener(name, fn, options)
    } else if (DEV) {
      console.error(new Unexpected('!elem'))
    }
  })
  onCleanup(() => {
    // the if statement is necessary because elem can become null
    // after the fact, despite being a valid reference earlier.
    if (elem) {
      elem.removeEventListener(name, fn, options)
    }
  })
}
