import { createEffect } from "solid-js"
import { DoubleSide, Group, Mesh, MeshBasicMaterial, PlaneGeometry, Vector3 } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { dijkstraDirections, type DirectionDxDy } from "../../core/tile_position_xy/dijkstraDirections"
import { PI } from "../../core/util/math"
import { VERTICES_PER_TILE } from "../consts"

const bottomY = 40
export default function createMapEditorEdgeWallsGroupV2(
  engine : Engine,
): void {
  // console.log('createMapEditorEdgeWallsGroupV2')
  const { threeCtx } = engine
  // console.log('threeCtx', threeCtx, engine)
  if (threeCtx) {
    const { scene } = threeCtx

    const group : Group = new Group()

    const dirGroupMap = new Map<DirectionDxDy, Mesh>

    const geometry = new PlaneGeometry(1, 1)
    const material = new MeshBasicMaterial({
      // color: 0xffff00,
      // opacity: 0.25,
      side: DoubleSide,
      transparent: true,
      color: 0x000000,
      // opacity: 0.25,
    })

    const whDiv = 4

    for (const dir of dijkstraDirections) {
      const { dx, dy } = dir
      const mesh = new Mesh(geometry, material)
      if (dy !== 0) {
        mesh.rotateZ(PI / 2)
      } else {
        mesh.rotateY(PI / 2)
      }
      mesh.position.set(dx, bottomY, dy)
      mesh.scale.multiply(new Vector3(
        VERTICES_PER_TILE / whDiv,
        VERTICES_PER_TILE / whDiv,
        VERTICES_PER_TILE))
      group.add(mesh)

      dirGroupMap.set(dir, mesh)
    }

    // console.log('group', group)

    const mult = VERTICES_PER_TILE
    createEffect(() => {
      const { width, height } = engine.state
      // console.log('effect', {width, height})

      const offsetX = ((width - 1) * VERTICES_PER_TILE / 2)
      const offsetZ = ((height - 1) * VERTICES_PER_TILE / 2)

      dirGroupMap.forEach((mesh, dir: DirectionDxDy) => {
        const { dx, dy  } = dir
        if (dy !== 0) {
          // mesh.position.z = mult * dy * (width/2)
          mesh.position.z = (mult * dy * (width/2)) + offsetZ
          mesh.position.x = offsetX
          // console.log('dy', dy, mesh.position.z)
          mesh.scale.y = mult * dy * width
        } else {
          // mesh.position.x = mult * dx * (height/2)
          mesh.position.x = (mult * dx * (height/2)) + offsetX
          mesh.position.z = offsetZ
          mesh.scale.x = mult * dx * height
        }
        mesh.position.y = 0
      })
    })

    scene.add(group)
  }
}
