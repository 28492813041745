import createEngineRoot from './createEngineRoot'
import { EngineMode } from './EngineMode/EngineMode.enum'

// placeholder for declaring intent
const createEngineForMapEditor = function () {
  return {
    ...createEngineRoot(),
    mode: EngineMode.MapEditor,
  }
}

export default function () {
  const engine = createEngineForMapEditor()
  engine.mode = EngineMode.MapEditor
  return engine
}
