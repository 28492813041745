import { createEffect, on, onCleanup } from "solid-js"
import type { Nullable } from "vite-node"
import type { Engine } from "../../lib/core/engine/Engine.type"
import type { Player } from "../../lib/core/player/Player.type"
import type { PlayerId } from "../../lib/core/player/PlayerId"
import { deepClone } from "../../lib/deep_clone"
import createPlayerThreeObject from "../../lib/three/PlayerThreeObject/createPlayerThreeObject"
import type { PlayerThreeObject } from "../../lib/three/PlayerThreeObject/PlayerThreeObject.type"
import createSharedCachedFactory from "./createSharedCachedFactory"

export const createSharedPlayerThreeObjectsMap = createSharedCachedFactory((engine: Engine) => {
  const playerThreeObjectsMap : Map<PlayerId, PlayerThreeObject> = new Map()

  const neutralPto : PlayerThreeObject = createPlayerThreeObject(null)

  // createMountedInverval(() => {
  //   playerThreeObjectsMap.forEach((pto: PlayerThreeObject, playerId: PlayerId) => {
  //     pto.material.color = new Color().setHex(Math.random() * 0xffffff)
  //   })
  // }, 2000)

  let generation = 1
  createEffect(on(() => engine.state.players, (newList : Array<Player>) => {
    // console.log('createSharedPlayerThreeObjectsMap.effect', newList.map(((player: Player) => player.id)))
    generation++
    newList.forEach((player: Player) => {
      const playerId = player.id
      const pto = playerThreeObjectsMap.get(playerId)
      if (pto) {
        pto.material.userData.g = generation
        pto.player = player
      } else {
        const pto : PlayerThreeObject = createPlayerThreeObject(player)
        const { material } = pto
        material.userData.id = playerId
        material.userData.g = generation

        playerThreeObjectsMap.set(playerId, pto)
      }
    })

    playerThreeObjectsMap.forEach((pto: PlayerThreeObject, playerId: PlayerId) => {
      if (pto.material.userData.g !== generation) {
        pto.material.dispose()
        playerThreeObjectsMap.delete(playerId)
      }
    })
  }))

  onCleanup(() => {
    playerThreeObjectsMap.forEach((pto: PlayerThreeObject, playerId: PlayerId) => {
      pto.material.dispose()
      playerThreeObjectsMap.delete(playerId)
    })
  })

  return function getPlayerThreeObjects(playerId: Nullable<PlayerId>): PlayerThreeObject {
    if (playerId) {
      const pto = playerThreeObjectsMap.get(playerId)
      if (pto) {
        return pto
      }
      console.log('playerId', playerId)
      console.log('player', deepClone(engine.state.players.find(p => p.id === playerId)))

      throw new Error('!pto')
    }
    return neutralPto
  }
})