import { createEffect, createMemo, on } from 'solid-js'
import playLocalBotRound from '../../lib/core/bot/bot1/playLocalBotRound'
import type { Engine } from '../../lib/core/engine/Engine.type'
import type { PlayerId } from '../../lib/core/player/PlayerId'
import { PlayerKind } from '../../lib/core/player/PlayerKind.enum'
import { PlayerTurnStatus } from '../../lib/core/player/PlayerTurnStatus'
import createDefaultWait from '../../lib/createDefaultWait'
import type { Nullable } from '../../typescript'

export default function useLocalBotPlayer(
  engine: Engine
) {
  const playerId = createMemo(() => {
    const playerId = engine.state.players.find((player) => {
      return engine.state.turnPlayerId === player.id
        && player.kind === PlayerKind.Bot
        && player.is_local
        && (player.turn_status === PlayerTurnStatus.Playing || player.turn_status === PlayerTurnStatus.Pending)
    })
      ?.id

    // console.log('%c[useLocalBotPlayer][memo] %cplayerId', 'color: grey; font-weight: lighter; font-size: 10px;', '', playerId)

    return playerId
  })

  let lastPromise : Promise<void> | null = null
  createEffect(on(playerId, async (playerId : Nullable<PlayerId>): Promise<void> => {
    // console.log('%c[useLocalBotPlayer][effect] %cplayerId', 'color: grey; font-weight: lighter; font-size: 10px;', '', playerId)
    if (!playerId) {
      return
    }

    // give any previous effects a fair chance to finish
    // also let solidjs do render stuff first
    await createDefaultWait(1)
    await lastPromise

    // console.log('LocalBot.playerId', playerId)
    lastPromise = playLocalBotRound(engine, playerId)

    await lastPromise
  }))
}
