import { DEV } from "solid-js"
import { parse } from "valibot"
import type { Nullable } from "vite-node"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import entityHeadingsMetaList from "../../core/entity/EntityHeading/entityHeadingsMetaList"
import toFixed1 from "../../core/util/toFixed1"
import isNil from "../../ldsh/isNil"

/**
 * @param heading: Nullable<number> 0, 90, 180, 270, null, undefined
 * @param targetHeading: Nullable<number> 0, 90, 180, 270, null, undefined
 * @param entityHeading: Nullable<number> 0, 90, 180, 270, null, undefined
 **/
export default function createEntityHeadingWithTargetTextCanvas(
  heading: Nullable<number>,
  targetHeading: Nullable<number>,
  entityHeading: Nullable<number>
): OffscreenCanvas {

  if (DEV) {
    parse(DegreeNumberSchema, heading)
    parse(DegreeNumberSchema, targetHeading)
    parse(DegreeNumberSchema, entityHeading)
  }

  const width = 768
  const height = 384
  const canvas = createOffscreenCanvas(width, height)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    // Clear the canvas
    ctx.clearRect(0, 0, width, height)

    ctx.fillStyle = `rgba(0, 0, 0, 0.5)`
    ctx.fillRect(0, 0, width, height)

    // Set up text properties
    const fontSize = 112
    const fontFamily = 'Arial'
    ctx.font = `${fontSize}px ${fontFamily}`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'

    // Determine the text to display
    let headingText: string
    let headingOpacity = 1 // Default opacity
    if (isNil(heading)) {
      headingText = 'H: nil'
      headingOpacity = 0.5 // Faded opacity for nil
    } else {
      const meta = entityHeadingsMetaList.find(m => m.value === heading)
      headingText = meta ? `H: ${heading} ${meta.dname}` : `H: ${toFixed1(heading)}`
    }

    let targetText: string
    let targetOpacity = 1 // Default opacity
    if (isNil(targetHeading)) {
      targetText = 'T: nil'
      targetOpacity = 0.5 // Faded opacity for nil
    } else {
      const meta = entityHeadingsMetaList.find(m => m.value === targetHeading)
      targetText = meta ? `T: ${targetHeading} ${meta.dname}` : `T: ${toFixed1(targetHeading)}`
    }

    let entityText: string
    let entityOpacity = 1 // Default opacity
    if (isNil(entityHeading)) {
      entityText = 'E: nil'
      entityOpacity = 0.5 // Faded opacity for nil
    } else {
      const meta = entityHeadingsMetaList.find(m => m.value === entityHeading)
      entityText = meta ? `E: ${entityHeading} ${meta.dname}` : `E: ${toFixed1(entityHeading)}`
    }

    // Set the fill style with opacity and draw the texts
    ctx.fillStyle = `rgba(255, 255, 255, ${headingOpacity})`
    ctx.fillText(headingText, width / 2, height / 6)

    ctx.fillStyle = `rgba(255, 255, 255, ${targetOpacity})`
    ctx.fillText(targetText, width / 2, height / 2)

    ctx.fillStyle = `rgba(255, 255, 255, ${entityOpacity})`
    ctx.fillText(entityText, width / 2, (5 * height) / 6)
  }

  return canvas
}
