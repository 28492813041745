import { keys } from "../core/util/Object"

/**
 * Compares two objects shallowly and returns their differences.
 * @param obj1 - The first object.
 * @param obj2 - The second object.
 * @returns An object containing keys that differ between obj1 and obj2.
 */
export default function shallowObjectDiff<T extends Record<string, unknown>>(
  obj1: T,
  obj2: T
) {
  const diff: Partial<Record<keyof T, {
    oldValue: unknown,
    newValue: unknown,
  }>> = {}

  const allKeys = new Set([...keys(obj1), ...keys(obj2)])

  for (const key of allKeys) {
    if (obj1[key] !== obj2[key]) {
      diff[key as keyof T] = {
        oldValue: obj1[key],
        newValue: obj2[key],
      }
    }
  }

  return diff
}