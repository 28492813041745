import { fallback, maxValue, minValue, number, parse, pipe } from 'valibot'
import clamp from '../../../../lib/core/util/clamp'
import { getDeviceUserPreference, setDeviceUserPreference } from './deviceUserPreferencesSignal'

export const PERSPECTIVE_CAMERA_FAR_STEP : number = 20
export const PERSPECTIVE_CAMERA_FAR_MIN : number = 500
export const PERSPECTIVE_CAMERA_FAR_MAX : number = 4000
export const PERSPECTIVE_CAMERA_FAR_DEFAULT : number = 1000

export const CameraFarSchema = fallback(pipe(number(),
  minValue(PERSPECTIVE_CAMERA_FAR_MIN),
  maxValue(PERSPECTIVE_CAMERA_FAR_MAX),
  ), PERSPECTIVE_CAMERA_FAR_DEFAULT)

export function updateCameraFar(newValue : number): void {
  newValue = clamp(newValue,
    PERSPECTIVE_CAMERA_FAR_MIN,
    PERSPECTIVE_CAMERA_FAR_MAX)

  // console.log('updateFov', getDeviceUserPreference().fov, '->', newValue)

  const parsedNewValue = parse(CameraFarSchema, newValue)

  setDeviceUserPreference({
    ...getDeviceUserPreference(),
    camera_far: parsedNewValue,
  })
}