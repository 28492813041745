import { Color, Mesh, MeshStandardMaterial, type Group, type MeshBasicMaterial } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import assertClassInDev from "../../ldsh/assertClassInDev"
import type { EntityThreeModelUserData } from "../EntityThreeModel/EntityThreeModelUserData.type"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"
import createMeshStandardMaterialFromMeshBasicMaterial from "./createMeshStandardMaterialFromMeshBasicMaterial"
import findMaterialByName from "./findMaterialByName"
import onGltfLoaderError from "./onGltfLoaderError"
import setEmissiveWindowMaterial from "./setEmissiveWindowMaterial"

const totalLoopMs = 2000

export default function makePropertyObject3DFactory<T extends Entity>(GLTF_Model : string) {
  return function makePropertyObject3D(
    ent: T,
    pto: PlayerThreeObject,
    callback: (newGroup: Group | Mesh) => void
  ): void {
    const { material: baseMaterial } = pto
    return appGltfLoader.load(
      GLTF_Model,
      function (ModelObj) {
        // console.log(entityDisplayName(ent), "ModelObj", ModelObj)
        const { scene } = ModelObj
        applyPlayerColors(scene, ent, pto)

        // console.log('scene', scene)
        const darkWindowMesh = scene.getObjectByName("Window") as Mesh
        assertClassInDev(darkWindowMesh, Mesh)
        // console.log('darkWindowMesh', darkWindowMesh)

        // Find the material named "Window"
        const darkWindowMaterial = findMaterialByName(darkWindowMesh, "Window") as MeshBasicMaterial
        assertClassInDev(darkWindowMaterial, MeshStandardMaterial)

        const windowLightMaterial = createMeshStandardMaterialFromMeshBasicMaterial(darkWindowMaterial)
        const lightWindowMesh = darkWindowMesh.clone()
        lightWindowMesh.name = 'WindowLight'
        lightWindowMesh.material = windowLightMaterial
        scene.add(lightWindowMesh)

        const windowLightColorYellow = new Color(0xf8f870)
        // const windowLightColorOrange = new Color(0xffa500)
        const windowLightColorOrange = windowLightColorYellow
        const windowDarkColor = baseMaterial.color.clone().multiplyScalar(0.5)
        // console.log('windowDarkColor', windowDarkColor.getHexString(), 'baseMaterial.color', baseMaterial.color.getHexString())
        setEmissiveWindowMaterial(windowLightMaterial, windowLightColorOrange)

        // Create materials for light & dark windows
        const windowDarkMaterial = baseMaterial.clone()
        windowDarkMaterial.color = windowDarkColor
        windowLightMaterial.color = windowLightColorYellow
        darkWindowMesh.material = windowDarkMaterial

        let lightsOff = true

        scene.userData.update = (elapsedMs: number, frameAt: number) => {
          const mod = frameAt % totalLoopMs
          const {
            e: ent,
          } = (scene.userData as EntityThreeModelUserData)

          const shouldBeOff = (mod > 400 || !ent.player_id)
          if (!lightsOff && shouldBeOff) {
            lightsOff = true
            darkWindowMesh.visible = lightsOff
            lightWindowMesh.visible = !lightsOff
          } else if (lightsOff && !shouldBeOff) {
            lightsOff = false
            darkWindowMesh.visible = lightsOff
            lightWindowMesh.visible = !lightsOff
          }
          // darkWindowMesh.visible = true

          applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
        }

        callback(scene)
      },
      undefined,
      onGltfLoaderError
    )
  }
}



