import type { Object3D } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import makeEntityGroup from "../fn/makeEntityGroup"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import type { EntityThreeModel } from "./EntityThreeModel.type"

export default function loadEntityThreeModelGLTF(
  engine: Engine,
  ent: EntityThreeModel['ent'],
  pto: PlayerThreeObject,
): Promise<Object3D> {
  return new Promise((resolve => {
    makeEntityGroup(engine, ent, pto, resolve)
  }))
}