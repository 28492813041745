import type { HasId } from "../../../lib/core/util/id"
import type { Nullable } from "../../../typescript"

export default function isIdListSame(
  list1 : Nullable<Array<HasId>>,
  list2 : Nullable<Array<HasId>>
) : boolean {
  // If either array is null or they are of different lengths, return false.
  if (!list1 || !list2 || list1.length !== list2.length) {
    return false
  }

  // Check if every id in list1 matches the corresponding id in list2.
  for (let i = 0; i < list1.length; i++) {
    if (list1[i].id !== list2[i].id) {
      return false
    }
  }
  return true
}