import { Mesh, MeshStandardMaterial, PlaneGeometry, RepeatWrapping } from "three"
import tmpRng from "../../core/rng/tmpRng"
import { PI, sin } from "../../core/util/math"
import generateNoiseTexture from "./generateNoiseTexture"

export default function createWaterPlaneMesh(
  // engine: Engine,
  geometrySize : number = 10000
) {
  // const geometrySize = 10000
  tmpRng.state = 1

  const noiseTexture1 = generateNoiseTexture(64, tmpRng, 127, 255)
  noiseTexture1.wrapS = noiseTexture1.wrapT = RepeatWrapping
  noiseTexture1.repeat.set(64, 64)
  // material.bumpMap = noiseTexture1

  const noiseTexture2 = generateNoiseTexture(64, tmpRng, 127, 255)
  noiseTexture2.wrapS = noiseTexture2.wrapT = RepeatWrapping
  noiseTexture2.repeat.set(64, 64)
  // material.lightMap = noiseTexture2
  // material.lightMapIntensity = 1

  const material = new MeshStandardMaterial({
    // color: 0x0000ff,
    color: 0x4A90E2, // RiverBlue

    bumpMap: noiseTexture1,

    lightMap: noiseTexture2,
    lightMapIntensity: 1,

    transparent: true,
    opacity: 0.75,

    // depthWrite: false,
    // depthWrite: true,
    // flatShading: true,
    // alphaHash: true,
    // clipIntersection: true,
    // depthTest: false,
    forceSinglePass: true,
  })

  // tell the GPU to render the water first
  // material.polygonOffset = true
  // material.polygonOffsetFactor = -1 // Pulls the water surface forward slightly
  // material.polygonOffsetUnits = 1

  const geometry = new PlaneGeometry(geometrySize, geometrySize)
  const mesh = new Mesh(geometry, material)
  // mesh.renderOrder = -1

  mesh.rotation.x = - PI / 2
  // scene.add(mesh)

  // mesh.position.y += 5

  mesh.userData.update = (elapsedMs: number, frameAt: number) => {

    const s1 = sin(frameAt / 2500)
    mesh.position.y = ((s1 - 1) / 3)
    // mesh.position.y = max(mesh.position.y, ((s1 - 1) / 3))
    // console.log('mesh.position.y', toFixed1(mesh.position.y))
  }

  // mesh.visible = false

  return mesh
}