import { WebGLRenderer } from "three"
import deviceUserPreferencesSignal from "../../../rx/shared/device/userPreference/deviceUserPreferencesSignal"

export default function createMainRenderer(
  canvasElem: HTMLCanvasElement | OffscreenCanvas,
) {
  const prefs = deviceUserPreferencesSignal[0]()

  const renderer = new WebGLRenderer({
    // alpha: true,
    canvas: canvasElem,
    antialias: prefs.antialias,
    // powerPreference: "high-performance", "low-power", "default",
    powerPreference: prefs.powerPreference,
    // default is false, which is faster
    // preserveDrawingBuffer: true,

    // this settings reduces tearing between polygon edges.
    logarithmicDepthBuffer: true,
  })

  renderer.setSize(canvasElem.width, canvasElem.height)

  // renderer.outputEncoding = THREE.sRGBEncoding
  // renderer.toneMapping = THREE.ACESFilmicToneMapping

  return renderer
}