import { createMemo, type Accessor } from "solid-js"
import type { Engine } from "../../lib/core/engine/Engine.type"
import type { Entity } from "../../lib/core/entity/index"
import type { TilePositionXY } from "../../lib/core/tile_position_xy/TilePositionXY.type"
import toCoord from "../../lib/core/tile_position_xy/toCoord"
import { byLayerIdDesc } from "../../lib/core/util/id"
import createSharedCachedFactory from "./createSharedCachedFactory"

export type EntityXYGridMap = Map<TilePositionXY, Array<Entity>>

export const createSharedEntityXYGridMapMemo = createSharedCachedFactory((engine: Engine): Accessor<EntityXYGridMap> => {
  const memo = createMemo(() => {
    const map = new Map<TilePositionXY, Array<Entity>>
      engine.state.ents.forEach((entity : Entity): void => {
        const xy = toCoord(entity)
        const list = map.get(xy) || []
        list.push(entity)
        list.sort(byLayerIdDesc)
        map.set(xy, list)
      })
      return map
  })

  return memo
})