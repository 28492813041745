import { Engine } from '../../core/engine/Engine.type'
import onWindowEvent from '../../onWindowEvent'

export default function registerWheelEvent(engine: Engine) {
  // console.log('registerWheelEvent')
  function onWheel(event: WheelEvent) {
    event.stopPropagation()
    const { viewCtx } = engine

    // engine.viewCtx.zoomQueue.push({
    //   clientX: event.clientX,
    //   clientY: event.clientY,
    //   deltaY: event.deltaY,
    //   tile_size: viewCtx.tile_size,
    //   pan_x: viewCtx.pan_x,
    //   pan_y: viewCtx.pan_y,
    // })

    engine.viewCtx.zoomQueue.push({
      clientX: event.clientX,
      clientY: event.clientY,
      deltaY: event.deltaY,
      prevMapWidthPx: viewCtx.mapWidthPx,
      prevMapHeightPx: viewCtx.mapHeightPx,
      prevPanX: viewCtx.pan_x,
      prevPanY: viewCtx.pan_y,
    })

    // console.table(deepClone(engine.viewCtx.zoomQueue))
  }

  onWindowEvent('wheel', onWheel)
}
