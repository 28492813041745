/*eslint @typescript-eslint/no-explicit-any: ["off"]*/

import { hasOwn, keys } from "./core/util/Object"

/**
 * @param input
 *
 * there is something called structured clone,
 * but it's neither faster nor better suited
 * for cloning state.
 *
 * valibot's parse will probably superceed deepClone
 *
 * @returns deep cloned input
 */
export function isPlainObjDeeplyEqual(
  obj1: any,
  obj2: any
): boolean {
  if (obj1 === obj2) return true // Handle reference equality

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false // Handle primitive values and null
  }

  const keys1 = keys(obj1)
  const keys2 = keys(obj2)

  if (keys1.length !== keys2.length) return false // Different number of keys

  const allKeys = new Set([...keys1, ...keys2])

  for (const key of allKeys) {
    if (!hasOwn(obj1, key)) return false // Key missing in obj1
    if (!hasOwn(obj2, key)) return false // Key missing in obj2
    if (!isPlainObjDeeplyEqual(obj1[key], obj2[key])) return false // Recursively check nested properties
  }

  return true // All properties match
}

