import { DEV, onCleanup, onMount } from 'solid-js'
import { ACESFilmicToneMapping, Raycaster, Vector2, WebGLRenderer, type Camera } from 'three'
import createDraftUnitMovesUnitEffect from '../../rx/effect/createDraftUnitMovesUnitEffect'
import createDraftUnitRotatesTurretEffect from '../../rx/effect/createDraftUnitRotatesTurretEffect'
import { Engine } from '../core/engine/Engine.type'
import { EngineMode } from '../core/engine/EngineMode/EngineMode.enum'
import applyCameraRotationInFrame from '../core/entity/EntityHeading/applyCameraRotationInFrame'
import { isEntityMountain, isEntityRiver } from '../core/entity/entityTypeMetaList.generated'
import progressArrowKeyPanV3 from '../core/progressArrowKeyPanV3'
import registerContextMenuEvent from '../dom/event/registerContextMenuEvent'
import registerGamePlayKeyboardEvents from '../dom/event/registerGamePlayKeyboardEvents'
import registerKeyDownEvent from '../dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../dom/event/registerKeyUpEvent'
import registerMouseDownEvent from '../dom/event/registerMouseDownEvent'
import registerMouseUpEvent from '../dom/event/registerMouseUpEvent'
import registerPointerMoveEvent from '../dom/event/registerPointerMoveEvent'
import rememberCameraPositionOnHotModuleReload from '../hmr/rememberCameraPositionOnHotModuleReload'
import { VERTICES_PER_TILE } from '../three/consts'
import callAllUserDataUpdatesRecursive from '../three/fn/callAllUserDataUpdates'
import createBombardCursorGroup from '../three/fn/createBombardCursorGroup'
import createDraftMoveObject3D from '../three/fn/createDraftMoveObject3D'
import createEntityThreeModelCollectionV2 from '../three/fn/createEntityThreeModelCollectionV2'
import createGetPointerVector3Point from '../three/fn/createGetPointerVector3Point'
import createHoverPositionMesh from '../three/fn/createHoverPositionMesh'
import createMapEditorEdgeWallsGroupV2 from '../three/fn/createMapEditorEdgeWallsGroupV2'
import createOceanFloorPlaneMesh from '../three/fn/createOceanFloorPlaneMesh'
import createRayCastTargetPlaneMesh from '../three/fn/createRayCastTargetPlaneMesh'
import createSelectedPositionMesh from '../three/fn/createSelectedPositionMesh'
import createThreeContext from '../three/fn/createThreeContext'
import createUnloadCargoTargetPositionObject3D from '../three/fn/createUnloadCargoTargetPositionObject3D'
import createWaterPlaneMesh from '../three/fn/createWaterPlaneMesh'
import pointerVector3PointToPointerTilePosition from '../three/fn/pointerVector3PointToPointerTilePosition'
import setMapControlsPolarAngle from '../three/fn/setMapControlsPolarAngle'
import createMountainTerrainEffectV2 from '../three/objects/Mountain/createMountainTerrainEffectV2'
import createRiverTerrainEffect from '../three/objects/River/createRiverTerrainEffect'
import createShoalTerrainEffect from '../three/objects/Shoal/createShoalTerrainEffect'
import createTerrainMesh1Effect from '../three/objects/TerrainMesh1V1/createTerrainMesh1Effect'
import { isEntityTerrainMesh1 } from '../three/objects/TerrainMesh1V1/makeSingleTileTerrainMesh1Object3D'
import { createAmbientLight } from '../three/sky/createAmbientLight'
import { createDirectionalLight } from '../three/sky/createDirectionalLight'
import createGradientTextureSky from '../three/sky/createGradientTextureSky'
import { createHemisphereLight } from '../three/sky/createHemisphereLight'

export default function bindEngineToComponentV2(
  engine: Engine,
  canvasRef: HTMLCanvasElement,
  renderer: WebGLRenderer,
  camera: Camera,
  // renderTarget: WebGLRenderTarget,
): void {

  const isMapPreview = engine.mode === EngineMode.MapPreview

  onMount(() => {
    // Set up the scene, camera, and renderer
    const threeCtx = engine.threeCtx = createThreeContext(canvasRef, camera)
    const { scene, mc: mapControls } = threeCtx
    // renderer.toneMapping = ACESFilmicToneMapping
    // renderer.toneMappingExposure = 0.5

    // renderer.toneMapping = NoToneMapping
    // renderer.toneMapping = LinearToneMapping
    // renderer.toneMapping = ReinhardToneMapping
    // renderer.toneMapping = CineonToneMapping
    renderer.toneMapping = ACESFilmicToneMapping
    // renderer.toneMapping = AgXToneMapping
    // renderer.toneMapping = NeutralToneMapping
    // renderer.toneMapping = CustomToneMapping

    // createSingleColorSky(scene)
    createGradientTextureSky(scene)
    scene.add(createHemisphereLight())
    scene.add(createAmbientLight())
    scene.add(createDirectionalLight())

    scene.add(createOceanFloorPlaneMesh())
    scene.add(createWaterPlaneMesh())
  
    const raycastTargetMesh = createRayCastTargetPlaneMesh(engine, scene)

    // createRoadEntities(engine, scene)
    // const getUnitEntityList = createSharedGetUnitEntityListMemo(engine)
    const getEntityList = () => engine.state.ents.filter((ent) => {
      return !isEntityTerrainMesh1(ent) && !isEntityMountain(ent) && !isEntityRiver(ent)
    })
    // const entityThreeModelCollection = createEntityThreeModelCollection(engine, scene, getEntityList)
    const entityThreeModelCollection = createEntityThreeModelCollectionV2(engine, scene, getEntityList)
    createDraftUnitRotatesTurretEffect(engine, entityThreeModelCollection)
    createDraftUnitMovesUnitEffect(engine, entityThreeModelCollection)

    createTerrainMesh1Effect(engine, scene)
    createShoalTerrainEffect(engine, scene)
    createRiverTerrainEffect(engine, scene)
    // createMountainTerrainEffect(engine, scene)
    createMountainTerrainEffectV2(engine, scene)
    // const fog = createFogExp2()
    // scene.fog = fog

    // mouse events

    const raycaster = new Raycaster()

    raycastTargetMesh.visible = false
    // raycastTargetMesh.position.y += 5

    const pointerRef = new Vector2()
    const getPointerVector3Point = createGetPointerVector3Point(camera, raycaster, raycastTargetMesh)

    // const [getPointerVector3PointText, setPointerVector3PointText] = createSignal<Nullable<string>>()
    // createPointerVector3PointDebugText(engine, getPointerVector3PointText)

    const updateHoverTilePosition = createHoverPositionMesh(engine, scene)
    createSelectedPositionMesh(engine, scene)
    createBombardCursorGroup(engine, scene)
    createUnloadCargoTargetPositionObject3D(engine, scene, entityThreeModelCollection)
    registerPointerMoveEvent(engine, canvasRef, pointerRef)
    registerMouseDownEvent(engine, canvasRef, getPointerVector3Point)
    registerMouseUpEvent(engine, canvasRef, getPointerVector3Point)
    // registerMouseClickEvent(engine)
    registerContextMenuEvent(engine)
    registerGamePlayKeyboardEvents(engine)
    // registerMapEditKeyboardEvents(engine, true)

    registerKeyUpEvent(engine)
    registerKeyDownEvent(engine)

    if (!isMapPreview) {
      createDraftMoveObject3D(engine)
    }

    rememberCameraPositionOnHotModuleReload(mapControls, camera)
    camera.userData.heading = 0
    // camera.position.y = 120
    // camera.rotation.set(0, 0, PI)
    // mapControls.update()
    // setMapControlsPolarAngle(mapControls, 0.8)
    // camera.userData.targetHeading = 0
    // console.log('camera changed', toFixed1(camera.rotation.x), toFixed1(camera.rotation.y), toFixed1(camera.rotation.z))


    setTimeout(() => {
      // setMapControlsPolarAngle(mapControls, 8)
      mapControls.target.x = engine.state.width * VERTICES_PER_TILE / 2
      mapControls.target.z = engine.state.height * VERTICES_PER_TILE / 2
      camera.position.y = 120
      mapControls.update()
      setMapControlsPolarAngle(mapControls, 0.8)
      camera.position.y = 120
      mapControls.update()
    }, 1)

    createMapEditorEdgeWallsGroupV2(engine)

    // renderer.autoClear = false
    // scene2.alpha = true
    // renderTarget.
    // scene2.background = new Color(0x000000)
    // scene2.background = null
    // scene2.background = 'transparent'
    // renderer.setClearColor(0x000000, 0)
    // renderer.setClearColor(0x000000, 0.5)
    // const composer = new EffectComposer(renderer, renderTarget)
    // const renderPass1 = new RenderPass(scene1, camera)
    // const renderPass2 = new RenderPass(scene2, camera)
    // renderPass1.clear = false
    // renderPass2.clear = false
    // // renderPass2.clearAlpha = 1
    // // renderPass1.clear = true
    // // renderPass2.clear = true
    // composer.addPass(renderPass1)
    // composer.addPass(renderPass2)
    // {
    //   [renderPass1, renderPass2].forEach((renderPass) => {
    //     renderPass.clearColor = new Color( 0, 0, 0 );
    //     renderPass.clearAlpha = 0;
    //   })
    // }

    // const compass = createOnScreenCompassObject3D()
    // scene.add(compass)
    // addOnScreenCompassToMapControlsEvents(camera, compass, mapControls)

    // Animation loop
    let start: number = performance.now()
    let elapsedMs: number = 0
    let timestampMs: number = 0

    const render : FrameRequestCallback = (newTimestampMs: number) => {
      // console.log('render')
      // scene.environment = environment
      // scene.environment = getRendererSceneEnvironment(pmremGenerator)
    
      timestampMs = newTimestampMs
      elapsedMs = timestampMs - start
      start = timestampMs
      // const frameAt = performance.now()
      // angle += 0.0025 // Increment the angle
      // camera.position.x = radius * cos(angle) // Update camera position (x)
      // camera.position.z = radius * sin(angle) // Update camera position (z)

      // camera.position.y = (sin(time / 1000) * 5) + 1 // Bounce camera up and down

      // camera.lookAt(0, 0, 0) // Ensure the camera looks at the center
      // waterMesh.material.uniforms.time.value += 0.25 / 60.0
      // const maxWaterDip = 0.05
      // waterMesh.position.y = ((sin((timestampMs * 0.001) / 100) * maxWaterDip)) - maxWaterDip // Bounce water up and down

      const pointerVector3Point = getPointerVector3Point(pointerRef)
      // setPointerVector3PointText(pointerVector3Point ? `PointerV3: {${toFixed1(pointerVector3Point.x)}, ${toFixed1(pointerVector3Point.y)}, ${toFixed1(pointerVector3Point.z)}}` : 'PointerV3 NULL')
      const pointerTilePosition = pointerVector3PointToPointerTilePosition(engine, pointerVector3Point)

      // updateHoverTilePosition(pointerTilePosition, pointerVector3Point)
      if (!isMapPreview) {
        updateHoverTilePosition(pointerTilePosition)
      }
      // applyMousePanVelocityToCamera()

      // progressZoomQueueV2(engine, elapsedMs, camera)
      // progressArrowKeyPanV2(engine, elapsedMs, camera)
      mapControls.update()

      progressArrowKeyPanV3(engine, elapsedMs, camera, mapControls)
      applyCameraRotationInFrame(camera, mapControls, elapsedMs)

      callAllUserDataUpdatesRecursive(scene, elapsedMs, timestampMs)
      // scene.traverse(callAllUserDataUpdates)
      // scene2.traverse((child) => {
      //   const update : Nullable<FrameUpdateCallback> = child.userData.update
      //   if (update) {
      //     update(elapsedMs, timestampMs)
      //   }
      // })

      renderer.render(scene, camera)
      // renderer.clearDepth()
      // renderer.render(scene2, camera)
      // composer.render()
      animationId = requestAnimationFrame(render)
    }

    let animationId = requestAnimationFrame(render)

    // Cleanup on component unmount
    onCleanup(() => {
      cancelAnimationFrame(animationId)
    })

    if (DEV) {
      setTimeout(() => {
        if (renderer) {
          console.log('renderer.info.render.triangles', renderer.info.render.triangles)
        }
      }, 5000)
    }

  })
}


