import { floor, max, PI } from "../../../core/util/math"
import { getCanvas2dContext } from "../../get_canvas_2d_context"

const LIGHT_BLUE = '#aaf'

export default function createRenderUnloadTargetIconCanvasBufferV2(
  buffer: OffscreenCanvas,
  texture_size: number
): void {
  buffer.width = texture_size
  buffer.height = texture_size

  const half_texture_size = texture_size / 2

  const ctx = getCanvas2dContext(buffer)
  if (ctx) {

    // blue bg
    ctx.clearRect(0, 0, texture_size, texture_size)

    // console.log('attackPosition', attackPosition)
    // const sWCut1 = (texture_size - half_texture_size) / 2
    // const sHCut1 = (texture_size - half_texture_size) / 2
    // const sWCut2 = (texture_size - half_texture_size) * (3 / 8)
    // const sHCut2 = (texture_size - half_texture_size) * (3 / 8)
    // const sWCut3 = (texture_size - half_texture_size) * (5 / 8)
    // const sHCut3 = (texture_size - half_texture_size) * (5 / 8)
    const radius = floor(half_texture_size * 0.75)
    ctx.strokeStyle = LIGHT_BLUE
    ctx.lineWidth = max(2, floor(half_texture_size / 16))
    // console.log('ctx.lineWidth', ctx.lineWidth)
    ctx.beginPath()
    ctx.arc(half_texture_size, half_texture_size, radius, 0, PI * 2)
    ctx.stroke()
  }
}