import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindowV2 from '../lib/bindViewCtxToWindowV2'
import bindEngineToComponentV2 from '../lib/canvas/bindEngineToComponentV2'
import createEngineForGamePlay from '../lib/core/engine/createEngineForGamePlay'
import { Engine } from '../lib/core/engine/Engine.type'
import { EngineMode } from '../lib/core/engine/EngineMode/EngineMode.enum'
import syncEngineWithPropsRow from '../lib/core/engine/syncEngineWithPropsRow'
import { EntityHeadingEastDeg, EntityHeadingNorthDeg, EntityHeadingSouthDeg } from '../lib/core/entity/EntityHeading/consts'
import { createAPCEntity } from '../lib/core/entity/EntityType/APC'
import { createBuildingEntity } from '../lib/core/entity/EntityType/Building'
import { createInfantryEntity } from '../lib/core/entity/EntityType/Infantry'
import { createLightReconEntity } from '../lib/core/entity/EntityType/LightRecon'
import { createLightTankEntity } from '../lib/core/entity/EntityType/LightTank'
import { createMediumArtillaryEntity } from '../lib/core/entity/EntityType/MediumArtillary'
import { createMediumCopterEntity } from '../lib/core/entity/EntityType/MediumCopter'
import { createMediumRocketsEntity } from '../lib/core/entity/EntityType/MediumRockets'
import { createMediumTankEntity } from '../lib/core/entity/EntityType/MediumTank'
import { isEntityLightRecon } from '../lib/core/entity/entityTypeMetaList.generated'
import nextEntityIdFromEngine from '../lib/core/entity/nextEntityIdFromEngine'
import { PlayerKind } from '../lib/core/player/PlayerKind.enum'
import { StateType } from '../lib/core/state/state_type.enum'
import { loadGameFromLocalStorage } from '../lib/core/Storage/LocalStorage/localStorageEngineLoader'
import registerGamePlayKeyboardEvents from '../lib/dom/event/registerGamePlayKeyboardEvents'
import registerKeyDownEvent from '../lib/dom/event/registerKeyDownEvent'
import registerKeyUpEvent from '../lib/dom/event/registerKeyUpEvent'
import registerMouseClickEvent from '../lib/dom/event/registerMouseClickEvent'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import createMainCamera from '../lib/three/fn/createMainCamera'
import createMountedMainCanvasElement from '../lib/three/fn/createMountedMainCanvasElement'
import createMountedMainRenderer from '../lib/three/fn/createMountedMainRenderer'
import createRedirectIfWrongStateTypeEffect from '../rx/effect/createRedirectIfWrongStateTypeEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import createSetEngineAuthPlayerIdFromLocalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromLocalEffect'
import useCornerWidgetEngine from '../rx/shared/CornerWidget/useCornerWidgetEngine'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import registerServerSentEvents from '../rx/sse/registerServerSentEvents'
import useLocalBotPlayer from '../rx/use/useLocalBotPlayer'
import EngineContext from './EngineContext'
import GameEndedModal from './GameEndedModal'
import bindCameraToUserPreferences from '../lib/three/camera/bindCameraToUserPreferences'

interface Props {
  // session: BackendSessionPayload,
  row?: GameRowValue,
  local?: boolean,
}

const componentStateType = StateType.Game

const GamePlayThreeJsCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForGamePlay())
  // eslint-disable-next-line solid/reactivity
  engine.mode = EngineMode.Game

  // eslint-disable-next-line solid/reactivity
  if (props.local) {
    loadGameFromLocalStorage(engine)

    // eslint-disable-next-line solid/reactivity
    engine.state.players.forEach((player, index) => {
      // player.kind = PlayerKind.Bot
      // player.kind = PlayerKind.Human
      player.kind = index > 0 ? PlayerKind.Bot : PlayerKind.Human
      player.is_local = true
    })

    /* eslint-disable solid/reactivity */
    engine.state.ents.push(createInfantryEntity(nextEntityIdFromEngine(engine.state), 2, 0, engine.state.players[0].id))
    engine.state.ents.push(createInfantryEntity(nextEntityIdFromEngine(engine.state), 0, 2, engine.state.players[1].id))
    // // engine.state.ents.push(createInfantryEntity(nextEntityIdFromEngine(engine.state), 7, 7, engine.state.players[0].id))
    engine.state.ents.push(createLightReconEntity(nextEntityIdFromEngine(engine.state), 4, 1, engine.state.players[0].id))
    engine.state.ents.push(createMediumTankEntity(nextEntityIdFromEngine(engine.state), 3, 4, engine.state.players[1].id))
    engine.state.ents.push(createMediumRocketsEntity(nextEntityIdFromEngine(engine.state), 0, 3, engine.state.players[0].id))
    engine.state.ents.push(createMediumCopterEntity(nextEntityIdFromEngine(engine.state), 2, 2, engine.state.players[1].id))
    // engine.state.ents.push(createTransportCopterEntity(nextEntityIdFromEngine(engine.state), 0, 4, engine.state.players[1].id))
    // engine.state.ents.push(createTransportCopterEntity(nextEntityIdFromEngine(engine.state), 1, 4, engine.state.players[1].id))
    engine.state.ents.push(createMediumRocketsEntity(nextEntityIdFromEngine(engine.state), 3, 5, engine.state.players[1].id))
    engine.state.ents.push(createLightTankEntity(nextEntityIdFromEngine(engine.state), 4, 2, engine.state.players[0].id))
    engine.state.ents.push(createAPCEntity(nextEntityIdFromEngine(engine.state), 5, 4, engine.state.players[1].id))
    // engine.state.ents.push(createMediumMissileAntiAirEntity(nextEntityIdFromEngine(engine.state), 8, 1, engine.state.players[0].id))
    // engine.state.ents.push(createMediumMissileAntiAirEntity(nextEntityIdFromEngine(engine.state), 4, 4, engine.state.players[1].id))
    const unitMediumArtillary80 = createMediumArtillaryEntity(nextEntityIdFromEngine(engine.state), 8, 0, engine.state.players[0].id)
    engine.state.ents.push(unitMediumArtillary80)
    unitMediumArtillary80.weapons.forEach((w) => {
      w.ammo = 1
    })
    engine.state.ents.push(createInfantryEntity(nextEntityIdFromEngine(engine.state), 8, 2, engine.state.players[1].id))
    engine.state.ents.push(createMediumCopterEntity(nextEntityIdFromEngine(engine.state), 3, 0, engine.state.players[1].id))
    engine.state.ents.push(createLightReconEntity(nextEntityIdFromEngine(engine.state), 3, 1, engine.state.players[1].id))
    engine.state.ents.push(createInfantryEntity(nextEntityIdFromEngine(engine.state), 7, 0, engine.state.players[0].id))
    engine.state.ents.push(createBuildingEntity(nextEntityIdFromEngine(engine.state), 8, 1, engine.state.players[0].id))

    engine.state.players[0].money += 30

    // const unitAPC40 = createAPCEntity(nextEntityIdFromEngine(engine.state), 4, 0, engine.state.players[0].id)
    // const unitInf40 = createInfantryEntity(nextEntityIdFromEngine(engine.state), 4, 0, engine.state.players[0].id)
    // unitAPC40.cargo = [unitInf40.id]
    // unitInf40.taxi_id = unitAPC40.id
    // engine.state.ents.push(unitAPC40, unitInf40)
    // engine.state.ents.push(unitAPC40)
    // engine.state.ents.push(unitInf40)

    // const unitInf71 = createInfantryEntity(nextEntityIdFromEngine(engine.state), 7, 1, engine.state.players[0].id)
    // engine.state.ents.push(unitInf71)

    // const annexInf: InfantryEntity = engine.state.ents.find((e) => isEntityInfantry(e) && samePosition(e, coord(7, 0))) as InfantryEntity
    // const building70: BuildingEntity = engine.state.ents.find((e) => isEntityBuilding(e) && samePosition(e, annexInf)) as BuildingEntity
    // building70.ap_ent_id = annexInf.id
    // building70.ap = 5
    // console.log(deepClone({ annexInf, building70 }))

    engine.state.ents.forEach((ent) => {
      if (ent.player_id) {
        if (ent.player_id === engine.state.players[0].id) {
          ent.heading = EntityHeadingNorthDeg
        } else {
          ent.heading = EntityHeadingSouthDeg
        }
        if (isEntityLightRecon(ent)) {
          ent.heading = EntityHeadingEastDeg
        }
      }
    })
    /* eslint-enable solid/reactivity */

    createSetEngineAuthPlayerIdFromLocalEffect(engine)

    // something about this breaks the ability to create draftMove UI elements
    // dispatchClient(engine, createStartTurnAction(engine.state.players[0].id)).then(async () => {
    //     resetEngineToDefaultSelectedTool(engine)
    //     //   const unit = engine.state.ents.find(isEntityInfantry) as InfantryEntity
    //     //   await new Promise(resolve => setTimeout(resolve, 1000))
    //     //   engine.selectedPosition = toCoord(unit)
    //     //   await new Promise(resolve => setTimeout(resolve, 1000))
    //     //   // engine.draftMove = createDraftMove(unit)
    //     //   engine.hoveredPosition = coord(10, 10)
    // })

    // // eslint-disable-next-line solid/reactivity
    // console.log('engine.actionLogs', deepClone(engine.actionLogs))

    // engine.actionLogs = engine.actionLogs.filter(l => l.action.type > 2000 || l.action.type === 509)
    // console.log('engine.actionLogs', deepClone(engine.actionLogs))
    // saveGameToLocalStorage(engine)

    useLocalBotPlayer(engine)
  } else {
    onMount(() => {
      syncEngineWithPropsRow(engine, props.row)
    })
    const [authProfile] = createAuthProfileSignal()
    createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)
  }

  // registerMouseDownEvent(engine)
  // registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  // registerWheelEvent(engine)
  registerKeyUpEvent(engine)
  registerKeyDownEvent(engine)
  registerGamePlayKeyboardEvents(engine)
  // registerTouchEventsV2(engine)

  // const isStateDirty = createIsStateDirtyMemo(engine)
  // useConfirmLeaveWithUnsavedChanges(isStateDirty)

  useCornerWidgetEngine(engine)

  registerServerSentEvents(engine)
  createRedirectIfWrongStateTypeEffect(componentStateType, () => engine.state.type, () => engine.state.online?.id)

  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
    }))
  })

  const canvasElem = createMountedMainCanvasElement()
  const renderer = createMountedMainRenderer(canvasElem)
  const camera = createMainCamera(canvasElem)

  bindViewCtxToWindowV2(engine, canvasElem, renderer, camera)
  bindEngineToComponentV2(engine, canvasElem, renderer, camera)
  bindCameraToUserPreferences(camera)

  return <EngineContext.Provider value={engine}>
    <div id="gpc">
      <div id="gvp" ref={(ref: HTMLElement) => {
        // console.log('ref', ref)
        if (ref) {
          registerTouchEventsV2(engine, ref)
          // registerMouseMoveEvent(engine, ref)
        }
      }}>
        {canvasElem}
      </div>
    </div>
    <GameEndedModal />
  </EngineContext.Provider>
}

export default GamePlayThreeJsCanvas
