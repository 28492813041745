import { type Texture, CanvasTexture } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { nextIntRange } from "../../core/rng/index"
import type { RNGConfig } from "../../core/rng/RNGConfig.type"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"

// // Apply the bump texture
// const exampleTexture = generateNoiseTexture();
// exampleTexture.wrapS = exampleTexture.wrapT = THREE.RepeatWrapping;
// exampleTexture.repeat.set(4, 4);
// exampleMaterial.bumpMap = exampleTexture;
// exampleMaterial.bumpScale = 0.2;

export default function generateNoiseTexture(
  texture_size: number = 256,
  rng: RNGConfig,
  lower_bound: number,
  upper_bound: number,
): Texture {
  const canvas = createOffscreenCanvas(texture_size, texture_size);
  canvas.width = texture_size;
  canvas.height = texture_size;
  const ctx = getCanvas2dContext(canvas)

  if (ctx) {
    const imageData = ctx.createImageData(texture_size, texture_size);
    for (let y = 0; y < texture_size; y++) {
      for (let x = 0; x < texture_size; x++) {
        const index = (y * texture_size + x) * 4
        const noise = nextIntRange(lower_bound, upper_bound, rng) // Random shades
        imageData.data[index] = noise   // Red
        imageData.data[index + 1] = noise // Green
        imageData.data[index + 2] = noise // Blue
        imageData.data[index + 3] = 255    // Alpha
      }
    }
    ctx.putImageData(imageData, 0, 0)
  }
  
  return new CanvasTexture(canvas);
}
