import { POSTGRES_MAX_INT } from "@sg/shared/src/util/numbers"
import { keyArray } from "@solid-primitives/keyed"
import { createEffect, createMemo, DEV, on, onCleanup, type Accessor } from "solid-js"
import { Group, type Scene } from "three"
import { parse } from "valibot"
import type { Nullable } from "vite-node"
import { createSharedPlayerThreeObjectsMap } from "../../../rx/memo/createSharedPlayerThreeObjectsMap"
import type { Engine } from "../../core/engine/Engine.type"
import entityDisplayName from "../../core/entity/entityDisplayName"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import { DegreeNumberSchema } from "../../core/entity/EntityHeading/DegreeNumber.type"
import type { EntityId } from "../../core/entity/EntityId.type"
import type { Entity } from "../../core/entity/index"
import isEntityUnit from "../../core/entity/isEntityUnit"
import { deepClone } from "../../deep_clone"
import { isPlainObjDeeplyEqual } from "../../isPlainObjDeeplyEqual"
import prettyPlainObjectDiffText from "../../ldsh/prettyPlainObjectDiffText"
import addEntityThreeModelDebugTextSprite from "../EntityThreeModel/addEntityThreeModelDebugTextSprite"
import createEntityThreeModel from "../EntityThreeModel/createEntityThreeModel"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import loadEntityThreeModelGLTF from "../EntityThreeModel/loadEntityThreeModelGLTF"
import updateEntityThreeModel from "../EntityThreeModel/updateEntityThreeModel"
import addProcedurallyGeneratedEntityTextures from "./addProcedurallyGeneratedEntityTextures"
import disposeRecursive from "./disposeRecursive"

export type EntityThreeModelCollection = {
  group: Group
  ents: Map<EntityId, EntityThreeModel>
}

export default function createEntityThreeModelCollectionV2(
  engine: Engine,
  scene: Scene,
  getSourceEntityList: Accessor<Array<Entity>>
): EntityThreeModelCollection {
  const entityThreeModelMap = new Map<number, EntityThreeModel>()
  const getPlayerThreeObject = createSharedPlayerThreeObjectsMap(engine)

  const entityCollectionGroup = new Group()
  const modelsLoadingPromiseSet = new Set<ReturnType<typeof loadEntityThreeModelGLTF>>()
  scene.add(entityCollectionGroup)

  const mapped = keyArray(
    getSourceEntityList,
    (ent: Entity) => ent.id,
    (getEntity) => {
      // console.log("mapped", getIndex(), getEntity().id, entityDisplayName(getEntity()))

      let prevEnt: Entity = deepClone(getEntity())
      let obj : Nullable<Awaited<ReturnType<typeof loadEntityThreeModelGLTF>>> = null

      const entityThreeModel = createEntityThreeModel(getEntity())
      entityThreeModelMap.set(prevEnt.id, entityThreeModel)

      const getPto = createMemo(() => getPlayerThreeObject(getEntity().player_id))

      let loadingModelPromise : Nullable<ReturnType<typeof loadEntityThreeModelGLTF>> = null

      // if the player_id or player's paints change, we must reload the model
      createEffect(on(getPto, async (pto) => {
        // console.log('onPlayerThreeObject', getEntity().id)
        try {
          await loadingModelPromise
          loadingModelPromise = loadEntityThreeModelGLTF(engine, getEntity(), pto)
          modelsLoadingPromiseSet.add(loadingModelPromise)
          const newObjValue = await loadingModelPromise
          modelsLoadingPromiseSet.delete(loadingModelPromise)

          entityThreeModel.obj = newObjValue

          const ent = getEntity()

          newObjValue.name = `${entityDisplayName(ent)}_${ent.id}`
          entityCollectionGroup.add(newObjValue)
          if (DEV) {
            parse(DegreeNumberSchema, ent.heading)
          }
          
          newObjValue.userData.heading = newObjValue.userData.targetHeading = ent.heading
          updateEntityThreeModel(entityThreeModel, ent, engine)
          addEntityThreeModelDebugTextSprite(newObjValue, ent)
          if (isEntityUnit(ent)) {
            addProcedurallyGeneratedEntityTextures(newObjValue, ent)
          }
          // because we are making the object exist for the first time,
          // we'll use a large number for elapsedMs to jump straight to intended heading
          applyEntityRotationToNextObject3DFrame(newObjValue, POSTGRES_MAX_INT)
    
          // const prevValue = entityThreeModelMap.get(entityId)
          if (obj) {
            entityCollectionGroup.remove(obj)
            disposeRecursive(obj)
          }
          obj = newObjValue
          // entityThreeModelMap.set(entityId, entityThreeModel)
        } catch (err) {
          console.error(err)
        }
      }))

      onCleanup(() => {
        if (obj) {
          entityCollectionGroup.remove(obj)
          disposeRecursive(obj)
        }
      })

      // if the entity's state has changed, we must update the object3D
      createEffect(on(getEntity, (ent) => {
        // const ent = getEntity()
        if (isPlainObjDeeplyEqual(prevEnt, ent)) {
          return
        }
        // console.warn('entityThreeModel.h !== newHash')
        console.log('update.effect', ent.id, entityDisplayName(ent))
        console.log('  - diff', prettyPlainObjectDiffText(entityThreeModel.ent, ent))

        // const entityThreeModel = entityThreeModelMap.get(ent.id)
        if (obj) {
          entityThreeModel.g++ // Mark as updated
          updateEntityThreeModel(entityThreeModel, ent, engine)
        }

        prevEnt = deepClone(ent)
      }))
      return
    }
  )

  createEffect(mapped)

  onCleanup(() => {
    entityCollectionGroup.removeFromParent()
    disposeRecursive(entityCollectionGroup)

    modelsLoadingPromiseSet.forEach(async (p) => {
      try {
        const obj = await p
        // const { obj } = entityThreeModel
        if (obj) {
          disposeRecursive(obj)
          obj.removeFromParent()
        }
      } catch (err) {
        console.error(err)
      }
    })
  })

  return {
    group: entityCollectionGroup,
    ents: entityThreeModelMap,
  }
}
