import { nullable, number, optional, picklist, pipe, type InferOutput } from "valibot"
import { EntityHeadingEastDeg, EntityHeadingNorthDeg, EntityHeadingSouthDeg, EntityHeadingWestDeg } from "./consts"

export const EntityHeadingSchema = pipe(number(), picklist([
  EntityHeadingNorthDeg, // 0
  EntityHeadingEastDeg, // 90
  EntityHeadingSouthDeg, // 180
  EntityHeadingWestDeg, // 270
], "Invalid heading"))

export type EntityHeading = InferOutput<typeof EntityHeadingSchema>

export const NullableEntityHeadingSchema = nullable(EntityHeadingSchema)

export type NullableEntityHeading = InferOutput<typeof NullableEntityHeadingSchema>

export const OptionalNullableEntityHeadingSchema = optional(NullableEntityHeadingSchema)

export type OptionalNullableEntityHeading = InferOutput<typeof OptionalNullableEntityHeadingSchema>