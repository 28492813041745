import { createMemo, type Accessor } from "solid-js"
import type { Engine } from "../../lib/core/engine/Engine.type"
import type { Entity } from "../../lib/core/entity/index"
import type { PlayerId } from "../../lib/core/player/PlayerId"
import type { TilePositionXY } from "../../lib/core/tile_position_xy/TilePositionXY.type"

export type EntityXYGridMap = Map<TilePositionXY, Array<Entity>>

export function createFirstPlayerIdMemo(engine: Engine): Accessor<PlayerId> {
  const memo = createMemo(() => {
    return engine.state.players[0].id
  })

  return memo
}