import { ErrorBoundary } from "solid-js"
/* @refresh reload */
import '@/sentry'
import { captureException } from '@sentry/browser'
import { Route, Router } from "@solidjs/router"
import { render } from 'solid-js/web'
import { flatten, isValiError } from "valibot"
import App from './App'
import SignInPage from './pages/Auth/SignInPage'
import SignOutPage from './pages/Auth/SignOutPage'
import CodexDamageCalculatorIndexPage from "./pages/Codex/DamageCalculator/Index"
import CodexDamageCalculatorMatrixPage from "./pages/Codex/DamageCalculator/Matrix"
import CodexIndexPage from './pages/Codex/Index'
import CodexTerrainIndexPage from './pages/Codex/Terrain/Index'
import CodexTerrainShowPage from './pages/Codex/Terrain/Show'
import CodexUnitIndexPage from './pages/Codex/Unit/Index'
import CodexUnitShowPage from './pages/Codex/Unit/Show'
import GameIndexPage from './pages/Games/Index'
import GamePlayPage from "./pages/Games/Play"
import GameReplayPage from "./pages/Games/Replay"
import GameShowPage from './pages/Games/Show'
import IndexPage from './pages/IndexPage'
import MapCreatePage from './pages/Maps/Create'
import MapEditPage from "./pages/Maps/Edit"
import MapIndexPage from './pages/Maps/Index'
import MapShowPage from './pages/Maps/Show'
import NotFound from './pages/NotFound'
import PlayCreateGamePage from './pages/Play/CreateGamePage'
import PlayCreateLobbyPage from "./pages/Play/CreateLobbyPage"
import ProfilePage from './pages/ProfilePage'
import './sass/index.sass'

render(() => (
  <ErrorBoundary
    fallback={(err) => {
      // if (error instanceof AxiosError) {
      //   const statusCode = error.response?.status
      //   // if (statusCode === 406) {
      //   //   // message might be "JSON object requested, multiple (or no) rows returned"
      //   // }
      //   // console.log('AxiosError.error.code', error.code)
      //   // console.log('AxiosError.error.response.status', error.response.status)
      // }

      const sentryContext: Record<string, unknown> = {}
      if (isValiError(err)) {
        sentryContext.errors = flatten(err.issues)
      }
      console.error(err, sentryContext)
      captureException(err, sentryContext)
      return <div class="alert alert-danger">{err?.message || 'Unknown Error'}</div>
    }}
  >
    <Router root={App}>
      <Route path="/lobby/create" component={PlayCreateLobbyPage} />
      <Route path="/game/create" component={PlayCreateGamePage} />
      <Route path="/game/replay" component={GameReplayPage} />
      <Route path="/game/play" component={GamePlayPage} />
      <Route path="/game" component={GameShowPage} />
      <Route path="/games" component={GameIndexPage} />
      <Route path="/maps/create" component={MapCreatePage} />
      <Route path="/map/edit" component={MapEditPage} />
      <Route path="/map" component={MapShowPage} />
      <Route path="/maps" component={MapIndexPage} />
      <Route path="/auth/sign-in" component={SignInPage} />
      <Route path="/auth/sign-out" component={SignOutPage} />
      <Route path="/auth/profile" component={ProfilePage} />
      <Route path="/codex/damage-calculator" component={CodexDamageCalculatorIndexPage} />
      <Route path="/codex/damage-calculator/matrix" component={CodexDamageCalculatorMatrixPage} />
      <Route path="/codex/unit/:unit" component={CodexUnitShowPage} />
      <Route path="/codex/unit" component={CodexUnitIndexPage} />
      <Route path="/codex/terrain/:terrain" component={CodexTerrainShowPage} />
      <Route path="/codex/terrain" component={CodexTerrainIndexPage} />
      <Route path="/codex" component={CodexIndexPage} />
      {/* <Route path="/test/context" component={TestContextPage} /> */}
      <Route path="/" component={IndexPage} />
      <Route path="*" component={NotFound} />
    </Router>
  </ErrorBoundary>
), document.getElementById('root') as HTMLElement)
