import { InferOutput, literal, object } from 'valibot'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { BaseEntitySchema } from '../BaseEntity'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityIdSchema } from '../EntityId.type'
import { EntityTypeId } from '../EntityTypeId.enum'

const entDefaults = {
  etype_id: EntityTypeId.Shoal,
  layer_id: EntityLayerId.TerrainL1,
} as const

export const ShoalEntitySchema = object({
  ...BaseEntitySchema.entries,
  etype_id: literal(EntityTypeId.Shoal),
  layer_id: literal(EntityLayerId.TerrainL1),
  id: EntityIdSchema,
  // shoal: fallback(ShoalKindSchema, () => ShoalKind.Island),
  ...HasXYSchema.entries,
})

export type ShoalEntity = InferOutput<typeof ShoalEntitySchema>

export const ShoalEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Shoal,
  dname: 'Shoal',
  layer_id: EntityLayerId.TerrainL1,
  defense: 0,
  entDefaults,
}

export function createShoalEntity(
  id: number,
  x: number,
  y: number,
  // shoal: ShoalKind
): ShoalEntity {
  return {
    ...entDefaults,
    heading: 0,
    id,
    x,
    y,
    // shoal,
  }
}
