import { onCleanup } from 'solid-js'
import disposeRecursive from '../../lib/three/fn/disposeRecursive'
import type { HasDisposeOrTraverse } from '../../typescript'

/**
 * Disposes a disposable on cleanup
 */
export function onCleanupDispose(disposable: HasDisposeOrTraverse) {
  onCleanup(() => {
    disposeRecursive(disposable)
  })
}
