import { DEV } from "solid-js"
import { MathUtils } from "three"
import createDebugPrintOnChangeEffect from "../../../../rx/effect/createDebugPrintOnChangeEffect"
import { deepClone } from "../../../deep_clone"
import isNil from "../../../ldsh/isNil"
import type { EntityThreeModel } from "../../../three/EntityThreeModel/EntityThreeModel.type"
import { abs } from "../../util/math"
import normalizeDeg from "../../util/normalizeDeg"
import toFixed1 from "../../util/toFixed1"
import { isEntityLightRecon } from "../entityTypeMetaList.generated"

const entityRotationSpeed = 90 // degrees per second

const { clamp, degToRad, radToDeg } = MathUtils

const log = createDebugPrintOnChangeEffect('WalkHeading')
const clampLog = createDebugPrintOnChangeEffect('WalkHeadingClamp')

export default function applyEntityRotationToNextObject3DFrame(
  obj: NonNullable<EntityThreeModel['obj']>,
  elapsedMs: number,
) {
  // if (isEntityRoad(obj.userData.e)) {
  //   console.log(entityDisplayName(obj.userData.e), deepClone(obj.userData.e))
  //   console.log('targetHeading', obj.userData.targetHeading, 'heading', obj.userData.heading)
  // }
  // Ensure it's in [0, 360)
  const targetHeadingRaw = obj.userData.targetHeading
  if (isNil(targetHeadingRaw)) {
    if (DEV) {
      throw new Error('isNil(targetHeadingRaw)')
    }
    return
  }
  if (DEV) {
    const headingRaw = obj.userData.heading
    if (isNil(headingRaw)) {
      throw new Error('isNil(headingRaw)')
    }
  }
  // something about 180 lastStep breaks
  // if I don't do this.
  if (obj.userData.walk) {
    return
  }
  const currentHeadingDeg = normalizeDeg(radToDeg(obj.rotation.y))
  const targetHeadingDeg = normalizeDeg(targetHeadingRaw)

  // Compute the shortest rotation difference in the range [-180, 180]
  let rotationDifference = targetHeadingDeg - currentHeadingDeg
  if (rotationDifference > 180) {
    rotationDifference -= 360
  } else if (rotationDifference < -180) {
    rotationDifference += 360
  }
  if (obj.userData.e && isEntityLightRecon(obj.userData.e) && obj.userData.player_id === 29992) {
    log({
      currentHeadingDeg: toFixed1(currentHeadingDeg),
      targetHeadingDeg: toFixed1(targetHeadingDeg),
      rotationDifferenceA: toFixed1(targetHeadingDeg - currentHeadingDeg),
      rotationDifferenceB: toFixed1(rotationDifference),
      'obj.rotation.y': toFixed1(radToDeg(obj.rotation.y)),
      'obj.userData': deepClone(obj.userData),
    })
  }

  if (rotationDifference !== 0) {
    const maxRotationStep = (entityRotationSpeed * elapsedMs) / 1000

    if (abs(rotationDifference) <= maxRotationStep) {
      // If the required rotation is smaller than max step, snap to final rotation
      obj.rotation.y = degToRad(targetHeadingDeg)
      obj.userData.heading = targetHeadingDeg
    } else {
      // Otherwise, apply the incremental rotation step
      const rotationStep = clamp(rotationDifference, -maxRotationStep, maxRotationStep)
      const oldDeg = normalizeDeg(radToDeg(obj.rotation.y))
      const newDeg = normalizeDeg(currentHeadingDeg + rotationStep)
      if (obj.userData.e && isEntityLightRecon(obj.userData.e) && obj.userData.player_id === 29992) {
        clampLog({
          rotationStep: toFixed1(rotationStep),
          rotationDifference: toFixed1(rotationDifference),
          maxRotationStep: toFixed1(maxRotationStep),
          oldDeg: toFixed1(oldDeg),
          newDeg: toFixed1(newDeg),
        })
      }
      // commenting out `obj.rotation.y = degToRad(newDeg)` fixed the
      // "Recon does 180 after commit"
      // still looking for what this line made work tho
      // Update: this line makes "MediumArtillary" Turrent rotate on bombard
      if (obj.name === 'Turret') {
        obj.rotation.y = degToRad(newDeg)
      }
      obj.rotation.y = degToRad(newDeg)
      obj.userData.heading = newDeg
    }
  }
}
