import type { BoxGeometry, Material, Object3D, Texture } from "three"
import disposeMaterialTextures from "./disposeMaterialTextures"

type MaybeHasMaterialAndGeometry = {
  material: (Material & Record<string, Texture>) | null,
  geometry: BoxGeometry | null,
}

export default function disposeForTraverse (obj : Object3D): void {
  const { material, geometry } = obj as unknown as MaybeHasMaterialAndGeometry
  if (material) {
    disposeMaterialTextures(material)
    material.dispose()
  }
  if (geometry) {
    geometry.dispose()
  }
}