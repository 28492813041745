// an engine will usually have one mode
// but for debug purposes
// and future features
// multiple modes can active at the same time
export enum EngineMode {
  MapEditor = 1 << 1,
  Lobby = 1 << 2,
  Game = 1 << 3,
  Replay = 1 << 4,
  MapPreview = 1 << 5,
}
