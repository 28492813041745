import { DEV } from 'solid-js'
import { mergeGeometries, mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import { createSharedEntityXYGridMapMemo } from '../../../../rx/memo/createSharedEntityXYGridMapMemo'
import type { Engine } from '../../../core/engine/Engine.type'
import type { ShoalEntity } from '../../../core/entity/EntityType/Shoal'
import { createPerlinNoise2D } from '../../../core/rng/perlinNoise2DV2'
import tmpRngWith from '../../../core/rng/tmpRngWith'
import { DIRECTION_DXDY_DOWN, DIRECTION_DXDY_DOWN_LEFT, DIRECTION_DXDY_DOWN_RIGHT, DIRECTION_DXDY_LEFT, DIRECTION_DXDY_RIGHT, DIRECTION_DXDY_UP, DIRECTION_DXDY_UP_LEFT, DIRECTION_DXDY_UP_RIGHT } from '../../../core/tile_position_xy/dijkstraDirections'
import { abs, max, min, sqrt } from '../../../core/util/math'
import Unexpected from '../../../Exception/Unexpected.class'
import { VERTICES_PER_TILE } from '../../consts'
import createInwardPointedPlaneGeometry from '../../fn/createInwardPointedPlaneGeometry'
import setGeometryZValues from '../../fn/setGeometryZValues'
import getShoalNeighborType from './getShoalNeighborType'
import { ShoalNeighborType } from './ShoalNeighborType.enum'

// a regular plane will have all it's triangles
// pointing diagonally in the same direction
export default function createShoalTerrainGeometry (
  engine : Engine,
  shoalEnts : Array<ShoalEntity>,
) {
  console.log('createShoalTerrainGeometry')

  if (DEV) {
    if (shoalEnts.length === 0) {
      throw new Unexpected('shoalEnts.length === 0')
    }
  }

  const getEntityXYGridMap = createSharedEntityXYGridMapMemo(engine)
  const entityXYGridMap = getEntityXYGridMap()

  const size = VERTICES_PER_TILE / 2
  const segments = 3 * 2

  // {
  //   const geometry = createInwardPointedPlaneGeometry(
  //     size, size, segments, segments)
  //   setGeometryZValues(geometry, sphericalDome4)
  //   return geometry
  // }

  // console.log('shoalEnts', deepClone(shoalEnts))

  // const table : Array<object> = []

  // const vals = new Set()

  const geometryList = shoalEnts.map((ent : ShoalEntity) => {

    const noise2D = createPerlinNoise2D(tmpRngWith(ent.id))

    const geometry = createInwardPointedPlaneGeometry(size, size, segments, segments)

    const northType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_UP, ent)
    const southType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_DOWN, ent)
    const westType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_LEFT, ent)
    const eastType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_RIGHT, ent)
    const northEastType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_UP_LEFT, ent)
    const northWestType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_UP_RIGHT, ent)
    const southWestType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_DOWN_LEFT, ent)
    const southEastType = getShoalNeighborType(entityXYGridMap, DIRECTION_DXDY_DOWN_RIGHT, ent)

    // table.push({
    //   ...toCoord(ent),
    //   n: northType,
    //   s: southType,
    //   w: westType,
    //   e: eastType,
    // })

    // const radius = 16

    const t1 = 8
    const t2 = 6

    setGeometryZValues(geometry, (x: number, y: number, z: number): number => {
      const absX = abs(x)
      const absY = abs(y)
      const maxAbsXY = max(absX, absY)
      // const distanceToEdge = radius - maxAbsXY

      const islandY = sphericalDome4B(x, y, z) - 2

      if (northType === ShoalNeighborType.Sea
        && southType === ShoalNeighborType.Sea
        && westType === ShoalNeighborType.Sea
        && eastType === ShoalNeighborType.Sea
      ) {
        return islandY
      }

      // if (islandY > 0.5) {
      //   // return 1
      // }

      if ((y < -t1 && northType === ShoalNeighborType.Sea)
        || (y > t1 && southType === ShoalNeighborType.Sea)) {

        if ((x > t2) && (eastType === ShoalNeighborType.Sea)) {
          return islandY
        }
        if ((x < -t2) && (westType === ShoalNeighborType.Sea)) {
          return islandY
        }
        if ((x > t2) && (eastType === ShoalNeighborType.Sea)) {
          return islandY
        }
        return sphericalDome4B((x**2/t1**2), y, z) - 2
      }


      if ((x < -t1 && westType === ShoalNeighborType.Sea)
        || (x > t1 && eastType === ShoalNeighborType.Sea)) {
        if ((y > t2) && (southType === ShoalNeighborType.Sea)) {
          return islandY
        }
        if ((y < -t2) && (northType === ShoalNeighborType.Sea)) {
          return islandY
        }

        return sphericalDome4B(x, (y**2/t1**2), z) - 2
      }

      if (x < -t1 && y < -t1 && northEastType === ShoalNeighborType.Sea) {
        if (absX > absY) {
          return sphericalDome4B((x**2/t1**2), y, z) - 2
        } else {
          return sphericalDome4B(x, (y**2/t1**2), z) - 2
        }
      }
      if (x > t1 && y < -t1 && northWestType === ShoalNeighborType.Sea) {
        if (absX > absY) {
          return sphericalDome4B((x**2/t1**2), y, z) - 2
        } else {
          return sphericalDome4B(x, (y**2/t1**2), z) - 2
        }
      }

      if (x < -t1 && y > t1 && southWestType === ShoalNeighborType.Sea) {
        if (absX > absY) {
          return sphericalDome4B((x**2/t1**2), y, z) - 2
        } else {
          return sphericalDome4B(x, (y**2/t1**2), z) - 2
        }
      }
      if (x > t1 && y > t1 && southEastType === ShoalNeighborType.Sea) {
        if (absX > absY) {
          return sphericalDome4B((x**2/t1**2), y, z) - 2
        } else {
          return sphericalDome4B(x, (y**2/t1**2), z) - 2
        }
      }

      // vals.add(noise2D(x, y))
      // vals.add(max(0.1, (noise2D(x, y) + 0.5)))
      // return 0.1
      const gx = ((ent.x) * VERTICES_PER_TILE) + x
      const gy = ((ent.y) * VERTICES_PER_TILE) + y

      const div = 10
      // const nv = ((noise2D(gx/div, gy/div) + 0.5) * 1.5) ** 2
      const nv = (noise2D(gx/div, gy/div) + 0.5) * 1.8

      if (maxAbsXY > 14) {
        return sphericalDome4B(0, t1, z) - 2
      }
      const t3 = 10
      if (maxAbsXY > t3) {
        return max(0.1, nv * (1 - ((maxAbsXY - t3) / maxAbsXY)))
      }

      return max(0.1, nv)

      // return islandY

      // return 2
      // return 0.5
      // return -1


      // const maxAbsXY = max(abs(x), abs(y))
      // const distanceToEdge = radius - maxAbsXY
      // if (x < 0 && westType !== ShoalNeighborType.Sea
      //   || x > 0 && eastType !== ShoalNeighborType.Sea
      // ) {
      //   if (y < 0 && northType !== ShoalNeighborType.Sea
      //     || y > 0 && southType !== ShoalNeighborType.Sea) {
      //     return sphericalDome4B(0, 0, z) * (distanceToEdge / 16)
      //   }
      //   // return sphericalDome4B(0, y, z) * (distanceToEdge / 16)
      //   // return sphericalDome4B(0, y, z) * (distanceToEdge / 16) - 2
      // }
      
      // return sphericalDome4B(x, y, z) - 2
    })

    geometry.translate(
      ent.x * VERTICES_PER_TILE,
      ent.y * -VERTICES_PER_TILE, 0)
    // setObjPosition(geometry, ent)
    return geometry
  })

  // console.table(table)
  // console.log([...vals].sort((a, b) => a - b))

  const geometry = mergeGeometries(geometryList, false)
  mergeVertices(geometry)

  return geometry
}

// function sphericalDome4A(
//   x: number,
//   y: number,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   z: number
// ): number {
//   // console.table([{x, y, z}])
//   const maxDistance = sqrt((16 ** 2) * 2) - 4
//   // round mound:
//   // const distance = sqrt(x **2  + y **2)
//   // round, but corners stretch out
//   const distance = (x **4  + y **4) ** (1/4)

//   // Linearly scale the height from 1 to -2
//   const z1 = 2 - (((distance ** 2.1) / (maxDistance ** 2)) * 5)

//   // const z2 = z1 ** 2

//   // Clamp the z value between -2 and maxZ
//   return max(-2, min(2, z1))
// }

function sphericalDome4B(
  x: number,
  y: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  z: number
): number {
  // console.table([{x, y, z}])
  const maxDistance = sqrt((16 ** 2) * 2) - 4
  // round mound:
  // const distance = sqrt(x **2  + y **2)
  // round, but corners stretch out
  const distance = (x **4  + y **4) ** (1/4)

  // Linearly scale the height from 1 to -2
  const z1 = 4 - (((distance ** 2.1) / (maxDistance ** 2)) * 5)

  return max(0, min(4, z1))
}

// console.table([
//   [0, 0],
//   [0, 8],
//   [0, 16],
//   [8, 8],
//   [8, 16],
// ].map((input) => {
//   const [x, y] = input
//   const z = 0
//   return {
//     x,
//     y,
//     z: toFixed1(sphericalDome4B(x, y, z)),
//   }
// }))