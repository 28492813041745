import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import { floor } from "../../core/util/math"

export const DEBUG_TEXT_OBJECT3D_NAME = 'DT'

export default function createDebugTextSprite(text: string): Sprite {
  // console.log('createDebugTextSprite')
  const size = 256 // Size of the canvas
  const canvas = createOffscreenCanvas(size, size)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    // Set text properties
    ctx.font = '48px Arial'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = 'rgba(0,0,0,0.25)'
    ctx.fillRect(0, 0, size, size)
    ctx.fillStyle = 'white'
    const lines = text.split('\n')
    const lineHeight = floor(size / (lines.length + 1))
    lines.forEach((line, index) => {
      ctx.fillText(line, size / 2, (index + 1) * lineHeight)
    })
  }

  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({ map: texture, transparent: true })
  const sprite = new Sprite(material)
  // sprite.scale.set(5, 5, 1) // Adjust size to match box

  // sprite.userData.vampire = true

  sprite.name = DEBUG_TEXT_OBJECT3D_NAME

  sprite.visible = false

  return sprite
}
