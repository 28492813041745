import { DEV } from "solid-js"
import { getCanvas2dContext } from "./get_canvas_2d_context"

export function makeCanvasBlankAndTransparent(
  canvas: HTMLCanvasElement | OffscreenCanvas,
  x: number = 0,
  y: number = 0,
  w: number = canvas.width,
  h: number = canvas.height
): void {
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    ctx.clearRect(x, y, w, h)
    ctx.fillStyle = 'transparent'
    if (DEV) {
      ctx.fillStyle = 'pink'
    }
    ctx.fillRect(x, y, w, h)
  }
}
