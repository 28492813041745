import { EntityHeadingEastDeg, EntityHeadingNorthDeg, EntityHeadingSouthDeg, EntityHeadingWestDeg } from "../../entity/EntityHeading/consts"
import type { EntityHeading } from "../../entity/EntityHeading/EntityHeading.type"
import isOrthogonallyAdjacent from "../isOrthogonallyAdjacent"
import type { TilePositionXY } from "../TilePositionXY.type"

export default function getEntityHeadingDegFromAToB(
  p1: TilePositionXY,
  p2: TilePositionXY
): EntityHeading {
  if (isOrthogonallyAdjacent(p1, p2)) {
    if (p1.y > p2.y) {
      return EntityHeadingSouthDeg
    }
    if (p1.y < p2.y) {
      return EntityHeadingNorthDeg
    }
    if (p1.x < p2.x) {
      return EntityHeadingEastDeg
    }
    if (p1.x > p2.x) {
      return EntityHeadingWestDeg
    }
  }
  throw new Error("p1 and p2 are the same or invalid movement")
}