import { createEffect, on, onCleanup } from "solid-js"
import { modifyMutable, produce, unwrap } from "solid-js/store"
import { Group } from "three"
import type { Nullable } from "vite-node"
import calculateDestPositionHoverPositionPaths from "../../core/draft_move/calculateDestPositionHoverPositionPaths"
import calculateDraftMoveAttackEstimates from "../../core/draft_move/calculateDraftMoveAttackEstimates"
import calculateDraftMoveRangedAttackEstimates from "../../core/draft_move/calculateDraftMoveRangedAttackEstimates"
import createDraftMove from "../../core/draft_move/createDraftMove"
import type { DraftMove } from "../../core/draft_move/DraftMove.type"
import type { Engine } from "../../core/engine/Engine.type"
import type { Entity } from "../../core/entity/index"
import isEntityHasMovesLeft from "../../core/entity/isEntityHasMovesLeft"
import type { UnitEntityUnion } from "../../core/entity/UnitEntityUnion.type"
import { SelectedToolId } from "../../core/map_editor/SelectedToolId.enum"
import createIsPositionInDraftMoveRangedAttackRange from "../../core/tile_position_xy/createIsPositionInRangedAttackRange/createIsPositionInDraftMoveRangedAttackRange"
import createIsPositionReachableInOneTurnV2 from "../../core/tile_position_xy/createIsPositionReachableInOneTurnV2/createIsPositionReachableInOneTurnV2"
import createLazyEfficientPathGrid, { type LazyEfficientPathGrid_Result } from "../../core/tile_position_xy/createLazyEfficientPathGrid"
import entityListToGridXY from "../../core/tile_position_xy/entityListToGridXY"
import { samePosition } from "../../core/tile_position_xy/samePosition"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import createRequestAnimationFramePromise from "../../createRequestAnimationFramePromise"
import { deepClone } from "../../deep_clone"
import isNotNil from "../../ldsh/isNotNil"
import BlueMovementArrowPathGroup from "./BlueMovementArrowPathGroup.class"
import createIsPositionInRangedAttackRangeGroup from "./createIsPositionInRangedAttackRangeGroup"
import createIsPositionReachableInOneTurnGroup from "./createIsPositionReachableInOneTurnGroup"
import disposeRecursive from "./disposeRecursive"
import RedAttackArrowPathGroup from "./RedAttackArrowPathGroup.class"

export default function createDraftMoveObject3D (engine: Engine) {
  // console.log('createDraftMoveObject3D')
  const { threeCtx } = engine
  if (threeCtx) {
    const { scene } = threeCtx
    const group = new Group()
    group.name = 'DraftMove'

    // const entityObject3DReactiveMap = createSharedEntityObject3DReactiveMap(engine)

    // const getSelectedPositionEnts = createSharedGetSelectedPositionEntsListMemo(engine)

    // createEffect(on(()=>engine.hoveredPosition, (newValue) => {
    //   console.log('%c[createDraftMoveObject3D].hoveredPosition', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(newValue))
    // }))
    // createEffect(on(()=>engine.selectedPosition, (newValue) => {
    //   console.log('%c[createDraftMoveObject3D].selectedPosition', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(newValue))
    // }))
    // createEffect(on(getSelectedPositionEnts, (newValue) => {
    //   console.log('%c[createDraftMoveObject3D].selectedPositionEnts', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(newValue))
    // }))
    // createEffect(on(()=>engine.draftMove, (draftMove) => {
    //   console.log('%c[createDraftMoveObject3D].draftMove', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(draftMove))
    // }))

    // createIsPositionReachableInOneTurnGroup
    let isPositionReachableInOneTurnGroup : Nullable<Group>
    let isPositionInRangedAttackRangeGroup : Nullable<Group>
    const blueArrowPathGroup = new BlueMovementArrowPathGroup()
    scene.add(blueArrowPathGroup)
    const redArrowPathGroup = new RedAttackArrowPathGroup()
    scene.add(redArrowPathGroup)
    function updateIsPositionInGridGroups () {
      const { selectedTool } = engine
      if (isPositionReachableInOneTurnGroup) {
        isPositionReachableInOneTurnGroup.visible = selectedTool !== SelectedToolId.RangedAttackRadius
      }
      if (isPositionInRangedAttackRangeGroup) {
        const shouldShowBombard = selectedTool === SelectedToolId.RangedAttackRadius
        isPositionInRangedAttackRangeGroup.visible = shouldShowBombard
        // isPositionInRangedAttackRangeGroup.visible = true
        if (!shouldShowBombard) {
          redArrowPathGroup.reset()
        }
      }
      // RedAttackArrowPathGroup
    }
    createEffect(updateIsPositionInGridGroups)
    function removeIsPositionInGroups() {
      // console.log('removeIsPositionInGroups')
      if (isPositionReachableInOneTurnGroup) {
        isPositionReachableInOneTurnGroup.removeFromParent()
        disposeRecursive(isPositionReachableInOneTurnGroup)
      }
      if (isPositionInRangedAttackRangeGroup) {
        isPositionInRangedAttackRangeGroup.removeFromParent()
        disposeRecursive(isPositionInRangedAttackRangeGroup)
      }
      blueArrowPathGroup.reset()
      redArrowPathGroup.reset()
    }
    onCleanup(removeIsPositionInGroups)

    // console.log(name)
    let isPositionReachableInOneTurn: Nullable<(x: number, y: number) => boolean> = null
    let isPositionInRangedAttackRange: Nullable<(x: number, y: number) => boolean> = null
    // let canUnitDirectAttackThisTurn: Nullable<(x: number, y: number) => boolean> = null
    // let canUnitRangedAttackThisTurn: Nullable<(x: number, y: number) => boolean> = null
    let getEfficientPathToTile: LazyEfficientPathGrid_Result | null = null
    let entityGridXY: ReturnType<typeof entityListToGridXY> | null = null

    createEffect(on(() => engine.selectedPosition, (selectedPosition : TilePositionXY | null) => {
      // console.log('%c[createDraftMoveObject3D].effect(on(selectedPosition))', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(selectedPosition))
      selectedPosition = unwrap(selectedPosition)
      // console.log(
      //   `[${name}].effect.selectedPosition`,
      //   deepClone({ selectedPosition, draftMove: engine.draftMove })
      // )
      let draftUnitEntity: Entity | null = null
      const { authPlayerId, draftMove, selectedPositionEnts } = engine
      if (draftMove) {
        // console.log('already have draftMove')
        if (selectedPosition && !samePosition(selectedPosition, draftMove.startPosition)) {
          // console.warn('selectedPosition different than draftMove', {
          //   selectedPosition,
          //   draftMoveStartPosition: draftMove.startPosition,
          //   samePosition: samePosition(selectedPosition, draftMove.startPosition),
          // })
        }
      } else if (selectedPosition) {
        const selectedPositionEntsThatCanMove = unwrap(selectedPositionEnts).filter((ent) => {
          // player must select "unload" first
          if (ent.taxi_id) {
            return false
          }
          // console.log(entityDisplayName(ent))
          // console.log('ent.player_id === authPlayerId', ent.player_id, ent.player_id === authPlayerId, authPlayerId)
          // console.log('isEntityHasMovesLeft(ent)', isEntityHasMovesLeft(ent))
          if (ent.player_id === authPlayerId) {
            return isEntityHasMovesLeft(ent)
          }
          return false
        })
        // console.log('selectedPositionEntsThatCanMove', deepClone(selectedPositionEntsThatCanMove))
        // console.log('selectedPositionEntsThatBelongToAuthPlayer', selectedPositionEnts)
        if (selectedPositionEntsThatCanMove.length === 1) {
          draftUnitEntity = unwrap(selectedPositionEntsThatCanMove[0])
          // console.log(`[${name}].effect.draftUnitEntity`, deepClone(draftUnitEntity))

          // console.log(`[${name}].effect.isPositionReachableInOneTurn`, isPositionReachableInOneTurn)
        } else {
          // console.log(
          //   'selectedPositionEntsThatBelongToAuthPlayer',
          //   deepClone(selectedPositionEnts)
          // )
          entityGridXY = null
          // isPositionReachableInOneTurn = null
          // isPositionInRangedAttackRange = null
          removeIsPositionInGroups()
        }

        createRequestAnimationFramePromise().then(() => {
          modifyMutable(
            engine,
            produce((engine: Engine) => {
              if (draftUnitEntity) {
                engine.selectedTool = SelectedToolId.MoveUnit
                // console.log('createDraftMove', deepClone({ draftUnitEntity }))
                // console.log('engine.draftMove = createDraftMove(draftUnitEntity)')
                engine.draftMove = createDraftMove(draftUnitEntity)
                calculateDestPositionHoverPositionPaths(engine)
                calculateDraftMoveAttackEstimates(engine)
                calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
              } else {
                engine.selectedTool = SelectedToolId.Inspect
              }
            })
          )
        })

        // console.log({ hoveredPosition, entityGridXY, draftUnitEntity })
        // lm.until = 0
      } else {
        // console.log('clearing draftMove utils')
        isPositionReachableInOneTurn = null
        isPositionInRangedAttackRange = null
        removeIsPositionInGroups()
      }
    }))

    createEffect(on(() => engine.draftMove, (draftMove: DraftMove | null) => {
      // console.log('%c[createDraftMoveObject3D].effect(on(draftMove))', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(draftMove))
      if (draftMove) {
        // console.log('draftMove NOT NULL')
        const { width, height, ents } = engine.state
        entityGridXY = entityListToGridXY(ents, width, height)
        removeIsPositionInGroups()
        isPositionReachableInOneTurn = createIsPositionReachableInOneTurnV2(
          entityGridXY,
          width,
          height,
          draftMove.unit
        )
        isPositionInRangedAttackRange = createIsPositionInDraftMoveRangedAttackRange(
          draftMove,
          width,
          height
        )

        isPositionReachableInOneTurnGroup = createIsPositionReachableInOneTurnGroup(engine, isPositionReachableInOneTurn)
        scene.add(isPositionReachableInOneTurnGroup)
        isPositionInRangedAttackRangeGroup = createIsPositionInRangedAttackRangeGroup(engine, isPositionInRangedAttackRange)
        scene.add(isPositionInRangedAttackRangeGroup)
        updateIsPositionInGridGroups()

        entityGridXY = entityListToGridXY(ents, width, height)
        getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, draftMove.unit)
        // canUnitDirectAttackThisTurn = createCanUnitDirectAttackThisTurn(getEfficientPathToTile)
        // canUnitRangedAttackThisTurn = createCanUnitRangedAttackThisTurn(engine.draftMove)

      } else {
        // console.log('draftMove IS NULL')
        entityGridXY = null
        // isPositionReachableInOneTurn = null
        // isPositionInRangedAttackRange = null
        removeIsPositionInGroups()
        getEfficientPathToTile = null
        // canUnitDirectAttackThisTurn = null
        // canUnitRangedAttackThisTurn = null
      }
      // lm.until = 0
    }))

    const getTargetPosition = () => engine.draftMove && (engine.draftMove?.destPosition || engine.hoveredPosition)
    createEffect(on(getTargetPosition, (targetPosition) => {
      // console.log('%c[createDraftMoveObject3D].effect(on(targetPosition))', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone(targetPosition))
      if (engine.selectedTool === SelectedToolId.MoveUnit) {
        const upts = targetPosition && isPositionReachableInOneTurn && getEfficientPathToTile
          && getEfficientPathToTile(targetPosition.x, targetPosition.y)
        blueArrowPathGroup.update(upts, engine)
      } else if (engine.selectedTool === SelectedToolId.RangedAttackRadius) {
        redArrowPathGroup.update(targetPosition, isPositionInRangedAttackRange, engine)
      }
    }))

    // const [getCloneEntityList, setCloneEntityList] = createSignal<Array<UnitEntityUnion>>([])
    // const clonedEntityThreeModelCollection = createEntityThreeModelCollection(engine, scene, getCloneEntityList)
    // clonedEntityThreeModelCollection.group.position.y += 2.5
    createEffect(() => {
      // console.log('%c[createDraftMoveObject3D].effect(on(destPosition))', 'color: #BB6; font-weight: lighter; font-size: 10px;', deepClone({
      //   destPosition: engine.draftMove?.destPosition || null,
      // }))
      const { draftMove, selectedTool } = engine
      if (draftMove && selectedTool === SelectedToolId.MoveUnit) {
        const { unit, destPosition, destHeading, destPositionPaths } = draftMove
        if (destPosition && !samePosition(destPosition, unit)) {
          // workaround for bug that createEntityThreeModelCollection
          const cloneEnt = deepClone({
            ...unit as UnitEntityUnion,
            ...destPosition,
          })
          // set heading from move estimates
          if (destPositionPaths) {
            const { lastStep } = destPositionPaths
            if (lastStep) {
              // console.log('lastStep.heading', lastStep.heading)

              // console.log('[createDraftMoveObject3D] cloneEnt.heading = lastStep.heading', {
              //   'cloneEnt.heading': cloneEnt.heading,
              //   'lastStep.heading': lastStep.heading,
              //   destHeading,
              // })

              cloneEnt.heading = lastStep.heading
            }
          }
          // allow override heading
          if (isNotNil(destHeading)) {
            // console.log('draftMove.destHeading', draftMove.destHeading)

            // console.log('[createDraftMoveObject3D] cloneEnt.heading = destHeading', {
            //   'cloneEnt.heading': cloneEnt.heading,
            //   destHeading,
            // })
            cloneEnt.heading = destHeading
          }
          // setCloneEntityList([cloneEnt])
          // return early to not delete
          return
        }
      }
      // setCloneEntityList([])
    })

    scene.add(group)
  }
}