import { createRoot } from "solid-js"
import { type Object3D } from "three"
import createEngineForUI from "../../../core/engine/createEngineForUI"
import { createPlainEntity } from "../../../core/entity/EntityType/Plain"
import { createSeaEntity } from "../../../core/entity/EntityType/Sea"
import { createShoalEntity } from "../../../core/entity/EntityType/Shoal"
import createShoalTerrainMesh from "./createShoalTerrainMesh"

export default function makeShoalForIconObject3D (
  callback: (newGroup: Object3D) => void
): void {
  createRoot(() => {
    const engine = createEngineForUI()
    const { state } = engine
    let id = 1
    const ent1 = createSeaEntity(id++, 0, 0)
    const ent2 = createSeaEntity(id++, 0, 1)
    const ent3 = createSeaEntity(id++, 0, 2)
    const ent4 = createSeaEntity(id++, 1, 0)
    const ent5 = createShoalEntity(id++, 1, 1)
    const ent6 = createPlainEntity(id++, 1, 2)
    const ent7 = createPlainEntity(id++, 2, 0)
    const ent8 = createPlainEntity(id++, 2, 1)
    const ent9 = createPlainEntity(id++, 2, 2)
    state.ents = [
      ent1,
      ent2,
      ent3,
      ent4,
      ent5,
      ent6,
      ent7,
      ent8,
      ent9,
    ]
    state.width = state.height = 3
    const mesh1 = createShoalTerrainMesh(engine, [
      ent5,
    ])
    callback(mesh1)
  })
}
