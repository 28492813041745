import { type Object3D } from "three"
import { DEBUG_TEXT_OBJECT3D_NAME } from "./createDebugTextSprite"
import disposeForTraverse from "./disposeForTraverse"

export default function removeDebugTextObject(obj: Object3D): void {
  const list = new Set<Object3D>()
  obj.traverse((child) => {
    if (child.name === DEBUG_TEXT_OBJECT3D_NAME) {
      list.add(child)
    }
  })
  list.forEach(child => {
    child.traverse(disposeForTraverse)
    child.removeFromParent()
  })
}
