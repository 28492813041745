import { EntityHeadingEastDeg, EntityHeadingNorthDeg, EntityHeadingSouthDeg, EntityHeadingWestDeg } from "./consts"
import type { EntityHeadingMeta } from "./EntityHeadingMeta.type"

const entityHeadingsMetaList : Array<EntityHeadingMeta> = [
  { value: EntityHeadingNorthDeg,
    dname: 'North', abbr: 'N' },
  { value: EntityHeadingEastDeg,
    dname: 'East', abbr: 'E' },
  { value: EntityHeadingSouthDeg,
    dname: 'South', abbr: 'S' },
  { value: EntityHeadingWestDeg,
    dname: 'West', abbr: 'W' },
]

export default entityHeadingsMetaList