import { createEffect, DEV } from 'solid-js'
import { CatmullRomCurve3, Vector3 } from 'three'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getEntityRenderHeight from '../../lib/core/entity/getEntityRenderHeight'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import type { PathTurnStepCollection } from '../../lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnStepCollection.type'
import type { PathTurnSteps_Step } from '../../lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type'
import type { TilePositionXY } from '../../lib/core/tile_position_xy/TilePositionXY.type'
import toCoord from '../../lib/core/tile_position_xy/toCoord'
import { VERTICES_PER_TILE } from '../../lib/three/consts'
import getEntityExtraRenderHeight from '../../lib/three/EntityThreeModel/getEntityExtraRenderHeight'
import { BlueArrowPathDefaultY } from '../../lib/three/fn/BlueMovementArrowPathGroup.class'
import type { EntityThreeModelCollection } from '../../lib/three/fn/createEntityThreeModelCollection'
import createObject3DWalk from '../../lib/three/Object3DWalk/createObject3DWalk'
import type { Nullable } from '../../typescript'
import { createSharedEntityXYGridMapMemo } from '../memo/createSharedEntityXYGridMapMemo'
import { removeAllDebugCubes } from './DebugCube'

const AboveBlueArrowPathDefaultY = BlueArrowPathDefaultY + 2

export default function createDraftUnitMovesUnitEffect(
  engine: Engine,
  entityThreeModelCollection: EntityThreeModelCollection,
) {
  const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)
  const { group, ents: entityThreeModelMap } = entityThreeModelCollection
  createEffect(() => {
    // console.log('DraftUnitMovesUnitEffect')
    const { selectedTool, draftMove } = engine
    if (DEV) {
      removeAllDebugCubes(group)
    }
    if (draftMove && selectedTool === SelectedToolId.MoveUnit) {
      const { unit, destPosition, destPositionPaths, hoveredPositionPaths, unload } = draftMove
      // console.log('draftMove', deepClone(draftMove))
      const targetPosition = destPosition || (
        // if an unload is not active, use hoverPosition
        unload ? unit : engine.hoveredPosition
      )
      // console.log('targetPosition', deepClone(targetPosition))
      if (unload && !destPosition) {
        // do nothing
      } else if (targetPosition) {
        const entityThreeModel = entityThreeModelMap.get(unit.id)
        if (entityThreeModel) {
          const { obj, ent } = entityThreeModel
          // console.log('unitObj', entityDisplayName(unit), obj)
          if (obj) {

            // instantly reset unit to original position
            // later we'll add logic to backtrack if this ends up
            // glitchy
            const x = (ent.x * VERTICES_PER_TILE)
            const z = (ent.y * VERTICES_PER_TILE)
            const rh = getEntityRenderHeight(getEntityListAtXYMap().get(toCoord(ent)) || [], ent)
              + getEntityExtraRenderHeight(ent)
            obj.position.set(x, rh, z)

            const upts : Nullable<PathTurnStepCollection> = destPositionPaths || hoveredPositionPaths
            // console.log('upts', deepClone(upts))
            if (upts) {
              function stepToVector3 (step : TilePositionXY) {
                const { x: stateX, y: stateY } = step
                const stack = getEntityListAtXYMap().get(toCoord(step)) || []
                const dx = ((stateX) * VERTICES_PER_TILE)
                const dy = ((stateY) * VERTICES_PER_TILE)
                const dh = getEntityRenderHeight(stack)
                return new Vector3(dx, dh, dy)
              }
              const { turns, start: startPosition, lastStep } = upts
              const [turn] = turns
              if (turn) {
                const { steps } = turn
                const stepsLen = steps.length
                if (stepsLen > 0) {

                  const turnLastStep = turn.steps.at(-1) as PathTurnSteps_Step
                  // const turnLastStep2 = turn.steps.at(-2) as PathTurnSteps_Step

                  const catmullRomCurve3List = steps.map(stepToVector3)
                  const last1V3 = catmullRomCurve3List.at(-1)
                  const last2V3 = catmullRomCurve3List.at(-2)
                  // console.log('turnLastStep', deepClone(turnLastStep))
                  if (last1V3 && last2V3) {
                    // console.log('last1V3 && last2V3')
                    if (turnLastStep.occupant) {
                      // console.log('turnLastStep.occupant')
                      last1V3.lerp(last2V3, 0.5)
                      last1V3.y += AboveBlueArrowPathDefaultY
                    } else {
                      // console.log('!turnLastStep.occupant')
                      const newLast1V3 = last1V3.clone()
                      // the unit shall be above the blue arrow
                      newLast1V3.y += AboveBlueArrowPathDefaultY
  
                      last1V3.lerp(last2V3, 0.1)
                      // this does a bad rotation
                      catmullRomCurve3List.push(newLast1V3)
                    }
                  }
                  const extraFirstV3 = stepToVector3(startPosition).lerp(catmullRomCurve3List[0], 0.33)
                  catmullRomCurve3List.unshift(extraFirstV3)
                  const curve = new CatmullRomCurve3(catmullRomCurve3List)
                  const speed = 1
                  obj.userData.walk = createObject3DWalk(engine, entityThreeModel,
                    curve, lastStep, stepsLen, speed)

                  // // debug cubes
                  // if (DEV) {
                  //   catmullRomCurve3List.forEach((v3 : Vector3) => {
                  //     const debugCube = createDebugCube(v3, 0xffaaaa)
                  //     group.add(debugCube)
                  //     debugCube.position.y += 15
                  //   })
                  // }

                  return
                }
              }
            }
          }
        }
      }
    }

    // console.log('Reset DraftUnitMovesUnitEffect.walk')
    // we didn't return early, so reset
    group.traverse((obj) => {
      // const { turret } = obj.userData
      const walk = obj.userData.walk as ReturnType<typeof createObject3DWalk>
      if (walk) {
        walk.reset()
      }
    })
  })
}
