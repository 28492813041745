import type { Bone, Object3D } from "three"

export default function getBoneByName (
  object: Object3D,
  boneName: string
): Bone | undefined {
  let bone : Bone | undefined

  object.traverse((child) => {
    if ((child as Bone).isBone && child.name === boneName) {
      if (bone) {
        throw new Error(`Multiple bones named ${boneName}`)
      } else {
        bone = child as Bone
      }
    }
  });

  return bone
}
