// export enum ShoalNeighborType {
//   Sea = 1,
//   Shoal = 2,
//   Land = 3,
// }

import type { EntityXYGridMap } from "../../../../rx/memo/createSharedEntityXYGridMapMemo"
import { isEntityRiver, isEntitySea, isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import coord from "../../../core/tile_position_xy/coord"
import type { DirectionDxDy } from "../../../core/tile_position_xy/dijkstraDirections"
import { ShoalNeighborType } from "./ShoalNeighborType.enum"

export default function getShoalNeighborType (
  // engine : Engine
  entityXYGridMap: EntityXYGridMap,
  dir: DirectionDxDy,
  ent: Entity,
): ShoalNeighborType {
  const p2 = coord(ent.x + dir.dx, ent.y + dir.dy)
  const stack = entityXYGridMap.get(p2) || []

  if (stack.find(isEntitySea)) {
    return ShoalNeighborType.Sea
  }
  if (stack.find(isEntityRiver)) {
    return ShoalNeighborType.Sea
  }
  if (!stack.find(isEntityShoal)) {
    // etc includes voids, out of bounds.
    // will be treated as land
    return ShoalNeighborType.Land
  }
  return ShoalNeighborType.Shoal
}