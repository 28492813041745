import type { Defense } from '../Defense.type'
import type { Entity } from '../entity/index'
import findByIdOrThrow from '../findByIdOrThrow'
import type { HasMobility } from '../has_mobility'
import { EntityTypeId } from './EntityTypeId.enum'
import entityTypeMetaList from './entityTypeMetaList.generated'
import { MoveTypeId } from './move_type_id.enum'

export default function getUnitDefenseAtPosition(
  unit: Entity | null,
  positionStack: Array<Entity>
): Defense {
  let defenseValue = 0
  if (unit && (unit as HasMobility).mtype_id === MoveTypeId.Helicopter) {
    return defenseValue
  }
  for (let index = 0; index < positionStack.length; index++) {
    const entity = positionStack[index]
    const etm = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
    if (entity.etype_id === EntityTypeId.Road) {
      return etm.defense || 0
    }
    const entDefenseValue = etm.defense
    if ((entDefenseValue as number) > defenseValue) {
      defenseValue = entDefenseValue as number
    }
  }
  return defenseValue
}
