import { BufferGeometry, NormalBufferAttributes } from 'three'

/**
 * @deprecated
 */
export default function setGeometryZValues(
  geometry: BufferGeometry<NormalBufferAttributes>,
  fn: (x: number, y: number, z: number) => number
) {
  const positionAttribute = geometry.getAttribute('position')

  for (let i = 0; i < positionAttribute.count; i++) {
    const x = positionAttribute.getX(i)
    const y = positionAttribute.getY(i)
    const oldZ = positionAttribute.getY(i)
    const z = fn(x, y, oldZ)

    positionAttribute.setZ(i, z)
  }

  positionAttribute.needsUpdate = true
}
