import { createEffect, createSignal } from "solid-js"
import { type Scene } from "three"
import type { Nullable } from "vite-node"
import isIdListSame from "../../../../rx/signal/equals/isIdListSame"
import type { Engine } from "../../../core/engine/Engine.type"
import type { ShoalEntity } from "../../../core/entity/EntityType/Shoal"
import { isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import nullIfEmptyArray from "../../../ldsh/nullIfEmptyArray"
import disposeRecursive from "../../fn/disposeRecursive"
import createShoalTerrainMesh from "./createShoalTerrainMesh"

// pointing diagonally in the same direction
export default function createShoalTerrainEffect (
  engine : Engine,
  scene : Scene,
): void {
  // return
  // console.log('createShoalTerrainEffect')
  let mesh : Nullable<ReturnType<typeof createShoalTerrainMesh>>

  const [getShoalEnts, setTerrgetShoalEnts] = createSignal<Array<ShoalEntity>>([], {
    equals: isIdListSame,
  })

  createEffect(() => {
    setTerrgetShoalEnts(engine.state.ents.filter(isEntityShoal))
  })
  createEffect(() => {
    // console.log('[createShoalTerrainEffect].effect')

    const shoalEnts = getShoalEnts()

    const prevMesh = mesh

    const nextMesh = nullIfEmptyArray(shoalEnts) && createShoalTerrainMesh(engine, shoalEnts)
    if (prevMesh) {
      prevMesh.removeFromParent()
      disposeRecursive(prevMesh)
    }
    // nextMesh.rotateX(-PI / 2)
    // nextMesh.position.y = 10
    // nextMesh.position.y = 20
    // nextMesh.scale.multiplyScalar(100)

    if (nextMesh) {
      scene.add(nextMesh)
    }

    // const position = coord(4, 4)
    // setObjPosition(nextMesh, position)

    // console.log('[createShoalTerrainEffect] nextMesh', nextMesh)
    mesh = nextMesh
  })
}
