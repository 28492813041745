const A = 0x85ebca6b
const B = 0xc2b2ae35

export default function hashXYToNumber(x: number, y: number): number {
  // Constants (prime numbers for mixing)

  // Combine x and y deterministically
  x = x * 0x1b873593 + y * 0x1e35a7bd
  // Apply the mix function to spread entropy
  x ^= x >> 16
  x *= A
  x ^= x >> 13
  x *= B
  x ^= x >> 16
  return x
}
