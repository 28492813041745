import setMapControlsPolarAngle from "../../../three/fn/setMapControlsPolarAngle"
import type { Engine } from "../../engine/Engine.type"

export const SET_CAMERA_DEFAULT_POLAR_ANGLE_DEFAULT = 0.8

export default function handleSetCameraPolarAngleDefault(
  engine: Engine,
) {
  console.log('handleSetCameraPolarAngleDefault')
  const { threeCtx } = engine
  if (threeCtx) {
    const { mc: mapControls } = threeCtx
    setMapControlsPolarAngle(mapControls, SET_CAMERA_DEFAULT_POLAR_ANGLE_DEFAULT)
    mapControls.update()
  }
}

export function shouldOfferSetCameraPolarAngleDefault(
  // engine: Engine
) {
  return false
}