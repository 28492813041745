import { modifyMutable, produce } from "solid-js/store"
import type { Nullable } from "../../../../typescript"
import { deepClone } from "../../../deep_clone"
import calculateDraftMoveRangedAttackEstimates from "../../draft_move/calculateDraftMoveRangedAttackEstimates"
import type { Engine } from "../../engine/Engine.type"
import calculateSelectedPositionEnts from "../../entity/calculateSelectedPositionEnts"
import centerPanCameraToPosition from "../../entity/EntityHeading/centerPanCameraToPosition"
import { EntityTypeId } from "../../entity/EntityTypeId.enum"
import type { Entity } from "../../entity/index"
import isMapControlTarget from "../../entity/isMapControlTarget"
import { SelectedToolId } from "../../map_editor/SelectedToolId.enum"
import type { HasPlayerId } from "../../player/has_player_id"
import toCoord from "../../tile_position_xy/toCoord"

export default function handleRotateNextHome(engine: Engine, forward: boolean) {
  // console.log('handleRotateNextHome')
  modifyMutable(engine, produce((engine : Engine) => {
    engine.selectedTool = SelectedToolId.Inspect

    const nextHome = getNextHome(engine, forward)
    console.log('nextHome', deepClone(nextHome))
    engine.hoveredPosition = null
    engine.selectedPosition = nextHome ? toCoord(nextHome) : null
    engine.draftMove = null
    calculateSelectedPositionEnts(engine)
    calculateDraftMoveRangedAttackEstimates(engine, engine.draftMove)
    if (nextHome) {
      // 2D canvas:
      // centerPanOnPosition(engine, nextHome)

      centerPanCameraToPosition(engine, nextHome)
    }
  }))
}

function getNextHome(engine: Engine, forward: boolean): Nullable<Entity> {
  const playerId = engine.selectedPlayerId || engine.authPlayerId
  const { state, selectedPositionEnts } = engine
  const homeEnt = selectedPositionEnts.find(isPlayerHomeEnt)
  // console.log('selectedPosition', deepClone(selectedPosition))
  // console.log('selectedPositionEnts', deepClone(selectedPositionEnts))
  // console.log('prevHomeEnt', deepClone(homeEnt))
  // console.log('playerId', playerId)
  const activePlayerHomeEnts = state.ents.filter(isPlayerHomeEnt)
    .sort(byPlayerHomeEntsPriority)
  // console.log('activePlayerHomeEnts', deepClone(activePlayerHomeEnts))

  const entIndex = homeEnt ? activePlayerHomeEnts.findIndex((ent) => ent.id === homeEnt.id) : -1
  // console.log('entIndex', deepClone(entIndex))

  function isPlayerHomeEnt(ent: Entity) {
    if ((ent as HasPlayerId).player_id !== playerId) {
      return false
    }
    return isMapControlTarget(ent)
  }

  if (forward) {
    return activePlayerHomeEnts[entIndex + 1] || activePlayerHomeEnts[0]
  }
  return activePlayerHomeEnts[entIndex - 1] || activePlayerHomeEnts.at(-1)
}

const priorityMap: Record<number, number> = {
  [EntityTypeId.HQ]: 0,
  [EntityTypeId.Factory]: 1,
  [EntityTypeId.Building]: 2,
}
function byPlayerHomeEntsPriority(a: Entity, b: Entity) {
  const aPriority = priorityMap[a.etype_id] ?? 999
  const bPriority = priorityMap[b.etype_id] ?? 999

  return aPriority - bPriority || a.etype_id - b.etype_id
}