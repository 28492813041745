import createDefaultWait from "../../../createDefaultWait"
import type { Engine } from "../../engine/Engine.type"
import findByIdOrThrow from "../../findByIdOrThrow"
import type { PlayerId } from "../../player/PlayerId"
import type { Bot } from "../Bot.type"
import botAnnexesFind from "./botAnnexesFind"
import botAnnexesNew from "./botAnnexesNew"
import botAnnexesOld from "./botAnnexesOld"
import botAttackAnnexers from "./botAttackAnnexers"
import botDirectAttackFind from "./botDirectAttackFind"
import botDirectAttacks from "./botDirectAttacks"
import botEndTurn from "./botEndTurn"
import botMergeUnits from "./botMergeUnits"
import botPlaceFactoryOrders from "./botPlaceFactoryOrders"
import botRangedAttackFind from "./botRangedAttackFind"
import botRangedAttacks from "./botRangedAttacks"
import botReportUnmovedUnits from "./botReportUnmovedUnits"
import botStartTurn from "./botStartTurn"

export default async function playLocalBotRound(
  engine: Engine,
  // note: is proxy
  playerId: PlayerId
) {
  console.log('%c[playLocalBotRound] %cStart', 'color: grey; font-weight: lighter; font-size: 10px;', '', 'playerId', playerId)

  const bot : Bot = {
    get player() {
      return findByIdOrThrow(engine.state.players, playerId)
    },
    // stepDelay: 2,
    stepDelay: 1000,
    maxIterations: engine.state.ents.length * 4,
  }

  await createDefaultWait(bot.stepDelay)

  const debugStopRound = 1000
  if (engine.state.round === debugStopRound) {
    console.log(`%c[playLocalBotRound] %cdebugStopRound ${debugStopRound}`, 'color: red; font-weight: bold; font-size: 14px;', '')
    return
  }

  await botStartTurn(engine, bot)

  await botAnnexesOld(engine, bot)
  await botRangedAttacks(engine, bot)

  // await botMoveTaxiUnits(engine, bot)
  await botAnnexesNew(engine, bot)
  await botAttackAnnexers(engine, bot)
  await botDirectAttacks(engine, bot)
  await botMergeUnits(engine, bot)

  await botAnnexesFind(engine, bot)
  await botDirectAttackFind(engine, bot)
  await botRangedAttackFind(engine, bot)

  await botReportUnmovedUnits(engine, bot)

  await botPlaceFactoryOrders(engine, bot)

  await botEndTurn(engine, bot)
}
