import type { Engine } from "../../../core/engine/Engine.type"
import type { Entity } from "../../../core/entity/index"
import coord from "../../../core/tile_position_xy/coord"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import type { TerrainMesh1TileData } from "./createTerrainMesh1Mesh"

export default function addTerrainMesh1TileDataPlaceholder(
  engine: Engine,
  data: Map<TilePositionXY, TerrainMesh1TileData>,
  ents: Array<Entity>,
): void {

  // console.log('ents', deepClone(ents))

  const { width: maxX, height: maxY } = engine.state

  for (let stateX = 0; stateX < maxX; stateX++) {
    for (let stateY = 0; stateY < maxY; stateY++) {
      const ent = ents.find(ent => ent.x === stateX && ent.y === stateY)
      if (ent) {
        data.set(coord(stateX, stateY), {
          ent,
          edgeIndexes: [],
        })
      }
    }
  }

}
