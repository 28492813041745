import { DEV } from "solid-js"
import { CatmullRomCurve3, Color, Group, Mesh, MeshBasicMaterial, MeshStandardMaterial, Quaternion, TubeGeometry, Vector3 } from "three"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import type { Nullable } from "../../../typescript"
import type { Engine } from "../../core/engine/Engine.type"
import getEntityRenderHeight from "../../core/entity/getEntityRenderHeight"
import type { Entity } from "../../core/entity/index"
import type { LazyEfficientPathGrid_Result } from "../../core/tile_position_xy/createLazyEfficientPathGrid"
import type { PathTurnSteps_Step } from "../../core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type"
import type { TilePositionXY } from "../../core/tile_position_xy/TilePositionXY.type"
import toCoord from "../../core/tile_position_xy/toCoord"
import { max, min, range } from "../../core/util/math"
import Unexpected from "../../Exception/Unexpected.class"
import firstOrNil from "../../ldsh/firstOrNil"
import { VERTICES_PER_TILE } from "../consts"
import { Cursor_Arrow2_GLTF_Model } from "../gltf_models.generated"
import createLoadCargoIntoTaxiSprite from "../sprite/createLoadCargoIntoTaxiSprite"
import { appGltfLoader } from "./appGltfLoader"
import disposeForTraverse from "./disposeForTraverse"
import onGltfLoaderError from "./onGltfLoaderError"
import setObjPosition from "./setObjPosition"
import setVectorV3Position from "./setVectorV3Position"

const scaleValue = 3
// const scaleValue = 1.5
const scale = new Vector3(scaleValue, 0.3, scaleValue)

export const BlueArrowPathDefaultY = 2
const arrowPathDipOffsetY = 3

// Vibrant Sky & Deep Navy Blue
// const headBlue = new Color(0x3498db) // (Vibrant Sky Blue)
// const tailBlue = new Color(0x1f4e78) // (Deep Navy Blue)

// Electric & Dark Blue
// const headBlue = new Color(0x007bff) // (Electric Blue)
// const tailBlue = new Color(0x003f7f) // (Deep Royal Blue)

// Cyan & Midnight Blue
// const headBlue = new Color(0x00a8e8) // (Cyan Blue)
// const tailBlue = new Color(0x002f5f) // (Midnight Blue)

// Neon & Muted Blue
// const headBlue = new Color(0x4facfe) // (Neon Blue)
// const tailBlue = new Color(0x23395d) // (Muted Navy)

const headBlue = new Color(0x4facfe) // (Midnight Blue)
const tailBlue = new Color(0x007bff) // (Electric Blue)

const headGray = new Color(0xb0b0b0) // Light Gray (for the arrowhead)
const tailGray = new Color(0x909090) // Darker Gray (for the tail line)

const headRed = new Color(0xff6b6b) // Soft Red (for a clear but not too harsh denial)
const tailRed = new Color(0xd9534f) // Muted Danger Red (for a strong but balanced effect)

export default class BlueMovementArrowPathGroup extends Group {

  update(upts : Nullable<ReturnType<LazyEfficientPathGrid_Result>>,
    engine : Engine): void {
    // console.log('[BlueMovementArrowPathGroup].update', deepClone(upts))
    this.reset()
    if (upts) {
      const { lastStep } = upts
      if (lastStep) {
        appGltfLoader.load(Cursor_Arrow2_GLTF_Model, (ModelObj) => {
          this.reset()

          const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)

          function stepToVector3 (step : TilePositionXY) {
            const stack = getEntityListAtXYMap().get(toCoord(step)) || []
            const dh = getEntityRenderHeight(stack) + BlueArrowPathDefaultY
            const v3 = new Vector3(0, dh, 0)
            setVectorV3Position(v3, step)
            return v3
          }

          const ArrowGroup = ModelObj.scene
          const ArrowHeadMesh = ArrowGroup.getObjectByName('Head') as Mesh
          ArrowHeadMesh.scale.multiply(scale)

          const ArrowTailMesh = ArrowGroup.getObjectByName('Tail') as Mesh
          // console.log('ArrowTailMesh', ArrowTailMesh)
          ArrowTailMesh.scale.multiply(scale)

          // material.transparent = true
          // material.opacity = 0.25

          // draw arrow heads
          let prevTurnLastStep: TilePositionXY = upts.start
          const { turns } = upts
          const turnsLen = turns.length
          turns.forEach((turn, turnIndex) => {

            const { steps } = turn
            const stepsLen = steps.length
            const turnLastStep = turn.steps.at(-1) as PathTurnSteps_Step

            if (stepsLen > 0) {
              const catmullRomCurve3List = steps.map(stepToVector3)

              const extraFirstV3 = stepToVector3(prevTurnLastStep).lerp(catmullRomCurve3List[0], 0.33)
              catmullRomCurve3List.unshift(extraFirstV3)
              const lastPoint = catmullRomCurve3List.at(-1)
              if (catmullRomCurve3List.length > 2) {
                extraFirstV3.y -= arrowPathDipOffsetY
                lastPoint!.y -= arrowPathDipOffsetY
              }

              // // debug cubes
              // if (DEV) {
              //   catmullRomCurve3List.forEach((v3 : Vector3) => {
              //     const debugCube = createDebugCube(v3, 0xaaffaa)
              //     this.add(debugCube)
              //     debugCube.position.y += 25
              //   })
              // }

              const curve = new CatmullRomCurve3(catmullRomCurve3List)
              // const points = curve.getPoints(stepsLen * 4)
              // const geometry = new BufferGeometry().setFromPoints(points)
              // const material = new LineBasicMaterial({
              //   color: 0xff0000,
              //   linewidth: 5,
              // })
              // const splineObject = new Line(geometry, material)
              // this.add(splineObject)
              // splineObject.position.y = arrowPathDefaultY

              const tubeGeometry = new TubeGeometry(curve, 100, 0.25, 8, false)
              const tubeMaterial = new MeshBasicMaterial({
                color: 0x00ff00,
                // wireframe: true,
              })
              const tubeMesh = new Mesh(tubeGeometry, tubeMaterial)
              this.add(tubeMesh)

              if (stepsLen > 1) {
                const doubleStepsLen = stepsLen * 2
                range(1, doubleStepsLen).forEach((num, index) => {
                  const mesh = ArrowTailMesh.clone()
                  const material = (mesh.material as MeshStandardMaterial).clone()
                  material.transparent = true
                  // console.log('material', material)
                  material.color = turnIndex === 0 ? tailBlue : tailGray
                  // material.depthTest = false
                  // material.depthWrite = false
                  mesh.material = material
                  // mesh.castShadow = true
                  const point = curve.getPointAt(1)
                  mesh.position.copy(point)
                  this.add(mesh)
    
                  let t = index / doubleStepsLen // Normalized progress along curve (0 to 1)
                  const curveLen = curve.getLength()
                  const threshold1 = max(0.15, 5 / curveLen)
                  const threshold2 = min(0.85, (curveLen - 10) / curveLen)
                  // const speed = 0.0035821
                  // const speed = 0.0055734
                  const speed = curveLen / (5000 * (stepsLen + 1))
                  // console.log(JSON.stringify({ curveLen, threshold1, threshold2, speed}).replaceAll(/"/g, ''))

                  mesh.userData.update = () => {
                    // Move the object along the curve
                    const point = curve.getPointAt(t)
                    mesh.position.copy(point)

                    // Fade out at the end, fade in at the start
                    if (t < threshold1) {
                      // Fade in
                      material.opacity = (t / threshold1) ** 3
                    } else if (t > threshold2) {
                      // Fade out
                      material.opacity = ((1 - t) / (1 - threshold2)) ** 3
                    } else {
                      // Fully visible
                      material.opacity = 1
                    }

                    applyQuaternionFromCurve(curve, t, mesh)
                    // Loop animation
                    t += speed
                    if (t > 1) t = 0
                  }
                })
              } else if (DEV) {
                // console.log('stepsLen', stepsLen)
              }

              if (DEV && !turnLastStep) {
                throw new Unexpected('!lastStep')
              }

              {
                const mesh = ArrowHeadMesh.clone()
                const material = (ArrowHeadMesh.material as MeshStandardMaterial).clone()
                material.color = turnIndex === 0 ? headBlue : headGray
                mesh.material = material
                // mesh.castShadow = true
                const point = curve.getPointAt(1)
                mesh.position.copy(point)
                if (stepsLen > 1) {
                  mesh.position.y += arrowPathDipOffsetY
                }
                applyQuaternionFromCurve(curve, 1, mesh)

                this.add(mesh)

                const turnLastStep2 = turn.steps.at(-2) || prevTurnLastStep

                if (turnLastStep.taxis?.length) {
                  // offer to jump into APC
                  if (turnLastStep2) {
                    midpoint(mesh.position, turnLastStep, turnLastStep2)
                    if (turnsLen === 1) {
                      const sprite = createLoadCargoIntoTaxiSprite()
                      this.add(sprite)
                      sprite.scale.set(4, 4, 0.1)
                      setObjPosition(sprite, turnLastStep)
                      sprite.position.y += 24
                    }
                  }
                } else if (turnLastStep.occupant) {
                  console.log('turnLastStep', turnLastStep)
                  // blocked by occupied tile!
                  const pickupEnt : Nullable<Entity> = firstOrNil(turnLastStep.pickup)
                  if (pickupEnt) {
                    // keep blue, add sprite

                    if (turnsLen === 1) {
                      const sprite = createLoadCargoIntoTaxiSprite()
                      this.add(sprite)
                      sprite.scale.set(4, 4, 0.1)
                      setObjPosition(sprite, turnLastStep)
                      sprite.position.y += 24
                    }

                  } else {
                    // different reds for "blocked this turn"
                    // vs "potentially blocked future turns"
                    material.color = turnIndex === 0 ? headRed : tailRed
                  }
                  if (turnLastStep2) {
                    midpoint(mesh.position, turnLastStep, turnLastStep2)
                  }
                }
              }
            }

            prevTurnLastStep = turnLastStep
          })

        }, undefined, onGltfLoaderError)
      } else {
        this.reset()
      }
    } else {
      this.reset()
    }
  }

  reset () {
    this.traverse(disposeForTraverse)
    this.clear()
  }
}

function midpoint (meshPosition: Vector3, p0: TilePositionXY, p1: TilePositionXY): void {
  meshPosition.x = ((p0.x + p1.x) / 2) * VERTICES_PER_TILE
  meshPosition.z = ((p0.y + p1.y) / 2) * VERTICES_PER_TILE
}

// const up = new Vector3(0, 1, 0) // Use Y-up to maintain level orientation

function applyQuaternionFromCurve(
  curve: CatmullRomCurve3,
  t: number,
  mesh: Mesh
): void {
  const tangent = curve.getTangentAt(t).normalize()

  // Project tangent onto the X-Z plane (remove Y component)
  const projectedTangent = new Vector3(tangent.x, 0, tangent.z).normalize()

  // Apply rotation using a stable 'up' direction
  const quaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 0, -1), projectedTangent)
  mesh.quaternion.copy(quaternion)
}
