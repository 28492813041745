import { enum_ } from 'valibot'

export type HasEntityTypeId = {
  /**
   * EntityTypeId
   */
  etype_id: EntityTypeId
}

export enum EntityTypeId {
  // Void = 0

  // L1
  Plain = 1,
  Snow = 2,
  Desert = 3,
  Sea = 4,

  // L2
  Forest = 20,
  Mountain = 21,
  Road = 22,
  Barren = 29,
  River = 30,
  Shoal = 31,
  RoughSea = 32,
  Reef = 33,

  // L3
  Building = 40,
  HQ = 41,
  Factory = 42,
  // Airport,
  // Harbor,
  // ResearchLab,
  // ComsTower,
  // MissleSilo,
  // Radar,
  // Ruins,

  // L4
  // Fog = 60
  // Wind,

  // L5
  // Submarine = 80,

  // L6
  Infantry = 100,

  // L7
  LightRecon = 120, // humvees
  // MediumRecon = 121,
  // HeavyRecon = 122,
  // IFV, // IDK, sounds like armored LightRecon or APC with gun holes
  APC = 123, // M113

  // Booker 105mm cannon
  LightTank = 124, // M2 Bradley IFV // 25mm autocannon
  MediumTank = 125, // M1 Abrams 120mm cannon
  HeavyTank = 126, // DNE

  LightArtillary = 127, // DNE
  MediumArtillary = 128, // Type 99 155 mm self-propelled howitzer
  HeavyArtillary = 129, // DNE

  LightRockets = 130, // DNE
  MediumRockets = 131, // M142 HIMARS
  HeavyRockets = 132, // M270 MLRS

  LightGunAntiAir = 133, // DNE
  MediumGunAntiAir = 134, // M247 Sergeant York
  HeavyGunAntiAir = 135, // DNE

  LightMissileAntiAir = 137, // DNE
  MediumMissileAntiAir = 138, // P4R Crotale
  HeavyMissileAntiAir = 139, // DNE

  // L7
  TransportCopter = 140, // CH47 Chinook
  LightCopter = 141, // DNE
  MediumCopter = 142, // AH64 Apache // 30mm M230 chain gun // Hydra 70 AirToGround rockets // AGM-114 Hellfire Anti-Armor missile
  HeavyCopter = 143, // DNE

  // Rocket = unguided, Missile = guided

  // L8
  // FighterPlane = 160,
  // BomberPlane,

  // L9
  // Satellite = 180,
}

export const EntityTypeIdSchema = enum_(EntityTypeId, 'Invalid Entity Type ID')
