import { type Mesh, type MeshStandardMaterial, type Object3D, Color, Vector3 } from "three"
import type { Engine } from "../../core/engine/Engine.type"
import { sin } from "../../core/util/math"
import { Cursor_Arrow2_GLTF_Model } from "../gltf_models.generated"
import createUnloadCargoFromTaxiSprite from "../sprite/createUnloadCargoFromTaxiSprite"
import { appGltfLoader } from "./appGltfLoader"

const scaleValue = 3
const scale = new Vector3(scaleValue, 0.3, scaleValue)
const headBlue = new Color(0x4facfe) // (Midnight Blue)

export default function createBlueUnloadCargoArrow (
  engine: Engine,
  callback: (obj: Object3D) => void
) : void {
  // console.log('createBlueUnloadCargoArrow')
  appGltfLoader.load(Cursor_Arrow2_GLTF_Model, (ModelObj) => {
    const ArrowGroup = ModelObj.scene
    const ArrowHeadMesh = ArrowGroup.getObjectByName('Head') as Mesh
    ArrowHeadMesh.scale.multiply(scale)
    // ArrowHeadMesh.scale.multiplyScalar(10)
    ;(ArrowHeadMesh.material as MeshStandardMaterial).color = headBlue

    const ArrowHeadMeshPosition = ArrowHeadMesh.position
    ArrowHeadMesh.userData.brh = ArrowHeadMeshPosition.y

    ArrowHeadMesh.userData.update = function (elapsedMs: number, frameAt: number) {
      const s1 = sin(frameAt / 500)
      ArrowHeadMeshPosition.y = ArrowHeadMesh.userData.brh + (1 + (s1 * s1) / 2)

      // const s2 = sin(frameAt / 800)
      // material.opacity = 0.5 + ((1 + s2*s2) / 32)
    }

    const sprite = createUnloadCargoFromTaxiSprite()
    sprite.center.set(0.5, 0)

    ArrowHeadMesh.add(sprite)
    sprite.position.y += 3
    sprite.scale.set(4, 4, 4)
    sprite.scale.divide(scale)

    callback(ArrowHeadMesh)
  })
}