import { PerspectiveCamera } from "three"

export default function createMainCamera(
  canvasElem: HTMLCanvasElement | OffscreenCanvas,
) {
  const fov = 75
  const aspect = canvasElem.width / canvasElem.height
  const near = 0.1
  const far = 1000
  const camera = new PerspectiveCamera(fov, aspect, near, far)
  return camera
}