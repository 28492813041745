import { CanvasTexture, LinearFilter, Sprite, SpriteMaterial } from "three"
import { createSharedEntityXYGridMapMemo } from "../../../rx/memo/createSharedEntityXYGridMapMemo"
import type { Nullable } from "../../../typescript"
import { createOffscreenCanvas } from "../../canvas/create_canvas"
import { FADED_FRAME_CANVAS_FILTER } from "../../canvas/draw/createFadedFrameCanvas"
import { getCanvas2dContext } from "../../canvas/get_canvas_2d_context"
import type { Engine } from "../../core/engine/Engine.type"
import canEntityAnnexInGeneral from "../../core/entity/canEntityAnnexInGeneral"
import isBeingAnnexedBy from "../../core/entity/isBeingAnnexedBy"
import isEntityAnnexable from "../../core/entity/isEntityAnnexable"
import isEntityUnit from "../../core/entity/isEntityUnit"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import toCoord from "../../core/tile_position_xy/toCoord"
import nullIfEmptyArray from "../../ldsh/nullIfEmptyArray"
import type { EntityThreeModel } from "../EntityThreeModel/EntityThreeModel.type"
import createAnnexSomethingCanvas from "./createAnnexSomethingCanvas"
import createLowAmmoCanvas, { getEntityLowAmmoSpriteType, LowAmmoSpriteType } from "./createLowAmmoCanvas"
import createVerticalHealthBarCanvas from "./createVerticalHealthBarCanvas"
import createWoodenCrateCanvas from "./createWoodenCrateCanvas"

export const UNIT_SPRITE_NAME = 'US'

const OPACITY_NORMAL = 0.75
// const OPACITY_FADED = 0.5

export default function createUnitSprite(
  engine: Engine,
  ent: EntityThreeModel['ent']
): Nullable<Sprite> {
  const { cargo } = ent
  // console.log('createUnitSprite')

  const padding = 24

  const rows : Array<OffscreenCanvas> = []
  if (nullIfEmptyArray(cargo)) {
    rows.push(createWoodenCrateCanvas(196, 196, 0))
  }
  // if (isOutofAmmo(cargo)) {
  //   rows.push(createOutOfAmmoSprite(196, 196, 0))
  // }
  const lowAmmoType = getEntityLowAmmoSpriteType(ent)
  if (lowAmmoType) {
    rows.push(createLowAmmoCanvas(196, 196, 0, LowAmmoSpriteType.Low))
  }

  if (canEntityAnnexInGeneral(ent)) {
    const getEntityListAtXYMap = createSharedEntityXYGridMapMemo(engine)
    const positionStack = getEntityListAtXYMap().get(toCoord(ent)) || []
    const annexableEnt = positionStack.find(isEntityAnnexable)
    if (annexableEnt && isBeingAnnexedBy(annexableEnt, ent)) {
      rows.push(createAnnexSomethingCanvas(196, 196, 32))
    }
  }

  // if ((hp as number) >= 0) {
  //   rows.push(createEntityHeadingTextCanvas(ent.heading))
  // }

  if (isEntityUnit(ent)) {
    rows.push(createVerticalHealthBarCanvas(ent.hp as number))
  }

  const outerHeight : number = rows.reduce((carry : number, row: OffscreenCanvas): number => {
    // if (isEntityAPC(ent)) {
    //   console.log({'row.height': row.height, carry})
    // }
    return carry + row.height
  }, (rows.length - 1) * padding)
  const outerWidth = 512

  // if (isEntityAPC(ent)) {
  //   console.log('createUnitSprite', {outerHeight, outerWidth, rows, cargo, hp})
  // }

  if (rows.length === 0 || !(outerHeight > 0)) {
    return
  }

  const canvas = createOffscreenCanvas(outerWidth, outerHeight)
  const ctx = getCanvas2dContext(canvas)
  if (ctx) {
    // ctx.clearRect(0, 0, outerWidth, outerHeight)
    let cursorH = 0

    if (shouldEntityShowFadedFrames(ent)) {
      ctx.filter = FADED_FRAME_CANVAS_FILTER
    }
    rows.forEach((canvas, index) => {
      if (index) {
        cursorH += padding
      }
      ctx.drawImage(canvas, outerWidth - canvas.width, cursorH)

      cursorH += canvas.height
    })
  }

  // Create a texture
  const texture = new CanvasTexture(canvas)
  texture.minFilter = LinearFilter // Prevent mipmaps
  texture.needsUpdate = true

  // Create a sprite material and sprite
  const material = new SpriteMaterial({
    map: texture,
    transparent: true,
    opacity: OPACITY_NORMAL,
    // forceSinglePass: true,

    // IDK why but this fixes the issue
    // where water behind transsparent parts of the sprite
    // does not render, showing the river basin/ocean floor.
    depthTest: false,
    // depthWrite: false,
  })
  const sprite = new Sprite(material)

  // sprite.userData.vampire = true

  sprite.name = UNIT_SPRITE_NAME
  sprite.userData.width = outerWidth
  sprite.userData.height = outerHeight
  sprite.center.set(0.5, 0)

  // sprite.userData.update = () => {
  //   sprite.material.opacity = shouldEntityShowFadedFrames(ent) ? OPACITY_FADED : OPACITY_NORMAL
  // }

  return sprite
}
