import { Spherical, Vector3 } from 'three'
import { MapControls } from 'three/addons/controls/MapControls.js'
import { max, min, PI } from '../../core/util/math'

export default function setMapControlsPolarAngle(
  controls: MapControls,
  polarAngle: number
): void {
  // console.log('setMapControlsPolarAngle', polarAngle)
  const spherical = new Spherical()

  // Get current camera position relative to target in spherical coordinates
  const offset = new Vector3().subVectors(controls.object.position, controls.target)
  spherical.setFromVector3(offset)

  // Set the new polar angle (clamping to prevent flipping)
  spherical.phi = max(0.1, min(PI - 0.1, polarAngle))

  // Convert back to Cartesian coordinates
  offset.setFromSpherical(spherical)
  controls.object.position.copy(controls.target).add(offset)

  // Ensure the camera is still looking at the target
  controls.update()
}


