import type { Object3D } from "three"
import { MathUtils } from "three"
import { abs } from "../../util/math"
import normalizeDeg from "../../util/normalizeDeg"

const entityRotationSpeed = 90 // degrees per second

const { clamp, degToRad, radToDeg } = MathUtils

const rotationDiv1 = 1000
const rotationDiv2 = 1500

export default function applyEntityRotationForMediumRocketsToNextObject3DFrame(
  turretMesh: Object3D,
  rocketPodMesh: Object3D,
  elapsedMs: number,
) {
  // Ensure it's in [0, 360)
  const currentHeadingDeg = normalizeDeg(radToDeg(turretMesh.rotation.y))
  // Ensure it's in [0, 360)
  const targetHeadingDeg = normalizeDeg(turretMesh.userData.targetHeading)

  // Compute the shortest rotation difference in the range [-180, 180]
  let rotationDifference = targetHeadingDeg - currentHeadingDeg
  if (rotationDifference > 180) {
    rotationDifference -= 360
  } else if (rotationDifference < -180) {
    rotationDifference += 360
  }

  const shouldBeRaised = rocketPodMesh.userData.raise

  // If there's a meaningful rotation to be applied
  if (abs(rotationDifference) > 0.0001) {

    // first, check if the RocketPod needs to be raised.
    if (shouldBeRaised) {
      // Ensure it's in [0, 360)
      const currentHeadingDeg = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))
      // Ensure it's in [0, 360)
      const targetHeadingDeg = normalizeDeg(-30)

      // Compute the shortest rotation difference in the range [-180, 180]
      let rotationDifference = targetHeadingDeg - currentHeadingDeg
      if (rotationDifference > 180) {
        rotationDifference -= 360
      } else if (rotationDifference < -180) {
        rotationDifference += 360
      }
      // console.log('rotationDifference', rotationDifference)

      // If there's a meaningful rotation to be applied
      if (abs(rotationDifference) > 0.0001) {
        const maxRotationStep = (entityRotationSpeed * elapsedMs) / rotationDiv2
        const rotationStep = clamp(rotationDifference, -maxRotationStep, maxRotationStep)

        // Apply rotation and update heading
        rocketPodMesh.rotation.x = degToRad(currentHeadingDeg + rotationStep)
        rocketPodMesh.userData.heading = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))

        // the base does not rotate until the rocket pod has finished raising
        return
      }

      // rocketPodMesh.rotation.x = degToRad(-30)
      // rocketPodMesh.userData.vheading = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))
    // } else {
      // rocketPodMesh.rotation.x = degToRad(0)
      // rocketPodMesh.userData.vheading = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))
    }

    const maxRotationStep = (entityRotationSpeed * elapsedMs) / rotationDiv1
    const rotationStep = clamp(rotationDifference, -maxRotationStep, maxRotationStep)

    // Apply rotation and update heading
    turretMesh.rotation.y = degToRad(currentHeadingDeg + rotationStep)
    turretMesh.userData.heading = normalizeDeg(radToDeg(turretMesh.rotation.y))
  } else if (!shouldBeRaised) {
    // if the Turret does not need to be rotated,
    // check if the RocketPod needs to be lowered.

    // Ensure it's in [0, 360)
    const currentHeadingDeg = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))
    // Ensure it's in [0, 360)
    const targetHeadingDeg = 0

    // Compute the shortest rotation difference in the range [-180, 180]
    let rotationDifference = targetHeadingDeg - currentHeadingDeg
    if (rotationDifference > 180) {
      rotationDifference -= 360
    } else if (rotationDifference < -180) {
      rotationDifference += 360
    }
    // console.log('rotationDifference', rotationDifference)

    // If there's a meaningful rotation to be applied
    if (rotationDifference !== 0) {
      const maxRotationStep = (entityRotationSpeed * elapsedMs) / 1000
      if (abs(rotationDifference) <= maxRotationStep) {
      // If the required rotation is smaller than max step, snap to final rotation
        rocketPodMesh.rotation.x = degToRad(targetHeadingDeg)
        rocketPodMesh.userData.heading = targetHeadingDeg
      } else {
        // Otherwise, apply the incremental rotation step
        const rotationStep = clamp(rotationDifference, -maxRotationStep, maxRotationStep)
        rocketPodMesh.rotation.x = degToRad(currentHeadingDeg + rotationStep)
        rocketPodMesh.userData.heading = normalizeDeg(radToDeg(rocketPodMesh.rotation.x))
      }
    }
  }
}
