import { Group, Mesh, type Object3D } from "three"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"
import applyEntityWalkPathToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityWalkPathToNextObject3DFrame"
import type { Entity } from "../../core/entity/index"
import shouldEntityShowFadedFrames from "../../core/entity/shouldEntityShowFadedFrames"
import tmpRng from "../../core/rng/tmpRng"
import { sin } from "../../core/util/math"
import assertClassInDev from "../../ldsh/assertClassInDev"
import { AH64Apache1_AH64Apache1_GLTF_Model } from "../gltf_models.generated"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import { appGltfLoader } from "./appGltfLoader"
import applyPlayerColors from "./applyPlayerColors"

export default function makeMediumCopterScene (
  ent: Entity,
  pto: PlayerThreeObject,
  callback: (newGroup: Object3D) => void
): void {
  return appGltfLoader.load(AH64Apache1_AH64Apache1_GLTF_Model, function (ModelObj) {
    tmpRng.seed = ent.id
    const { scene } = ModelObj
    // console.log('MediumCopter', scene)

    // scene.scale.multiplyScalar(10)

    // scene.position.y -= 4

    const frontRotorGroup = scene.getObjectByName('Rotor1') as Group
    assertClassInDev(frontRotorGroup, Group)
    // console.log('frontRotorGroup', frontRotorGroup)

    const rearRotorMesh = scene.getObjectByName('Rotor2') as Mesh
    assertClassInDev(rearRotorMesh, Mesh)
    // console.log('rearRotorMesh', rearRotorMesh)

    applyPlayerColors(scene, ent, pto)

    // group.position.y += 3

    // set base render height
    scene.userData.y = scene.position.y

    scene.userData.update = (elapsedMs: number, frameAt: number): void => {
      // const mod = frameAt % totalLoopMs
      if (shouldEntityShowFadedFrames(ent)) {
        scene.position.y = scene.userData.y
      } else if (ent.player_id) {
        scene.position.y = scene.userData.y + ((sin(frameAt / 1000)) + 4)
        frontRotorGroup.rotation.y = (frameAt / 120)
        rearRotorMesh.rotation.x = (-frameAt / 120)
      }
      applyEntityRotationToNextObject3DFrame(scene, elapsedMs)
      applyEntityWalkPathToNextObject3DFrame(scene, elapsedMs)
    }

    callback(scene)
  })
}

