import { trackDeep } from '@solid-primitives/deep'
import { createEffect } from 'solid-js'
import { Nullable } from '../../../typescript'
import createLayerMeta from '../../core/create_layer_meta'
import { Engine } from '../../core/engine/Engine.type'
import { Entity } from '../../core/entity'
import { EntityLayerId } from '../../core/entity/entity_layer_id.enum'
import type { HasTaxiID } from '../../core/entity/HasTaxiID'
import type { HasHP } from '../../core/has_hp'
import {
  MakeRenderSpriteFunction,
  RenderSpriteFunction,
  renderSpriteByIdMap,
} from '../../core/render_sprite'
import { byEntityLayerIdAsc } from '../../core/util/sort_by_entity_layer_id'
import { byYAsc } from '../../core/util/sort_by_y'
import bindCanvasToEngine from '../bind_canvas_to_engine'
import { createCanvasElement, createOffscreenCanvas } from '../create_canvas'
import { disableCanvasSmoothing } from '../disable_canvas_smoothing'
import defaultErrorRender from '../draw/default_render_error'
import drawEntityHp from '../draw/drawEntityHp'
import { getCanvas2dContext } from '../get_canvas_2d_context'

const name = 'DrawEntsByKind'
const relZIndex = 0

/**
 * @deprecated
 */
export default function addDrawEntsByKindCanvas(
  engine: Engine,
  entityLayerIdList: Array<EntityLayerId>
): void {
  const entityLayerIdListText: string = entityLayerIdList.join(',')
  // console.log(`${name}[${entityLayerIdListText}].add`)
  const lm = createLayerMeta()
  const elem = createCanvasElement()
  const buffer1 = createOffscreenCanvas(1, 1)
  elem.setAttribute('name', `${name}[${entityLayerIdListText}]`)
  // const [track, dirty] = engine.cache
  let ents: Array<Entity> = []
  let entFns: Array<RenderSpriteFunction> = []

  createEffect(() => {
    elem.style.zIndex = (engine.viewCtx.zIndex + relZIndex).toString()
  })

  let draftMoveUnit: Nullable<Entity> = null
  createEffect(() => {
    const { draftMove } = engine
    if (draftMove && draftMove.destPosition) {
      draftMoveUnit = draftMove.unit
    } else {
      draftMoveUnit = null
    }
  })
  // TODO I'd like panning to only paste buffer the buffer at new position
  // createEffect(() => {
  //   track(EngineCache.ViewCtx)
  //   // console.log(`${name}[${entityLayerIdListText}].effect.pan`)
  //   lm.until = 0
  // })
  createEffect(() => {
    // track(EngineCache.ViewCtx)
    // console.log(`${name}[${entityLayerIdListText}].effect.pan`)
    trackDeep(engine.viewCtx)
    lm.until = 0
  })
  // TODO I'd like ngineCache.EntityList to update the buffer
  createEffect(() => {
    // console.log(`${name}[${entityLayerIdListText}].effect.track(EngineCache.EntityList)`, state.ents.filter(ent => entityLayerIdList.includes(ent.layer_id)).length, state.ents.filter(ent => ent.layer_id))
    // track(EngineCache.EntityList)
    // track(EngineCache.ViewCtx)

    ents = engine.state.ents.filter((ent) => {
      if ((ent as HasTaxiID).taxi_id) {
        return false
      }
      return entityLayerIdList.includes(ent.layer_id)
    })
    ents.sort(byEntityLayerIdAsc).sort(byYAsc)
    entFns = ents.map((ent: Entity) => {
      const makeRenderSprite: Nullable<MakeRenderSpriteFunction> = renderSpriteByIdMap[ent.etype_id]
      return makeRenderSprite ? makeRenderSprite(engine, ent) : defaultErrorRender
    })
    lm.until = 0
    // if (ents.length < 100) {
    //   console.table(ents)
    // }
  })

  function render(timeStamp: number) {
    // console.log(`${name}[${entityLayerIdListText}].render`)
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image

    // if (!(timeStamp > 0)) console.log(`${name}[${entityLayerIdListText}]`, '!(timeStamp > 0)', timeStamp)

    if (lm.until > timeStamp) {
      return
    }

    const { viewCtx } = engine

    const {
      renderWidthPx,
      renderHeightPx,
      tile_size,
      tile_gap_px,
      canvas_edge_buffer,
      pan_x,
      pan_y,
    } = viewCtx
    const tile_size_plus_gap: number = tile_size + tile_gap_px

    const stateWidth = engine.state.width
    const stateHight = engine.state.height

    // buffer1 will be the full map, unpanned
    const bufferWidthPx =
      tile_size * stateWidth + tile_gap_px * (stateWidth - 1) + 2 * canvas_edge_buffer
    const bufferHeightPx =
      tile_size * stateHight + tile_gap_px * (stateHight - 1) + 2 * canvas_edge_buffer

    const ctx1 = getCanvas2dContext(buffer1)
    if (!ctx1) return
    const ctx0 = getCanvas2dContext(elem)
    if (!ctx0) return

    // IDK what this was for, but it caused the animation to stop running
    // lm.until = timeStamp + EXPIRE_NEVER

    // reset buffer1
    buffer1.width = bufferWidthPx
    buffer1.height = bufferHeightPx
    ctx1.clearRect(0, 0, bufferWidthPx, bufferHeightPx)
    disableCanvasSmoothing(ctx1)

    // draw ents
    for (let index = 0, ents_len = ents.length; index < ents_len; index++) {
      const ent: Entity = ents[index]
      const dx: number = canvas_edge_buffer + ent.x * tile_size_plus_gap
      const dy: number = canvas_edge_buffer + ent.y * tile_size_plus_gap
      const fn: RenderSpriteFunction = entFns[index]

      if (ent === draftMoveUnit) {
        // console.log('ent === engine.draftMove?.unit')
        // ctx1.filter = 'brightness(50%) grayscale(50%) sepia(50%) blur(1px)'
        ctx1.filter = 'brightness(50%) grayscale(50%) sepia(50%) drop-shadow(5px 5px 10px black)'
        // ctx1.filter = 'brightness(50%) grayscale(50%) sepia(50%)'
      }

      fn(ctx1, lm, ent, dx, dy, tile_size)

      ctx1.filter = 'none'
      // const makeRenderSprite: Nullable<MakeRenderSpriteFunction> = renderSpriteByIdMap[ent.etype_id]
      // if (makeRenderSprite) {
      //   // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
      //   // s=source, d=destination
      //   const fn: RenderSpriteFunction = makeRenderSprite(engine, ent)
      //   fn(ctx1, ent, dx, dy, tile_size, timeStamp)
      // } else {
      //   defaultErrorRender(ctx1, ent, dx, dy, tile_size, timeStamp)
      // }
      if ((ent as HasHP).hp < 10) {
        drawEntityHp(ctx1, lm, ent, dx, dy, tile_size)
      }
    }

    // reset screen
    elem.width = renderWidthPx
    elem.height = renderHeightPx
    ctx0.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx0)

    // write buffer1 to screen
    // console.log(`${name}[${entityLayerIdListText}] write buffer1 to screen`)
    ctx0.drawImage(buffer1, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
  }

  bindCanvasToEngine(engine, elem, render)
}
