import type { Object3D } from "three"
import type { Nullable } from "../../../../typescript"
import type { Object3DWalkFn } from "../../../three/Object3DWalk/createObject3DWalk"

export default function applyEntityWalkPathToNextObject3DFrame(
  obj: Object3D,
  elapsedMs: number
) {
  const walk = obj.userData.walk as Nullable<Object3DWalkFn>
  if (walk) {
    walk.next(elapsedMs)
  } else {
    // console.log('applyEntityWalkPathToNextObject3DFrame', 'ELSE')
  }
}
