import { Spherical, Vector3 } from 'three'
import { MapControls } from 'three/addons/controls/MapControls.js'

export default function getMapControlsPolarAngle(controls: MapControls): number {
  const spherical = new Spherical()
  
  // Get current camera position relative to target in spherical coordinates
  const offset = new Vector3().subVectors(controls.object.position, controls.target)
  spherical.setFromVector3(offset)

  return spherical.phi // Return the current polar angle
}
