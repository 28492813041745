import { DEV } from "solid-js"
import type { Engine } from "../../lib/core/engine/Engine.type"
import Unexpected from "../../lib/Exception/Unexpected.class"

export default function createSharedCachedFactory<T>(fn: (engine: Engine) => T) {
  const cache = new WeakMap<Engine, T>

  return (engine: Engine) => {

    if (DEV) {
      if (!engine) {
        throw new Unexpected('!engine')
      }
    }

    let memo = cache.get(engine)
    if (!memo) {
      memo = fn(engine)
  
      cache.set(engine, memo)
    }
    return memo
  }
}
