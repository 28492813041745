import { Color } from "three"
import type { Nullable } from "../../../../typescript"
import { isEntityShoal } from "../../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../../core/entity/index"
import type { TilePositionXY } from "../../../core/tile_position_xy/TilePositionXY.type"
import averageColorsByMean from "../../color/averageColorsByMean"
import { getTerrainMesh1TileColor } from "./colors"
import type { TerrainMesh1TileData } from "./createTerrainMesh1Mesh"

export default function averageMutualVertices(
  data : Map<TilePositionXY, TerrainMesh1TileData>,
  indices: Array<number>,
  colorBuffer: Array<number>,
): void {
  const entListByDataMap = new Map<number, Array<Nullable<Entity>>>

  for (const row of data.values()) {
    const { ent, edgeIndexes } = row
    if (!ent || !isEntityShoal(ent)) {
      for (const index of edgeIndexes) {
        const arr = entListByDataMap.get(index) || []
        arr.push(ent)
        entListByDataMap.set(index, arr)
      }
    }
  }

  for (const [index, entList] of entListByDataMap) {
    if (entList.length > 1) {
      const colorList = entList.map(getTerrainMesh1TileColor)

      // TODO: average colors
      const avgColor : Color = averageColorsByMean(colorList)
      // const avgColor : Color = averageColorsByPerceptual(colorList)

      const i3 = index * 3
      colorBuffer[i3] = avgColor.r
      colorBuffer[i3 + 1] = avgColor.g
      colorBuffer[i3 + 2] = avgColor.b
    }
  }
}
