import { PerspectiveCamera, type Camera } from 'three'
import type { MapControls } from 'three/examples/jsm/controls/MapControls.js'
import { Engine } from './engine/Engine.type'
import { abs, max, min } from './util/math'

const MAX_VELOCITY = 100
const PAN_MULT = 0.05
const FRICTION = 1.6

export default function progressArrowKeyPanV3(
  engine: Engine,
  elapsedMs: number,
  camera: Camera,
  mapControls: MapControls,
): void {

  const { viewCtx, keydownMap } = engine
  const { ArrowRight, ArrowLeft, ArrowDown, ArrowUp } = keydownMap
  // console.log('progressArrowKeyPanV3', viewCtx.pan_vx, viewCtx.pan_vy)

  const framePanMult = elapsedMs * PAN_MULT

  let { pan_vx, pan_vy } = viewCtx

  // Update velocity based on arrow keys
  if (ArrowRight && !ArrowLeft) {
    pan_vx = max(pan_vx - framePanMult, -MAX_VELOCITY)
  } else if (ArrowLeft && !ArrowRight) {
    pan_vx = min(pan_vx + framePanMult, MAX_VELOCITY)
  } else if (pan_vx !== 0) {
    pan_vx /= FRICTION
  }
  if (ArrowDown && !ArrowUp) {
    pan_vy = max(pan_vy - framePanMult, -MAX_VELOCITY)
  } else if (ArrowUp && !ArrowDown) {
    pan_vy = min(pan_vy + framePanMult, MAX_VELOCITY)
  } else if (pan_vy !== 0) {
    pan_vy /= FRICTION
  }
  // console.log('progressArrowKeyPanV3', pan_vx, pan_vy, deepClone(keydownMap))

  // Zero out small velocities
  if (abs(pan_vx) < 0.1) {
    pan_vx = 0
  }
  if (abs(pan_vy) < 0.1) {
    pan_vy = 0
  }

  // Apply velocity to camera position
  // camera.position.x -= pan_vx * (elapsedMs / 1000) // Convert velocity to position
  // camera.position.y -= pan_vy * (elapsedMs / 1000) // Convert velocity to position

  // pan_x_f -= pan_vx
  // pan_y_f -= pan_vy

  ;(mapControls as unknown as ({
    _pan: (x: number, y: number) => void
  }))._pan(pan_vx, pan_vy)

  // camera.position.x += pan_vx
  // camera.position.z += pan_vy
  // // camera.translateX(pan_vx)
  // // camera.translateZ(pan_vy)

  // if (camera instanceof PerspectiveCamera) {
  //   camera.position.x += pan_vx
  //   camera.position.z += pan_vy

  //   // // Get the camera's current rotation angle about the y-axis
  //   // const rotationY = camera.rotation.y;

  //   // // Compute the direction vectors
  //   // const forward = new Vector3(Math.sin(rotationY), 0, Math.cos(rotationY)); // Forward vector
  //   // const right = new Vector3(forward.z, 0, -forward.x); // Right vector (90 degrees rotated)

  //   // // Apply velocity to camera's position
  //   // camera.position.add(forward.multiplyScalar(pan_vy)); // Apply pan_vy along forward
  //   // camera.position.add(right.multiplyScalar(pan_vx));   // Apply pan_vx along right

  if (camera instanceof PerspectiveCamera) {
    camera.updateProjectionMatrix()
  }

  // } else if (camera instanceof OrthographicCamera) {
  //   throw new NotImplemented('OC')
  // }
  viewCtx.pan_vx = pan_vx
  viewCtx.pan_vy = pan_vy

}
