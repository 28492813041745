import { Color } from 'three'

export default function averageColorsByMean(colors: Array<Color>): Color {
  if (colors.length === 0) throw new Error('No colors provided')

  const result = new Color()
  let r = 0, g = 0, b = 0

  colors.forEach(color => {
    r += color.r
    g += color.g
    b += color.b
  })

  const len = colors.length
  result.setRGB(r / len, g / len, b / len)
  return result
}