import { Color } from "three"
import randomElement from "../../core/rng/randomElement"
import type { RNGConfig } from "../../core/rng/RNGConfig.type"

const skinToneList = [
  // Light tones:
  0xFFDFC4, //(Peach)
  0xF0D5BE, //(Fair)
  0xEAC086, //(Light tan)
  // Medium tones:
  0xD1A377, //(Golden)
  0xC68642, //(Medium tan)
  0xA1665E, //(Reddish-brown)
  // Dark tones:
  0x8D5524, //(Deep brown)
  0x7C4A25, //(Rich chocolate)
  0x5C322E, //(Dark mocha)
]

export default function randomSkinToneRGB(rng: RNGConfig): Color {
  // const r = next(rng) * (255 - 200) + 200; // Red between 200-255
  // const g = next(rng) * (220 - 150) + 150; // Green between 150-220
  // const b = next(rng) * (200 - 120) + 120; // Blue between 120-200
  // const color = new Color(r / 255, g / 255, b / 255); // Normalize RGB values to 0-1
  // return color;

  return new Color(randomElement(skinToneList, rng))
}
