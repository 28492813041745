import { createRoot } from "solid-js"
import { type Object3D } from "three"
import createEngineForUI from "../../../core/engine/createEngineForUI"
import { createPlainEntity } from "../../../core/entity/EntityType/Plain"
import { createRiverEntity } from "../../../core/entity/EntityType/River"
import createRiverTerrainMesh from "./createRiverTerrainMesh"

export default function makeRiverForIconObject3D (
  callback: (newGroup: Object3D) => void
): void {
  createRoot(() => {
    const engine = createEngineForUI()
    const { state } = engine
    let id = 1
    const riverEnt1 = createRiverEntity(id++, 1, 0)
    const riverEnt2 = createRiverEntity(id++, 1, 1)
    const riverEnt3 = createRiverEntity(id++, 1, 2)
    const plain1 = createPlainEntity(id++, 0, 0)
    const plain2 = createPlainEntity(id++, 0, 1)
    const plain3 = createPlainEntity(id++, 0, 2)
    const plain4 = createPlainEntity(id++, 2, 0)
    const plain5 = createPlainEntity(id++, 2, 1)
    const plain6 = createPlainEntity(id++, 2, 2)
    state.ents = [
      riverEnt1,
      riverEnt2,
      riverEnt3,
      plain1,
      plain2,
      plain3,
      plain4,
      plain5,
      plain6,
    ]
    state.width = state.height = 3
    const mesh1 = createRiverTerrainMesh(engine, [
      // riverEnt1,
      riverEnt2,
      // riverEnt3,
    ])
    callback(mesh1)
  })
}
