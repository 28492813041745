import { Color, MeshBasicMaterial, MeshStandardMaterial, ShaderMaterial, SpriteMaterial, type Object3D } from "three"
import { isEntityInfantry } from "../../core/entity/entityTypeMetaList.generated"
import type { Entity } from "../../core/entity/index"
import tmpRng from "../../core/rng/tmpRng"
import type { PlayerThreeObject } from "../PlayerThreeObject/PlayerThreeObject.type"
import applyLuminanceFromGreyscale from "./applyLuminanceFromGreyscale"
import logEntityMaterialColorChange from "./logEntityMaterialColorChange"
import randomSkinToneRGB from "./randomSkinToneColor"

export default function applyPlayerColors (
  group: Object3D,
  ent: Entity,
  pto: PlayerThreeObject
): void {
  const { material: baseMaterial } = pto

  group.traverse((obj) => {
    const material : MeshBasicMaterial | MeshStandardMaterial | null = (obj as typeof obj & {material : MeshBasicMaterial | MeshStandardMaterial | null}).material
    if (material) {

      if (material.userData.Window) {
        // for now
        // console.log('material.userData.Window', material)
        return
      }
      if (material instanceof ShaderMaterial) {
        return
      }

      if ('metalness' in material) {
        ;(material as MeshStandardMaterial).metalness = 0.5
      }
      if ('roughness' in material) {
        ;(material as MeshStandardMaterial).roughness = 0.5
      }
  
      if (material instanceof SpriteMaterial) {
        // IDK ignore SpriteMaterial
        return
      }
      const PC = material.userData.PC
      if (PC===0) {
        return
      }
      // console.log('PC', PC)
      if (PC || isEntityInfantry(ent)) {
        const obj2 = obj as typeof obj & {material : MeshBasicMaterial | MeshStandardMaterial}
        if (!(material instanceof MeshBasicMaterial) && !(material instanceof MeshStandardMaterial)) {
          console.log('applyPlayerColors.material', material)
          throw new Error('material is not a MeshBasicMaterial | MeshStandardMaterial')
        }
        if (isEntityInfantry(ent)) {
          // console.log('material', material)
          // if ((material instanceof MeshStandardMaterial)) {
          //   console.log('applyPlayerColors.material', material.color.getHexString(), material.name, material)
          //   // throw new Error('material is a MeshStandardMaterial')
          // }

          tmpRng.state = ent.id
          const skinColor = randomSkinToneRGB(tmpRng)

          // if ([
          //   'Skin',
          //   'Main',
          //   'Black',
          //   'Grey',
          //   'Face',
          //   'Helmet',
          // ].includes(material.name)) {
          const newColor : Color = ((): Color => {
            if (material.name === 'Skin') {
              return skinColor
            }
            if (material.name === 'Face') {
              return new Color(0x000000)
            }
            // if (material.name === 'Grey') {
            //   return material.color
            // }
            if (material.name === 'Black') {
              return applyLuminanceFromGreyscale(new Color(0xaaaaaa), baseMaterial.color)
            }
            return baseMaterial.color
          })()
          obj2.material = material.clone()
          obj2.material.color = newColor.clone()

          return
        }

        if ((material instanceof MeshStandardMaterial)) {
          const newColor = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          logEntityMaterialColorChange(ent, material, newColor)
          // console.log(entityDisplayName(ent), material.name, 'oldColor', material.color.getHexString(), 'newColor', newColor.getHexString())
          // material.blendColor = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          // throw new Error('material is a MeshStandardMaterial')
          obj2.material = material.clone()
          // obj2.material.blendColor = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          // obj2.material.color = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          obj2.material.color = newColor.clone()
          // obj2.material.blendColor = baseMaterial.color.clone()
          // obj2.material.alphaTest = 0.5
          // obj2.material.metalness = 0
        } else {
          const newColor = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          logEntityMaterialColorChange(ent, material, newColor)
          // console.log('obj.material', obj.material)
          // material.color.set(baseMaterial.color)
          // material.color.sub(baseMaterial.color)
          // material.color.multiply(baseMaterial.color)
          // material.color.multiplyScalar(baseMaterial.color)

          // material.color = mixPlayerColorIntoColor(material.color, baseMaterial.color)
          obj2.material = material.clone()
          // obj2.material.color = mixPlayerColorIntoColor(material.color, baseMaterial.color)
          // obj2.material.color = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
          // obj2.material.color = blendColorPreserveLuminance(material.color, baseMaterial.color)
          // obj2.material.color = mixPlayerColorIntoColorByBrightness(material.color, baseMaterial.color)
          obj2.material.color = applyLuminanceFromGreyscale(material.color, baseMaterial.color)
        }
      }
    }

  })
}
