import { A } from "@solidjs/router"
import { FormGroup, Modal } from 'solid-bootstrap'
import { FaSolidPencil } from "solid-icons/fa"
import { Component, For, createEffect, createMemo, createSignal, on } from "solid-js"
import { createMutable, unwrap } from "solid-js/store"
import bindEngineToComponent from "../../lib/canvas/bindEngineToComponentV1"
import createEngineForUI from "../../lib/core/engine/createEngineForUI"
import { Engine } from "../../lib/core/engine/Engine.type"
import createSampleEntity from "../../lib/core/entity/create_sample_entity"
import { EntityTypeId } from "../../lib/core/entity/EntityTypeId.enum"
import entityTypeMetaList from "../../lib/core/entity/entityTypeMetaList.generated"
import { EntityTypeMeta, isSpriteUnit } from "../../lib/core/EntityTypeMeta"
import findById from "../../lib/core/findById"
import findByIdOrThrow from "../../lib/core/findByIdOrThrow"
import { RestrictedUnitRule } from "../../lib/core/restricted_unit_rule"
import { State } from "../../lib/core/state/State.type"
import parseValidIntFromInputEventOrNull from "../../lib/dom/event/parseValidIntFromInputEventOrNull"
import preventDefault from "../../lib/dom/event/preventDefault"
import isBothNumbersSameOrBothNil from "../../lib/ldsh/isBothNumbersSameOrBothNil"
import isNotNil from "../../lib/ldsh/isNotNil"
import { toSlug } from "../../lib/slug"
import { Nullable } from "../../typescript"
import SelectPlayerIdDropDownButton from "../Btn/SelectPlayerIdDropDownButton"
import FormText from "../Form/FormText"
import SingleSpriteIcon from "../MapEditor/SingleSpriteIcon"
import SmallTextMuted from "../Util/SmallTextMuted"

interface Props {
  // session: BackendSessionPayload,
  state: State,
}

// the valid keys for RuleGroups
// type RuleGroupName = "banned" | "limited" | "else"

// const enum RuleGroup {
//   Banned = 0,
//   Limited = 1,
//   Player = 2,
//   Else = 3,
// }

// type RuleGroupBag = [
//   Array<RestrictedUnitRule>,
//   Array<RestrictedUnitRule>,
//   Array<RestrictedUnitRule>,
//   Array<RestrictedUnitRule>,
// ]

// function createRuleGroupBag(): RuleGroupBag {
//   return [[], [], [], []]
// }

function copyOrCreateRestrictedUnitRule(etype_id: EntityTypeId, player_id: Nullable<number>, oldRestrictedUnits: Readonly<Array<RestrictedUnitRule>>): RestrictedUnitRule {
  const r1: RestrictedUnitRule = {
    etype_id,
    player_id: void 0,
    limit: void 0,
    turn: void 0,
  }
  if (player_id) {
    r1.player_id = player_id
  }
  oldRestrictedUnits.forEach(r2 => {
    if (r2.etype_id === etype_id) {
      if (isBothNumbersSameOrBothNil(r2.player_id, player_id)) {
        if (isNotNil(r2.limit)) {
          r1.limit = r2.limit
        }
        if (isNotNil(r2.turn)) {
          r1.turn = r2.turn
        }
      }
    }
  })
  return r1
}

function createExpandedMatrix(state: State, entityTypes: Array<EntityTypeMeta>): Array<RestrictedUnitRule> {
  const data: Array<RestrictedUnitRule> = []
  const oldRestrictedUnits: Readonly<Array<RestrictedUnitRule>> = state.config.restrictedUnits
  const playerIdList: Array<number | null> = state.players.map(p => p.id)
  playerIdList.unshift(null)
  playerIdList.forEach((playerId: number | null) => {
    entityTypes.forEach((entityType: EntityTypeMeta) => {
      const rule = copyOrCreateRestrictedUnitRule(entityType.id, playerId, oldRestrictedUnits)
      data.push(rule)
    })
  })
  return data
}

function createCompactedMatrix(matrix: Array<RestrictedUnitRule>): Array<RestrictedUnitRule> {
  return matrix.filter(rule => isNotNil(rule.limit) || isNotNil(rule.turn))
}

const RestrictedUnitRuleEditor: Component<Props> = (props) => {

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteUnit(sprite))
  const engine: Engine = createMutable(createEngineForUI())

  // non-editing mode will be concise icons
  const [isRuleFormOpen, setIsRuleFormOpen] = createSignal<boolean>(false)
  // const [draftRuleList, setDraftRuleList] = createSignal<RestrictedUnitRule[]>(createExpandedMatrix(props.state, entityTypes))
  const [draftRuleList, setDraftRuleList] = createSignal<RestrictedUnitRule[]>([])

  const [selectedPlayerId, setSelectedPlayerId] = createSignal<number | null>(null)
  const handleOpenRuleForm = (event: Event) => {
    // no idea why double click without this.
    preventDefault(event)
    setIsRuleFormOpen(true)
  }
  const handleCloseRuleForm = (event: Event) => {
    // no idea why double click without this.
    preventDefault(event)
    setIsRuleFormOpen(false)
  }
  createEffect(on(isRuleFormOpen, (isRuleFormOpen: boolean) => {
    if (isRuleFormOpen) {
      setDraftRuleList(createExpandedMatrix(props.state, entityTypes))
    }
  }))
  createEffect(() => {
    engine.state.players = structuredClone(unwrap(props.state.players))
  })

  const handleClickSaveButton = (event: Event) => {
    // no idea why double click without this.
    preventDefault(event)
    props.state.config.restrictedUnits = createCompactedMatrix(draftRuleList())
    setIsRuleFormOpen(false)
  }

  // const ruleGroupsBag = createMemo<RuleGroupBag>(() => {
  //   const rg: RuleGroupBag = createRuleGroupBag()
  //   props.state.config.restrictedUnits.forEach((rule: RestrictedUnitRule) => {
  //     if (rule.player_id) {
  //       rg[RuleGroup.Player].push(rule)
  //     } else if (rule.turn) {
  //       rg[RuleGroup.Else].push(rule)
  //     } else if ((rule.limit as number) > 0) {
  //       rg[RuleGroup.Limited].push(rule)
  //     } else if (rule.limit === 0) {
  //       rg[RuleGroup.Banned].push(rule)
  //     } else {
  //       rg[RuleGroup.Else].push(rule)
  //     }
  //   })
  //   return rg
  // })

  const rulesForSelectedPlayer = createMemo(() => {
    const playerId = selectedPlayerId()
    return draftRuleList().filter((rule: RestrictedUnitRule) => {
      return isBothNumbersSameOrBothNil(rule.player_id, playerId)
    })
  })

  // need sample player for UI engine
  const samplePlayerId = createMemo<number | null>(() => {
    // return engine.state.players[0].id
    const playerId = selectedPlayerId()
    return findById(engine.state.players, playerId)?.id || null
  })

  bindEngineToComponent(engine)

  return <FormGroup>
    <label class="form-label">Restricted Units{' '}
      {SmallTextMuted(<>(Rules: {props.state.config.restrictedUnits.length})</>)}
      {' '}
      <button class="btn btn-secondary btn-sm" onClick={handleOpenRuleForm}>
        <FaSolidPencil /> Edit
      </button>
    </label>
    <Modal show={isRuleFormOpen()} onHide={handleCloseRuleForm} scrollable>
      <Modal.Header>Add Restricted Unit Rule</Modal.Header>
      <Modal.Body>
        <FormText>
          <label>Limit:</label> Max units of this type.
        </FormText>
        <FormText>
          <label>Delay:</label> Players must wait this many days before building this unit.
        </FormText>
        <SelectPlayerIdDropDownButton
          value={selectedPlayerId()}
          onChange={setSelectedPlayerId}
          players={props.state.players}
        />
        <table class="table table-sm">
          <thead>
            <tr>
              <td />{/* for icon */}
              <td>Unit</td>
              <td class="text-end">
                Limit
              </td>
              <td class="text-end">
                Delay
              </td>
            </tr>
          </thead>
          <tbody>
            <For each={rulesForSelectedPlayer()} fallback={<div class="text-muted">Empty List</div>}>
              {(rule: RestrictedUnitRule) => {
                const entityType: EntityTypeMeta = findByIdOrThrow(entityTypeMetaList, rule.etype_id)
                return <tr>
                  <td>
                    <SingleSpriteIcon
                      entity={createSampleEntity(entityType, samplePlayerId())}
                    />
                  </td>
                  <td>
                    <A href={`/codex/unit/${toSlug(entityType.dname)}`} tabIndex={-1}>
                      {entityType.dname}
                    </A>
                  </td>
                  <td class="text-end">
                    <input name="limit" type="number" class="w-100 text-end" value={rule.limit}
                      onInput={(event: InputEvent) => {
                        const newValue = parseValidIntFromInputEventOrNull(event)
                        setDraftRuleList((list) => {
                          rule.limit = newValue as (typeof rule.limit)
                          return [...list]
                        })
                      }}
                      placeholder="no limit" min={0} max={99} />
                  </td>
                  <td class="text-end">
                    <input name="turns" type="number" class="w-100 text-end" value={rule.turn}
                      onInput={(event: InputEvent) => {
                        const newValue = parseValidIntFromInputEventOrNull(event)
                        setDraftRuleList((list) => {
                          rule.turn = newValue as (typeof rule.turn)
                          return [...list]
                        })
                      }}
                      placeholder="days" min={0} max={99} />
                  </td>
                </tr>
              }}
            </For>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button class="btn btn-secondary" onClick={handleCloseRuleForm}>Cancel</button>
        <button class="btn btn-primary" onClick={handleClickSaveButton}>Save New Rule</button>
      </Modal.Footer>
    </Modal>
    {/* <div>
      <label>Banned: {ruleGroupsBag()[RuleGroup.Banned].length}</label>{' '}
      <label>Limited: {ruleGroupsBag()[RuleGroup.Limited].length}</label>{' '}
      <label>Delays: {ruleGroupsBag()[RuleGroup.Banned].length}</label>{' '}
      <label>Player: {ruleGroupsBag()[RuleGroup.Player].length}</label>
    </div> */}
  </FormGroup>
}

export default RestrictedUnitRuleEditor
