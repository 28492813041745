import { deepClone } from "../../deep_clone"
import type { Engine } from "../engine/Engine.type"
import findUnloadableCargoTileCollection, { type UnloadableCargoTileGroup } from "../tile_position_xy/findUnloadableCargoTileCollection"

export default function setOrRotateDraftMoveUnloadableCargoPath(engine: Engine): void {
  console.log('setOrRotateDraftMoveUnloadableCargoPath', deepClone({draftMove: engine.draftMove}))
  const { draftMove } = engine
  if (draftMove) {
    const oldGroup = draftMove.unload
    const groupList : Array<UnloadableCargoTileGroup> = findUnloadableCargoTileCollection(engine, draftMove)

    if (oldGroup) {
      for (let index = 0; index < groupList.length; index++) {
        const group : UnloadableCargoTileGroup = groupList[index]
        if (oldGroup.cargo_id === group.cargo_id) {
          draftMove.unload = groupList[index + 1] || groupList[0]
          return
        }
      }
    } else {
      draftMove.unload = groupList[0]
    }
  }
}