import { type Group, type Object3D } from "three"
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader.js"
import type { RoadEntity } from "../../core/entity/EntityType/Road"
import { RoadKind } from "../../core/RoadKind.enum"
import { PI } from "../../core/util/math"
import PavedRoadCorner from '../models/Road/Paved/PavedRoadCorner1.gltf?url'
import PavedRoadFourWay from '../models/Road/Paved/PavedRoadFourWay1.gltf?url'
import PavedRoadStraight from '../models/Road/Paved/PavedRoadStraight1.gltf?url'
import PavedRoadThreeWay from '../models/Road/Paved/PavedRoadThreeWay1.gltf?url'
import { appGltfLoader } from "./appGltfLoader"
import onGltfLoaderError from "./onGltfLoaderError"
import applyEntityRotationToNextObject3DFrame from "../../core/entity/EntityHeading/applyEntityRotationToNextObject3DFrame"

const roadModelMap: Record<RoadKind, string> = {
  [RoadKind.FourWay]: PavedRoadFourWay,
  [RoadKind.ThreeWay]: PavedRoadThreeWay,
  [RoadKind.Corner]: PavedRoadCorner,
  [RoadKind.Straight]: PavedRoadStraight,
}

export default function makeRoadMeshFromRoadEntity(
  ent: RoadEntity,
  callback: (newGroup: Object3D) => void
): void {
  const modelUrl = roadModelMap[ent.road]
  if (!modelUrl) {
    throw new Error("Unhandled road kind")
  }
  
  loadRoadModel(modelUrl, ent.road, callback)
}

function loadRoadModel(modelUrl: string, roadKind: RoadKind, callback: (newGroup: Object3D) => void): void {
  appGltfLoader.load(
    modelUrl,
    function (ModelObj) {
      const group = getGroup(ModelObj)
      if (roadKind !== RoadKind.Straight) {
        group.rotateY(PI / 2)
      }
      group.userData.update = function (elapsedMs: number) {
        applyEntityRotationToNextObject3DFrame(group, elapsedMs)
      }
      callback(group)
    },
    undefined,
    onGltfLoaderError
  )
}

function getGroup(ModelObj: GLTF): Group {
  const group = ModelObj.scene.getObjectByName('Road') as Group
  if (!group) {
    throw new Error("!group")
  }
  return group
}
