import { Material, Texture } from "three"

type HasTextures = Material & Record<string, Texture>

export default function disposeMaterialTextures(
  material: Material
): void {
  for (const key in material) {
    const value = (material as HasTextures)[key]
    // console.log(key, 'value', value)
    if (value instanceof Texture) {
      value.dispose()
    }
  }
}